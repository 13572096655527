import {getUUID4} from './base.function';

export interface IBaseObject {
  action: string;
  obj: string;
  action_id: string;
  limit?: number;
  offset?: number;
  sort?: string;
}

export interface IRequestObject extends IBaseObject {
    params: any;
    filter?: any;
    sort?: any;
}

export interface IResponseListObject<T> extends IResponseObject {
  list: Array<T>;
  total_count: number;
}

export interface IResponseBodyObject<T> extends IResponseObject {
  body: T;
}

export interface IResponseObject extends IBaseObject {
  code: number;
  text: string;
  errors?: any;
  error?: string;
  notifies?: any[];
}

export interface ISelect {
  id: number | string;
  name: string;
}

export interface ISelectType {
  id: number | string;
  name: string;
  type: number | string;
}

export class Helper {
  static requestFormat(action: string, obj: string, params: any = {}, canParse = true) {
    const request = {};
    if (canParse) {
      if ('limit' in params) { request['limit'] = params.limit; delete params.limit; }
      if ('offset' in params) { request['offset'] = params.offset; delete params.offset; }
      if ('sort' in params) { request['sort'] = params.sort; delete params.sort; }
      if ('filter' in params) { request['filter'] = params.filter; delete params.filter; }
    //  if ('folder' in params) { request['folder'] = params.folder; delete params.folder; }
    //  if ('with_files' in params) { request['with_files'] = params.with_files; delete params.with_files; }
    }

    const req: IRequestObject = {
      action: action,
      obj: obj,
      action_id: getUUID4(4),
      params: params || {},
      ...request
    };
    return req;
  }
}


export interface DialogBoxMessageButtonObject {
  id?: string; // id кнопки
  type?: string; // тип кнопки: mat-icon-button, mat-raised-button, ...
  color?: string; // цвет кнопки: primary, warn, danger
  classNames?: string; // классы кнопки
  styles?: string; // стили кнопки
  matTooltip?: string; // всплывающий текст при наведении курсора на кнопку
  html?: string; // текст на кнопке

  icon?: string; // иконка на кнопке или иконка кнопки, если тип кнопки mat-icon-button
  iconColor?: string; // цвет иконка: primary, warn, danger
  iconClassNames?: string; // классы иконки
  iconStyles?: string; // стили иконки

  routerLink?: string|any[]; // раздел, куда нужно перейти при клике по кнопке
  queryParams?: any; // параметры при переходе в другой раздел

  cb?: (...args: any[]) => void; // функция, вызываемая при клике по кнопке
  cbContext?: any; // контекст функции, передаваемый в нее, при клике по кнопке
  cbParams?: any[]; // параметры функции, передаваемые в нее, при клике по кнопке
}

export interface DialogBoxMessageObject {
  id?: string; // идентификатор уведоммления (устанавливается автоматически)
  type?: string; // message|warning|error
  objType?: string; // тип объекта, о котором всплывает уведомление: call - входящий вызов; crm_task - уведомление о выполнении задачи по времени
  hidden?: boolean; // отображать уведомление внизу справа (false), как все, или отображать в уведомлениях заголовка кабинета (true)
  date?: string|number; // дата и время уведомления
  timeout?: number; // время отображения уведомления
  icon?: string; // иконка уведомления
  header: string; // заголовок уведомления
  text?: string; // текст уведомления
  html?: string; // html-разметка в уведомлении
  buttons?: DialogBoxMessageButtonObject[]; // кнопки уведомления
}



