import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Helper, IResponseListObject, IResponseBodyObject} from '../_helpers/api.helpers';
import {iif, Observable, of as observableOf, throwError} from 'rxjs';
import {catchError, delay, map, retryWhen, take, tap, concatMap} from 'rxjs/operators';
import {NotifyService} from '../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../auth/authentication.service';
import {ROLE_TYPE_CUSTOM, ROLE_TYPE_DOMAIN, ROLE_TYPE_PROVIDER} from './constant';
import {JsonAppConfigService} from '../config/json-app-config.service';
import {downloadBlobObject, getFileName} from './base.function';
const headers = new HttpHeaders().set('Content-Type', 'application/json');


@Injectable({
  providedIn: 'root'
})
export class BaseReportService {
  constructor(public http: HttpClient,
              public notifyService: NotifyService,
              public router: Router,
              public route: ActivatedRoute,
              public authenticationService: AuthenticationService,
              public AppConfig: JsonAppConfigService) {
  }

  public actions = {
    sync: false,
    xlsx: false,
    file: false,
    download: false,
    download_ivr_agg_s2_xlsx: false,
    download_ivr_agg_s2_csv: false,
    download_csv: false,
    download_xlsx: false,
    report_1_download_xlsx: false,
    export: false
  };

  getUser() {
    return this.authenticationService.currentUserValue;
  }

  getDomain() {
    return this.authenticationService.currentUserValue?.domain_id;
  }

  getDomainName() {
    return this.authenticationService.currentUserValue?.domain_name
  }

  getDomainMode() {
    return this.authenticationService.currentUserValue?.domain_mode
  }

  isSystemView() {
    return this.isRoleProvider() && !this.getDomain();
  }

  isDomainView() {
    return this.getDomain();
  }

  isRoleProvider() {
    return Object.values(ROLE_TYPE_PROVIDER).includes(this.authenticationService.currentUserValue?.role_type);
  }

  isRoleCustom() {
    return Object.values(ROLE_TYPE_CUSTOM).includes(this.authenticationService.currentUserValue?.role_type);
  }

  isRoleOperator() {
    return this.authenticationService.currentUserValue?.role_type===4 &&
      this.authenticationService.currentUserValue?.role_perms?.User?.includes(14); //self
  }


  get_fields(object='DomainReportCallsHistory', action='get_fields', params: any = {}) {

  }

  downloadReport(object='DomainReportCallsHistory', params: any = {}, action='download', name=null, ext='xlsx') {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, object, params);

    this.authenticationService.is_token_alive().subscribe(() => {
      return this.http.post<Blob>(this.AppConfig.getValue('apiUrl'), requestBody,
        {headers: headers, observe: 'response', responseType: 'blob' as 'json'})
        .pipe(tap(res => {
            if (res.status == 200) {
              setTimeout(() => {
                this.actions[action] = false;
                this.notifyService.message('NOTIFY.200');
              }, 1500);
            }
          }),
          catchError(error => {
            this.actions[action] = false;
            return observableOf(null);
          } ))
        .subscribe(
          (response: HttpResponse<Blob>) => {
            console.log(response);
            let filename: string = getFileName(response) || `${name}.${ext}`;
            downloadBlobObject(response, filename);
          })
    });
  }


  report(object='DomainReportCallsHistory', params: any = {}, action='get'): Observable<any> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }

    const requestBody = Helper.requestFormat(action, object, params);
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          // просто вывожу сообщение, что не удалось получить данные, когда не указано по какой причине
          // @ts-ignore
          if (res.code == 500 && 'notifies' in res && res.notifies.length == 0) {
            this.notifyService.message('BILLING.GETTING_STORAGE_DATA_FAILED');
            throw res;
          } else this.notifyService.checkCode(res);
        }),
        retryWhen(errors =>
          errors.pipe(// delay(3000), take(3)
            concatMap((e, i) =>
              // Executes a conditional Observable depending on the result
              // of the first argument
              iif(
                () => {return (i == 2) || e.message == '403'},
                // If the condition is true we throw the error (the last error)
                throwError(e),
                // Otherwise we pipe this back into our stream and delay the retry
                observableOf(e).pipe(delay(3000))
              ))
          ))
      );
  }

  save(obj= {}, action_append: string = 'append', action_update: string = 'update')  {
    if (this.authenticationService.currentUserValue?.domain_id) {
      obj['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }

    if (this.authenticationService.currentUserValue?.user_id) {
      obj['user_id'] = this.authenticationService.currentUserValue.user_id;
    }

    let requestBody = Helper.requestFormat(action_append, 'DomainReportPreset', obj);
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty('id') && obj['id']) {
      requestBody = Helper.requestFormat(action_update, 'DomainReportPreset', obj);
    }
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        }),
        map(data => data.body)
      );
  }

  deleteFilter(id: number) {
    if (typeof id === 'string') id = parseInt(id, 10);
    const obj = {id: id};
    if (this.authenticationService.currentUserValue?.domain_id) {
      obj['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat('delete', 'DomainReportPreset', obj);
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(tap(res => {
          this.notifyService.checkCode(res);
        }),
        map(data => data.body),
        catchError(() => observableOf({})));
  }

  getFilter(params={}) {

    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    if (this.authenticationService.currentUserValue?.user_id) {
      params['user_id'] = this.authenticationService.currentUserValue.user_id;
    }
    const requestBody = Helper.requestFormat('list', 'DomainReportPreset', params);
    return this.http.post<IResponseListObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        }),
        map(data => data.list),
        retryWhen(errors => errors.pipe(delay(3000), take(3))),
        catchError((error) => observableOf([]))
      );

  }

  getFilterWithCount(params={}) {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    if (this.authenticationService.currentUserValue?.user_id) {
      params['user_id'] = this.authenticationService.currentUserValue.user_id;
    }
    const requestBody = Helper.requestFormat('list', 'DomainReportPreset', params);
    return this.http.post<IResponseListObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers}).pipe(
      tap(res => {
        this.notifyService.checkCode(res);
      })
    );
  }

  customFilter(id, start_dt, end_dt) {
    let params = {
      field: id,
      start_dt: start_dt,
      end_dt: end_dt
    };

    const allow_filter = ['gw_number_list', 'pbx_user_list', 'ext_phone_list', 'queue_list',
      'project_list', 'group_list', 'dpe_list', 'gw_name_list', 'phrase_group_list', 'org_list']

    if (!allow_filter.includes(id)) return observableOf([])

    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }

    const requestBody = Helper.requestFormat('get', 'DomainReportFilter', params);
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        }),
        map(data => data.body),
        catchError((error) => observableOf([]))
      );

  }

  escapeFileName(name) {
    if (name) {
      name = name.replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g,'_');
    }
    return name;
  }

  downloadFile(route: string, name: string = null): void {
    this.http.get<Blob>(route,{observe: 'response', responseType: 'blob' as 'json'})
      .subscribe(
      (response: HttpResponse<Blob>) =>{
        let filename: string = getFileName(response) || `${name}`;
        downloadBlobObject(response, filename);
      }
    )
  }

  setLocalStorage(value: Event, reportName: string): void {
    localStorage.setItem(`${reportName}::displayedColumns`, JSON.stringify(value));
  }

}
