import {enableProdMode, NgModule, TRANSLATIONS} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';
import {AppModule} from './app/app.module';
import { environment } from './environments/environment';
import {AppComponent} from './app/app.component';
import {RouterModule} from '@angular/router';
import {AppRoutes} from './app/app.routing';
import {
  MenuInject,
  APP_MENU_DOMAIN_SETTINGS,
  APP_MENU_MODULES,
  APP_MENU_MARKETPLACE,
  PROJECT
} from './app/app.injection';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {DesktopKeptModule} from './app/__PROJECTS__/kept/desktop/desktop-kept.module';
import {DesktopDefModule} from "./app/__PROJECTS__/def/desktop/desktop-def.module";

let routers = AppRoutes;
let langs = [{prefix: './assets/locale/', suffix: '.json'}];

let MenuInjectModules: MenuInject = {
  router: [],
  menu: []
};

let MenuDomainSettingsModules: MenuInject = {
  router: [],
  menu: []
};

let MenuMarketplaceModules: MenuInject = {
  router: [],
  menu: []
};

if (environment?.client == 'KEPT') {
  MenuInjectModules = DesktopKeptModule.menu();
  MenuDomainSettingsModules = DesktopKeptModule.menu_domain_settings();
  MenuMarketplaceModules = DesktopKeptModule.menu_marketplace();
  langs.push(DesktopKeptModule.getTranslateFile());
}  else {
  MenuInjectModules = DesktopDefModule.menu();
  MenuDomainSettingsModules = DesktopDefModule.menu_domain_settings();
  MenuMarketplaceModules = DesktopDefModule.menu_marketplace();
  langs.push(DesktopDefModule.getTranslateFile());
}

MenuInjectModules.router.forEach(item=> routers?.find(i=>i.path=='')?.children?.push(item));

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new MultiTranslateHttpLoader(http, langs);
}


/**
 * Root module.
 * Iniate global providers, and import all modules
 *
 * @export
 * @class Root
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [

  ],
  imports: [
    AppModule,
    RouterModule.forRoot(routers, { relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      // missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationService },
      useDefaultLang: false,
    }),

  ],
  exports: [
  ],
  providers: [
    { provide: APP_MENU_MODULES, useValue: MenuInjectModules },
    { provide: APP_MENU_DOMAIN_SETTINGS, useValue: MenuDomainSettingsModules },
    { provide: APP_MENU_MARKETPLACE, useValue: MenuMarketplaceModules },
    { provide: PROJECT, useValue: 'rt-v2' },
  ],
})
export class Root {


}


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(Root)
  .catch(err => console.log('%cerror on loading app:', 'font-weight: 700; font-size: 14px;', err));

