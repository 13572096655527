import {Injectable} from "@angular/core";
import {NgSelectComponent} from "@ng-select/ng-select";

@Injectable({
  providedIn: 'root'
})

export class NgSelectScrollService {

  constructor() {}

  onScroll(event: any, selects) {
    selects?.forEach(select => {
      if (select && select.isOpen) {
        const isScrollingInScrollHost = (event.target.className as string).indexOf('ng-dropdown-panel-items') > -1;
        if (isScrollingInScrollHost) { return; }
        select.close();
      }
    })
  }
}
