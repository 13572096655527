export class User {
    user_id: number;
    user_name: string;
    user_login: string;
    user_email: string;
    verto_host_port: string;
    base_verto_host_port: string;
    role_type: number;
    domain_name: string|null;
    domain_mode: number|null;
    domain_id: number|null;
    token?: string;
    tariff_option_list?: any[] | null;
    tz: string;
    role_perms: any = {};
    role_visible: any = {};
    role_filters: any = {};
    domain_billing_options = {
      block_face: false,
      balance: 0
    };
    billing_id: string | null;
    domain_test_until: string | null;
}
