import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../../../../app.service';
import {DesktopColumns} from '../../desktop-columns';
import {AGENT_STATUS_LIST} from '../../../../_helpers/constant';
import {AuthenticationService} from '../../../../auth/authentication.service';
import {DomainAgentStatusService} from '../../../../domains/domain-agent-status/domain-agent-status.service';
import {Subscription} from 'rxjs';
import {DesktopService} from '../../base-desktop.service';
import {PerfectScrollbarConfigInterface} from "ngx-perfect-scrollbar";
import {CTIService} from "../../../../cti-panel/cti-panel.service";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {delay} from "rxjs/operators";


@Component({
  selector: 'app-desktop-local-call',
  templateUrl: './desktop-local-call.component.html',
  styleUrls: ['./desktop-local-call.component.scss',
    '../../desktop-table.scss']
})
export class DesktopLocalCallComponent implements OnDestroy, OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @Input() columns: DesktopColumns[];
  @Input() change = new EventEmitter();
  public status_system = AGENT_STATUS_LIST;
  private subscription: Subscription = new Subscription();
  customAgentStatus = [];
  displayedColumns: string[] = [];
  displayedColFilter: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  resultsLength = 0;
  last_call_abonent = null;
  isLoadingResults = true;
  isRateLimitReached = false;
  extParams = {};
  empFilters = [];
  public config: PerfectScrollbarConfigInterface = {
    minScrollbarLength: 25,
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
    wheelSpeed: 2,
    wheelPropagation: true
  };

  constructor(public authenticationService: AuthenticationService,
              public appService: AppService,
              public CTI: CTIService,
              public desktopService: DesktopService,
              public agentStatus: DomainAgentStatusService) {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe((val) => {
      this.sortData(val);
    });
  }

  ngOnInit() {
    this.displayedColumns = this.columns.filter(item=>item.checked).map(item=>item.id);
    this.displayedColFilter = this.columns.filter(item=>item.checked)
      .map(item=>`${item.id}_${item.filterType || 'empty'}`);
    let subMenu$ = this.desktopService.changeMenu.subscribe(menu => {
      if (menu.name == 'DesktopLocalCallComponent') {
        this.displayedColumns = menu.columns.filter(item=>item!='abonent');
        this.displayedColFilter = this.columns.filter(item=>menu.columns.includes(item.id))
          .map(item=>`${item.id}_${item.filterType || 'empty'}`);
      }
    });

    this.CTI.ctiCalls$.pipe(delay(600)).subscribe(()=>{
      this.dataSource.data = this.CTI.last_calls_for_report;
      this.resultsLength = this.CTI.last_calls_for_report.length;
    })

    this.CTI.get_last_calls_report();

    this.dataSource.data = this.CTI.last_calls_for_report;
    this.resultsLength = this.CTI.last_calls_for_report.length;
    this.subscription.add(subMenu$);
  }

  sortData(sort: Sort) {
    this.dataSource.sort = new MatSort();
    this.dataSource.sort.sort({id: null, start: 'asc', disableClear: true});

    if (sort.active && sort.direction !== '') {
      this.dataSource.sort.sort({id: sort.active, start: sort.direction, disableClear: true});
    }
  }

  getStatus(id) {
    const stateArray = this.status_system.concat(this.customAgentStatus).filter(elem => elem.id == id);
    return (stateArray.length > 0) ? stateArray[0].name : '-';
  }

  getCallDirection(direction) {
    return (direction == 1 ? 'INBOUND_CALL' : 'OUTBOUND_CALL');
  }

  getTransferStatus(status) {
    // 0 - попытка, 1-active, 2-answer, 3-слепой соединил, 4-соединил не слепой, 5-отменил
    switch (status) {
      case 0:
        return 'TRANSFER_0';
      case 1:
        return 'TRANSFER_1';
      case 2:
        return 'TRANSFER_2';
      case 3:
        return 'TRANSFER_3';
      case 4:
        return 'TRANSFER_4';
      case 5:
        return 'TRANSFER_5';
    }
  }
}
