import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {of as observableOf, throwError} from 'rxjs';
import {DomainDialogScriptFormService} from '../forms/domain-dialog-script-form.service';
import {DomainDialogScriptService} from './domain-dialog-script.service';
import {catchError, map, concatMap} from "rxjs/operators";
import {DomainDialogScriptResultService} from "./domain-dialog-script-result.service";
import {DomainDialogScriptFormResultService} from "../forms/domain-dialog-script-form-result.service";
import {NotifyService} from "../../../_helpers/notify.service";

@Component({
  selector: 'app-domain-dialog-script-result',
  templateUrl: './domain-dialog-script-result.component.html',
  styleUrls: []
})
export class DomainDialogScriptResultComponent implements OnInit {
  @Input() script_id: string;
  @Input() script_ver: number;
  @Input() call_uuid: string = null;
  @Input() dialog_id: string = null;
  @Input() event_id: number = null;
  @Output() onEndDialogScript = new EventEmitter();

  public dialogScript: {id: string, ver: number, call_uuid: string, dialog_id: string, event_id: number, script_result: any} = null;
  public dialogScriptLoading: boolean = false;
  public dialogScriptStep: any = null;
  public isServerAvailable: boolean = true;

  constructor(
    public notifyService: NotifyService,
    private api: DomainDialogScriptResultService,
    private domainDialogScriptService: DomainDialogScriptService,
    private domainDialogScriptFormService: DomainDialogScriptFormService,
    private domainDialogScriptFormResultService: DomainDialogScriptFormResultService
  ) {
  }

  ngOnInit() {
    this.dialogScript = {id: this.script_id, ver: this.script_ver, call_uuid: this.call_uuid, dialog_id: this.dialog_id, event_id: this.event_id, script_result: null};

    this.showDialogScript();
  }

  sortConditions(conditions: any = {}){
    return Object.keys(conditions).sort();
  }

  hasPrevStep(){
    return this.dialogScriptStep.parent;
  }

  hasNextStep() {
    return (
      // this.dialogScriptStep?.params?.formResultData &&
      (
        (this.dialogScriptStep.leg?.id && this.dialogScriptStep.leg?.model_name != 'DDSESelect') ||
        (
          this.dialogScriptStep.legs?.[this.dialogScriptStep?.params?.formResultData]?.leg?.id &&
          this.dialogScriptStep.legs?.[this.dialogScriptStep?.params?.formResultData]?.leg?.model_name != 'DDSESelect'
        ) ||
        (
          this.dialogScriptStep.default_leg?.leg?.id &&
          this.dialogScriptStep.default_leg?.leg?.id == this.dialogScriptStep?.params?.formResultData &&
          this.dialogScriptStep.default_leg?.leg?.model_name != 'DDSESelect'
        )
      )
    );
  }

  getOrCreateScriptResult(step, result_value) {
    return this.api.list({
      filter: {field_list: [
          {field: 'call_id', value: this.dialogScript.call_uuid, condition_type: 0},
          {field: 'dialog_id', value: this.dialogScript.dialog_id, condition_type: 0},
          {field: 'event_id', value: this.dialogScript.event_id, condition_type: 0},
          {field: 'script_id', value: this.dialogScript.id, condition_type: 0},
          {field: 'script_ver', value: this.dialogScript.ver, condition_type: 0},
        ], type: 0},
      limit: 1
    }).pipe(
      catchError(resp => resp.notifies?.[0]['msg_id'] == 10002 ? observableOf(null) : throwError(resp)),
      concatMap(script_dialog => {
        return (
          !script_dialog?.list?.length ?
            this.api.save({
              call_id: this.dialogScript.call_uuid,
              dialog_id: this.dialogScript.dialog_id,
              event_id: this.dialogScript.event_id,
              script_id: this.dialogScript.id,
              script_ver: this.dialogScript.ver,
              owner_id: this.api.getUser().user_id,
              // @ts-ignore
              body: result_value != null ? [{id: step.id, value: result_value}] : []
            }).pipe(
              map(created_script => created_script.body)
            ):
            observableOf(script_dialog.list[0])
        ).pipe(
          map(script_result => {
            if (script_result) this.dialogScript.script_result = script_result;
            return script_result;
          })
        )
      })
    )
  }

  getDialogScriptStep(control, direction: number) {
    let new_control = null;

    if (direction == 0) { // перейти на предыдущий шаг скрипта
      new_control = control.parent;
    } else { // перейти на следующий шаг скрипта
      if (control?.body) {
        new_control = control.body.leg;
        new_control.parent = null;
      } else {
        if (control.leg?.id != null) {
          new_control = control.leg;
          new_control.parent = control;
        } else if (control.params.formResultData) {
          if (control?.legs?.[control.params.formResultData]?.leg?.id) new_control = control.legs[control.params.formResultData].leg;
          else if (control?.legs?.[control.params.formResultData]?.default_leg?.leg?.id) new_control = control.legs[control.params.formResultData].default_leg.leg;
          else if (control?.default_leg?.leg?.id == control.params.formResultData) new_control = control.default_leg.leg;
          if (new_control) new_control.parent = control;
        }
      }
    }

    if (new_control && new_control.model_name != 'DDSESelect') {
      if (new_control?.['model_name'] == 'DDSEForm' && typeof new_control['params']['formResultData'] == 'number') {
        this.dialogScriptLoading = true;

        this.domainDialogScriptFormResultService.get(new_control['params']['formResultData'], {__ignore__: {10002: true}}).subscribe(
          form_result_data => {
            if (form_result_data) new_control['params']['formResultData'] = form_result_data;
            this.dialogScriptStep = null;
            setTimeout(() => this.dialogScriptStep = new_control, 50);
            this.dialogScriptLoading = false;
          },
          resp => {
            this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502;
            this.dialogScriptLoading = false;
          }
        );
      } else {
        if (this.dialogScript.script_result.body.find(item => item.id == new_control.id)?.value == null || new_control?.['model_name'] != 'DDSEForm') {
          new_control.params.formResultData = this.dialogScript.script_result.body.find(item => item.id == new_control.id)?.value;
        }
        this.dialogScriptStep = null;
        setTimeout(() => this.dialogScriptStep = new_control, 50);
        this.dialogScriptLoading = false;
      }
    } else this.dialogScriptLoading = false;
  }

  getDialogScriptStepById(control, id) {
    let child_control = null;
    if (control?.body && control?.body.leg?.id == id) {
      child_control = control.body.leg;
    } else {
      if (control.leg && control.leg.id == id) child_control = control.leg;
      else if (control.legs && control.legs[id] != null) child_control = control.legs[id].leg;
      else if (control.default_leg.leg && control.default_leg.leg.id == id) child_control = control.default_leg.leg;
      if (child_control) child_control.parent = control;
    }

    return child_control;
  }

  updateScriptResult(data) {
    // Сохраняю в результаты диалога результат шага:
    // - форма сохранит результат в своем компоненте и пришлет результат. Если она была первым шагом, то она создаст результат скрипта и передаст его сюда
    if (!this.dialogScript.script_result?.id && data.script_result?.id) this.dialogScript.script_result = data.script_result;
    (!this.dialogScript?.script_result?.id ?
        this.getOrCreateScriptResult(this.dialogScriptStep, data.step_result) :
        observableOf(this.dialogScript.script_result)
    ).subscribe(
      script_result => {
        if (this.dialogScript?.script_result?.id) {
          let resultItemInd = this.dialogScript.script_result.body.findIndex(r => r.id == this.dialogScriptStep.id);
          if (resultItemInd != -1) {
            this.dialogScript.script_result.body.splice(
              resultItemInd,
              this.dialogScript.script_result.body.length,
              {id: this.dialogScriptStep.id, value: data.step_result}
            );
          } else this.dialogScript.script_result.body.push({id: this.dialogScriptStep.id, value: data.step_result});

          this.dialogScriptStep.params.formResultData = data.step_form_result || data.step_result;

          this.api.save({
            id: this.dialogScript.script_result.id,
            call_id: this.dialogScript.call_uuid,
            dialog_id: this.dialogScript.dialog_id,
            event_id: this.dialogScript.event_id,
            script_id: this.dialogScript.id,
            script_ver: this.dialogScript.ver,
            owner_id: this.api.getUser().user_id,
            body: this.dialogScript.script_result.body
          }).subscribe(
            () => {
              if (!('need_get_next_step' in data) || data.need_get_next_step) this.getDialogScriptStep(this.dialogScriptStep, 1);
            },
            resp => {
              this.notifyService.setFormErrors(resp) != 502;
            }
          );
        } else {
          this.notifyService.message('DOMAINDIALOGSCRIPTRESULT.GET_FAILED');
        }
      },
      resp => this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502
    );
  }

  showDialogScript() {
    if (this.dialogScript.id) {
      this.dialogScriptLoading = true;
      this.domainDialogScriptService.get(null, {...this.dialogScript, __ignore__: {10002: true}}).subscribe( // маршрут скрипта
        script_data => {
          // проверяю, что скрипт еще не начат, а если начат, то продолжаю с шага, идущего после последнего
          (
            !this.dialogScript?.script_result?.id ?
              this.getOrCreateScriptResult(null, null) :
              observableOf(this.dialogScript.script_result)
          ).subscribe(
            script_result => {
              if (this.dialogScript?.script_result?.id) {
                if (this.dialogScript.script_result.body.length > 0) {
                  let prev_step = script_data;
                  for (let item of this.dialogScript.script_result.body) {
                    if (prev_step?.['model_name'] == 'DDSEMenu') {
                      prev_step = this.getDialogScriptStepById(prev_step, prev_step['params'].formResultData);
                    } else {
                      prev_step = this.getDialogScriptStepById(prev_step, item.id);
                    }
                    prev_step['params']['formResultData'] = item.value;
                  }

                  this.getDialogScriptStep(prev_step,1);

                  if (prev_step?.['model_name'] == 'DDSEForm' && typeof prev_step['params']['formResultData'] == 'number') {
                    this.domainDialogScriptFormResultService.get(prev_step['params']['formResultData'], {__ignore__: {10002: true}}).subscribe(
                      form_result_data => {
                        if (form_result_data) prev_step['params']['formResultData'] = form_result_data;
                        this.dialogScriptStep = null;
                        setTimeout(() => this.dialogScriptStep = prev_step, 50);
                        this.dialogScriptLoading = false;
                      },
                      resp => {
                        this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502;
                        this.dialogScriptLoading = false;
                      }
                    );
                  } else {
                    this.dialogScriptStep = null;
                    setTimeout(() => this.dialogScriptStep = prev_step, 50);
                    this.dialogScriptLoading = false;
                  }
                } else {
                  this.getDialogScriptStep(script_data, 1); // получаю первый его элемент
                }
              } else {
                this.notifyService.message('DOMAINDIALOGSCRIPTRESULT.GET_FAILED');
                this.dialogScriptLoading = false;
              }
            },
            resp => {
              this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502;
              this.dialogScriptLoading = false;
            }
          );
        },
        resp => {
          this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502;
          this.dialogScriptLoading = false;
        }
      );
    }
  }

  endDialogScript(reason: number|null = null) {
    this.dialogScript = null;
    this.dialogScriptStep = null;
    this.onEndDialogScript.emit(reason);
  }
}
