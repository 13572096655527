/* Interface to JSON RPC Client constructor */

import {generateGUID} from "./verto-lab";

interface JsonRpcClientParams {
  socketUrl   : string
  ajaxUrl?    : string
  onmessage?    : {(event:Object):void}
  login     : string
  passwd      : string
  sessid?     : string
  loginParams?  : Object
  userVariables?  : Object
}

/* Interface to JSON RPC requests */

interface JsonRpcRequest {
  id    : number
  jsonrpc : string
  method  : string
  params  : Object
}

/* Interface to JSON RPC replies */

interface JsonRpcReply {
  id      : number
  jsonrpc   : string
  method?   : string
  params?   : JsonRpcParams
  result?   : any
  error?    : {code?:number}
}

/* Interface to JSON RPC calls */

interface JsonRpcCall {
  request: JsonRpcRequest
  success_cb?: {(data: Object): void}
  error_cb?: {(data: Object): void}
}

interface JsonRpcParams {
  callID        : string
  sdp?        : string
  eventChannel?        : string
  caller_id_number  : string
  caller_id_name    : string
  callee_id_number  : string
  callee_id_name    : string

}

/* JSON RPC Client */

class JsonRpcClient {
  public rpc_id: string = generateGUID();
  options: JsonRpcClientParams
  private request_id: number = 1
  private socket_: WebSocket
  private queue: Array<string> = []
  private callbacks: Array<JsonRpcCall> = []
  private eventHandler: {(method:string, params:JsonRpcParams):void}
  private reconnectHandler: {():void}
  private debug: boolean = false

  private initSocket_(){
    this.socket_ = new WebSocket(this.options.socketUrl)
    console.log('%ccreated socket #' + this.rpc_id + ':', 'color: pink; font-weight: 700;', this.socket_);
    this.socket_.onmessage = this.onMessage.bind(this)
    // TODO: Implement auto reconnect attepts
    this.socket_.onclose = () => {
      setTimeout(() => {
        this.initSocket_()
      }, 1000)
    }
    this.socket_.onopen = () => {
      let req: string = ''
      if(this.reconnectHandler) this.reconnectHandler()

      while(req = this.queue.pop()){
        console.log(this.rpc_id);
        this.socket.send(req)
      }
    }
  }

    private closeSocket_(){
        this.socket_.onclose = () => {};
        this.socket_.onmessage = () => {};
        this.socket_.close();
        this.socket_ = null;
    }

  private get socket(): WebSocket {
    if(this.socket_) {
      return this.socket_
    }
    this.initSocket_()
    return this.socket_

  }

  constructor(options: JsonRpcClientParams, debug?: boolean) {
    this.debug = debug
    this.options = Object.assign({
    }, options)
  }

  private onMessage(msg:MessageEvent){
    // Check if this is JSON RPC

    let response: JsonRpcReply
    try{
      response = JSON.parse(msg.data)
    } catch(error) {
      // Got something else, just ignore in case
    }

    if(typeof response === 'object'
      && 'jsonrpc' in response
      && response['jsonrpc'] === '2.0'
      ) {
      if ('method' in response){
        this.eventHandler(response.method, response.params)
      }
      else if ('result' in response && this.callbacks[response.id]) {
        // We've just got response
        let success_cb = this.callbacks[response.id].success_cb
        delete this.callbacks[response['id']]
        if (success_cb) success_cb(Object.assign({}, response.result))
      } else if('error' in response && this.callbacks[response.id]) {
        let error_cb = this.callbacks[response.id].error_cb
        let failed_request = Object.assign({}, this.callbacks[response.id])

        delete this.callbacks[response['id']]

        if(response.error.code == -32000 && this.options.login && this.options.passwd) {
          // Auth is needed
          this.call('login', {
              login: this.options.login,
              passwd: this.options.passwd,
              loginParams: this.options.loginParams,
              userVariables: this.options.userVariables
            },
            (data) => {
              // Re-send failed request
              this.socketCall_(failed_request)
            },
            (data) => {
              // Auth failed
              error_cb(Object.assign({}, response.result))
            })
        } else {
          if (error_cb) error_cb(Object.assign({}, response.error))
        }
      }
    }
  }

  private socketCall_({request, success_cb, error_cb}: JsonRpcCall) {
        request.id = this.request_id++

    let rawData = JSON.stringify(request)

    this.callbacks[request.id] = { request, success_cb, error_cb }

    if(this.socket.readyState < 1) {
      // Socket is not ready, queue message
      this.queue.push(rawData)
    } else {
      this.socket?.send(rawData)
    }
  }

  public setEventHandler(handler: {(method: string, params: JsonRpcParams):void}){
    this.eventHandler = handler
  }

  public setReconnectHandler(handler: {():void}){
    this.reconnectHandler = handler
  }

  public call(method: string, params?: Object, success_cb?: {(data: Object): void}, error_cb?: {(data: Object): void}) {
    // Construct the JSON-RPC 2.0 request.
    let call_params = Object.assign({}, params)

    let request = {
      jsonrpc : '2.0',
            method  : method,
            params  : call_params,
            id    : 0
    }

    if(this.socket) {
      this.socketCall_({request, success_cb, error_cb})
    }
  }

  public close() {

      this.queue = []
      this.callbacks = []
      this.eventHandler = null
      this.reconnectHandler = null
      this.closeSocket_()
  }

  public getSocketState() {
    return this.socket?.readyState;
  }
}

export { JsonRpcClient, JsonRpcClientParams }

