import {Directive, HostListener, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, map} from 'rxjs/operators';
import {ISelect} from './api.helpers';
import {Observable, of as observableOf} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { NotifyService } from './notify.service';
import { RxFormHelpers } from './form.helpers';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
import {ROLE_TYPE_CUSTOM, ROLE_TYPE_DOMAIN_LIST, ROLE_TYPE_PROVIDER_LIST} from './constant';
import {AuthenticationService} from '../auth/authentication.service';
import {BaseService} from './base.service';

@Directive()
export class BaseFormComponent<T> implements OnInit, OnDestroy {
  public form: FormGroup;
  public message = '';
  public id = null;
  extParams = {};
  public rxFormHelpers: RxFormHelpers = new RxFormHelpers();
  public select_type: ISelect[];
  public roleTypeCustom = ROLE_TYPE_CUSTOM;
  public navigate = '';
  public formT;
  public isLoadingResults = true;
  public isServerAvailable = true;
  public is_received_data: Observable<boolean> = observableOf(false);
  public prev_state;
  protected needCheckAuth: boolean = true;

  constructor(
    private type: {new(): T},
    public api: BaseService<T>,
    public fb: RxFormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public http: HttpClient,
    public notifyService: NotifyService,
    public authenticationService: AuthenticationService
  ) {
    this.formT = new type();
    this.prev_state = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation().extras.state : null;
    this.onInitial();
  }

  onInitial() {
    if (this.needCheckAuth) this.authenticationService.is_token_alive().subscribe();
  }

  ngOnDestroy() {}

  ngOnInit() {
    this.rxFormHelpers = new RxFormHelpers();
    this.form = this.fb.formGroup(this.formT);
    this.select_type = (this.api.isRoleProvider()) ? ROLE_TYPE_PROVIDER_LIST : ROLE_TYPE_DOMAIN_LIST;

    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id) {
      this.update();
    } else {
      if (this.form.get('id')) { this.form.removeControl('id'); }
      this.isLoadingResults = false;
      this.is_received_data = observableOf(true);
    }
  }

  update() {
    this.id = this.id || this.route.snapshot.paramMap.get('id');
    if (this.id) {
      this.api.get(parseInt(this.id, 10), this.extParams).subscribe(
        data => {
          if (!this.form.get('id')) { this.form.addControl('id', new FormControl(this.id)); }
          this.form.patchValue(<T>data);
          this.callbackFormPatch(<T>data);
          this.isLoadingResults = false;
        },
        resp => {
          this.isLoadingResults = false;
          this.callbackGetError(resp);
          this.isServerAvailable = this.notifyService.setFormErrors(resp, this.form, this.navigate) != 502;
        }
      );
    }
  }

  callbackFormPatch(data: T) {
    this.is_received_data = observableOf(true);
  }

  callbackGetError(resp?) {}

  callbackChangeForm(form, data) {}
  callbackExtraOptions() {

  }

  callbackSaveSuccess(data, submit_btn) {
    if (submit_btn) submit_btn.disabled = false;
    this.notifyService.showRequestResult(data, true); // отображаю код 200 или предупреждения

    const btn_class = submit_btn?._elementRef?.nativeElement?.classList;
    if (btn_class && Array.from(btn_class).includes('save-btn')) {}
    else this.router.navigate([this.navigate]);
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    event.preventDefault();
    this.router.navigate([this.navigate], {state: this.prev_state});
  }

  statusChange(event: MatSlideToggleChange, field: string = 'status') {
    let f_control = this.form.get(field);
    if (f_control){
      this.form.controls[field].setValue(event.checked === true ? 1 : 0);
    }
  }

  prepareDataToSubmit() {
    let params = this.form.value;
    if (this.form.value.id && this.form.value.password === 'secret' ) {
      delete params['password'];
    }
    return params;
  }

  onSubmit(submit_btn?: MatButton) {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();

    if (this.form.valid) {
      if (submit_btn) submit_btn.disabled = true;
      let params = this.prepareDataToSubmit();
      this.api.save(params).subscribe(
        (data) => {
          this.callbackChangeForm(this.form, data);
          this.callbackSaveSuccess(data, submit_btn);
          this.callbackExtraOptions();
        },
        resp => {
          if (submit_btn) submit_btn.disabled = false;
          this.isServerAvailable = this.notifyService.setFormErrors(resp, this.form) != 502;
        }
      );
    } else {
      Object.entries(this.form.controls).filter(filter => filter[1]['status']==='INVALID').forEach(item => {
        this.form.controls[`${item[0]}`].updateValueAndValidity();
        this.form.controls[`${item[0]}`].markAllAsTouched();
        console.log('invalid field: ', item);
      });

      this.notifyService.message('NOTIFY.REQUIRED');
    }
  }

  onSave(): Observable<T>|null {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const params = this.form.value;
      return this.api.save(params).pipe(
        catchError(resp => {
          this.isServerAvailable = this.notifyService.setFormErrors(resp, this.form) != 502;
          return observableOf(null);
        }),
        map(res => {
          this.notifyService.showRequestResult(res, false);
          return res ? res.body : null;
        })
      );
    } else {
      this.notifyService.message('NOTIFY.REQUIRED');
      return observableOf(null);
    }
  }
}
