import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of as observableOf, timer} from 'rxjs';
import {catchError, map, tap, concatMap} from 'rxjs/operators';
import {IResponseBodyObject} from '../_helpers/api.helpers';
import { User } from './user';
import { Helper } from '../_helpers/api.helpers';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Domain} from '../domains/domain';
import {JsonAppConfigService} from '../config/json-app-config.service';
import {AuthenticationBaseService} from "./authentication-base.service";
import {PROJECT} from "../app.injection";
const headers = new HttpHeaders().set('Content-Type', 'application/json');


@Injectable({ providedIn: 'root' })
export class AuthenticationService extends AuthenticationBaseService {
  snackBarRef: MatSnackBarRef<any>;
  snackBarConfig: MatSnackBarConfig = {
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
    duration: 2000,
    panelClass: 'glam-snackbar'
  };

  constructor(@Inject(PROJECT) public project: string, http: HttpClient, snackBar: MatSnackBar, translate: TranslateService,
              route: ActivatedRoute, router: Router,
              ConfigService: JsonAppConfigService) {
    super(http, snackBar, translate, route, router, ConfigService)
  }

  checkStatus(res) {
    if (this.project == 'cti-panel') {
      return super.checkStatus(res);
    } else {
      return this.checkStatusOverride(res)
    }
  }

  login(login: string, password: string, remember_me: boolean, cb?) {
    if (this.project == 'cti-panel') {
      return super.login(login, password, remember_me, cb);
    } else {
      return this.loginOverride(login, password, remember_me, cb)
    }
  }

  logout(withNextUrl = true, withRedirect = true) {
    if (this.project == 'cti-panel') {
      return super.logout();
    } else {
      return this.logoutOverride(withNextUrl, withRedirect)
    }
  }

  is_token_alive(logout = true) {
    if (this.project == 'cti-panel') {
      return super.is_token_alive(logout = true);
    } else {
      return this.is_token_alive_override(logout)
    }
  }

  //////

  checkStatusOverride(res){
    if (res == 'Unknown Error' || res  == 'Bad Gateway' || res.constructor === String) { // когда сервер не доступен, генерирую ошибку
      this.snackBarRef = this.snackBar.open(this.translate.instant('NOTIFY.502'), '', this.snackBarConfig);
      throw this.translate.instant('NOTIFY.502');
    } else if (res.code == 407) {
      throw this.translate.instant('ACCESS_TOKEN.EXPIRED');
    } else if (res.code == 500 || res.code == 403 || res.code == 401) {
      let error_text = '';
      for (let err of res.notifies) {
        switch (err['msg_id']) {
          case 10002: // сотрудник не существует или введен неверный пароль
            if ('password' in err['filter']) { // если в фильтре возвращено поле пароль, то это означает, что введен неверный пароль
              error_text = this.translate.instant('AUTH_ERROR');
            } else if ('name' in err['filter'] && err.obj == 'Domain') {
              error_text = this.translate.instant('ERROR.10002_1', {arg1: this.translate.instant('DOMAIN') + ' ' + err.filter.name});
            } else { // иначе сотрудник по введенному логину не найден
              let undef_obj = this.translate.instant((err['obj'] || '').toUpperCase());
              error_text = this.translate.instant('ERROR.10002_1', {arg1: undef_obj});
            }
            break;
          case 10011: // сотрудник или его домен выключен
            let dis_obj_name = this.translate.instant((err['obj'] || '').toUpperCase());
            error_text = this.translate.instant('ERROR.10011_1', {arg1: dis_obj_name});
            break;
          case 10005:
            for (let field in err.error) {
              if (field != 'domain_id') {
                if (err.error[field].constructor === Array) { // ошибка содержит список кодов ошибок
                  for (let err_code of err.error[field]) {
                    if (err_code == 2) error_text += field + this.translate.instant('ERROR.REQUIRED');
                    else error_text += this.translate.instant('ERROR.10005.' + err_code);
                  }
                } else { // ошибка содержит список объектов с полями ошибок, когда formControl - это массив
                  for (let item_number in err.error[field]) {
                    for (let item_field in err.error[field][item_number]) {
                      try {
                        for (let item_err_code of err.error[field][item_number][item_field]) {
                          if (item_err_code == 2) error_text += item_field + this.translate.instant('ERROR.REQUIRED');
                          else error_text += item_field + this.translate.instant('ERROR.10005.' + item_err_code);
                        }
                      } catch (e) {
                        error_text += item_field + this.translate.instant('ERROR.10005.0' +  JSON.stringify(err));
                      }
                    }
                  }
                }
              }
            }
            break;
          case 100:
            let err_text;
            if (err.text.startsWith('update or delete on table "user" violates foreign key constraint') && err.obj == 'User') {
              err_text = this.translate.instant('ERROR.TO_DELETE_USER');
            } else if (err.text == 'Got ambiguous input for polymorphic field' && res.obj == 'DomainDialPlan') {
              err_text = this.translate.instant('DP.NO_ROUTE_TYPE_DATA_OR_EMPTY_ROUTE');
            } else {
              err_text = this.translate.instant((err.text || '').toUpperCase()) != (err.text || '').toUpperCase() ? this.translate.instant((err.text || '').toUpperCase()) : err.text;
            }
            error_text = this.translate.instant((err['obj'] != 'NA' ? err['obj'] : res['obj'] || '').toUpperCase()) + ': ' + err_text;
            break;
          case 10000:
          case 10001:
            error_text = this.translate.instant('ERROR.' + err['msg_id']);
            break;
          case 10014:
            error_text = this.translate.instant('ERROR.' + err['msg_id']);
            break;
          default:
            error_text = JSON.stringify(err);
        }
        this.snackBarRef = this.snackBar.open(error_text, '', this.snackBarConfig);
        throw error_text;
      }
    }
  }

  version() {
    return this.http.get('./assets/version', {responseType: 'text'})
      .pipe(
        map(ver => {
            return ver
          }
        ),
        catchError(error => observableOf(''))
      )
  }

  getSSORedirect(params: any = {}) {
    const requestBody = Helper.requestFormat('get_redirect', 'DomainExternalAuthSSO', params);
    return this.http.post<IResponseBodyObject<any>>(this.ConfigService.getValue('apiUrl')+'sso/adfs/get_redirect/', requestBody, {headers: headers})
      .pipe(
        map(data => {
          if (data.code == 200) return <any>data.body;
          else throw data;
        }),
        catchError(err => {
          this.checkStatus(err); // проверяю, что за ошибка и из нее получу ошибку, которая будет обработана на уровень выше в ветке ошибок
          return observableOf({}); // это вернется, только если checkStatus не сгенерит ошибку
        })
      );
  }

  getSSOUserData(token) {
    const requestBody = Helper.requestFormat('get_session_data', 'User', {});
    return this.http.post<IResponseBodyObject<User>>(this.ConfigService.getValue('apiUrl'), requestBody, {
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
    }).pipe(
      map(data => {
        if (data.code == 200) {
          const user = data.body as User;
          user.token = token;
          this.secureStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.removeItem('verto_session');
          this.currentUserSubject.next(user);
          return user;
        } else throw data;
      }),
      catchError(err => {
        this.secureStorage.removeItem('currentUser');
        localStorage.removeItem('verto_session');
        this.checkStatus(err); // проверяю, что за ошибка и из нее получу ошибку, которая будет обработана на уровень выше в ветке ошибок
        return observableOf(new User()); // это вернется, только если checkStatus не сгенерит ошибку
      })
    );
  }

  getSSOAvailable(domain_name: string): Observable<number | Domain> {
    let requestBody = Helper.requestFormat('get_available', 'DomainExternalAuth', {domain_name: domain_name});

    return this.http.post<IResponseBodyObject<Domain>>(this.ConfigService.getValue('apiUrl')+'sso/get_available/', requestBody, {headers: headers})
      .pipe(
        map(data => data.body),
        catchError(() => observableOf(null))
      );
  }

  loginOverride(login: string, password: string, remember_me: boolean, cb?) {
    return this.http.post<IResponseBodyObject<User>>(
      this.ConfigService.getValue('apiUrl'), Helper.requestFormat('auth', 'User',
        { login: login, password: password, remember_me: remember_me}))
      .pipe(
        concatMap(data => {
          if (data.code == 200) {
            if (cb) {
              this.currentUserSubject.next({token: data.body.token} as User); // для того, чтобы в запрос подставился токен
              return cb(data);
            } else return observableOf(data);
          } else return observableOf(data);
        }),
        map(data => {
          if (data['code'] == 200) {
            const user = data['body'] as User;
            this.secureStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.removeItem('verto_session');
            this.currentUserSubject.next(user);
            return user;
          } else throw data;
        }),
        catchError(err => {
          this.secureStorage.removeItem('currentUser');
          localStorage.removeItem('verto_session');
          if (cb && typeof err != 'object') throw err; else this.checkStatus(err); // проверяю, что за ошибка и из нее получу ошибку, которая будет обработана на уровень выше в ветке ошибок
          return observableOf(new User()); // это вернется, только если checkStatus не сгенерит ошибку
        })
      );
  }

  logoutOverride(withNextUrl = true, withRedirect = true) {
    const requestBody = Helper.requestFormat('logout', 'User');
    this.http.post<IResponseBodyObject<User>>(this.ConfigService.getValue('apiUrl'), requestBody, {headers: headers})
      .subscribe(res=>{
        // remove user from local storage to log user out
        this.secureStorage.removeItem('currentUser');
        localStorage.removeItem('verto_session');
        this.currentUserSubject.next(null);
        let nextUrl = this.router.url.includes(';nextUrl=') ? decodeURIComponent(this.router.url.split(";nextUrl=")[1]) : this.router.url;
        // this.router.navigate(withNextUrl ? ['/login', {nextUrl:nextUrl}] : ['/login']);
        if (withRedirect) window.location.replace(withNextUrl ? '/login;nextUrl='+encodeURIComponent(nextUrl) : '/login');
      }, error=>{
        // remove user from local storage to log user out
        this.secureStorage.removeItem('currentUser');
        localStorage.removeItem('verto_session');
        this.currentUserSubject.next(null);
        let nextUrl = this.router.url.includes(';nextUrl=') ? decodeURIComponent(this.router.url.split(";nextUrl=")[1]) : this.router.url;
        // this.router.navigate(withNextUrl ? ['/login', {nextUrl:nextUrl}] : ['/login']);
        if (withRedirect) window.location.replace(withNextUrl ? '/login;nextUrl='+encodeURIComponent(nextUrl) : '/login');
      });

    // на случай, если запрос разавторизации не выполнится
    // remove user from local storage to log user out
    this.secureStorage.removeItem('currentUser');
    localStorage.removeItem('verto_session');
    this.currentUserSubject.next(null);
  }

  is_token_alive_override(logout = true){
    return this.http.post<IResponseBodyObject<any>>(this.ConfigService.getValue('apiUrl'), Helper.requestFormat('check_auth', 'User'))
      .pipe(
        tap(data => {
          if (data.code === 200) {
            if (data.body && data.body.token) {
              let user = this.currentUserValue;
              user.token = data.body.token;
              this.secureStorage.setItem('currentUser', JSON.stringify(user));
              this.currentUserSubject.next(user);
            }
            return true;
          }
          else if (logout && (data.code === 407 || data.code === 401)) { // токена нет или он устарел
            this.snackBarRef = this.snackBar.open(this.translate.instant('NOTIFY.407'), '', this.snackBarConfig);
            this.logout();
            return false;
          }
          else {
            this.secureStorage.removeItem('currentUser');
            localStorage.removeItem('verto_session');
            this.currentUserSubject.next(null);
          }
        }),
        map(data => data.code == 200)
      );
  }


  ////

}

