import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
import {Observable, of as observableOf, Subject} from 'rxjs';
import {CALL_STAUS_LIST} from '../../../_helpers/constant';
import {RxFormHelpers} from '../../../_helpers/form.helpers';
import {NotifyService} from '../../../_helpers/notify.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {ConfirmComponent} from '../../../dialogs/modals/confirm/confirm.component';
import {DPConditionVarType} from '../../../dialplans/components/condition/dialplan.condition-router';
import {PERIOD} from '../../../dialplans/components/processing/dialplan.processing';
import {UserService} from "../../../users/user.service";
import {User} from "../../../users/user";
import {DomainDialogScriptFormService} from '../forms/domain-dialog-script-form.service';
import {DDSEBaseParams, DDSEDefaultLeg, DDSEOneLeg, LegMenu, MenuRow} from './domain-dialog-script';
import {DomainDialogScriptService} from './domain-dialog-script.service';

@Component({
  selector: 'app-domain-dialog-script-node-dialog',
  templateUrl: './domain-dialog-script-node-dialog.component.html',
  styles: [`
    ::ng-deep .ng-dropdown-panel {
      z-index: 9999999!important;
    }
  `],
  styleUrls: []
})
export class DomainDialogScriptNodeDialogComponent implements OnInit, OnDestroy {
  public rxFormHelpers: RxFormHelpers;
  public form: FormGroup;
  public isLoadingResults = true;
  public isServerAvailable = true;
  public is_received_data: Observable<boolean> = observableOf(false);

  public optionsService;
  public options = [];
  public totalOptions: number = null;
  public loadedOptions: number = 0;
  public optionsLoading: boolean = false;
  public search$: Subject<any> = new Subject<any>();
  public searchSub;

  public periods = PERIOD;
  public callStatuses = CALL_STAUS_LIST;
  public valueTypes = DPConditionVarType.filter(type => type.id != 2 && type.id != 3);
  users: User[];

  constructor(
    public api: DomainDialogScriptService,
    public domainFormService: DomainDialogScriptFormService,
    public fb: RxFormBuilder,
    public notifyService: NotifyService,
    public userService: UserService,
    public authenticationService: AuthenticationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DomainDialogScriptNodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.rxFormHelpers = new RxFormHelpers();
    this.form = this.api.paramsModel(this.data.control.value.model_name);
    this.form.patchValue(this.data.control.value.params);

    this.userService.toSelect({}, 'select').subscribe(_=>this.users=_)
    this.isLoadingResults = false;

    switch (this.data.control.value.model_name) {
      case 'DDSEForm':
        this.optionsService = this.domainFormService;
        if (this.form.value.form_id != null) this.optionsScrollEnd([this.form.value.form_id], true);
        break;
      case 'DDSESetVar':
        this.callbackFormPatch();
        break;

      case 'DDSEMenu':
        for (let uid of Object.keys(this.data.control.value.params.conditions).sort()) {
          (this.form.get('conditions') as FormGroup).setControl(uid, this.fb.formGroup(new MenuRow()));
          this.form.get(['conditions', uid]).patchValue({
            label: this.data.control.value.legs[uid].label, button: this.data.control.value.params.conditions[uid].button
          });
        }
        break;

      default: this.callbackFormPatch();
    }
  }

  ngOnDestroy(): void {
    if (this.searchSub) this.searchSub.unsubscribe();
  }

  callbackFormPatch() {
    this.is_received_data = observableOf(true);
  }

  changeLabel(control_name, value) {
    // Если при открытии окна редактирования элемента маршрута не было выбранной опции, то при ее выборе по-любому устанавливаю метку
    let optName = (control_name == 'form_id' || control_name == 'queue_id') ? this.options.find(o => o.id == value)?.name : value?.name;
    if (!this.data.control.value.params[control_name]) this.form.patchValue({name: optName});
    else if (value && value != this.form.value.name) { // иначе спрашиваю менять метку или нет
      const confirmDialog = this.dialog.open(ConfirmComponent, {
        data: {
          title: 'DIALOG.CONFIRM_ACTION',
          message: 'DIALOG.CHANGE_LABEL_MESSAGE'
        }
      });
      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) {
          this.form.patchValue({name: optName});
        }
      });
    }
    if (control_name == 'form_id') this.form.patchValue({ver: this.options.find(o => o.id == value)?.ver});
  }

  optionsScrollEnd(ids: number[] = [], callCB: boolean = false) {
    if (this.totalOptions === null || (this.loadedOptions < this.totalOptions) || ids.length > 0) {
      this.optionsLoading = true;
      this.optionsService.list({
        ...(ids.length > 0 ? {filter: {field_list: [{field: 'id', value: ids, condition_type: 9}], type: 0}} : {}),
        sort: {name: '+'},
        limit: 200,
        offset: this.loadedOptions
      }, 'select').subscribe(
        (data) => {
          if (this.totalOptions === null && ids.length == 0) this.totalOptions = data.total_count;
          for (let sg of data.list) {
            if (this.options.findIndex(sc => sc.id == sg['id']) == -1) {
              if (this.data.control.value.model_name == 'DOCRMUser') sg.name = (sg?.surname?.length>0) ? `${sg.surname} ${sg.name}` : sg.name;
              this.options.push(sg);
              if (ids.length == 0) this.loadedOptions += 1;
            }
          }
          this.options.sort((a,b) => (a.name < b.name) ? -1 : (a.name > b.name ? 1 : 0));
          this.options = [...this.options];
          this.optionsLoading = false;
          if (callCB) this.callbackFormPatch();
        },
        resp => {
          this.optionsLoading = false;
          this.notifyService.setFormErrors(resp);
        }
      );
    }
  }

  addUserVar(value) {
    return {id: value, name: value, label: value};
  }

  addMenuRow() {
    (this.form.get('conditions') as FormGroup).setControl(
      this.api.getNextUID(Object.keys(this.form.value.conditions)),
      this.fb.formGroup(new MenuRow)
    );
  }

  removeMenuRow(uid) {
    (this.form.get('conditions') as FormGroup).removeControl(uid);
  }

  save() {
    this.authenticationService.is_token_alive().subscribe(() => {
      this.form.updateValueAndValidity();
      this.form.markAllAsTouched();
      if (!this.form.valid) {
        this.notifyService.message('NOTIFY.ERROR_FORM');
        return false;
      } else {
        this.data.control.get('params').patchValue(this.form.value);

        if (this.data.nodeData.name == 'DDSEMenu' && this.form.get('conditions').dirty) {
          // удаляю кондишены и их ноги из контрола, которые были удалены на форме
          Object.keys(this.data.control.value.params.conditions)
            .filter(uid => !this.form.value.conditions[uid])
            .forEach(uid => {
              this.data.control.get(['params', 'conditions']).removeControl(uid);
              this.data.control.get('legs').removeControl(uid);
            });

          for (let uid of Object.keys(this.form.value.conditions).sort()) {
            if (!this.data.control.value.params.conditions[uid]) {
              this.data.control.get(['params', 'conditions']).setControl(uid, this.fb.formGroup(new LegMenu()));
              this.data.control.get('legs').setControl(uid, this.fb.formGroup(new DDSEDefaultLeg()));
              let m = new DDSEOneLeg();
              // m.params = new DDSEBaseParams();
              let addingControl = this.fb.formGroup(m);
              this.data.control.get('legs').get(uid).setControl('leg', addingControl);
            }

            this.data.control.get(['params', 'conditions', uid]).patchValue({button: this.form.value.conditions[uid].button});

            this.data.control.get('legs').get(uid).patchValue({label: this.form.value.conditions[uid].label});
          }
        }

        this.dialogRef.close();
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  getUsersName(value: string) {
    return this.users?.find(_=>_.id == parseInt(value))
  }
}
