import {Injectable, isDevMode} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, of, of as observableOf, timer} from 'rxjs';
import {catchError, map, tap, concatMap} from 'rxjs/operators';
import {IResponseBodyObject} from '../_helpers/api.helpers';
import { User } from './user';
import { Helper } from '../_helpers/api.helpers';
import * as CryptoJS from 'crypto-js';
import SecureStorage from 'secure-web-storage';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Domain} from '../domains/domain';
import {JsonAppConfigService} from '../config/json-app-config.service';
import {ROLE_TYPE_CUSTOM, ROLE_TYPE_PROVIDER} from '../_helpers/constant';
import { DomainSettings } from '../domains/domain-settings/domainSettings';
const SECRET_KEY = 'very_secret_key';
const headers = new HttpHeaders().set('Content-Type', 'application/json');


@Injectable({ providedIn: 'root' })
export class AuthenticationBaseService {

  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public secureStorage = !(isDevMode()) ? new SecureStorage(localStorage, {
    hash: function hash(key) {
      key = CryptoJS.SHA256(key, SECRET_KEY);
      return key.toString();
    },
    encrypt: function encrypt(data) {
      data = CryptoJS.AES.encrypt(data, SECRET_KEY);
      data = data.toString();
      return data;
    },
    decrypt: function decrypt(data) {
      data = CryptoJS.AES.decrypt(data, SECRET_KEY);
      data = data.toString(CryptoJS.enc.Utf8);
      return data;
    }
  }) : localStorage;
  constructor(public http: HttpClient, public snackBar: MatSnackBar, public translate: TranslateService,
              public route: ActivatedRoute, public router: Router,
              public ConfigService: JsonAppConfigService) {
    const storage = JSON.parse(this.secureStorage.getItem('currentUser'));
    this.currentUserSubject = new BehaviorSubject<User>(storage);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  checkStatus(res){
    if (res == 'Unknown Error' || res  == 'Bad Gateway' || res.constructor === String) { // когда сервер не доступен, генерирую ошибку
      throw this.translate.instant('NOTIFY.502');
    } else if (res.code == 407) {
      throw this.translate.instant('ACCESS_TOKEN.EXPIRED');
    } else if (res.code == 500 || res.code == 403 || res.code == 401) {
      let error_text = '';
      for (let err of res.notifies) {
        switch (err['msg_id']) {
          case 10002: // сотрудник не существует или введен неверный пароль
            if ('password' in err['filter']) { // если в фильтре возвращено поле пароль, то это означает, что введен неверный пароль
              error_text = this.translate.instant('AUTH_ERROR');
            } else if ('name' in err['filter'] && err.obj == 'Domain') {
              error_text = this.translate.instant('ERROR.10002_1', {arg1: this.translate.instant('DOMAIN') + ' ' + err.filter.name});
            } else { // иначе сотрудник по введенному логину не найден
              let undef_obj = this.translate.instant((err['obj'] || '').toUpperCase());
              error_text = this.translate.instant('ERROR.10002_1', {arg1: undef_obj});
            }
            break;
          case 10011: // сотрудник или его домен выключен
            let dis_obj_name = this.translate.instant((err['obj'] || '').toUpperCase());
            error_text = this.translate.instant('ERROR.10011_1', {arg1: dis_obj_name});
            break;
          case 10005:
            for (let field in err.error) {
              if (field != 'domain_id') {
                if (err.error[field].constructor === Array) { // ошибка содержит список кодов ошибок
                  for (let err_code of err.error[field]) {
                    if (err_code == 2) error_text += field + this.translate.instant('ERROR.REQUIRED');
                    else error_text += this.translate.instant('ERROR.10005.' + err_code);
                  }
                } else { // ошибка содержит список объектов с полями ошибок, когда formControl - это массив
                  for (let item_number in err.error[field]) {
                    for (let item_field in err.error[field][item_number]) {
                      try {
                        for (let item_err_code of err.error[field][item_number][item_field]) {
                          if (item_err_code == 2) error_text += item_field + this.translate.instant('ERROR.REQUIRED');
                          else error_text += item_field + this.translate.instant('ERROR.10005.' + item_err_code);
                        }
                      } catch (e) {
                        error_text += item_field + this.translate.instant('ERROR.10005.0' +  JSON.stringify(err));
                      }
                    }
                  }
                }
              }
            }
            break;
          case 100:
            let err_text;
            if (err.text.startsWith('update or delete on table "user" violates foreign key constraint') && err.obj == 'User') {
              err_text = this.translate.instant('ERROR.TO_DELETE_USER');
            } else if (err.text == 'Got ambiguous input for polymorphic field' && res.obj == 'DomainDialPlan') {
              err_text = this.translate.instant('DP.NO_ROUTE_TYPE_DATA_OR_EMPTY_ROUTE');
            } else {
              err_text = this.translate.instant((err.text || '').toUpperCase()) != (err.text || '').toUpperCase() ? this.translate.instant((err.text || '').toUpperCase()) : err.text;
            }
            error_text = this.translate.instant((err['obj'] != 'NA' ? err['obj'] : res['obj'] || '').toUpperCase()) + ': ' + err_text;
            break;
          case 10000:
          case 10001:
            error_text = this.translate.instant('ERROR.' + err['msg_id']);
            break;
          case 10014:
            error_text = this.translate.instant('ERROR.' + err['msg_id']);
            break;
          default:
            error_text = JSON.stringify(err);
        }
        console.error(error_text)
        //throw error_text;
      }
    }
  }


  login(login: string, password: string, remember_me: boolean, cb?) {
    return this.http.post<IResponseBodyObject<User>>(
      this.ConfigService.getValue('apiUrl'), Helper.requestFormat('auth', 'User',
        { login: login, password: password, remember_me: remember_me}))
      .pipe(
        concatMap(data => {
          if (data.code == 200) {
            if (cb) {
              this.currentUserSubject.next({token: data.body.token} as User); // для того, чтобы в запрос подставился токен
              return cb(data);
            } else return observableOf(data);
          } else return observableOf(data);
        }),
        map(data => {
          if (data['code'] == 200) {
            const user = data['body'] as User;
            this.secureStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.removeItem('verto_session');
            this.currentUserSubject.next(user);
            return user;
          } else throw data;
        }),
        catchError(err => {
          this.secureStorage.removeItem('currentUser');
          localStorage.removeItem('verto_session');
          if (cb && typeof err != 'object') throw err; else this.checkStatus(err); // проверяю, что за ошибка и из нее получу ошибку, которая будет обработана на уровень выше в ветке ошибок
          return observableOf(new User()); // это вернется, только если checkStatus не сгенерит ошибку
        })
      );
  }

  logout() {
    const requestBody = Helper.requestFormat('logout', 'User');
    this.http.post<IResponseBodyObject<User>>(this.ConfigService.getValue('apiUrl'), requestBody, {headers: headers})
      .subscribe(res=>{
        // remove user from local storage to log user out
        this.secureStorage.removeItem('currentUser');
        localStorage.removeItem('verto_session');
        this.currentUserSubject.next(null);
      }, error=>{
        // remove user from local storage to log user out
        this.secureStorage.removeItem('currentUser');
        localStorage.removeItem('verto_session');
        this.currentUserSubject.next(null);
      });

    // на случай, если запрос разавторизации не выполнится
    // remove user from local storage to log user out
    this.secureStorage.removeItem('currentUser');
    localStorage.removeItem('verto_session');
    this.currentUserSubject.next(null);
  }

  is_token_alive(logout = true){
    return this.http.post<IResponseBodyObject<any>>(this.ConfigService.getValue('apiUrl'), Helper.requestFormat('check_auth', 'User'))
      .pipe(
        tap(data => {
          if (data.code === 200) {
            if (data.body && data.body.token) {
              let user = this.currentUserValue;
              user.token = data.body.token;
              this.secureStorage.setItem('currentUser', JSON.stringify(user));
              this.currentUserSubject.next(user);
            }
            return true;
          }
          else if (logout && (data.code === 407 || data.code === 401)) { // токена нет или он устарел

            console.error(this.translate.instant('NOTIFY.407'));
            this.logout();
            return false;
          }
          else {
            this.secureStorage.removeItem('currentUser');
            localStorage.removeItem('verto_session');
            this.currentUserSubject.next(null);
          }
        }),
        map(data => data.code == 200)
      );
  }

  permission() {

  }

  getDomain() {
    return  this.currentUserValue?.domain_id;
  }

  isCCMode() {
    return  this.currentUserValue?.domain_mode === 2;
  }

  getDomainData(id: number, params: any = {}): Observable<any> {
    const requestBody = Helper.requestFormat('get', 'Domain', { id: id, ...params});
    return this.http.post<IResponseBodyObject<any>>(this.ConfigService.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        map(data => {
          return <any>data.body;
        })
      );
  }

  getPbxHost(domain_id?: number): Observable<number> {
    if (!domain_id) domain_id = this.getDomain();

    let requestBody = Helper.requestFormat('get', 'Domain', {id: domain_id});


    return this.http.post<IResponseBodyObject<Domain>>(this.ConfigService.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        map(data => data.body.pbx_host_id),
        catchError(() => observableOf(null))
      );
  }

  setDomain(domain_id, domain_name, domain_mode, domain_test_until, with_update_user: boolean = true) {
    this.currentUserValue.domain_id = domain_id;
    this.currentUserValue.domain_name = domain_name;
    this.currentUserValue.domain_mode = domain_mode;
    this.currentUserValue.domain_test_until = domain_test_until;
    this.secureStorage.setItem('currentUser', JSON.stringify(this.currentUserValue));
    if (with_update_user) this.currentUserSubject.next(this.currentUserValue);
  }

  getDomainSettings(domain_id: any): Observable<DomainSettings> {
    const requestBody = Helper.requestFormat('get', 'DomainSettings', {domain_id: domain_id});
    return this.http.post<IResponseBodyObject<any>>(this.ConfigService.getValue('apiUrl'),
      requestBody, { headers })
      .pipe(
        map(data => {
          return data.body.params as DomainSettings;
        }),
      );
  }

  setLicense(license) {
    this.currentUserValue.tariff_option_list = license;
    this.secureStorage.setItem('currentUser', JSON.stringify(this.currentUserValue));
    this.currentUserSubject.next(this.currentUserValue);
  }

  listPerms() {
    const perms = this.getUserPerms();
    let perm_list = [];
    Object.keys(perms).forEach(key =>
    {
      if ((perms[key] || []).length>0) perm_list.push(key)
    });
    return perm_list;
  }

  getUserPerms() {
    return this.currentUserValue?.role_perms || {};
  }

  listVisible() {
    const perms = this.getUserPerms();
    let perm_list = [];
    Object.keys(perms).forEach(key =>
    {
      if ((perms[key] || []).length>0) perm_list.push(key)
    });
    return perm_list;
  }

  getUserPerm(key) {
    const perms = this.getUserPerms();
    if (perms && perms[key] && perms[key]) {
      return perms[key] || [];
    } else {
      return [];
    }
  }

  hasUserPerm(key: string, perm: number) {
    return this.getUserPerm(key).includes(perm);
  }

  hasPerm(modelName: string, perms: number[]|number, isAny: boolean = true){
    if (!this.isRoleCustom()) return true;
    if (!this.currentUserValue?.role_perms[modelName] || this.currentUserValue?.role_perms[modelName]?.length == 0) return false;
    if (typeof perms == 'number') return this.currentUserValue?.role_perms[modelName].indexOf(perms) != -1;
    else if (isAny) return perms.some(perm => this.currentUserValue?.role_perms[modelName].indexOf(perm) != -1);
    else return perms.filter(perm => this.currentUserValue?.role_perms[modelName].indexOf(perm) == -1).length == 0;
  }

  getUserId() {
    return this.currentUserValue?.user_id;
  }

  getUserLicense() {
    return this.currentUserValue?.tariff_option_list;
  }

  isLic(license: string) {
      const lic = (this.getUserLicense() || []).filter(item=>item.available==true).map(item=>item.name);
      return lic.includes(license);
  }

  isAsyncLic(license: string, time = 1000) {
    if (this.getUserLicense()) {
      return of(this.isLic(license))
    } else {
      return timer(time).pipe(map(_=> this.isLic(license)))
    }
  }

  getUserTZ() {
    return this.currentUserValue?.tz;
  }

  getUserEmail() {
    return this.currentUserValue?.user_email;
  }

  getUserName() {
    return this.currentUserValue?.user_name;
  }

  isRoleProvider() {
    return Object.values(ROLE_TYPE_PROVIDER).includes(this.currentUserValue?.role_type);
  }

  isRoleOperator() {
    return this.currentUserValue?.role_type===4 &&
      this.currentUserValue?.role_perms?.User?.includes(14);
  }

  isRoleCustom() {
    return Object.values(ROLE_TYPE_CUSTOM).includes(this.currentUserValue?.role_type);
  }

  getDockURL() {
    return this.ConfigService.getValue('dockURL');
  }
}

