import {ISelect, ISelectType} from './api.helpers';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';

//MEETING_PERM_ORGANIZER = 0
//MEETING_PERM_SPEAKER = 1
//MEETING_PERM_PARTICIPANT = 2
//MEETING_PERM_CO_ORGANIZER = 3

export const enum MeetingPerm {
  ORGANIZER = 0,
  SPEAKER = 1,
  PARTICIPANT = 2,
  CO_ORGANIZER = 3
}
export const enum RoleProvider {
  ADMIN = -1,
  CUSTOM = -2
}

export const enum RoleDomain {
  ADMIN = 1,
  CUSTOM = 4
}

export const PerfectConfig: PerfectScrollbarConfigInterface = {
  useBothWheelAxes: false, suppressScrollX: true, suppressScrollY: false
};

export const CALL_STATUS = [
  'CALL_STATUS.ANSWERED', 'CALL_STATUS.NOT_ANSWERED', 'CALL_STATUS.BUSY',  'CALL_STATUS.FORWARD', 'CALL_STATUS.TRANSFER'
];

export const enum TaxiAction {
  IVR_ACTION_HANGUP = 0,
  IVR_ACTION_CANCEL = 1,
  IVR_ACTION_BRIDGE = 2,
  IVR_ACTION_TRANSFER = 3,
  IVR_ACTION_QUEUE = 4,
  IVR_ACTION_USER = 5
}

export const TAXI_ACTION = [
  { name: 'IVR_ACTION_HANGUP', id: TaxiAction.IVR_ACTION_HANGUP },
  { name: 'IVR_ACTION_CANCEL', id: TaxiAction.IVR_ACTION_CANCEL },
  { name: 'IVR_ACTION_BRIDGE', id: TaxiAction.IVR_ACTION_BRIDGE, data: true },
  { name: 'IVR_ACTION_TRANSFER', id: TaxiAction.IVR_ACTION_TRANSFER, data: true },
  { name: 'IVR_ACTION_QUEUE', id: TaxiAction.IVR_ACTION_QUEUE, data: 0 },
  { name: 'IVR_ACTION_USER', id: TaxiAction.IVR_ACTION_USER, data: 0 }
]


export const enum TaxiTag {
  TAG_CAR_BRAND = 1,
  TAG_CAR_COLOR = 2,
  TAG_CAR_NUMBER = 3,
  TAG_DRIVE_TIME = 4,
  TAG_CAR_ID = 5
}

export const TAXI_TAGS = [
  { name: 'TAG_CAR_BRAND', id: TaxiTag.TAG_CAR_BRAND },
  { name: 'TAG_CAR_COLOR', id: TaxiTag.TAG_CAR_COLOR },
  { name: 'TAG_CAR_NUMBER', id: TaxiTag.TAG_CAR_NUMBER },
  { name: 'TAG_DRIVE_TIME', id: TaxiTag.TAG_DRIVE_TIME },
  { name: 'TAG_CAR_ID', id: TaxiTag.TAG_CAR_ID }
]



export const enum CallStatusQueue {
  Terminated = 100, // "Отвечен"
  CancelTimeout= 101, // "Завершен по таймауту очереди"
  //CancelNoAgentTimeout= 102, // "Завершен по таймауту агента"
  CancelBreakOut= 103, // "Завершен клиентом"
  CancelOther= 104, // "Завершен без соединения"
  //Other= 105, // "Завершен по неизвестной принчине"
}

export const CC_CALL_STATUS = [
  { name: 'CC_CALL_STATUS.TERMINATED', id: CallStatusQueue.Terminated },
  { name: 'CC_CALL_STATUS.CANCEL_TIMEOUT', id: CallStatusQueue.CancelTimeout },
  //{ name: 'CC_CALL_STATUS.CANCEL_NO_AGENT_TIMEOUT', id: CallStatusQueue.CancelNoAgentTimeout },
  { name: 'CC_CALL_STATUS.CANCEL_BREAK_OUT', id: CallStatusQueue.CancelBreakOut },
  { name: 'CC_CALL_STATUS.CANCEL_OTHER', id: CallStatusQueue.CancelOther },
  //{ name: 'CC_CALL_STATUS.OTHER', id: CallStatusQueue.Other }
];

export const HANGUP_INITIATOR = [
  { id: 0, name: 'DOMAIN_CDR_HANGUP_INITIATOR_UNKNOWN' },
  { id: 1, name: 'DOMAIN_CDR_HANGUP_INITIATOR_SRC' },
  { id: 2, name: 'DOMAIN_CDR_HANGUP_INITIATOR_DST' },
  { id: 3, name: 'DOMAIN_CDR_HANGUP_INITIATOR_SRC_REFUSED' }
];


export const ROLE_TYPE_CUSTOM = [RoleProvider.CUSTOM, RoleDomain.CUSTOM];
export const ROLE_TYPE_PROVIDER = [RoleProvider.ADMIN, RoleProvider.CUSTOM];
export const ROLE_TYPE_DOMAIN = [
  RoleDomain.ADMIN, RoleDomain.CUSTOM];

export const ROLE_TYPE_ALL = [...ROLE_TYPE_PROVIDER, ...ROLE_TYPE_DOMAIN];

export const ROLE_TYPE_PROVIDER_LIST: ISelect[] = [
  { name: 'ROLE.PROVIDER.ADMIN', id: RoleProvider.ADMIN} ,
  { name: 'ROLE.PROVIDER.CUSTOM', id: RoleProvider.CUSTOM}
];

export const ROLE_TYPE_DOMAIN_LIST: ISelect[] = [
  { name: 'ROLE.DOMAIN.ADMIN', id: RoleDomain.ADMIN},
  { name: 'ROLE.DOMAIN.CUSTOM', id: RoleDomain.CUSTOM}
];

export const ROLE_TYPE = ROLE_TYPE_PROVIDER_LIST.concat(ROLE_TYPE_DOMAIN_LIST);

export function getRoleName(role: RoleProvider|RoleDomain, domain_id): string  {
  if (role === 4 && domain_id === null) {
    return 'ROLE.DOMAIN.OPERATOR';
  }
  return ROLE_TYPE.filter((finder) => finder.id === role)[0]['name'];
}

interface ISelectStrategy extends ISelectType {
  level: boolean;
  position: boolean;
  order: number; // 0-внутренняя нумерация, 1-уровень, 2 - позицию
}

export const STRATEGY_LIST: ISelectStrategy[] = [
  {name: 'STRATEGY.RING_ALL', id: 1, type: 0, level: true, position: false, order: 1},
  {name: 'STRATEGY.LONGEST_IDLE_AGENT', id: 2, type: 2, level: true, position: false, order: 1},
  {name: 'STRATEGY.ROUND_ROBIN', id: 3, type: 0, level: false, position: true, order: 2},
  {name: 'STRATEGY.TOP_DOWN', id: 4, type: 1, level: false, position: true, order: 2},
  {name: 'STRATEGY.AGENT_WITH_LEAST_TALK_TIME', id: 5, type: 2, level: false, position: false, order: 0},
  {name: 'STRATEGY.AGENT_WITH_FEWEST_CALLS', id: 6, type: 2, level: false, position: false, order: 0},
  {name: 'STRATEGY.SEQUENTIALLY_BY_AGENT_ORDER', id: 7, type: 2, level: true, position: true, order: 2},
  {name: 'STRATEGY.RANDOM', id: 8, type: 1, level: false, position: false, order: 0}
];

export enum ANNOUNCE {
  SAY_SAY,
  SAY_POSITION,
  SAY_WAITTIME,
  SAY_CALLBACK,
  SAY_DP
}

export const OPERATION_RESULT: ISelect[] = [
  {name: 'OPERATION_RESULT_OK', id: 0},
  {name: 'OPERATION_RESULT_AUTH_FAIL', id: 1},
  {name: 'OPERATION_RESULT_NO_MONEY', id: 2},
  {name: 'OPERATION_RESULT_DST_ERROR', id: 3},
  {name: 'OPERATION_RESULT_TIME_OUT', id: 4},
  {name: 'OPERATION_RESULT_ERROR', id: 5},
  {name: 'OPERATION_RESULT_PARSE_ERROR', id: 6},
  {name: 'OPERATION_RESULT_PROCESS', id: 7},
  {name: 'OPERATION_RESULT_FORBIDDEN', id: 8},
  {name: 'OPERATION_RESULT_DELIVERED', id: 9}
];

export const ANNONCE_SAY_LIST: ISelect[] = [
  {name: 'ANNOUNCEMENT_SAY_SAY', id: 0},
  {name: 'ANNOUNCEMENT_SAY_POSITION', id: 1},
  {name: 'ANNOUNCEMENT_SAY_WAITTIME', id: 2},
  {name: 'ANNOUNCEMENT_SAY_CALLBACK', id: 3},
  {name: 'ANNOUNCEMENT_SAY_DP', id: 4}
];

export const ANNONCE_LIST: ISelect[] = [
  // {name: 'QUEUE_ANNONCE_DISABLED', id: 0},
  {name: 'QUEUE_ANNONCE_30_SEC', id: -1},
  {name: 'QUEUE_ANNONCE_TIME_1', id: 1},
  {name: 'QUEUE_ANNONCE_TIME_2', id: 2},
  {name: 'QUEUE_ANNONCE_TIME_3', id: 3},
  {name: 'QUEUE_ANNONCE_TIME_4', id: 4},
  {name: 'QUEUE_ANNONCE_TIME_5', id: 5}
]

export const PERIOD_LOCKED_LIST: ISelect[] = [
  {name: 'LOCKED_0', id: -1},
  {name: 'LOCKED_5', id: 5*60},
  {name: 'LOCKED_15', id: 15*60},
  {name: 'LOCKED_30', id: 30*60},
  {name: 'LOCKED_60', id: 60*60},
  {name: 'LOCKED_24', id: 24*60*60}
]

export const CALL_STAUS_LIST: ISelect[] = [
    { id: 0, name: 'CALL_STATUS.ANSWERED' },
    { id: 1, name: 'CALL_STATUS.NOT_ANSWERED' },
    { id: 2, name: 'CALL_STATUS.BUSY' },
    { id: 3, name: 'CALL_STATUS.FORWARD' },
    { id: 4, name: 'CALL_STATUS.TRANSFER' }
];
export const AGENT_STATUS_LIST: any[] = [
  {name: 'AGENT.AVAILABLE', id: -1, type: 'user', icon: 'support_agent', color: '$success', colorClass: 'text-success', available_for_operator: true},
  {name: 'AGENT.ON_BREAK', id: -3, type: 'user', icon: 'free_breakfast', color: '$muted', colorClass: 'text-muted', available_for_operator: true},
  {name: 'AGENT.LOGGED_OUT', id: -4, type: 'user', icon: 'exit_to_app', color: '$danger', colorClass: 'text-danger', available_for_operator: true},
  {name: 'AGENT.WAITING', id: -21, type: 'system', icon: 'hourglass_bottom'},
  {name: 'AGENT.RECEIVING', id: -22, type: 'system', icon: 'hourglass_bottom'},
  {name: 'AGENT.RESERVED', id: -24, type: 'system', icon: 'hourglass_bottom'},
  {name: 'AGENT.IN_A_QUEUE_CALL', id: -23, type: 'system', icon: 'phone'},
  {name: 'USER.NO_REG', id: -30, type: 'system', icon: 'phone_disabled'},
  {name: 'USER.UNKNOW', id: 0, type: 'system', icon: 'error_outline'},
  {name: 'USER.REG', id: -31, type: 'system', icon: 'support_agent'},
]

export const AGENT_STATUS_BY_ID: any = {
  '-1': 'AGENT.AVAILABLE',
  // '-2': 'AGENT.AVAILABLE_ON_DEMAND',
  '-3': 'AGENT.ON_BREAK',
  '-4': 'AGENT.LOGGED_OUT'
};

export const ICONS = {
  mat: [    // Material
    //включен
    'how_to_reg', 'local_phone', 'sentiment_satisfied_alt', 'add_ic_call', 'record_voice_over',

    //из дома
    'support_agent', 'headset_mic', 'headset', 'home_work', 'home', 'house_siding', 'laptop',

    //на перерыве
    'phone_paused', 'local_cafe', 'local_pizza', 'emoji_food_beverage',

    //не зарегистрирован
    'voice_over_off', 'phone_disabled', 'sentiment_dissatisfied', 'perm_identity', 'airport_shuttle'
  ],
  ti: [],   // Themify icons
  fa: [],   // Font Awesome
  fa5: [],  // Font Awesome5
  bs: []    // Bootstrap Glyphicon
}

export enum ModeDomain {
  SIMPLE = 0,
  BASE = 1,
  CC = 2
}



export const DOMAIN_MODE_LIST = [
  { name: 'MODE.SIMPLE', id: ModeDomain.SIMPLE},
  { name: 'MODE.BASE', id: ModeDomain.BASE},
  { name: 'MODE.CC', id: ModeDomain.CC}
];

export const ALL_DOMAIN_MODE = [ModeDomain.SIMPLE, ModeDomain.BASE, ModeDomain.CC];

export const CONTEXT_DOMAIN_NOTIFY: ISelect[] = [
  {name: 'CONTEXT.SMS', id: 0},
  {name: 'CONTEXT.EMAIL', id: 1}
];

export const CONTEXT_DP: ISelect[] = [
  {name: 'CONTEXT.OUTBOUND', id: 0},
  {name: 'CONTEXT.INBOUND', id: 1},
  {name: 'CONTEXT.FUTURE', id: 2}
];

export const CATEGORY_DP: ISelect[] = [
  {name: 'TEXT_TEMPLATE_CATEGORY_FSXML', id: 1},
  {name: 'TEXT_TEMPLATE_CATEGORY_SYSTEM_TEXT', id: 2},
  {name: 'TEXT_TEMPLATE_CATEGORY_DOMAIN_NOTIFY', id: 3}
];

export const CATEGORY_TITLE_LIST: ISelect[] = [
  {name: 'SYSTEM_XML_TEMPLATES', id: 1},
  {name: 'SYSTEM_TEXT_TEMPLATES', id: 2},
  {name: 'SYSTEM_NOTIFY_TEMPLATES', id: 3}
];

export const enum MenuView {
  PROVIDER = 0,
  DOMAIN = 1,
  SELF = 2
}

export const enum MenuPermView {
  PROVIDER = -1,
  DOMAIN = 1
}

export const PERM_VIEW: ISelect[] = [
  {name: 'ROLE_VIEW.OPERATOR', id: MenuPermView.PROVIDER},
  {name: 'ROLE_VIEW.DOMAIN', id: MenuPermView.DOMAIN}
];

export const enum PermAction {
  CREATE = 1,
  EDIT = 2,
  DELETE = 3,
  LIST = 4,
  GET = 5,
  SELECT = 6,
  DLIST = 7
}

export const enum PermProjectAction {
  EDIT = 22,
  LIST = 24,
  GET = 25,
  SELECT = 26,
  DLIST = 27
}

export const enum PermSelfAction {
  EDIT = 12,
  LIST = 14,
  GET = 15,
  SELECT = 16,
  DLIST = 17
}




export const PERM_CREATE_LIST = [PermAction.CREATE];
export const PERM_EDIT_LIST = [PermAction.EDIT, PermSelfAction.EDIT, PermProjectAction.EDIT];
export const PERM_DELETE_LIST = [PermAction.DELETE];
export const PERM_LIST_LIST = [PermAction.LIST, PermSelfAction.LIST, PermProjectAction.LIST];
export const PERM_SELECT_LIST = [PermAction.SELECT, PermSelfAction.SELECT, PermProjectAction.SELECT];
export const PERM_DLIST_LIST = [PermAction.DLIST, PermSelfAction.DLIST, PermProjectAction.DLIST];
export const PERM_GET_LIST = [PermAction.GET, PermSelfAction.GET, PermProjectAction.GET];

export const enum PermLabel {
  DENY = 'DENY',
  ALLOW = 'ALLOW',
  SELF = 'SELF',
  PROJECT = 'DOMAIN_PROJECT'
}

export const PERM_SELECT =  [
  [],
  [{name: PermLabel.DENY, id: 0}, {name:PermLabel.ALLOW, id: 1}],
  [{name: PermLabel.DENY, id: 0}, {name:PermLabel.ALLOW, id: 2}, {name: PermLabel.PROJECT, id: 22}, {name: PermLabel.SELF, id: 12}],
  [{name: PermLabel.DENY, id: 0}, {name:PermLabel.ALLOW, id: 3}],
  [{name: PermLabel.DENY, id: 0}, {name:PermLabel.ALLOW, id: 4}, {name: PermLabel.PROJECT, id: 24}, {name: PermLabel.SELF, id: 14}],
  [{name: PermLabel.DENY, id: 0}, {name:PermLabel.ALLOW, id: 5}, {name: PermLabel.PROJECT, id: 25}, {name: PermLabel.SELF, id: 15}],
  [{name: PermLabel.DENY, id: 0}, {name:PermLabel.ALLOW, id: 6}, {name: PermLabel.PROJECT, id: 26}, {name: PermLabel.SELF, id: 16}],
  [{name: PermLabel.DENY, id: 0}, {name:PermLabel.ALLOW, id: 7}, {name: PermLabel.PROJECT, id: 27}, {name: PermLabel.SELF, id: 17}]
];

export const PERM_ALL_DOMAIN = [
  {name: 'OA_CREATE_D', id: 1},
  {name: 'OA_EDIT_D', id: 2},
  {name: 'OA_DELETE_D', id: 3},
  {name: 'OA_LIST_D', id: 4},
  {name: 'OA_GET_D', id: 5},
  {name: 'OA_SELECT_D', id: 6},
  {name: 'OA_DLIST_D', id: 7},

  {name: 'OA_EDIT_D_S', id: 12},
  {name: 'OA_LIST_D_S', id: 14},
  {name: 'OA_GET_D_S', id: 15},
  {name: 'OA_SELECT_D_S', id: 16},
  {name: 'OA_DLIST_D_S', id: 17},

  {name: 'OA_EDIT_D_P', id: 22},
  {name: 'OA_LIST_D_P', id: 24},
  {name: 'OA_GET_D_P', id: 25},
  {name: 'OA_SELECT_D_P', id: 26},
  {name: 'OA_DLIST_D_P', id: 27}
]

export const PERM_ALL_OPERATOR = [
  {name: 'OA_CREATE_P', id: -1},
  {name: 'OA_EDIT_P', id: -2},
  {name: 'OA_DELETE_P', id: -3},
  {name: 'OA_LIST_P', id: -4},
  {name: 'OA_GET_P', id: -5},
  {name: 'OA_SELECT_P', id: -6},
  {name: 'OA_DLIST_P', id: -7},

  {name: 'OA_EDIT_P_S', id: -12},
  {name: 'OA_LIST_P_S', id: -14},
  {name: 'OA_GET_P_S', id: -15},
  {name: 'OA_SELECT_P_S', id: -16},
  {name: 'OA_DLIST_P_S', id: -17},
]

export const PERM_ALL = [...PERM_ALL_DOMAIN, ...PERM_ALL_OPERATOR];


export const CATEGORY_DOC: ISelect[] = [
  {name: 'INFO_STRUCTURE_TYPE_DOC', id: 0},
  {name: 'INFO_STRUCTURE_TYPE_BUG', id: 1},
  {name: 'INFO_STRUCTURE_TYPE_NEWS', id: 2},
  {name: 'INFO_STRUCTURE_TYPE_VER', id: 3},
  {name: 'INFO_STRUCTURE_TYPE_TODO', id: 4},
  {name: 'INFO_STRUCTURE_TYPE_ROADMAP', id: 5}
];

export const VIEW_DOC: ISelect[] = [
  {name: 'INFO_STRUCTURE_CUSTOMER_VIEW', id: 0},
  {name: 'INFO_STRUCTURE_PROVIDER_VIEW', id: 1},
  {name: 'INFO_STRUCTURE_ALL_VIEW', id: 2}
];

export const FK_PROFILE_TYPE: ISelect[] = [
  {name: 'FK.FAST_NUMBER', id: 'DomainPhoneFuncKey0'},
  {name: 'FK.TRANSFER', id: 'DomainPhoneFuncKey1'}
];

export const FK_ICON_NAME: string[] = ['account_balance', 'account_balance_wallet',  'account_box',  'account_circle',
'alarm', 'mood', 'mood_bad', 'notifications', 'notifications_active',
  'replay', 'phone', 'phonelink_ring'
];

export const SPEECH_VOICE: any[] = [
  { name: 'Alyss', id: 'alyss', type: 'DEPYandexSpeechKit', emotion: ['good', 'evil', 'neutral']},
  { name: 'Oksana', id: 'oksana', type: 'DEPYandexSpeechKit', emotion: ['good', 'evil', 'neutral']},
  { name: 'Jane', id: 'jane', type: 'DEPYandexSpeechKit', emotion: ['good', 'evil', 'neutral']},
  { name: 'Omazh', id: 'omazh', type: 'DEPYandexSpeechKit', emotion: ['good', 'evil', 'neutral']},
  { name: 'Zahar', id: 'zahar', type: 'DEPYandexSpeechKit', emotion: ['good', 'evil', 'neutral']},
  { name: 'Ermil', id: 'ermil', type: 'DEPYandexSpeechKit', emotion: ['good', 'evil', 'neutral']},
  { name: 'Alena', id: 'alena', type: 'DEPYandexSpeechKit', emotion: ['good', 'evil', 'neutral']},
  { name: 'maxim', id: 'maxim', type: 'DEPTinkoffVoiceKit', emotion: ['info']},
  { name: 'alyona', id: 'alyona', type: 'DEPTinkoffVoiceKit',
    emotion: ['info', 'sad', 'funny', 'flirt']},
  { name: 'dorofeev', id: 'dorofeev', type: 'DEPTinkoffVoiceKit',
    emotion: ['info', 'drama', 'comedy', 'tragedy']},
];

export const SPEECH_LANG: ISelect[] = [
  { name: 'RU', id: 'ru-RU'},
  { name: 'EN', id: 'en-US'},
  { name: 'TR', id: 'tr-TR'}
];

export const SPEECH_EMOTION: any[] = [
  { name: 'GOOD', id: 'good', type: 'DEPYandexSpeechKit'},
  { name: 'EVIL', id: 'evil', type: 'DEPYandexSpeechKit'},
  { name: 'NEUTRAL', id: 'neutral', type: 'DEPYandexSpeechKit'},
  { name: 'INFO', id: 'info', type: 'DEPTinkoffVoiceKit'},
  { name: 'SAD', id: 'sad', type: 'DEPTinkoffVoiceKit'},
  { name: 'FUNNY', id: 'funny', type: 'DEPTinkoffVoiceKit'},
  { name: 'FLIRT', id: 'flirt', type: 'DEPTinkoffVoiceKit'},
  { name: 'DRAMA', id: 'drama', type: 'DEPTinkoffVoiceKit'},
  { name: 'COMEDY', id: 'comedy', type: 'DEPTinkoffVoiceKit'},
  { name: 'TRAGEDY', id: 'tragedy', type: 'DEPTinkoffVoiceKit'}
];

export const SPEECH_MODEL: ISelect[] = [
  { name: 'DOMAINVOICEKITSOURCETEXT', id: 'DomainVoiceKitSourceText'},
  { name: 'DOMAINVOICEKITSOURCEAB', id: 'DomainVoiceKitSourceAB'},
  { name: 'DOMAINVOICEKITSOURCEDIALER', id: 'DomainVoiceKitSourceDialer'}
];

export const CONTACT_STATUS_LIST = [
  {id: '0', _id: 0,  name: 'CONTACT_STATUS_PROCESS', icon: 'sync', color: 'bg-info'},
  {id: '1', _id: 1, name: 'CONTACT_STATUS_SUCCESS', icon: 'task_alt', color: 'bg-primary'},
  {id: '2', _id: 2, name: 'CONTACT_STATUS_NOT_SUCCESS', icon: 'remove_circle_outline', color: 'bg-warning'},
  {id: '3', _id: 3, name: 'CONTACT_STATUS_LOCK', icon: 'phone_disabled', color: 'bg-muted'},
  {id: '4', _id: 4, name: 'CONTACT_STATUS_EXTERNAL', icon: 'share', color: 'bg-primary'},
];

export const DP_DIRECTION_STATUS = [
  {id: '0',_id: 0, name: 'DIRECTION_OUTBOUND', icon: 'phone_forwarded', color: 'bg-primary'},

  {id: '1',_id: 1, name: 'DIRECTION_INBOUND', icon: 'phone_callback', color: 'bg-primary'},

  {id: '2',_id: 2, name: 'DIRECTION_INTERNAL', icon: 'social_distance', color: 'bg-primary'}
];



export const DIAL_STATUS_LIST = [
  {id: '0', name: 'CONTACT_STATUS_WAIT', icon: 'hourglass_empty', color: ''},
  {id: '1', name: 'CONTACT_STATUS_CALL', icon: 'ring_volume', color: 'primary'},
];

export const ATTEMPT_STATUS_LIST = [
  {id: '0', name: 'CALL_DIAL_STATUS_ANSWERED', icon: 'task_alt', color: 'primary'},
  {id: '1', name: 'CALL_DIAL_STATUS_NO_ANSWER', icon: 'highlight_off', color: 'accent'},
  {id: '2', name: 'CALL_DIAL_STATUS_BUSY', icon: 'sync', color: 'accent'},
  {id: '-1', name: 'ATTEMPT_STATUS_ERROR', icon: 'error', color: 'warn'}
];

export const ATTEMPT_STATUS_TASK_LIST = [
   {id: '0', name: 'CALL_DIAL_STATUS_ANSWERED', icon: 'task_alt', color: 'primary'},
   {id: '1', name: 'CALL_DIAL_STATUS_NO_ANSWER', icon: 'highlight_off', color: 'accent'},
   {id: '2', name: 'CALL_DIAL_STATUS_BUSY', icon: 'sync', color: 'accent'},
   {id: '-1', name: 'ATTEMPT_STATUS_INIT', icon: 'hourglass_empty', color: 'primary'},
   {id: '-2', name: 'ATTEMPT_STATUS_OUTBOUND_CALL', icon: 'phone_forwarded', color: 'primary'},
   {id: '-3', name: 'ATTEMPT_STATUS_CLIENT_ANSWER', icon: 'task_alt', color: 'warn'},
   {id: '-4', name: 'ATTEMPT_STATUS_CLIENT_NOT_ANSWER', icon: 'highlight_off', color: 'warn'},
   {id: '-5', name: 'ATTEMPT_STATUS_ERROR', icon: 'error', color: 'warn'},
   {id: '-6', name: 'ATTEMPT_STATUS_SUCCESS', icon: 'task_alt', color: 'accent'},
   {id: '-10', name: 'ATTEMPT_STATUS_PROCESSED_BY_AGENT', icon: 'support_agent', color: 'accent'},
   {id: '-11', name: 'ATTEMPT_STATUS_CANCELLED_BY_AGENT', icon: 'error', color: 'accent'},
];

export const TASK_PROCESSING = [
  {id: 'ContactProcessingCallback', name: 'CONTACT_CALLBACK'},
  {id: 'WebCallRedirect', name: 'WEB_CALL'},
  {id: 'TaxiNotify', name: 'TAXI_NOTIFY'},
]

export const WEEK_WORKDAY = [
  {checked: false, id: 'monday', name: 'WEEK.MONDAY', _id: 0},
  {checked: false, id: 'tuesday', name: 'WEEK.TUESDAY', _id: 1},
  {checked: false, id: 'wednesday', name: 'WEEK.WEDNESDAY', _id: 2},
  {checked: false, id: 'thursday', name: 'WEEK.THURSDAY', _id: 3},
  {checked: false, id: 'friday', name: 'WEEK.FRIDAY', _id: 4}
];
export const WEEK_WEEKEND = [
  {checked: false, id: 'saturday', name: 'WEEK.SATURDAY', _id: 5},
  {checked: false, id: 'sunday', name: 'WEEK.SUNDAY', _id: 6}
];


export const TAXI_TYPE_MENU: ISelect[] = [
  { name: 'TAXI.DRIVER_IVR', id: 'driver_ivr'},
  { name: 'TAXI.DRIVER_NOTIFY', id: 'driver_notify'},
  { name: 'TAXI.CLIENT_IVR', id: 'client_ivr'},
  { name: 'TAXI.CLIENT_NOTIFY', id: 'client_notify'}
];

export const TAXI_LANG: ISelect[] = [
  { name: 'RU', id: 'ru'},
  { name: 'EN', id: 'en'}
];

export const CC_MEMBER_TAG: ISelect[] = [
  { name: 'DPEAudioCCMemberTagPositionA', id: 2},
  { name: 'DPEAudioCCMemberTagWaitTime', id: 1},
  { name: 'DPEAudioCCMemberTagPositionB', id: 4}
];

export const GOOTAX_STATUS: ISelect[] = [
  { name: 'OS_NEW', id: '0'},
  { name: 'OS_DRIVER_SET', id: '1'},
  { name: 'OS_DRIVER_ON_ROAD', id: '2'},
  { name: 'OS_INVITE', id: '3'},
  { name: 'OS_EXECUTING', id: '5'},
  { name: 'OS_CANCELED', id: '6'},
  { name: 'OS_COMPLETED', id: '7'},
  { name: 'OS_NOT_FOUND', id: '9'}
];

export const DOC_MEMBER_STATE: ISelect[] = [
  { name: 'DOC_MEMBER_STATE_UNKNOWN',   id: 0},
  { name: 'DOC_MEMBER_STATE_WAITING',   id: 1},
  { name: 'DOC_MEMBER_STATE_TRYING',    id: 2},
  { name: 'DOC_MEMBER_STATE_ANSWERED',  id: 3},
  { name: 'DOC_MEMBER_STATE_ABANDONED', id: 4}
];

export const DOC_ATTEMPT_REASON: ISelect[] = [
  { name: 'REASON_REJECTED_BY_AGENT',   id: 0},
  { name: 'REASON_RESPONSE_TIMEOUT',   id: 1},
  { name: 'REASON_RESPONSE_CUSTOMER_IDLE',    id: 2},
  { name: 'REASON_ANOTHER_AGENT_ACCEPT',  id: 3},
  { name: 'REASON_CLOSE_DIALOG', id: 4},
  { name: 'REASON_QUEUE_EXIT', id: 5}
];

export const ONLY_GOOTAX: ISelect[] = [
  { name: 'OS_NEW_PRE_ORDER', id: '10'},
  { name: 'OS_DRIVE_SET_PRE_ORDER', id: '11'},
]

export const NOTIFY_ALWAYS_TAGS = [
  {
    id: 'lang',
    value: 'ru',
    controlType: 'dropdown',
    placeHolder: 'LANG',
    label: 'LANG',
    required: true,
    options: TAXI_LANG,
    errorMessage: 'ERROR.REQUIRED'
  }
]


export const NOTIFY_TAGS = [
  { name: 'TAG.INTEGER', id: 'DPEDomainDialerContactIntegerTag', dynamic: [
      {
        id: 'split_size',
        value: 0,
        controlType: 'dropdown',
        placeHolder: 'SPLIT_SIZE',
        label: 'SPLIT_SIZE',
        required: true,
        options: [
          {id: 0, name: 0},
          {id: 1, name: 1},
          {id: 2, name: 2},
          {id: 3, name: 3}
        ],
        errorMessage: 'ERROR.REQUIRED'
      },
      ...NOTIFY_ALWAYS_TAGS
    ]
  },
  { name: 'TAG.MONEY', id: 'DPEDomainDialerContactMoneyTag', dynamic: [
      {
        id: 'currency',
        value: 1,
        controlType: 'dropdown',
        placeHolder: 'TAG_CURRENCY',
        label: 'TAG_CURRENCY',
        required: true,
        options: [
          {id: 1, name: 'RUB'}
        ],
        errorMessage: 'ERROR.REQUIRED'
      },
      ...NOTIFY_ALWAYS_TAGS
    ]
  },
  { name: 'TAG.PHRASE', id: 'DPEDomainDialerContactPhraseTag', dynamic: [
      ...NOTIFY_ALWAYS_TAGS
    ]
  }
]

// желательно не переименновывать, т.к. по этим полям идет поиск при импорте контактов для автосопоставления колонок
export const CRM_FIELDTYPES = {
  phone: ['PHONE_WORK', 'PHONE_MOBILE','PHONE_HOME'],
  email: ['EMAIL_WORK','EMAIL_HOME'],
  address: ['ADDRESS_ACTUAL', 'ADDRESS_LEGAL']
};


export const TRANSFORMERS_LABELS = {
  '0': 'ADMIRATION',
  '1': 'AMUSEMENT',
  '2': 'ANGER',
  '3': 'ANNOYANCE',
  '4': 'APPROVAL',
  '5': 'CARING',
  '6': 'CONFUSION',
  '7': 'CURIOSITY',
  '8': 'DESIRE',
  '9': 'DISAPPOINTMENT',
  '10': 'DISAPPROVAL',
  '11': 'DISGUST',
  '12': 'EMBARRASSMENT',
  '13': 'EXCITEMENT',
  '14': 'FEAR',
  '15': 'GRATITUDE',
  '16': 'GRIEF',
  '17': 'JOY',
  // '18': 'LOVE',
  '19': 'NERVOUSNESS',
  '20': 'OPTIMISM',
  // '21': 'PRIDE',
  '22': 'REALIZATION',
  '23': 'RELIEF',
  '24': 'REMORSE',
  '25': 'SADNESS',
  '26': 'SURPRISE',
  '27': 'QM.NEUTRAL'
};

export const DDSFieldTypes = [
  {model_name: 'DDSFormFieldLine', icon: 'horizontal_rule'},
  {model_name: 'DDSFormFieldText', icon: 'text_fields'},
  {model_name: 'DDSFormFieldShortString', icon: 'short_text'},
  {model_name: 'DDSFormFieldLongString', icon: 'subject'},
  {model_name: 'DDSFormFieldRadio', icon: 'radio_button_checked'},
  {model_name: 'DDSFormFieldSingleButton', icon: 'crop_16_9'},
  {model_name: 'DDSFormFieldCheckBox', icon: 'check_box'},
  {model_name: 'DDSFormFieldSelect', icon: 'arrow_drop_down'},
  {model_name: 'DDSFormFieldBoolean', icon: 'toggle_on'},
  {model_name: 'DDSFormFieldInt', icon: 'pin'},
  {model_name: 'DDSFormFieldDecimal', icon: '1x_mobiledata'},
  {model_name: 'DDSFormFieldPhone', icon: 'phone'},
  {model_name: 'DDSFormFieldEmail', icon: 'email'},
  {model_name: 'DDSFormFieldDate', icon: 'calendar_today'},
  {model_name: 'DDSFormFieldDateTime', icon: 'today'},
  {model_name: 'DDSFormFieldUser', icon: 'person'},
  {model_name: 'DDSFormFieldQueue', icon: ' contact_phone'},
  {model_name: 'DDSFormFieldExtUser', icon: 'person_add_alt_1'},
  {model_name: 'DDSFormFieldExtQueue', icon: 'recent_actors'}
];

export const ReportsPeriods = [
  {value: 0, viewValue: 'PERIOD.CUSTOM'},
  {value: 2, viewValue: 'PERIOD.LAST_HOUR'},
  {value: 1, viewValue: 'PERIOD.YESTERDAY'},
  {value: 3, viewValue: 'PERIOD.TODAY'},
  {value: 4, viewValue: 'PERIOD.CURRENT_WEEK'},
  {value: 5, viewValue: 'PERIOD.CURRENT_MONTH'},
  {value: 6, viewValue: 'PERIOD.CURRENT_YEAR'},
]
