import {
  propObject,
  prop,
  alphaNumeric, alpha, compare, contains, creditCard, digit, email, greaterThanEqualTo,
  greaterThan, hexColor, json, lessThanEqualTo, lowerCase, maxLength, maxNumber,
  minNumber, password, pattern,
  lessThan, range, required, time, upperCase, url, propArray, minLength, minDate, maxDate, toBoolean, unique, port, oneOf, choice
} from '@rxweb/reactive-form-validators';
import {LSAudioList} from '../../../local-stream/local-stream';





export class DPEContactProcessingNotifyChannelBase {
  @required() model_name: string = 'DPEContactProcessingNotifyChannelEmail';
  @required() @choice({minLength: 1, message: 'ERROR.REQUIRED'})
  status_list: number[] = [];
}

export class DPEContactProcessingNotifyChannelEmail extends DPEContactProcessingNotifyChannelBase {
  @required() model_name: string = 'DPEContactProcessingNotifyChannelEmail';
  @prop() text_template_id: number;
  @prop() html_template_id: number;
  @prop() subject_template_id: number;

  @required()
  user_list: number[] = [];

  @required()
  email_list: number[] = [];

}

export class DPEContactProcessingNotifyChannelTelegram  extends DPEContactProcessingNotifyChannelBase {
  @required() model_name: string = 'DPEContactProcessingNotifyChannelTelegram';
  @required() text_template_id: number;

  @required()
  user_list: number[] = [];
}

export class notifyContact {

  @required() @choice({minLength: 1, message: 'ERROR.REQUIRED'})

  @propArray(DPEContactProcessingNotifyChannelBase)
  channels: DPEContactProcessingNotifyChannelBase[] = [];

}

export class callbackContact {
  @propObject(LSAudioList)
  hello: LSAudioList = new LSAudioList();
  @required() @digit()
  queue: number;
  @required()
  call_direction : number = 0;
  @required() @digit()
  expire: number = 10;
  @required() @digit()
  attempts_count : number = 3;
  @required() @digit()
  delay_next_call : number = 60;
  @required() @digit()
  group_id : number;
  @required()
  external_callback : boolean = false;
  @prop()
  preview_hk: string;
  @prop()
  preview_hk_cancel: string;
  @prop() @propObject(LSAudioList)
  preview_great: LSAudioList;
  @required() @digit() @maxNumber({value: 600})
  preview_timeout: number = 60;
}

export enum PERIOD_CONTACT {
  PERIOD_HOUR,
  PERIOD_DAY,
  PERIOD_WEEK,
  PERIOD_MONTH,
  PERIOD_YEAR
}

export const PERIOD = {
  PERIOD_HOUR: PERIOD_CONTACT.PERIOD_HOUR,
  PERIOD_DAY: PERIOD_CONTACT.PERIOD_DAY,
  PERIOD_MONTH: PERIOD_CONTACT.PERIOD_MONTH,
  PERIOD_WEEK: PERIOD_CONTACT.PERIOD_WEEK,
  PERIOD_YEAR: PERIOD_CONTACT.PERIOD_YEAR
}

export class smsContact {
  @required()
  text_template_id: number;
  @required()
  provider: number;
  @prop()
  sender: string;
  @required()
  status: number = 0;
  @digit()
  period: number = 1;
  @digit()
  count_per_period: number = 1;
}

export class searchContact {
  @digit()
  period: number = 0;
  @required()
  status_list: number[] = [];
  @prop()
  direction_list: number[] = [];
  @prop()
  gw_number_list: number[] = [];
}

export class managerContact {
  @propObject(LSAudioList)
  hello: LSAudioList = new LSAudioList();
  @propObject(LSAudioList)
  bye: LSAudioList = new LSAudioList();
  @required()
  timeout: number = 45;
}

export class calcContact {
  @prop()
  name: string;
  @prop()
  number: string;
}

export class DPCContactProcessing {
  @prop() name: string = '';

  @propObject(notifyContact)
  notify: notifyContact;

  @propObject(smsContact)
  sms: notifyContact;

  @propObject(managerContact)
  call_to_manager: managerContact;

  @propObject(callbackContact)
  callback: callbackContact;

  @propObject(searchContact)
  search: searchContact;

  @propObject(calcContact)
  effective_value: calcContact;

}
