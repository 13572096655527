import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'momentDateTime'
})

export class MomentDateTimePipe implements PipeTransform {
  transform(value: string, lang, withSeconds: boolean = false, empty: boolean = false, tz: string = null): any {
    // lang - передан для того, чтобы пайп заметил изменение языка и сам перерисовал дату
    if (value) {
      if (tz) {
        return moment.utc(value, 'YYYY-MM-DD HH:mm:ss').tz(tz).format(withSeconds ? 'L LTS' : 'L LT');
      } else {
        return moment(value, 'YYYY-MM-DD HH:mm:ss').format(withSeconds ? 'L LTS' : 'L LT');
      }
    }
    if (empty) {
      return '-';
    } else return moment().format('L');
  }
}
