import {
  propObject,
  prop,
  alphaNumeric, alpha, compare, contains, creditCard, digit, email, greaterThanEqualTo,
  greaterThan, hexColor, json, lessThanEqualTo, lowerCase, maxLength, maxNumber,
  minNumber, password, pattern,
  lessThan, range, required, time, upperCase, url, propArray, minLength, minDate, maxDate, toBoolean, unique, port, oneOf, choice
} from '@rxweb/reactive-form-validators';
import {DPCScheduleLeg} from './components/schedule/dialplan.schedule';
export enum DialPlanTypeEnum {
  DPEStartInbound = 'DPEStartInbound',
  DPEStartOutbound = 'DPEStartOutbound',
  DPEStartInternal = 'DPEStartInternal',
}


export const DPMain = [
  DialPlanTypeEnum.DPEStartOutbound, DialPlanTypeEnum.DPEStartInbound, DialPlanTypeEnum.DPEStartInternal
]

export type DialPlanType = keyof typeof DialPlanTypeEnum;

export interface IDialPlanLags {
  label: string;
  leg: IDialPlanNode;
  value: any;
}

export interface IDialPlanDefaultLag {
  label: string;
  leg: IDialPlanNode;
}

export interface IDialPlanNode {
  dpe_params: any;
  dpe_name: string;
  leg?: IDialPlanNode;
  legs?: IDialPlanLags[];
  default_leg?: IDialPlanDefaultLag;
}

export class DialPlanDefaultLag implements IDialPlanDefaultLag {
  @prop() label: string;
  @prop() leg: any;
}

export class DialPlanLags implements IDialPlanLags {
  @required() label: string;
  @prop() value = null;
  @prop() leg: any;
}

export class DialPlanLagsLists implements IDialPlanLags {
  @required() label: string;
  @required() @choice({minLength: 1, message: 'ERROR.REQUIRED'}) value: Array<number> = [0];
  @prop() leg: any;
}

export class DialPlanLagsGroups implements IDialPlanLags {
  @required() label: string;
  @required() @choice({minLength: 1, message: 'ERROR.REQUIRED'}) value: Array<number> = [0];
  @prop() leg: any;
}

export class DialPlanLagsSchedule implements IDialPlanLags {
  @required() label: string;
  @propArray(DPCScheduleLeg) value: DPCScheduleLeg[] = [new DPCScheduleLeg()];
  @prop() leg: any;
}

export class DialPlanNode implements IDialPlanNode {
  @prop() name?: string;
  @prop() dpe_name: string;
  @prop() dpe_params: object = {name: '  '};
  @propObject(DialPlanNode)
  leg?: DialPlanNode;

  @propArray(undefined, {entityProvider: function () {
      return this.dpe_name == 'DPELists' ? DialPlanLagsLists : ( this.dpe_name == 'DPEGroups' ? DialPlanLagsGroups :(this.dpe_name == 'DPESchedule' ? DialPlanLagsSchedule : DialPlanLags))
  }})
  legs?: DialPlanLags[] | DialPlanLagsLists[] |  DialPlanLagsGroups[] | DialPlanLagsSchedule[];
  @propObject(DialPlanDefaultLag)
  default_leg?: DialPlanDefaultLag;
}

export class DialPlanRoot {
  @prop()
  version_name: string = '';
  @digit()
  cdr: number = 1;
  @digit()
  recording: number = 1;
  @propObject()
  leg?: any;
  @prop()
  dpe_name: DialPlanType;
}

export class DialPlanInbound extends DialPlanRoot {
  @prop()
  dpe_name: DialPlanType = DialPlanTypeEnum.DPEStartInbound;
}

export class DialPlanOutbound extends DialPlanRoot {
  @prop()
  dpe_name: DialPlanTypeEnum.DPEStartOutbound;
}

export class DialPlanInternal extends DialPlanRoot {
  @prop()
  dpe_name: DialPlanTypeEnum.DPEStartInternal;
}



export class DialPlan {
  @prop() id?: number;
  @required()
  name = '';
  @digit()
  status: number = 1;
  @prop()
  type = 1;
  @prop()
  domain_id: number;
  @digit()
  priority: number = 5;
  @prop()
  hidden = false;
  @prop()
  @choice({minLength: 1, conditionalExpression: x => x.condition_source == 3, message: 'ERROR.REQUIRED'})
  condition: string[] = [];
  @digit()
  condition_source: number = 1;
  @digit()
  condition_type: number = 2;
  @prop()
  toll_allow: number[] = [];
  @propObject(DialPlanRoot)
  body: DialPlanRoot;

  @prop()
  @required({ conditionalExpression: x => x.condition_source == 3, message: 'ERROR.REQUIRED'})
  @minLength({value: 1})
  set condition_expert(val: string) {
    if (this.condition_source == 3 && Array.isArray(this.condition) && this.condition.length == 2) this.condition[0] = val;
  }
  get condition_expert(): string {
    if (this.condition_source == 3 && Array.isArray(this.condition) && this.condition.length == 2) {
      return this.condition[0];
    } else '';

  }



  @prop()
  @required({ conditionalExpression: x => x.condition_source == 3, message: 'ERROR.REQUIRED'})
  @minLength({value: 1})
  set conditions(val: string | string[]) {
    if (this.condition_source == 3 && Array.isArray(this.condition)) {
      this.condition[1] = val.toString();
    }
    else if (Array.isArray(val)) {
      this.condition=val;
    }
    else {
      this.condition=[val];
    }
  }

  get conditions(): string | string[] {
    if (this.condition_source == 1 || this.condition_source == 4 || this.condition_source == 5) {
      if (Array.isArray(this.condition)) return this.condition;
      else return [this.condition];
    }
    else if (this.condition_source == 3) {
      if (this.condition.length == 2) {
        return this.condition[1]
      } else if (this.condition.length == 1) return this.condition[0];
      else return '';
    }
    else if (Array.isArray(this.condition) && this.condition.length>0){
      return this.condition[0];
    }
    else {
      return this.condition;
    }

  }

  static className() {
    return 'DomainDialPlan';
  };

}
