import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { KtdGridComponent, KtdGridLayout, ktdTrackById } from '@katoid/angular-grid-layout';
import {Subscription} from 'rxjs';
import { DOCUMENT } from '@angular/common';
import {FormControl, FormGroup} from '@angular/forms';
import {StorageMap} from '@ngx-pwa/local-storage';
import {AppService} from '../../../app.service';
import {DomainService} from '../../../domains/domain.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {NotifyService} from '../../../_helpers/notify.service';
import {MonitorWSService} from '../../_common/monitor-ws.service';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
import {BaseDesktopComponent} from '../../_common/base-desktop.component';

import GRID_DATA from './kept-grid.json';
import {DesktopService} from '../../_common/base-desktop.service';
import {KeptSettingsService} from './components/smtp-settings/kept-setting.service';
import {KeptSettingsDesktop} from './components/smtp-settings/smtp-setting';
import {DomainProjectService} from '../../../domains/domain-projects/domain-project.service';

@Component({
  selector: 'app-desktop-kept',
  templateUrl: './desktop-kept.component.html',
  styleUrls: ['../../_common/desktop.component.scss'],
  providers: [MonitorWSService]
})
export class DesktopKeptComponent extends BaseDesktopComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(KtdGridComponent, {static: true}) grid: KtdGridComponent;

  public data: any = GRID_DATA.data;
  public layout: any = GRID_DATA.layout;
  public storageName = 'KEPT';

  trackById = ktdTrackById;
  settings: FormGroup = new FormGroup({
    StaffDirectoryComponent: new FormControl(true),
    ExternalClientsComponent: new FormControl(true),
    //ExternalSearchComponent: new FormControl(true),
    //DesktopLocalCallComponent: new FormControl(true),
    InboundCallsComponent: new FormControl(true),
    DesktopQueueComponent: new FormControl(true),
    DesktopAgentComponent: new FormControl(true),
    HistoryCallsKeptComponent: new FormControl(true),
    HistoryTransferComponent: new FormControl(true),
    TiersTableComponent: new FormControl(true),
    DragAndResize: new FormControl(false),
    // IFRAME: new FormControl(true),
  });
  projectSettings = {};
  subscription: Subscription = new Subscription();
  cols = 50;
  rowHeight = 10;
  tabIndex = 0;
  compactType = 'vertical'
  constructor(@Inject(DOCUMENT) public document: Document,
              public appService: AppService,
              public desktopService: DesktopService,
              public fb: RxFormBuilder,
              public translate: TranslateService,
              public domainService: DomainService,
              public projectService: DomainProjectService,
              public settingsService: KeptSettingsService,
              public monitorWSService: MonitorWSService,
              public authenticationService: AuthenticationService,
              public notifyService: NotifyService,
              public storage: StorageMap) {
    super(document, appService, desktopService, fb, translate, domainService, monitorWSService,
      authenticationService, notifyService, storage)

    this.subscription.add(this.settingsService.get(null).subscribe(
      (settings: KeptSettingsDesktop) =>
      {
        if (this.authenticationService.isRoleCustom()) {
          this.projectSettings = {
            ExternalClientsComponent: false,
            StaffDirectoryComponent: false,
            HistoryTransferComponent: false
          }
          this.subscription.add(this.projectService.get(null, {user_id: this.authenticationService.getUserId(), __ignore__: {10002: true}}, 'get_my_project')
            .subscribe((project: any)=>
            {

              this.settings.get('ExternalClientsComponent').setValue(
                  settings?.show_clients?.some(r=>project?.project_id_list?.includes(r)) || false);
              this.settings.get('StaffDirectoryComponent').setValue(
                  settings?.show_staff_directory?.some(r=>project?.project_id_list?.includes(r)) || false);
              this.settings.get('HistoryTransferComponent').setValue(
                settings?.show_transfers?.some(r=>project?.project_id_list?.includes(r)) || false);


              this.projectSettings = {
                ExternalClientsComponent: settings?.show_clients?.some(r=>project?.project_id_list?.includes(r)) || false,
                StaffDirectoryComponent: settings?.show_staff_directory?.some(r=>project?.project_id_list?.includes(r)) || false,
                HistoryTransferComponent: settings?.show_transfers?.some(r=>project?.project_id_list?.includes(r)) || false
              }

              console.log(this.projectSettings);



            }));
        }
      }
    ));
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  loadStorage() {
    this.settingsService.get(null).subscribe(
      (data: KeptSettingsDesktop) => {

        if (data.interface) {
          if (data.interface?.columns) {
            this.storage.set(`${this.storageName}::DESKTOP::COLUMNS`, data.interface?.columns).subscribe(
              _=>{
                const store: any = data.interface?.columns;
                this.notifyService.message('COLUMNS_LOAD_TEMPLATE');
                Object.keys(store || {}).forEach(key=> {
                  this.desktopService.findColumns(this.data, key)?.forEach(column=>{
                    console.log(column);
                    if (store?.[key]?.includes(column.id)) column.checked = true;
                    else column.checked = false;
                  });
                  this.desktopService.setMenu({name: key, columns: store[key] || []});
                })

                this.redraw();
              });
          }
          if (data.interface?.settings) {
            this.storage.set(`${this.storageName}::DESKTOP::SETTINGS`, data.interface?.settings).subscribe(
              _=>{
                const store: any = data.interface?.settings;
                if (store?.layout) {
                  this.layout = store.layout;
                }
                if (store?.settings) {
                  Object.keys(store?.settings).forEach(item=>{
                    if (this.projectSettings.hasOwnProperty(item)) store.settings[item] = this.projectSettings[item];

                  });
                  this.settings.patchValue(store.settings);
                }

                this.redraw();
                this.notifyService.message('SETTINGS_LOAD_TEMPLATE');
              }
            );
          }
        } else {
          this.notifyService.message('NO_TEMPLATE');
        }
      },
      resp => { this.notifyService.message('NO_TEMPLATE') }
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.storage.get(`${this.storageName}::DESKTOP::COLUMNS`).subscribe(
      (store: any) => {
        Object.keys(store || {}).forEach(key=> {
          this.desktopService.findColumns(this.data, key)?.forEach(column=>{
            console.log(column);
            if (store?.[key]?.includes(column.id)) column.checked = true;
            else column.checked = false;
          });
          this.desktopService.setMenu({name: key, columns: store[key] || []});
        })
      });
  }
}
