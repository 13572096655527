import {required, prop, maxLength, numeric, NumericValueType} from '@rxweb/reactive-form-validators';

export class DomainAgentStatus {
  @prop() id?: number;
  @required() @maxLength({value: 150}) name: string;
  @required() @numeric({acceptValue: NumericValueType.Both}) base_status_id: number; // -1 (В Очереди), -2 (В Очереди(Одиночный)), -3 (На перерыве), -4 (Вне очереди)
  @required() icon: string;
  @prop() hk: string; // '3', '4', '5', '6', '7', '8', '9'
  @prop() color: string;
  @required() available_for_mod: number = 0;  // ANY = 0, CALL = 1, OMNI = 2
  @required() group: number = 0; // OTHER = 0, LUNCH = 1, POSTPROCESSING = 2, TRAINING = 3, TECHNICAL = 4, PROCESSING = 5
  @prop() available_for_org_unit: number[] = []; // Если не указан список, доступно всем
  @required() available_for_operator: boolean = true;
  @required() deny_inbound_call: boolean = false;

  static className() {
    return 'DomainAgentStatus';
  }
}
