import {Component, OnInit, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import {ReactiveFormConfig} from '@rxweb/reactive-form-validators';
// import {AppService} from './app.service';
import {JsonAppConfigService} from './config/json-app-config.service';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS,} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {NGX_MAT_DATE_FORMATS, NgxMatDateAdapter} from '@angular-material-components/datetime-picker';
import {NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NGX_MAT_MOMENT_FORMATS, NgxMatMomentAdapter} from '@angular-material-components/moment-adapter';
import * as FontFaceObserver from 'fontfaceobserver';
// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';
const moment = _rollupMoment || _moment;
declare const tinycolor: any;

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    // {provide: MAT_DATE_LOCALE, useValue: 'ja-JP'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},

    {provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter, deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: NGX_MAT_DATE_FORMATS, useValue: NGX_MAT_MOMENT_FORMATS}
  ]
})
export class AppComponent implements OnInit {
  bodyFontFamily = 'Roboto';
  bodyHeadingFontFamily = 'Roboto';

  primaryColor = '#607d8b';
  primaryContrastColor = '#ffffff';
  primaryColorPalette: Color[] = [];
  primaryGreyPalette: Color[] = [];
  primaryBluePalette: Color[] = [];
  primaryContrastPalette: Color[] = [];
  accentContrastPalette: Color[] = [];
  warnContrastPalette: Color[] = [];
  accentColor = '#9e9e9e';
  accentContrastColor = '#ffffff';
  accentColorPalette: Color[] = [];
  warnColor = '#f44336';
  warnContrastColor = '#ffffff';
  warnColorPalette: Color[] = [];

  bglogin = '/assets/personal/runtel.jpg';
  logoicon = '/assets/personal/logo.png';
  logomini = '/assets/personal/logo_mini.png';

  favicon = '';
  bodyColor = '#eeeeee';
  dpBorderColor = '#d8dcdf';
  dpElemHover ='#dee5ea';
  sidebarIconColor = '#757575';
  sidebarTextColor = '#212121';
  headerBg ='#F5F5F5';
  headerColor ='#212121';
  gaussPalette = 0;
  authWindowPosition = 0;

  paletteGrey = '#9E9E9E';
  paletteBlue = '#2196f3';

  extraBlueInverse = '#bbdefb';
  sidebarWidth = 240;
  minSidebarWidth = 80;

  constructor(
    public renderer: Renderer2,
    public titleService: Title,
    public translate: TranslateService,
    // public appService: AppService,
    private AppConfig: JsonAppConfigService,
    private _adapter: DateAdapter<any>,
    private _dt_adapter: NgxMatDateAdapter<any>
  ) {
    const materialIcons = new FontFaceObserver('Material Icons');
    materialIcons.load(null, 10000)
      .then(() => this.renderer.addClass(document.body, 'material-icons-loaded'))
      .catch(() => this.renderer.addClass(document.body, 'material-icons-error'));

      this.titleService.setTitle(this.AppConfig.getValue('title')); // установка заголовка страницы
      // console.log([this.AppConfig.getValue('locales')]);
      translate.addLangs(this.AppConfig.getValue('locales'));

      const browserLang = translate.getBrowserLang();
      const lang = this.AppConfig.getValue('defaultLocale', browserLang.match(/en|ru/) ? browserLang : translate.defaultLang);
      translate.setDefaultLang(lang);
      const currLang = localStorage && localStorage.getItem('ifaceLang') ? localStorage.getItem('ifaceLang') : lang;
      this.translate.use(currLang);
      this._adapter.setLocale(currLang);
      this._dt_adapter.setLocale(currLang);
      moment.locale(currLang);

      let bglogin_imgs = this.AppConfig.getValue('backgroundLogin', this.bglogin);
      if (bglogin_imgs.constructor === Array && bglogin_imgs.length > 0) {
        this.bglogin = bglogin_imgs[Math.floor(Math.random()*bglogin_imgs.length)];
      } else if (bglogin_imgs) this.bglogin = bglogin_imgs;
      this.logomini = this.AppConfig.getValue('iconLogoMini', this.logomini);
      this.logoicon = this.AppConfig.getValue('iconLogo', this.logoicon);
      this.gaussPalette = this.AppConfig.getValue('gaussPalette', this.gaussPalette);
      this.authWindowPosition = this.AppConfig.getValue('authWindowPosition', this.authWindowPosition);
      this.favicon = this.AppConfig.getValue('favicon', this.favicon);
      this.primaryColor = this.AppConfig.getValue('primaryColor', this.primaryColor);
      this.primaryContrastColor = this.AppConfig.getValue('primaryContrastColor', this.primaryContrastColor);
      this.accentColor = this.AppConfig.getValue('accentColor', this.accentColor);
      this.accentContrastColor = this.AppConfig.getValue('accentContrastColor', this.accentContrastColor);
      this.warnColor = this.AppConfig.getValue('warnColor', this.warnColor);
      this.warnContrastColor = this.AppConfig.getValue('warnContrastColor', this.warnContrastColor);
      this.bodyColor = this.AppConfig.getValue('bodyColor', this.bodyColor);
      this.dpBorderColor = this.AppConfig.getValue('dpBorderColor', this.dpBorderColor);
      this.dpElemHover = this.AppConfig.getValue('dpElemHover', this.dpElemHover);
      this.sidebarIconColor = this.AppConfig.getValue('sidebarIconColor', this.sidebarIconColor);
      this.sidebarTextColor = this.AppConfig.getValue('sidebarTextColor', this.sidebarTextColor);
      this.headerBg = this.AppConfig.getValue('headerBg', this.headerBg);
      this.headerColor = this.AppConfig.getValue('headerColor', this.headerColor);

      this.savePrimaryColor();
      this.saveAccentColor();
      this.saveWarnColor();
      this.savePict();
      this.saveGreyColor();
      this.saveBlueColor();
      this.saveOtherStyles();
  }

  ngOnInit(): void {
    ReactiveFormConfig.set({
      internationalization: {
        dateFormat: 'dmy',
        seperator: '.'
      },
      validationMessage: {
        alpha: 'NOTIFY.ALPHA',
        alphaNumeric: 'NOTIFY.ALPHA_NUMERIC',
        compare: 'NOTIFY.COMPARE',
        contains: 'NOTIFY.CONTAINS',
        creditcard: 'NOTIFY.CREDIT_CARD',
        digit: 'NOTIFY.DIGIT',
        email: 'NOTIFY.EMAIL',
        greaterThanEqualTo: 'NOTIFY.GREATER_THAN_EQUAL_TO',
        greaterThan: 'NOTIFY.GREATER_THAN',
        hexColor: 'NOTIFY.HEX_COLOR',
        ip: 'ERROR.IP',
        json: 'NOTIFY.JSON',
        lessThanEqualTo: 'NOTIFY.LESS_THAN_EQUAL_TOCOMPARE',
        lessThan: 'NOTIFY.LESS_THAN',
        lowerCase: 'NOTIFY.LOWER_CASE',
        maxLength: 'NOTIFY.MAX_LENGTH',
        maxNumber: 'NOTIFY.MAX_NUMBER',
        minNumber: 'NOTIFY.MIN_NUMBER',
        maxTime: 'NOTIFY.MAX_TIME',
        minTime: 'NOTIFY.MIN_TIME',
        password: 'NOTIFY.PASSWORD',
        pattern: 'NOTIFY.PATTERN',
        range: 'NOTIFY.RANGE',
        required: 'ERROR.REQUIRED',
        time: 'NOTIFY.TIME',
        upperCase: 'NOTIFY.UPPER_CASE',
        url: 'NOTIFY.URL',
        zipCode: 'NOTIFY.ZIP_CODE',
        minLength: 'NOTIFY.MIN_LENGTH'
      }
    });
  }

  savePict() {
    document.documentElement.style.setProperty('--bglogin-img', `url('${this.bglogin}')`);
    document.documentElement.style.setProperty('--logoicon-img', `url('${this.logoicon}')`);
    document.documentElement.style.setProperty('--logoicon-mini-img', `url('${this.logomini}')`);
    document.documentElement.style.setProperty('--favicon-img', `url('${this.favicon}')`);

    let link: HTMLLinkElement = document.createElement('link');
    link.setAttribute('rel', 'icon');
    link.setAttribute('href', this.favicon);
    link.setAttribute('type', 'image/x-icon');
    document.head.appendChild(link);
  }

  savePrimaryColor() {
    this.primaryColorPalette = computeColors(this.primaryColor, this.gaussPalette);
    this.primaryContrastPalette = computeColors(this.primaryContrastColor, this.gaussPalette);

    for (const color of this.primaryColorPalette) {
      const key1 = `--theme-primary-${color.name}`;
      const value1 = color.hex;
      document.documentElement.style.setProperty(key1, value1);
    }

    for (const color of this.primaryContrastPalette) {
      const key2 = `--theme-primary-contrast-${color.name}`;
      const value2 = color.hex;
      document.documentElement.style.setProperty(key2, value2);
    }
  }

  saveGreyColor() {
    this.primaryGreyPalette = computeColors(this.paletteGrey, this.gaussPalette);
    for (const color of this.primaryGreyPalette) {
      const key1 = `--theme-grey-${color.name}`;
      const value1 = color.hex;
      document.documentElement.style.setProperty(key1, value1);
    }
  }

  saveBlueColor() {
    this.primaryBluePalette = computeColors(this.paletteBlue, this.gaussPalette);
    for (const color of this.primaryBluePalette) {
      const key1 = `--theme-blue-${color.name}`;
      const value1 = color.hex;
      document.documentElement.style.setProperty(key1, value1);
    }
  }

  saveOtherStyles() {
    document.documentElement.style.setProperty('--body-font-family', this.bodyFontFamily);
    document.documentElement.style.setProperty('--body--heading-font-family', this.bodyHeadingFontFamily);

    document.documentElement.style.setProperty('--body-color', this.bodyColor);
    document.documentElement.style.setProperty('--dp-border-color', this.dpBorderColor);
    document.documentElement.style.setProperty('--dp-elem-hover', this.dpElemHover);
    document.documentElement.style.setProperty('--sidebar-icon-color', this.sidebarIconColor);
    document.documentElement.style.setProperty('--sidebar-text-color', this.sidebarTextColor);
    document.documentElement.style.setProperty('--header-bg', this.headerBg);
    document.documentElement.style.setProperty('--header-color', this.headerColor);
    document.documentElement.style.setProperty('--primary-contrast-color', this.primaryContrastColor);
    document.documentElement.style.setProperty('--accent-contrast-color', this.accentContrastColor);
    document.documentElement.style.setProperty('--warn-contrast-color', this.warnContrastColor);

    document.documentElement.style.setProperty('--extra-blue-inverse', this.extraBlueInverse);
    document.documentElement.style.setProperty('--sidebar-width', this.sidebarWidth + 'px');
    document.documentElement.style.setProperty('--min-sidebar-width', this.minSidebarWidth + 'px');

    document.documentElement.style.setProperty('--gauss-color-number', this.gaussPalette.toString());
    document.documentElement.style.setProperty('--box-pos-x-n', '-'+(10 + 20 * (this.authWindowPosition % 5 - 1))+'%');
    document.documentElement.style.setProperty('--box-pos-x', 10 + 20 * (this.authWindowPosition % 5 - 1)+'%');
    document.documentElement.style.setProperty('--box-pos-y-n', '-'+(10 + 20 * Math.floor(this.authWindowPosition / 5))+'%');
    document.documentElement.style.setProperty('--box-pos-y', 10 + 20 * Math.floor(this.authWindowPosition / 5)+'%');
  }

  saveAccentColor() {
    this.accentColorPalette = computeColors(this.accentColor, this.gaussPalette);
    for (const color of this.accentColorPalette) {
      const key1 = `--theme-accent-${color.name}`;
      const value1 = color.hex;
      document.documentElement.style.setProperty(key1, value1);
    }

    this.accentContrastPalette = computeColors(this.accentContrastColor, this.gaussPalette);
    for (const color of this.accentContrastPalette) {
      const key2 = `--theme-accent-contrast-${color.name}`;
      const value2 = color.hex;
      document.documentElement.style.setProperty(key2, value2);
    }

  }

  saveWarnColor() {
    this.warnColorPalette = computeColors(this.warnColor, this.gaussPalette);
    for (const color of this.warnColorPalette) {
      const key1 = `--theme-warn-${color.name}`;
      const value1 = color.hex;
      document.documentElement.style.setProperty(key1, value1);
    }

    this.warnContrastPalette = computeColors(this.warnContrastColor, this.gaussPalette);
    for (const color of this.warnContrastPalette) {
      const key2 = `--theme-warn-contrast-${color.name}`;
      const value2 = color.hex;
      document.documentElement.style.setProperty(key2, value2);
    }
  }
}



function computeColors(hex: string, gauss: number = 5 ): Color[] {
  function computeLevel(val) {
    return Math.floor(val * (gauss/5));
  }

  return [
    getColorObject(tinycolor(hex).lighten(computeLevel(52)), '50'),
    getColorObject(tinycolor(hex).lighten(computeLevel(37)), '100'),
    getColorObject(tinycolor(hex).lighten(computeLevel(26)), '200'),
    getColorObject(tinycolor(hex).lighten(computeLevel(12)), '300'),
    getColorObject(tinycolor(hex).lighten(computeLevel(6)), '400'),
    getColorObject(tinycolor(hex), '500'),
    getColorObject(tinycolor(hex).darken(computeLevel(6)), '600'),
    getColorObject(tinycolor(hex).darken(computeLevel(12)), '700'),
    getColorObject(tinycolor(hex).darken(computeLevel(18)), '800'),
    getColorObject(tinycolor(hex).darken(computeLevel(24)), '900'),

    getColorObject(tinycolor(hex).lighten(computeLevel(50)).saturate(30), 'A100'),
    getColorObject(tinycolor(hex).lighten(computeLevel(30)).saturate(30), 'A200'),
    getColorObject(tinycolor(hex).lighten(computeLevel(10)).saturate(15), 'A400'),
    getColorObject(tinycolor(hex).lighten(computeLevel(5)).saturate(5), 'A700')
  ];
}

function getColorObject(value, name): Color {
  const c = tinycolor(value);
  return {
    name: name,
    hex: c.toHexString(),
    darkContrast: c.isLight()
  };
}

