import { JsonRpcClient, JsonRpcClientParams } from './json-rpc'
import { VertoRtc, CallDirection } from './rtc'
import { VertoLab, generateGUID } from './verto-lab'
import { Dtmf } from './dtmf'
import {BehaviorSubject} from "rxjs";

interface VertoOptions {
  transportConfig?  : JsonRpcClientParams
  rtcConfig?        : RTCConfiguration
  debug?            : boolean
  ice_timeout?      : number
  caller_id_name?   : string
  caller_id_number? : string
  deny_inbound_call?: boolean
  // Параметры управления звонком


}

interface VertoCallOptions {
  caller_id_number? : string
  caller_id_name?   : string
  callee_id_number? : string
  callee_id_name?   : string
  attached_call?    : boolean
  codec?: string
  // Параметры управления звонком

}



class VertoConf {
  private dialog = null
  private hasVid = false
  private laData = null
  public laChannel = null
  private onBroadcast = null
  private onLaChange: null
  private onLaRow: null

  public destroyed = false

  public members = []
  private members$ =new BehaviorSubject<any>(null);

  constructor( public verto: Verto, public call: VertoCall, public params) {
    console.log(params);
    this.laData = params.laData;
    this.laChannel = params.laData.laChannel;
    this.hasVid = params.hasVid;
    this.bootstrap();
  }


  bootstrap() {
    this.modLiveCommand('bootstrap', this.laData.laName)
  }

  modLiveCommand(cmd, name) {
    this.call.rpc.call("verto.broadcast", {
      "eventChannel": this.laData.laChannel,
      "sessid": this.verto.sessionId,
      "data": {
        "liveArray": {
          "command": cmd,
          "context": this.laData.laChannel,
          "name": name
        }

      }
    });

  }

  deaf(memberID) {
    this.modCommand("deaf", parseInt(memberID));
  }

  undeaf(memberID) {
    this.modCommand("undeaf", parseInt(memberID));
  }
  muteMic(memberID) {
    this.modCommand("tmute", parseInt(memberID));
  }

  muteVideo(memberID) {
    this.modCommand("tvmute", parseInt(memberID));
  }

  kick(memberID) {
    this.modCommand("kick", parseInt(memberID));
  }

  vidFloor(memberID) {
    this.modCommand("vid-floor", parseInt(memberID), "force");
  }

  vidResId(memberID, resolution) {
    this.modCommand("vid-res-id", parseInt(memberID), resolution);
  }

  modCommand(cmd, id, value = null) {
    this.call.rpc.call("verto.broadcast", {
      "eventChannel": this.laData.modChannel,
      "data": {
        "application": "conf-control",
        "command": cmd,
        "id": id,
        "value": value
      }
    });
  }

  updateMember(member, hashKey = null) {

    if (hashKey) {
      let mem = {
        'uuid': hashKey,
        'id': member[0],
        'number': member[1],
        'name': member[2],
        'codec': member[3],
        'status': JSON.parse(member[4]),
        'email': member[5].email
      }

      let idx = this.members.findIndex(member=>member.uuid == hashKey)
      if (idx > -1) {
        this.members[idx] = mem
      } else {
        this.members.push(mem)
      }
    } else {
      this.members.push({
        'uuid': member[0],
        'id': member[1][0],
        'number': member[1][1],
        'name': member[1][2],
        'codec': member[1][3],
        'status': JSON.parse(member[1][4]),
        'email': member[1][5].email
      })
    }
    this.members$.next(this.members);
  }
  sendChat(message, type) {
    this.call.rpc.call("verto.broadcast", {
      "eventChannel":this.laData.chatChannel,
      "data": {
        "action": "send",
        "message": message,
        "type": type
      }
    });
  }

  destroy() {

    this.destroyed = true;


    if (this.laData?.chatChannel) {
      this.verto.unsubscribe(this.laData.chatChannel);
    }

    if (this.laData?.modChannel) {
      this.verto.unsubscribe(this.laData.modChannel);
    }

    this.laData = null
    this.laChannel = null

  }
  bootObj(data) {
    this.members = [];
    console.log('bootObj')
    console.log(data)
    this.updateMember(data.data[0])
  }
  init(params) {
    this.members = [];
    console.log('init')
    console.log(params)
    this.members$.next([]);
  }
  add(data) {
    console.log('add')
    console.log(data)
    this.updateMember(data.data, data.hashKey)
  }
  modify(data) {
    console.log('modify')
    console.log(data)
    this.updateMember(data.data, data.hashKey)
  }
  del(data) {
    let idx = this.members.findIndex(mem=>mem.uuid == data.hashKey)
    this.members.splice(idx, 1);
    console.log('del')
    console.log(data)
    this.members$.next(this.members);
  }
  clear(params) {
    this.members = [];
    console.log('clear')
    console.log(params)
    this.members$.next([]);
  }
  reorder(params) {
    console.log('reorder')
    console.log(params)
  }
}

const VertoMessage = {
  Invite:       'verto.invite',
  Answer:       'verto.answer',
  Bye:          'verto.bye',
  Media:        'verto.media',
  Attach:       'verto.attach',
  Modify:       'verto.modify',
  Subscribe:    'verto.subscribe',
  Unsubscribe:  'verto.unsubscribe',
  Info:         'verto.info',
  Display:      'verto.display',
  ClientReady:  'verto.clientReady',
  Punt:         'verto.punt',
  Broadcast:    'verto.broadcast',
  Event:        'verto.event'
}

class VertoCall extends VertoLab{
  private rtc: VertoRtc
  private answeringTimer;
  rpc: JsonRpcClient
  public id: string
  public options: VertoCallOptions
  public direction: CallDirection
  public holdStatus: boolean

  constructor(conf: RTCConfiguration, rpc: JsonRpcClient, dest?: string,
              id?:string, options?: VertoCallOptions, ice_timeout?: number, debug?: boolean){
    super(debug)
    this.id = id || generateGUID()
    this.options = options || <VertoCallOptions>{}
    this.direction = dest?CallDirection.Outbound:CallDirection.Inbound
    this.rtc = new VertoRtc(conf, this.direction, ice_timeout, debug, options?.codec)
    this.rpc = rpc

    this.rtc.subscribeEvent('send-offer', sessionDescription => {
      let dialogParams = Object.assign({}, this.options)
      dialogParams = Object.assign(dialogParams, {destination_number: dest, callID: this.id})
      this.rpc.call(VertoMessage.Invite, { dialogParams, sdp: sessionDescription.sdp}, data => {}, data => {})
    })

    this.rtc.subscribeEvent('send-answer', sessionDescription => {
      if (this.options?.attached_call) {
        this.rpc.call(VertoMessage.Attach, { dialogParams: {callID: this.id},
          sdp: sessionDescription.sdp}, data => {this.options.attached_call = false}, data => {})
      } else {
        this.answeringTimer = setTimeout(() => this.emitEvent('error', {message: 'ANSWER ACCEPTING TIMEOUT', code: 500}), 2000);
        this.rpc.call(
          VertoMessage.Answer,
          { dialogParams: {destination_number: dest, callID: this.id}, sdp: sessionDescription.sdp},
            data => {
              // console.log('%cAnswer result:', 'color: green; border-bottom: 1px blue solid;', data);
              if (this.answeringTimer) clearTimeout(this.answeringTimer);
            },
            data => {
            console.log('%cError occurred in answer:', 'color: red; border-bottom: 1px blue solid;', data);
              if (this.answeringTimer) clearTimeout(this.answeringTimer);
            this.emitEvent('error', data);
          }
        )
      }
    })
    this.rtc.subscribeEvent('track', track => {
      this.emitEvent('track', track)
    })
    this.rtc.subscribeEvent('stream', stream => {
      this.emitEvent('stream', stream)
    })
  }

  getStats() {
    return this.rtc.getStats()
  }

  onAnswer(sdp: string) {
    if (sdp) {
      this.rtc.onMedia(sdp)
    }
    this.emitEvent('answer')
  }

  onMedia(sdp: string) {
    this.rtc.onMedia(sdp)
    this.emitEvent('media')
  }

  addTrack(track: MediaStreamTrack){
    this.rtc.addTrack(track)
  }

  preSdp(sdp: string) {
    this.rtc.preSdp(sdp)
  }

  answer(tracks: Array<MediaStreamTrack>) {
    this.rtc.answer(tracks)
  }

  getOptions() {
    return this.options
  }


  hangup(params = {}) {
    this.rpc.call(VertoMessage.Bye, { dialogParams: {callID: this.id}, ...params}, data => {}, data => {});
    this.rtc?.close(); // принудительное закрытие
  }

  clearPeerConnection() {
    this.rtc?.close();
  }

  transfer(input: string, params?: object) {
    this.rpc.call(VertoMessage.Modify,
      {action: 'transfer', destination: input, dialogParams: {callID: this.id, ...this.options, ...params}}, data => {
          this.emitEvent('transfer', this)
      }, data => {})
  }

  xref(replaceCallID: string, params?: object) {
    this.rpc.call(VertoMessage.Modify,
      {action: 'replace', replaceCallID: replaceCallID, dialogParams: {callID: this.id, ...this.options, ...params}}, data => {
        this.emitEvent('replace', this)
      }, data => {})
  }

  dtmf(input: string) {
        this.rpc.call(VertoMessage.Info,
          { dtmf: input.toString(), dialogParams: {callID: this.id}}, data => {}, data => {})
  }

  hold(params?: object) {
    this.rpc.call(VertoMessage.Modify,
      {action: 'hold', dialogParams: {callID: this.id, ...this.options, ...params}},(data: {holdState:string}) => {
      if (data.holdState === 'held') {
        this.holdStatus = true
          this.emitEvent('hold', this)
      } else {
          this.holdStatus = false
          this.emitEvent('unhold', this)
      }
    }, data => {})
  }

    unhold(params?: object) {
        this.rpc.call(VertoMessage.Modify,
          {action: 'unhold', dialogParams: {callID: this.id, ...this.options, ...params}},(data: {holdState:string}) => {
            if (data.holdState === 'held') {
                this.holdStatus = true
                this.emitEvent('hold', this)
            } else {
                this.holdStatus = false
                this.emitEvent('unhold', this)
            }
        }, data => {})
  }

    toggleHold(params?: object) {
        this.rpc.call(VertoMessage.Modify,
          {action: 'toggleHold', dialogParams: {callID: this.id, ...this.options, ...params}},(data: {holdState:string}) => {
            if (data.holdState === 'held') {
                this.holdStatus = true
                this.emitEvent('hold', this)
            } else {
                this.holdStatus = false
                this.emitEvent('unhold', this)
            }
        }, data => {})
  }

}

class Verto extends VertoLab{

  private calls     : {[key:string]: VertoCall} = {}
  private rpc       : JsonRpcClient
  private options   : VertoOptions
  private sessid    : string
  private logged_in : boolean = false

  private SERNO = 1;
  private eventSUBS = {};
  public get sessionId(): string {
    return this.sessid
  };

  constructor(options: VertoOptions) {
    super(options.debug)
    this.options = options
    this.rpc = new JsonRpcClient(options.transportConfig, options.debug)
    this.rpc.setEventHandler((method, params) => {
      switch(method) {
        case VertoMessage.Answer: {
          let callID: string = params.callID
          this.calls[callID].onAnswer(params.sdp)
          this.emitEvent('answer', params)
          break
        }
        case VertoMessage.Attach: {
          let call = new VertoCall(this.options.rtcConfig,this.rpc,'',params.callID,
            { attached_call: true,
              caller_id_name: params.caller_id_name, caller_id_number: params.caller_id_number,
              callee_id_name: params.callee_id_name, callee_id_number: params.callee_id_number
            },
            this.options.ice_timeout,
            this.options.debug
          )
          call.preSdp(params.sdp)
          this.calls[params.callID] = call
          this.emitEvent('attach',call)
          break
        }
        case VertoMessage.Media: {
          let callID: string = params.callID
          this.calls[callID].onMedia(params.sdp)
          this.emitEvent('media', params)
          break
        }
        case VertoMessage.Invite: {
          if (!(this.options.deny_inbound_call && Object.keys(this.calls).length > 0)) {
            let call = new VertoCall(this.options.rtcConfig, this.rpc, '', params.callID,
              {
                caller_id_name: params.caller_id_name, caller_id_number: params.caller_id_number
              },
              this.options.ice_timeout, this.options.debug)
            call.preSdp(params.sdp)
            this.calls[params.callID] = call
            this.emitEvent('invite', call)
          } else {
            console.log('%cОстались вызовы, не могу принять входящий вызов, т.к. включена Одноканальность у Сотрудника:', 'color:red; backgroud: lightblue;', this.calls);
            this.rpc.call(VertoMessage.Bye, { dialogParams: {callID: params.callID}, cause: 'USER_BUSY', causeCode: 17}, data => {}, data => {});
          }
          break
        }
        case VertoMessage.Bye: {
          this.emitEvent('bye', params.callID)
          if (this.calls[params.callID]) {
            let call = this.calls[params.callID];
            call.clearPeerConnection();
            delete this.calls[params.callID];
            call = null;
          }
          break
        }

        case VertoMessage.Event: {
          var list = null;
          var key = null;

          if (params.eventChannel) {
            key = params.eventChannel;
          }

          if (key) {
            list = this.eventSUBS[key];

            if (!list) {
              list = this.eventSUBS[key.split(".")[0]];
            }
          }

          this.emitEvent('event', params)
          break
        }

        case VertoMessage.Punt: {
          this.emitEvent('punt');
          this.logout(true);
          break
        }
        default: {
          this.emitEvent(method?.replace('verto.',''), params)
        }
      }
    })
    this.rpc.setReconnectHandler(() => {
      if(this.logged_in) this.login()
    })
    this.options.rtcConfig = Object.assign(
      {}, this.options.rtcConfig || {})

  }

  login(): Promise<any>{
    return new Promise((resolve, reject) => {
      this.rpc.call('login', this.options.transportConfig,
        (data: {sessid:string}) => {
        this.sessid = data.sessid
        this.logged_in = true
        resolve(data)
      }, (data: Object) => {
        reject(data)
      })
    })
  }

  call(tracks: Array<MediaStreamTrack>, destination: string, options?:VertoCallOptions): VertoCall {
    let call = new VertoCall(this.options.rtcConfig, this.rpc, destination, generateGUID(),
      Object.assign({caller_id_name: this.options.caller_id_name, caller_id_number: this.options.caller_id_number}, options),
      this.options.ice_timeout, this.options.debug)

    for(let track of tracks) call.addTrack(track)
    this.calls[call.id] = call
    return call
  }

  isLogged(): boolean {
    return this.logged_in
  }

  logout(punt: boolean = false): void {
      if (!punt && this.calls) {
          Object.keys(this.calls).forEach(
              key => {
                  this.hangup(key)
              }
          )
      }
      if (this.rpc) this.rpc.close();
      this.rpc = null
      this.sessid = null
      this.logged_in = false
  }

  getActiveCall() {
    const activeCall = this.getActiveCallId();
    if (activeCall) {
      return this.calls[activeCall];
    }
    return null;
  }

  getActiveCallId() {
    return Object.keys(this.calls).find(call => this.calls[call].holdStatus!==true);
  }

  getAllCall() {
    return this.calls;
  }

  getRPCSocketState() {
    return this.rpc ? this.rpc.getSocketState() : null;
  }

  hangup(callID: string, params: any = {}){
    if (this?.calls[callID]){
      this.calls[callID].hangup(params)
      this.calls[callID].clearPeerConnection();
      delete this.calls[callID]
      this.emitEvent('bye', callID)
    }
  }


  do_sub(channel, obj) {

  }

  drop_bad(channel) {
    console.error("drop unauthorized channel: " + channel);
    delete this.eventSUBS[channel];
  }

  mark_ready(channel) {
    for (var j in this.eventSUBS[channel]) {
      this.eventSUBS[channel][j].ready = true;
      console.log("subscribed to channel: " + channel);
      if (this.eventSUBS[channel][j].readyHandler) {
        this.eventSUBS[channel][j].readyHandler(this, channel);
      }
    }
  }

  do_subscribe(channel, subChannels, sparams) {
    let params = sparams || {};

    let local = params.local;

    let obj = {
      eventChannel: channel,
      userData: params.userData,
      handler: params.handler,
      ready: false,
      local: false,
      readyHandler: params.readyHandler,
      serno: this.SERNO++
    };

    let isnew = false;

    if (!this.eventSUBS[channel]) {
      this.eventSUBS[channel] = [];
      subChannels.push(channel);
      isnew = true;
    }

    this.eventSUBS[channel].push(obj);

    if (local) {
      obj.ready = true;
      obj.local = true;
    }

    if (!isnew && this.eventSUBS[channel][0].ready) {
      obj.ready = true;
      if (obj.readyHandler) {
        obj.readyHandler(this, channel);
      }
    }

    return {
      serno: obj.serno,
      eventChannel: channel
    };

  }


  unsubscribe(handle) {
    var i;

    if (!handle) {
      for (i in this.eventSUBS) {
        if (this.eventSUBS[i]) {
          this.unsubscribe(this.eventSUBS[i]);
        }
      }
    } else {
      var unsubChannels = {};
      var sendChannels = [];
      var channel;

      if (typeof(handle) == "string") {
        delete this.eventSUBS[handle];
        unsubChannels[handle]++;
      } else {
        for (i in handle) {
          if (typeof(handle[i]) == "string") {
            channel = handle[i];
            delete this.eventSUBS[channel];
            unsubChannels[channel]++;
          } else {
            var repl = [];
            channel = handle[i].eventChannel;

            for (var j in this.eventSUBS[channel]) {
              if (this.eventSUBS[channel][j].serno == handle[i].serno) {} else {
                repl.push(this.eventSUBS[channel][j]);
              }
            }

            this.eventSUBS[channel] = repl;

            if (this.eventSUBS[channel].length === 0) {
              delete this.eventSUBS[channel];
              unsubChannels[channel]++;
            }
          }
        }
      }

      for (var u in unsubChannels) {
        console.log("Sending Unsubscribe for: ", u);
        sendChannels.push(u);
      }

      if (sendChannels.length) {
        if (this.rpc) this.rpc.call("verto.unsubscribe", {
          eventChannel: sendChannels.length == 1 ? sendChannels[0] : sendChannels
        });
      }
    }
  }
  subscribe(channel, sparams) {
    let r = [];
    let subChannels = [];
    let params = sparams || {};

    if (typeof(channel) === "string") {
      r.push(this.do_subscribe(channel, subChannels, params));
    } else {
      for (var i in channel) {
        r.push(this.do_subscribe(channel, subChannels, params));
      }
    }

    if (subChannels.length) {
      this.rpc.call("verto.subscribe", {
        eventChannel: subChannels.length == 1 ? subChannels[0] : subChannels,
        subParams: params.subParams
      });
    }

    return r;
  }


}

export { Verto, VertoOptions, VertoConf, CallDirection, Dtmf, generateGUID }
