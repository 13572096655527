import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ModalBoxModule} from '../../../modal-box/modal-box.module';
import { DesktopKeptRoutingModule } from './desktop-kept-routing.module';
import { DesktopKeptComponent } from './desktop-kept.component';
import {MatIconModule} from '@angular/material/icon';
import {KtdGridModule} from '@katoid/angular-grid-layout';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MaterialModule} from '../../../material.module';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {LangChangeEvent, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {AgentListModule} from '../../../queues/agent-list.module';
import {StaffDirectoryComponent} from './components/staff-directory/staff-directory.component';
import {ExternalClientsComponent} from './components/external-clients/external-clients.component';
import {ExternalSearchComponent} from './components/external-search/external-search.component';
import {InboundCallsComponent} from './components/inbound-calls/inbound-calls.component';
import {DesktopDashboardComponent} from './components/desktop-dashboard/desktop-dashboard.component';
import {DesktopSettingsComponent} from './components/desktop-settings/desktop-settings.component';
import {NgArrayPipesModule} from 'ngx-pipes';
import {PipeModule} from '../../../_helpers/pipe.module';
import {NgApexchartsModule} from 'ng-apexcharts';
import {NgSelectModule} from '@ng-select/ng-select';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ALL_DOMAIN_MODE, MenuView, ModeDomain, PermAction, ROLE_TYPE_ALL} from '../../../_helpers/constant';
import {MenuInject} from '../../../app.injection';
import {SMTPSettingsModule} from './components/smtp-settings/smtp-settings.module';
import {HistoryTransferComponent} from './components/history-transfer/history-transfer.component';
import {SendMailDialogComponent} from './components/send-mail-dialog/send-mail-dialog.component';
import {ExternalClientsDialogComponent} from './components/external-clients/external-clients-dialog.component';
import {DesktopDeclarationModule} from '../../_common/declaration.module';
import {HistoryCallsKeptComponent} from './components/history-calls-kept/history-calls-kept.component';
import {OpenDADialogComponent} from './components/open-da-dialog/open-da-dialog.component';

@NgModule({
  declarations: [
    DesktopKeptComponent,
    HistoryCallsKeptComponent,
    StaffDirectoryComponent,
    ExternalClientsComponent,
    ExternalSearchComponent,
    InboundCallsComponent,
    HistoryTransferComponent,
    DesktopDashboardComponent,
    SendMailDialogComponent,
    OpenDADialogComponent,
    ExternalClientsDialogComponent
  ],
  providers: [],
  exports: [
    HistoryTransferComponent
  ],
  imports: [
    DesktopDeclarationModule,
    CommonModule,
    DesktopKeptRoutingModule,
    MatIconModule,
    KtdGridModule,
    NgxChartsModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    MaterialModule,
    FlexModule,
    TranslateModule.forChild(),
    AgentListModule,
    FlexLayoutModule,
    NgArrayPipesModule,
    PipeModule,
    NgApexchartsModule,
    NgSelectModule,
    PerfectScrollbarModule,
    ModalBoxModule,
  ]
})

export class DesktopKeptModule {
  constructor(){
    console.log('load KEPT');
  }
  static getTranslateFile = () => {return {prefix: './assets/locale/kept-', suffix: '.json'}};
  static menu(): MenuInject {
    return {
      menu: [{
        state: 'desktop',
        name: 'DESKTOP',
        type: 'module',
        icon: 'important_devices',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.CC, ModeDomain.BASE],
        view: [MenuView.DOMAIN],
        object: 'KeptDesktop',
        obj_perm: [PermAction.GET]
      }],
      router: [
        {
          path: 'desktop',
          loadChildren: () => DesktopKeptModule
        }
      ]
    }
  }


  static menu_domain_settings(): MenuInject {
    return {
      menu: [{
        name: 'DESKTOP_SMTP_SETTINGS',
        description: 'DESKTOP_SMTP_SETTINGS.DESCRIPTION',
        type: 'item',
        icon: 'settings',
        state: '/domain-settings/smtp',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        object: 'KeptSettings',
        obj_perm: [PermAction.EDIT]
      }],
      router: [
        {
          path: 'smtp',
          loadChildren: () => SMTPSettingsModule
        }
      ]
    }
  }

  static menu_marketplace(): MenuInject {
    return {
      menu: [],
      router: []
    }
  }
}

