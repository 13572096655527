import {APP_INITIALIZER, Injector} from '@angular/core';
import { ErrorHandler } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import {Router, RouterModule} from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import {BaseErrorHandler} from './_helpers/base-error-handler';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { ErrorInterceptor } from './auth/error.interceptor';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import {NotificationBarComponent} from './layouts/full/notification-bar/notification-bar.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {TechWorkComponent} from './layouts/tech-work/tech-work.component';
import {TechWorkGuard} from './layouts/tech-work/tech-work.guard';
import { MaterialModule } from './material.module';
import {ModalBoxModule} from './modal-box/modal-box.module';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { LoginComponent } from './login/login.component';
import { AuthModule } from './auth/auth.guard';
import { FileExplorerModule } from './file-explorer/file-explorer.module';
import { NgxAudioPlayerModule } from '@khajegan/ngx-audio-player';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NoPageFoundComponent } from './layouts/no-page-found/no-page-found.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableResponsiveModule } from './material-component/mat-table-responsive/mat-table-responsive.module';
import { CustomPaginator } from './material-component/mat-table-responsive/mat-table-pagination.module';
import { NotifyService } from './_helpers/notify.service';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { FileService } from './file-explorer/file.service';
import { JsonAppConfigService } from './config/json-app-config.service';
import { AppConfiguration } from './config/app-configuration';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import {AuthenticationService} from './auth/authentication.service';
import { QRCodeModule } from 'angular2-qrcode';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {PhoneDialogComponent} from './dialogs/modals/phoneDialog/phoneDialog.component';
import '@angular/common/locales/global/ru';
import {FieldModule} from './_helpers/dynamic/field.module';
import {NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular-material-components/moment-adapter';
import {GravatarModule} from 'ngx-gravatar';
import {AvatarModule} from 'ngx-avatar-2';
import {DateDialogComponent} from './dialogs/modals/dateDialog/dateDialog.component';
import {VoiceDirectoryFormComponent} from './voice-directory/voice-directory-form.component';
import {DomainService} from './domains/domain.service';
import {MenuService} from './roles/menu.service';
import {PipeModule} from './_helpers/pipe.module';
import {NgArrayPipesModule} from 'ngx-pipes';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {DomainFileDialogComponent} from "./domains/domain-file-storage/domain-file-dialog/domain-file-dialog.component";
import {STEPPER_GLOBAL_OPTIONS} from "@angular/cdk/stepper";
import {DocsComponent} from "./docs/docs.component";
import {SharedAppModule} from "./shared-app.module";
import {WebAppComponent} from "./webapp/webapp.component";
import {CronDialogComponent} from "./dialogs/modals/cronDialog/cronDialog.component";


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true
};


export function initializerFn(jsonAppConfigService: JsonAppConfigService,
                              menuService: MenuService, domainService: DomainService, auth: AuthenticationService) {
  return  () => {
    return jsonAppConfigService.load(auth.getDomain()).toPromise()
  };
}

export let AppInjector: Injector;

@NgModule({
    declarations: [
      AppComponent,
      NotificationBarComponent,
      FullComponent,
      AppHeaderComponent,
      SpinnerComponent,
      AppSidebarComponent,
      LoginComponent,
      WebAppComponent,
      AppBreadcrumbComponent,
      NoPageFoundComponent,
      TechWorkComponent,
      PhoneDialogComponent,
      DomainFileDialogComponent,
      VoiceDirectoryFormComponent,
      DateDialogComponent,
      CronDialogComponent,
      DocsComponent
    ],
  imports: [
    MatDatepickerModule, MatMomentDateModule,
    NgxAudioPlayerModule,
    MatTableResponsiveModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    NgSelectModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    HttpClientModule,
    SharedModule,
    SharedAppModule,
    QRCodeModule,
    RouterModule.forRoot(AppRoutes, {relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload'}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      // missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationService },
      useDefaultLang: false,
    }),
    FileExplorerModule,
    FieldModule,
    GravatarModule,
    AvatarModule,
    PipeModule,
    NgArrayPipesModule,
    TranslateModule,
    ModalBoxModule,
  ],
    providers: [
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        JsonAppConfigService,
        { provide: AppConfiguration, deps: [HttpClient], useExisting: JsonAppConfigService },
        { provide: APP_INITIALIZER, useFactory: initializerFn,
            deps: [JsonAppConfigService, MenuService, DomainService, AuthenticationService], multi: true },
        {provide: ErrorHandler, useClass: BaseErrorHandler},
        AuthModule, NotifyService, FileService, TechWorkGuard,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'ru' },
        { provide: MatPaginatorIntl, useClass: CustomPaginator },
        { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
        { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
        { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } }
    ],
  exports: [NoPageFoundComponent, SpinnerComponent]
})

export class AppModule {
  constructor(private injector: Injector, private readonly router: Router) {
    AppInjector = this.injector;
    router.events
      .subscribe((ent)=>{
        // console.log(evt)
      })
  }
}

function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new MultiTranslateHttpLoader(http, [{prefix: './assets/locale/', suffix: '.json'}]);
}
