 import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  Input, OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {CRM_FIELDTYPES, ROLE_TYPE_DOMAIN_LIST, ROLE_TYPE_PROVIDER_LIST} from '../../../_helpers/constant';
import {NotifyService} from '../../../_helpers/notify.service';
import {RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {AppService} from '../../../app.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {BaseFormComponent} from '../../../_helpers/base-form.component';
import {ConfirmComponent} from '../../../dialogs/modals/confirm/confirm.component';
import {DomainUserService} from '../../../users/domain-user.service';
import {DomainCRMDFieldConfService} from '../dfields/dfield.service';
import {DomainCRMEvent, DomainCRMEventDField, DomainCRMRefEvent} from '../events/event';
import {DomainCRMEventService} from '../events/event.service';
import {DomainCRMRefEventService} from '../events/ref-event.service';
import {CRMFieldAddress, CRMFieldEmail, CRMFieldPhone, DomainCRMEntity, DomainCRMEntityDField} from './entity';
import {OnInit} from '@angular/core'
import {DomainCRMEntityService} from './entity.service';
import {forkJoin, merge, Observable, of as observableOf, Subscription} from 'rxjs';
import {CTIService} from "../../../cti-panel/cti-panel.service";
import {DomainService} from "../../domain.service";
import {MatPaginator} from "@angular/material/paginator";
import {getOffset, getUUID4} from "../../../_helpers/base.function";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {catchError, map, startWith, switchMap, tap, concatMap} from "rxjs/operators";
import {BaseReportService} from "../../../_helpers/base-report.service";
import {AudioPlayerDialogComponent} from "../../../file-explorer/modals/audioDialog/audio-player-dialog.component";
import {RxFormHelpers} from "../../../_helpers/form.helpers";
import {KnowledgebaseService} from "../../../knowledgebase/viewer/knowledgebase.service";
import {DomainCrmSettings} from "../domain-crm-settings/domain-crm-settings";
import {DomainCrmSettingsService} from "../domain-crm-settings/domain-crm-settings.service";
// @ts-ignore
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
// @ts-ignore
import {default as _rollupMoment} from 'moment';
import {Track} from "@khajegan/ngx-audio-player";
import {IResponseListObject} from "../../../_helpers/api.helpers";
const moment = _rollupMoment || _moment;


@Component({
  selector: 'app-entity-form',
  templateUrl: './entity-form.component.html',
  styles: [
    `:host ::ng-deep tr.mat-footer-row {
      height: 25px;
    }`,
    `:host ::ng-deep .ng-dropdown-panel .ng-dropdown-footer {
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 50px 0 10px;
    }`,
    `:host ::ng-deep .ng-dropdown-panel .ng-dropdown-footer button {
      right: 5px;
      position: absolute;
      bottom: 4px;
    }`,
    `td.type-4 {
      min-width: 148px;
      max-width: 148px;
      text-align: center;
    }`,
    `td.type-17 {
      min-width: 60px;
      max-width: 60px;
      text-align: center;
    }`,
    `:host ::ng-deep .responsive-table .badge {
      width: fit-content;
      margin-left: 9px;
      cursor: pointer;
    }`,
    `mat-checkbox::ng-deep .mat-checkbox-layout {
      width: 100%;
      display: block;
      word-break: break-word;
      white-space: break-spaces;
    }`,
    `::ng-deep .ng-dropdown-panel {
      z-index: 9999999 !important;
    }`
  ],
  styleUrls: ['../../../material-component/mat-table-responsive/mat-table-responsive.directive.scss', './entity.component.scss']
})

export class DomainCRMEntityFormComponent extends BaseFormComponent<DomainCRMEntity> implements OnInit, AfterViewInit, OnDestroy {
  @Input() entity: any = null;
  @Input() entity_event_id: number = null;
  @Input() timezone: string;
  @Input() crmSettings: DomainCrmSettings = null;
  @Input() view: number = 0; // 0 - с заголовком | 1 - без заголовка
  public entity_type: number; // 0 - компания | 1 - контакт
  public phoneTypes: string[] = CRM_FIELDTYPES.phone;
  public emailTypes: string[] = CRM_FIELDTYPES.email;
  public addressTypes: string[] = CRM_FIELDTYPES.address;
  public companies = [];
  public companyContacts = [];
  public companyContactsTotal: number = 0;
  public dfields = [];
  public eventDfields = [];
  public selectedTabCtrl = new FormControl(0);
  public events = [];
  public entityEvents: FormArray = new FormArray([]);
  public entityEventsTotal: number = 0;
  public entityEventsLoading: boolean = false;
  public dfieldsLoading: boolean = false;

  public collapseAll: boolean = true;
  public domainUsers = [];
  public responsibleUserID = null;
  public eventResponsibleUserID = null;
  public totalDomainUsers: number = null;
  public loadedDomainUsers: number = 0; // не учитываю загруженных пользователей для отображения выбранных ID при открытии формы
  public domainUserLoading: boolean = false;
  public domainUserLimit: number = 200;

  public dialogScript: {id: string, ver: number, call_uuid: string, dialog_uuid: string, script_result: any} = null;

  // Панель фильтров
  filterForm: FormGroup = new FormGroup({
    period: new FormControl(5), // значение по-умолчанию для фильтра Период
    start_dt: new FormControl(moment.utc().startOf('month')),
    end_dt: new FormControl(moment.utc().endOf('month'))
  });
  filterPeriod = 5;
  callsColumns: any[] = [];
  columns_sub: any[] = [];
  footer = {};
  callsDataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  isRateLimitReached = false;
  resultsLength = 0;
  displayedColumns: string[] = [];
  sortParams = {f0: '-'};
  public isCallsLoadingResults: boolean = false;
  public showNewContactCard: boolean = false; // Показать карточку добавления нового контакта
  public newEntityForm: FormGroup;
  lsKey = 'desktopCRMEntityList';
  private dialogRef: MatDialogRef<any>;
  private initialData: any = {};

  @ViewChild(MatPaginator) callsPaginator: MatPaginator;
  @ViewChild(MatTable) callsTable: MatTable<any>;
  @ViewChild(MatSort) callsSort: MatSort;
  @ViewChild('newCompanyCard') newCompanyCard!: TemplateRef<any>;
  @ViewChild('entityTabsGroup') entityTabsGroup: MatTabGroup;
  @ViewChildren('panel') panels: QueryList<ElementRef>;
  @Output() renamed = new EventEmitter<{ id: number, name: string }>();
  @Output() deleted = new EventEmitter<{ id: number }>();
  @Output() closeTab = new EventEmitter<number>();
  public kw$: Observable<any[]> = observableOf([]);
  public call_link_list$: Observable<any> = observableOf({});
  private entityService: DomainCRMEntityService;
  private subscription: Subscription = new Subscription();

  constructor(
    api: DomainCRMEntityService,
    fb: RxFormBuilder,
    route: ActivatedRoute,
    router: Router,
    http: HttpClient,
    notifyService: NotifyService,
    authenticationService: AuthenticationService,
    public userDomainService: DomainUserService,
    public translate: TranslateService,
    public CTI: CTIService,
    private domainCRMDFieldConfService: DomainCRMDFieldConfService,
    private domainCRMRefEventService: DomainCRMRefEventService,
    private domainCRMEventService: DomainCRMEventService,
    private knowledgebaseService: KnowledgebaseService,
    private domainCrmSettingsService: DomainCrmSettingsService,
    public appService: AppService,
    public domainService: DomainService,
    public baseReportService: BaseReportService,
    private dialog: MatDialog
  ) {
    super(DomainCRMEntity, api, fb, route, router, http, notifyService, authenticationService);
    this.entityService = api;
  }

  ngOnInit() {
    if (!this.timezone) this.domainService.get_tz().subscribe(data => this.timezone = data);

    if (this.entity != null) {
      if (this.entity.entity_type != null) this.entity_type = this.entity.entity_type;
      this.id = this.entity.id;
    } else {
      this.id = this.route.snapshot.paramMap.get('id');
      if (this.route.snapshot.paramMap.get('id')) this.entity_event_id = +this.route.snapshot.paramMap.get('entity_event_id');

      if (this.router.url.includes('contacts')) this.entity_type = 1;
      else if (this.router.url.includes('companies')) this.entity_type = 0;
      super.navigate = this.entity_type == 0 ? 'crm/companies' : 'crm/contacts';
      //
      // if (this.id) {
      //   let desktopCRMEntityList: any[] = localStorage.getItem(this.lsKey) != null ? JSON.parse(localStorage.getItem(this.lsKey)) : [];
      //   let entity = desktopCRMEntityList.find(v => v.id == +this.id);
      //   if (entity) {
      //     this.entity = entity;
      //   }
      // }
    }

    this.formT.phone_list = new FormArray([]);
    this.formT.email_list = new FormArray([]);
    this.formT.address_list = new FormArray([]);
    this.formT.dfields = new FormArray([]);

    if (!this.id) {
      let phone = this.entity && !this.entity.id ? this.entity.calls[0].phone : this.route.snapshot.queryParamMap.get('phone');
      if (phone && !phone.startsWith('new_entity')) {
        if (!this.entity.name) this.entity.name = this.translate.instant('UNKNOWN_PHONE', {phone: this.entity.calls[0].phone});

        this.formT.phone_list.push(this.fb.formGroup(new CRMFieldPhone()));
        this.formT.phone_list.at(0).patchValue({
          value: phone,
          desc: this.notifyService.translate.instant('PHONE_WORK')
        });
      }
      // this.formT.email_list.push(this.fb.formGroup(new CRMFieldEmail()));
      // this.formT.email_list.at(0).patchValue({'desc': this.notifyService.translate.instant('EMAIL_WORK')});
      // this.formT.address_list.push(this.fb.formGroup(new CRMFieldAddress()));
      // this.formT.address_list.at(0).patchValue({'desc': this.notifyService.translate.instant('ADDRESS_ACTUAL')});
    }

    this.rxFormHelpers = new RxFormHelpers();
    this.form = this.fb.formGroup(this.formT);
    this.select_type = (this.api.isRoleProvider()) ? ROLE_TYPE_PROVIDER_LIST : ROLE_TYPE_DOMAIN_LIST;

    if (this.id) {
      if (typeof this.id == 'string') this.id = parseInt(this.id, 10);
      this.update();
    } else {
      if (this.form.get('id')) {
        this.form.removeControl('id');
      }
      this.isLoadingResults = false;
      this.is_received_data = observableOf(true);

      this.form.patchValue({entity_type: this.entity_type});
      if (this.entity?.name) {
        this.form.patchValue({name: this.entity.name});
        this.form.markAsDirty();
      }
      if (!this.entity || this.entity.id) this.getDFieldList();
      this.domainUsersScrollEnd(true);
    }

    this.getContactsOrCompanies();

    this.domainCRMRefEventService.list({sort: {name: '+'}, limit: 500}).subscribe(
      events => this.events = events.list,
      resp => this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502
    );
    //
    // // подписка на звонок контакта\компании CRM
    // let subCti$ = this.CTI.crmEntity$.subscribe((entity: any) => {
    //   this.entity = entity;
    //   if (!entity && this.route.snapshot.queryParamMap.get('call_uuid') && this.route.snapshot.queryParamMap.get('call_id')) {
    //     let crmList = localStorage.getItem('desktopCRMEntityList');
    //     this.entity = (crmList != null ? [{calls: []}, ...JSON.parse(crmList)] : [{calls: []}]).find(
    //       e => e.calls.length > 0 &&
    //         (
    //           e.calls[0].callID == this.route.snapshot.queryParamMap.get('call_uuid') ||
    //           e.calls[0].callID == this.route.snapshot.queryParamMap.get('call_id')
    //         )
    //     );
    //   }
    //
    //   if (this.entity) {
    //     this.id = this.route.snapshot.paramMap.get('id') || this.entity.id;
    //
    //     if (this.entity.entity_type != null) this.entity_type = this.entity.entity_type;
    //     else if (this.router.url.includes('contacts')) this.entity_type = 1;
    //     else if (this.router.url.includes('companies')) this.entity_type = 0;
    //     super.navigate = this.entity_type == 0 ? 'crm/companies' : 'crm/contacts';
    //
    //     if (this.entity?.calls?.length > 0 && !this.domainUserLoading && this.totalDomainUsers !== null) {
    //       console.log('%cПроверяю и создаю обращения по вызовам: ' + JSON.stringify(this.entity.calls) + '..', 'color: orange; font-size: 16px;');
    //       this.createCallEvents(this.entity.calls);
    //     }
    //     if (this.entity?.script_data) this.showDialogScript(this.entity?.script_data);
    //   }
    // });
    // this.subscription.add(subCti$);
  }

  ngAfterViewInit() {
    this.selectedTabCtrl.setValue(1);
    if (this.entity || this.entity_event_id) this.selectedTabCtrl.setValue(this.entity && this.id && this.entity_type == 0 ? 2 : 1);
    else this.selectedTabCtrl.setValue(0);
    setTimeout(() => this.entityTabsGroup?.realignInkBar(), 500);

    if (!this.id) this.callHistorySubscribe();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.subscription.unsubscribe();
  }

  callbackFormPatch(data: DomainCRMEntity) {
    if (this.entity?.id) {
      let desktopCRMEntityList: any[] = localStorage.getItem(this.lsKey) != null ? JSON.parse(localStorage.getItem(this.lsKey)) : [];
      let clientIndex = desktopCRMEntityList.findIndex(v => v.id == this.entity.id);
      if (clientIndex != -1 && desktopCRMEntityList[clientIndex].name != this.form.value.name) {
        // отрисовываю вкладку в окне CRM Рабочего стола с АКТУАЛЬНЫМ именем
        this.renamed.emit({id: this.entity.id, name: this.form.value.name});
      }
    }

    this.updateInitialData(data);

    this.responsibleUserID = data.responsible_user_id;

    for (let oData of data.phone_list) {
      let oRx = this.fb.formGroup(new CRMFieldPhone());
      oRx.patchValue(oData);
      (this.form.get('phone_list') as FormArray).push(oRx);
    }
    this.callHistoryApplyFilter();
    for (let oData of data.email_list) {
      let oRx = this.fb.formGroup(new CRMFieldEmail());
      oRx.patchValue(oData);
      (this.form.get('email_list') as FormArray).push(oRx);
    }
    for (let oData of data.address_list) {
      let oRx = this.fb.formGroup(new CRMFieldAddress());
      oRx.patchValue(oData);
      (this.form.get('address_list') as FormArray).push(oRx);
    }

    this.form.markAsPristine();
    if (this.entity_type == null) {
      this.entity_type = data.entity_type;
      if (this.entity_type == 0) this.selectedTabCtrl.setValue(2);
    }
    if (this.entity_event_id) this.selectedTabCtrl.setValue(this.entity_type == 0 ? 2 : 1);
    this.getDFieldList(data);

    this.domainUserLoading = true;
    this.entityEventsLoading = true;
    forkJoin([
      this.domainCRMEventService.list({
        filter: {type: 0, field_list: [{field: 'entity_id', value: this.id, condition_type: 0}]},
        sort: {id: '-'},
        limit: 5,
        offset: 0
      }).pipe(catchError(resp => observableOf({} as IResponseListObject<DomainCRMEvent>))),
      (this.crmSettings?.event_inbound_call != null ? observableOf(null) : this.domainCrmSettingsService.get(null).pipe(catchError(resp => observableOf(null))))
    ]).subscribe(
      results => {
        if (results[1] != null) this.crmSettings = results[1];

        this.entityEventsTotal = results[0].total_count;

        this.domainUsersScrollEnd(true, results[0].list); // подгружаю список пользователей т.к. их потенциально м.б. меньше пяти, а значит прокрутка не сработает
      },
      resp => {
        this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502;
        this.domainUserLoading = false;
        this.entityEventsLoading = false;
      }
    );

    super.callbackFormPatch(data);
    setTimeout(() => this.callHistorySubscribe(), 150);
  }

  callbackGetError(resp) {
    super.callbackGetError(resp);
    if (resp.notifies?.filter(err => err['msg_id'] == 10002).length > 0) this.deleted.emit({id: this.id});
  }

  changeSelectedTab(event: MatTabChangeEvent) {
    // this.selectedTabCtrl.setValue(event.index);
    if (event.index == 2) {
      // запрашиваю данные по событиям
    } else if (event.index == 3) {
      // запрашиваю историю вызовов
      this.callHistoryApplyFilter();
    }

    setTimeout(() => this.entityTabsGroup?.realignInkBar(), 500);
  }

  scrollToElement(selector, sleep: number = 0) {// скролл до элемента
    setTimeout(() => {
      document.querySelector(selector)?.scrollIntoView();
    }, sleep);
  }

  getEntityEvents(offset: number = 0) {
    this.entityEventsLoading = true;
    this.domainCRMEventService.list({
      filter: {type: 0, field_list: [{field: 'entity_id', value: this.id, condition_type: 0}]},
      sort: {id: '-'},
      limit: 5,
      offset: offset
    }).subscribe(
      data => {
        this.showEntityEvents(data.list);
        this.entityEventsTotal = data.total_count;
      },
      resp => {
        this.entityEventsLoading = false;
        this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502;
      }
    );
  }

  domainUsersScrollEnd(load_dfields: boolean = false, events?) {
    if (this.totalDomainUsers === null || (this.loadedDomainUsers < this.totalDomainUsers)) {
      this.domainUserLoading = true;
      this.userDomainService.list({
        sort: {name: '+', surname: '+', user_name: '+', user_surname: '+'},
        limit: this.domainUserLimit,
        offset: this.loadedDomainUsers
      }, 'select').subscribe(
        (data) => {
          this.loadedDomainUsers += data.list.length;

          for (let user of data.list) {
            // кладу в опции пользователей, которых нет еще в списке
            if (!this.domainUsers.find(u => u.id === user.id)) this.domainUsers.push({
              id: user.id,
              name: user['user_name'] + (user['user_surname'] ? ' ' + user['user_surname'] : ''),
              proto: user.proto,
              uid: user.uid
            });
          }
          this.domainUsers.sort((a, b) => (a.uid < b.uid) ? -1 : (a.uid > b.uid ? 1 : (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)));

          this.domainUserLoading = true;
          (!this.domainUsers.find(du => du.uid == this.CTI.ctiUser$.value)?.id ?
            this.userDomainService.toSelectWithDetail({
              filter: {
                type: 0,
                field_list: [{field: 'uid', value: this.CTI.ctiUser$.value, condition_type: 0}]
              }
            }) :
            observableOf([this.domainUsers.find(du => du.uid == this.CTI.ctiUser$.value)])
          ).subscribe(
            data => {
              if (data.length > 0) {
                let user = data[0];
                // кладу в опции пользователей, которых нет еще в списке
                if (!this.domainUsers.find(u => u.id === user.id)) this.domainUsers.push({
                  id: user.id,
                  name: user['user_name'] + (user['user_surname'] ? ' ' + user['user_surname'] : ''),
                  proto: user.proto,
                  uid: user.uid
                });
                this.domainUsers.sort((a, b) => (a.uid < b.uid) ? -1 : (a.uid > b.uid ? 1 : (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)));

                // автоматически подставляю ответственного при создании контакта/компании !при первом открытии селектора
                if (this.totalDomainUsers === null && !(this.id || this.form.value.responsible_user_id) && this.CTI.ctiUser$.value) {
                  this.responsibleUserID = user.id;
                  this.form.patchValue({responsible_user_id: this.responsibleUserID});
                }

                this.eventResponsibleUserID = user.id;
              }
              this.domainUserLoading = false;

              if (load_dfields) this.getEventDfields(events);
            },
            resp => {
              this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502;
              this.domainUserLoading = false;
            }
          );

          if (this.totalDomainUsers === null) this.totalDomainUsers = data.total_count;

          this.domainUserLoading = false;
        },
        resp => {
          this.domainUserLoading = false;
          this.notifyService.setFormErrors(resp);
        }
      );
    } else if (load_dfields) this.getEventDfields(events);
  }

  updateInitialData(data: DomainCRMEntity) {
    this.initialData = {...data};
    this.initialData.parent_id_list = [...data.parent_id_list];

    this.initialData.phone_list = [];
    this.initialData.email_list = [];
    this.initialData.address_list = [];
    this.initialData.dfields = [];
    for (let v of data.phone_list) this.initialData.phone_list.push({...v});
    for (let v of data.email_list) this.initialData.email_list.push({...v});
    for (let v of data.address_list) this.initialData.address_list.push({...v});
    for (let v of data.dfields) this.initialData.dfields.push(Object.assign(
      {},
      v,
      v.list_val?.length > 0 ? {list_val: [...v.list_val]} : {}
    ));
  }

  setFieldValidators(dfield, rxField) {
    if (dfield) {
      let f: FormControl;
      let validators = [];

      switch (dfield.ftype) {
        case 0:
          f = rxField.get('str_val') as FormControl;
          if (dfield.required) validators = [RxwebValidators.required({message: 'ERROR.REQUIRED'})];
          if (dfield.choices_only) validators.push(RxwebValidators.oneOf({matchValues: dfield.choices, message: 'ERROR.10005.1'}));
          break;
        case 1:
          f = rxField.get('int_val') as FormControl;
          if (dfield.required) validators = [RxwebValidators.required({message: 'ERROR.REQUIRED'})];
          break;
        case 2:
          f = rxField.get('bool_val') as FormControl;
          if (dfield.required) validators = [RxwebValidators.required({message: 'ERROR.REQUIRED'})];
          break;
        case 3:
          f = rxField.get('dt_val') as FormControl;
          if (dfield.required) validators = [RxwebValidators.required({message: 'ERROR.REQUIRED'})];
          break;
        case 4:
          f = rxField.get('list_val') as FormControl;
          if (dfield.required) validators = [RxwebValidators.choice({minLength: 1, message: 'ERROR.REQUIRED'})];
          if (dfield.choices_only) validators.push(RxwebValidators.oneOf({matchValues: dfield.choices, message: 'ERROR.10005.1'}));
          break;
        case 5:
          f = rxField.get('txt_val') as FormControl;
          if (dfield.required) validators = [RxwebValidators.required({message: 'ERROR.REQUIRED'})];
          break;
      }

      f.setValidators(validators);
      f.updateValueAndValidity();
    }
  }

  showDialogScript(script_data) {
    this.dialogScript = script_data;
    this.selectedTabCtrl.setValue(this.entity && this.entity_type == 0 ? 4 : 3);
  }

  hideDialogScript(reason: number|null = null) {
    let crmList = localStorage.getItem(this.lsKey) ? JSON.parse(localStorage.getItem(this.lsKey)) : [];
    let lsEntityInd = crmList.findIndex(e => e.id == this.entity.id || e.calls.find(c => c.callID == this.dialogScript.call_uuid));
    if (reason == 3) { // завершение скрипта с закрытием карточки
      this.closeTab.emit(lsEntityInd);
    } else {
      let lsEntity = crmList[lsEntityInd];
      if (lsEntity) {
        delete lsEntity['script_data'];
        localStorage.setItem(this.lsKey, JSON.stringify(crmList));
      }
      this.dialogScript = null;
      this.selectedTabCtrl.setValue(this.entity && this.entity_type == 0 ? 3 : 2);
    }
  }

  changeEntityType() {
    this.dfields = [];
    (this.form.get('dfields') as FormArray).clear();
    this.entity_type = this.form.value.entity_type;
    this.getDFieldList();
  }

  getContactsOrCompanies(offset: number = 0) {
    if (this.entity_type == 0) { // запрашиваю список контактов компании
      this.api.list({
        limit: 100,
        offset: offset,
        filter: {
          type: 0,
          field_list: [
            {field: 'parent_id_list', value: [this.id], condition_type: 9}
          ]
        },
        sort: {name: '+'}
      }).subscribe(
        data => {
          this.companyContacts = offset == 0 ? [...data.list] : [...this.companyContacts, ...data.list];
          this.companyContactsTotal = data.total_count;
        },
        resp => this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502
      );
    } else { // запрашиваю список компаний для выбора в контакте
      this.api.toSelect({
        filter: {type: 0, field_list: [{field: 'entity_type', value: 0, condition_type: 0}]},
        sort: {name: '+'},
        limit: 1000
      }, 'select').subscribe(
        companies => this.companies = companies,
        resp => this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502
      );
    }
  }

  getDFieldList(data?) {
    this.domainCRMDFieldConfService.list({
      filter: {type: 0, field_list: [{field: 'obj', value: [this.entity_type], condition_type: 9}]},
      sort: {id: '+'}
    }).subscribe(
      dfieldsData => {
        this.dfields = [...this.dfields, ...dfieldsData.list];
        (
          (this.dfields.length < dfieldsData.total_count) ?
            this.domainCRMDFieldConfService.list({
              limit: dfieldsData.total_count - this.dfields.length,
              offset: this.dfields.length,
              filter: {type: 0, field_list: [{field: 'obj', value: [this.entity_type], condition_type: 9}]},
              sort: {id: '+'}
            }) : observableOf({list: []})
        ).subscribe(
          dfieldsData => {
            this.dfields = [...this.dfields, ...dfieldsData.list];
            let formIsDirty = this.form.dirty;
            if (data) {
              data.dfields.forEach(
                (oData) => {
                  let oRx = this.fb.formGroup(new DomainCRMEntityDField());
                  if (oData.dt_val) oData.dt_val = moment(oData.dt_val * 1000);
                  oRx.patchValue(oData);
                  this.setFieldValidators(this.dfields.find(f => f.id == oData.field_id), oRx);
                  (this.form.get('dfields') as FormArray).push(oRx);
                }
              );
            }
            this.dfields.forEach(dfield => {
              if (dfield.required && this.getEntityExistedDFields(dfield.id).length == 0) this.addDField(dfield);
            });
            if (this.id && !formIsDirty) this.form.markAsPristine();
          },
          resp => this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502
        );
      }
    );
  }

  getEventDfields(events?) {
    // запрашиваю список динамических полей для СОБЫТИЙ
    this.dfieldsLoading = true;
    this.domainCRMDFieldConfService.list({
      filter: {type: 0, field_list: [{field: 'obj', value: [2], condition_type: 9}]},
      sort: {id: '+'}
    }).subscribe(
      dfData => {
        this.dfieldsLoading = false;
        this.eventDfields = dfData.list;
        (this.eventDfields.length < dfData.total_count ? this.domainCRMDFieldConfService.list({
          limit: dfData.total_count - this.eventDfields.length,
          offset: this.eventDfields.length,
          filter: {type: 0, field_list: [{field: 'obj', value: [2], condition_type: 9}]},
          sort: {id: '+'}
        }) : observableOf({list: []})).subscribe(
          data => {
            this.dfieldsLoading = false;
            this.eventDfields = [...this.eventDfields, ...data.list];
            this.showEntityEvents(events);
            if (this.entity?.calls?.length > 0) {
              console.log('%cПроверяю и создаю обращения по вызовам: ' + JSON.stringify(this.entity.calls) + '..', 'color: orange; font-size: 16px;');
              this.createCallEvents(this.entity.calls);
            }
            if (this.entity?.script_data) this.showDialogScript(this.entity?.script_data);
          },
          resp => {
            this.dfieldsLoading = false;
            this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502
          }
        )
      },
      resp => {
        this.dfieldsLoading = false;
        this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502
      }
    );
  }

  showEntityEvents(events) {
    this.entityEventsLoading = false;
    let bindingDomainUsers = [];

    if (events && events.length > 0) {
      let call_id_list = [];
      let list_val = [];
      for (let event of events) {
        if (bindingDomainUsers.indexOf(event.responsible_user_id) == -1 && this.domainUsers.findIndex(u => u.id == event.responsible_user_id) === -1) bindingDomainUsers.push(event.responsible_user_id);
        if (bindingDomainUsers.indexOf(event.author_user_id) == -1 && this.domainUsers.findIndex(u => u.id == event.author_user_id) === -1) bindingDomainUsers.push(event.author_user_id);

        let mEvent = new DomainCRMEvent();
        // @ts-ignore
        mEvent.dfields = new FormArray([]);
        let rxEvent = this.fb.formGroup(mEvent);
        rxEvent.patchValue({
          ...event,
          start_dt: moment(event.start_dt * 1000),
          ...event.end_dt ? {end_dt: moment(event.end_dt * 1000)} : {}
        });

        event.dfields.forEach(
          (oData) => {
            let dfield = this.eventDfields.find(f => f.id == oData.field_id);
            if (!dfield.event_id_list?.length || dfield.event_id_list.indexOf(event.ref_event_id) != -1) {
              let oRx;
              if (event.ref_event_id == this.crmSettings?.event_inbound_call && oData.field_id == this.crmSettings?.event_field_selected_documents && oData.list_val?.length > 0) {
                for (let doc_id of oData.list_val) {
                  if (list_val.indexOf(doc_id) == -1) list_val.push(doc_id);
                }
              } else if (oData.field_id == this.crmSettings?.event_field_call_id && oData.str_val) {
                if (call_id_list.length == 0) call_id_list.push(oData.str_val); // только первая активность автоматически загрузит файл разговора, остальные по кнопке
              }
              oRx = this.fb.formGroup(new DomainCRMEventDField());
              oRx.patchValue(Object.assign(oData, (oData.dt_val ? {dt_val: moment(oData.dt_val * 1000)} : {})));
              this.setFieldValidators(dfield, oRx);
              (rxEvent.get('dfields') as FormArray).push(oRx);
            }
          }
        );
        this.entityEvents.push(rxEvent);

        this.eventDfields.forEach(dfield => {
          if (
            dfield.required &&
            (!dfield.event_id_list?.length || dfield.event_id_list.indexOf(event.ref_event_id) != -1) &&
            !rxEvent.value.dfields.find(f => f.field_id == dfield.id)
          ) {
            let oRx = this.fb.formGroup(new DomainCRMEventDField());
            oRx.patchValue({id: null, event_id: event.id, field_id: dfield.id});
            this.setFieldValidators(dfield, oRx);
            (rxEvent.get('dfields') as FormArray).push(oRx);
          }
        });
      }

      if (this.entity_event_id) {
        setTimeout(() => {
          let panelIndex = this.entityEvents.value.findIndex(ee => ee.id == this.entity_event_id);
          if (panelIndex != -1) {
            // @ts-ignore
            document.getElementById(this.panels.toArray()[panelIndex]?.id)?.parentElement?.scrollIntoView();
          } else {
            setTimeout(() => {
              let panelIndex = this.entityEvents.value.findIndex(ee => ee.id == this.entity_event_id);
              if (panelIndex != -1) {
                // @ts-ignore
                document.getElementById(this.panels.toArray()[panelIndex]?.id)?.parentElement?.scrollIntoView();
              }
            }, 350);
          }
        }, 350);
      }

      if (list_val.length > 0) this.getKnowledgeDocuments(list_val);

      if (call_id_list.length > 0) this.getCDRLinks(call_id_list);
    }

    if (bindingDomainUsers.length > 0) {
      this.userDomainService.toSelectWithDetail({
        filter: {
          type: 0,
          field_list: [
            {field: 'id', value: bindingDomainUsers, condition_type: 9}
          ]
        },
        limit: bindingDomainUsers.length,
        sort: {name: '+', surname: '+', user_name: '+', user_surname: '+'}
      }).subscribe(
        data => {
          for (let user of data) {
            // кладу в опции пользователей, которых нет еще в списке
            if (!this.domainUsers.find(u => u.id === user.id)) this.domainUsers.push({
              id: user.id,
              name: user['user_name'] + (user['user_surname'] ? ' ' + user['user_surname'] : ''),
              proto: user.proto,
              uid: user.uid
            });
          }
          this.domainUsers.sort((a, b) => (a.uid < b.uid) ? -1 : (a.uid > b.uid ? 1 : (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)));
          this.domainUserLoading = false;
        },
        resp => {
          this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502;
          this.domainUserLoading = false;
        }
      );
    }
  }

  getEntityExistedDFields(id) {
    return (this.form.get('dfields') as FormArray).controls.filter(df => df.value.field_id == id);
  }

  addField(control, type) {
    let crmField;
    switch (type) {
      case 'phone':
        crmField = new CRMFieldPhone();
        break;
      case 'email':
        crmField = new CRMFieldEmail();
        break;
      case 'address':
        crmField = new CRMFieldAddress();
        break;
    }

    let rxField = this.fb.formGroup(crmField);
    if (type == 'phone') rxField.patchValue({'desc': this.notifyService.translate.instant('PHONE_WORK')});
    else if (type == 'email') rxField.patchValue({'desc': this.notifyService.translate.instant('EMAIL_WORK')});
    else rxField.patchValue({'desc': this.notifyService.translate.instant('ADDRESS_ACTUAL')});
    (control as FormArray).push(rxField);
    if (type == 'phone') this.callHistoryApplyFilter();
    control.markAsDirty();
  }

  callByField(phone) {
    let number = phone.split('вн')[0].split('д')[0].split('.')[0].split(',')[0].replace(/[^+0-9]+/g, '');
    if (number.length > 0) this.CTI.ctiActions$.next({obj: 'call', action: 'makeCall', number: number});
  }

  removeField(control, index, type) {
    (control as FormArray).removeAt(index);
    control.markAsDirty();
    if (type == 'phone') this.callHistoryApplyFilter();
  }

  addDField(dfieldData) {
    let rxField = this.fb.formGroup(new DomainCRMEntityDField());
    let dFieldId = this.initialData.dfields.find(f => f.field_id == dfieldData.id)?.id;
    rxField.patchValue({
      id: dFieldId || null,
      entity_id: this.id,
      field_id: dfieldData.id
    });
    this.setFieldValidators(dfieldData, rxField);
    (this.form.get('dfields') as FormArray).push(rxField);
    this.form.markAsDirty();
  }

  removeDField(data) {
    let ind = (this.form.get('dfields') as FormArray).controls.findIndex(df => data.id ? df.value.id == data.id : df.value.field_id == data.field_id);
    if (ind != -1) (this.form.get('dfields') as FormArray).removeAt(ind);
    this.form.markAsDirty();
  }

  getRefEvent(id) {
    return this.events.find(ev => ev.id == id) || {} as DomainCRMRefEvent;
  }

  addEvent(event) {
    if (!this.entityEvents.controls.length) this.domainUsersScrollEnd(false); // подгружаю список пользователей для самого первого события в сущности

    let mEvent = new DomainCRMEvent();
    // @ts-ignore
    mEvent.dfields = new FormArray([]);
    let rxEvent = this.fb.formGroup(mEvent);
    rxEvent.patchValue({
      entity_id: this.id,
      ref_event_id: event.id,
      start_dt: moment().set({seconds: 0}),
      end_dt: moment().add(1, 'hours').set({seconds: 0}),
      responsible_user_id: this.responsibleUserID,
      author_user_id: this.eventResponsibleUserID || this.responsibleUserID
    });

    this.eventDfields.forEach(dfield => {
      if (
        dfield.required &&
        (!dfield.event_id_list?.length || dfield.event_id_list.indexOf(event.id) != -1) &&
        !rxEvent.value.dfields.find(f => f.field_id == dfield.id)
      ) {
        let oRx = this.fb.formGroup(new DomainCRMEventDField());
        oRx.patchValue({id: null, event_id: event.id, field_id: dfield.id});
        this.setFieldValidators(dfield, oRx);
        (rxEvent.get('dfields') as FormArray).push(oRx);
      }
    });

    this.entityEvents.insert(0, rxEvent);
  }

  removeEvent(id) {
    let ind = this.entityEvents.controls.findIndex(evt => evt.value.id == id);
    if (ind != -1) this.entityEvents.removeAt(ind);
  }

  createCallEvents(calls) {
    // автосоздание события "Звонок" в Активности
    if (this.id && this.crmSettings?.event_field_call_id && this.crmSettings?.event_inbound_call && calls && calls.length > 0) {
      let call_id_list = [];
      for (let call of this.entity.calls) {
        if (!this.entityEvents.value.find(v => v.dfields.filter(v => v.field_id == this.crmSettings.event_field_call_id && v.str_val == call.callID).length > 0)) {
          console.log('%cОбращение с юидом ' + call.callID + ' не найдено. Создаю обращение..', 'color: orange; font-size: 16px;');
          call_id_list = [call.callID]; // только последнее добавляемое событие сразу подгрузит файл разговора, а остальные - по кнопке "Получить файл"

          this.domainCRMEventService.save({
            ref_event_id: this.crmSettings.event_inbound_call,
            entity_id: this.form.value.id,
            name: '   ',
            responsible_user_id: this.responsibleUserID,
            author_user_id: this.eventResponsibleUserID || this.responsibleUserID,
            start_dt: Math.floor(Date.now() / 1000),
            create_dt: Math.floor(Date.now() / 1000),
            dfields: [{
              id: null,
              field_id: this.crmSettings.event_field_call_id,
              str_val: call.callID
            }]
          } as DomainCRMEvent).subscribe(
            eventData => {
              let event = eventData.body;
              let mEvent = new DomainCRMEvent();
              // @ts-ignore
              mEvent.dfields = new FormArray([]);
              let rxEvent = this.fb.formGroup(mEvent);
              rxEvent.patchValue({
                ...event,
                start_dt: moment(event.start_dt * 1000),
                ...event.end_dt ? {end_dt: moment(event.end_dt * 1000)} : {}
              });
              if (event.dfields?.length > 0) event.dfields.forEach(
                (oData) => {
                  let dfield = this.eventDfields.find(f => f.id == oData.field_id);
                  if (!dfield.event_id_list?.length || dfield.event_id_list.indexOf(this.crmSettings.event_inbound_call) != -1) {
                    let oRx = this.fb.formGroup(new DomainCRMEventDField());
                    oRx.patchValue(oData);
                    this.setFieldValidators(this.eventDfields.find(f => f.id == oData.field_id), oRx);
                    (rxEvent.get('dfields') as FormArray).push(oRx);
                  }
                }
              );

              this.eventDfields.forEach(dfield => {
                if (
                  dfield.required &&
                  (!dfield.event_id_list?.length || dfield.event_id_list.indexOf(this.crmSettings.event_inbound_call) != -1) &&
                  !rxEvent.value.dfields.find(f => f.field_id == dfield.id)
                ) {
                  let oRx = this.fb.formGroup(new DomainCRMEventDField());
                  oRx.patchValue({id: null, event_id: event.id, field_id: dfield.id});
                  this.setFieldValidators(dfield, oRx);
                  (rxEvent.get('dfields') as FormArray).push(oRx);
                }
              });

              this.entityEvents.insert(0, rxEvent);
              this.entity.calls = this.entity.calls.filter(c => c.callID != call.callID);
              let crmList = localStorage.getItem(this.lsKey) ? JSON.parse(localStorage.getItem(this.lsKey)) : [];
              let lsEntity = crmList.find(e => e.id == this.entity.id);
              if (lsEntity) {
                lsEntity.calls = this.entity.calls;
                localStorage.setItem(this.lsKey, JSON.stringify(crmList));
              }
            },
            resp => {
              this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502;
              this.domainUserLoading = false;
            }
          )
        } else {
          this.entity.calls = this.entity.calls.filter(c => c.callID != call.callID);
          let crmList = localStorage.getItem(this.lsKey) ? JSON.parse(localStorage.getItem(this.lsKey)) : [];
          let lsEntity = crmList.find(e => e.id == this.entity.id);
          if (lsEntity) {
            lsEntity.calls = this.entity.calls;
            localStorage.setItem(this.lsKey, JSON.stringify(crmList));
          }
        }
      }

      if (call_id_list.length > 0) this.getCDRLinks(call_id_list);
    }
  }

  getKnowledgeDocuments(id_list) {
    this.knowledgebaseService.list({
      filter: {field_list: [{field: 'id', value: id_list, condition_type: 9}], type: 0},
    }).subscribe(
      kwData => {
        this.kw$.subscribe(docs => {
          this.kw$ = observableOf([...docs, ...(kwData.list || [])]);
        });
      },
      resp => {
        this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502;
      }
    );
  }

  getCDRLinks(id_list) {
    this.entityService.get_link_by_call_id({call_id_list: id_list}).subscribe(
      linksData => {
        this.call_link_list$.subscribe(links => {
          let play_list: any = {};
          for (let uuid in linksData.body) {
            play_list[uuid] = {uuid: linksData.body[uuid], play_list: [{link: linksData.body[uuid]} as Track]};
          }
          this.call_link_list$ = observableOf({...links, ...play_list});
        });
      },
      resp => {
        this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502;
      }
    );
  }

  callHistoryCallbackList(data: Array<any>): Observable<any> {
    return observableOf(data);
  }

  callHistorySetDataSource(data) {
    this.callsDataSource.data = data;
  }

  callHistoryDownloadAudio(link, data) {
    let name = 'noname.mp3';
    //if (data && data.id) name = `${data.id}.mp3`;
    if (data && data.f0) name = `${this.api.escapeFileName(data.f0)}.mp3`;
    this.baseReportService.downloadFile(link, name);
  }

  callHistoryFindPrev(index: number) {
    if (this.callsDataSource.data[index - 1]) return (this.callsDataSource.data[index - 1] as any)?.id;
    return '';
  }

  callHistoryFindNext(index: number) {
    if (this.callsDataSource.data[index + 1]) return (this.callsDataSource.data[index + 1] as any)?.id;
    return '';
  }

  callHistoryOpenAudioPlayerDialog(link, name = '', prev = '', next = '', id = '') {
    const dialogRef = this.dialog.open(AudioPlayerDialogComponent, {
      data: {
        id: id,
        link: link,
        name: name,
        prev: prev,
        next: next,
        comment: ''
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  callHistorySubscribe() {
    if (this.callsSort) {
      // If the user changes the sort order, reset back to the first page.
      this.callsSort.sortChange.subscribe(() => this.callsPaginator.pageIndex = 0);
      merge(this.callsSort.sortChange, this.callsPaginator.page)
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isCallsLoadingResults = true;
            return this.form['controls'].phone_list['controls'].filter(c => c.valid).length > 0 ? this.baseReportService.report('DomainReportCallsHistory', {
              limit: this.callsPaginator.pageSize,
              offset: this.callsPaginator.pageIndex * this.callsPaginator.pageSize,
              sort: this.sortParams,
              ...this.callHistoryGetParams()
            }, 'get').pipe(
              tap(res => {
                this.notifyService.checkCode(res);
              }),
              catchError(resp => {
                let code = this.notifyService.setFormErrors(resp);
                return observableOf({list: [], total_count: 0, code: code});
              })
            ) : observableOf({list: [], total_count: 0, code: 200})
          }),
          map(data => {
            // Flip flag to show that loading has finished.
            this.isCallsLoadingResults = false;
            this.isRateLimitReached = data.code == 502;
            this.callsColumns = [];
            this.columns_sub = [];
            this.footer = {};

            this.resultsLength = data.total_count;
            if (data.list) {
              if (data.list.fields && data.list.fields.fields) {
                data.list.fields.fields.forEach((column, index) => {
                  if (column.type == -1) return;

                  let elem = {
                    fix: data.list.fields.fix.includes(column.field),
                    type: column.type,
                    ds: (column?.children?.filter(ch => ch.ds === true)?.length > 0 || column.ds),
                    columnDef: column.field || getUUID4(4),
                    header: column.label,
                    class: `type-${column.type}`,
                    children: (column?.children?.map(ch => ch.field) || []),
                    cell: (element: any) => {
                      const val = `${element[column.field] ? element[column.field] : ''}`;
                      return val;
                    }
                  }

                  //this.columns.push(elem);
                  if (elem.children.length === 0) {
                    this.callsColumns.push(elem);
                    this.columns_sub.push({
                      name: `_${elem.columnDef}_`,
                      children: elem.children,
                      label: elem.header,
                      fix: elem.fix
                    });
                  } else {
                    const names = column.children.map(el => el.field).join('_');
                    this.columns_sub.push({name: `_${names}_`, children: elem.children, label: elem.header});
                    column.children.forEach(item => {
                      let elem2 = {
                        fix: false,
                        type: item.type,
                        ds: item.ds,
                        parent: elem.header,
                        columnDef: item.field || getUUID4(4),
                        header: item.label,
                        class: `type-${item.type}`,
                        cell: (element: any) => {
                          const val = `${element[item.field] ? element[item.field] : ''}`;
                          return val;
                        }

                      }
                      this.callsColumns.push(elem2);
                    })
                  }
                  //this.columns.push(elem);
                })
                this.footer = data.list.total;
                // чтобы данные в заголовке обновились, если названия и порядок столбцов не поменялся
                if (this.callsTable) this.callsTable.removeHeaderRowDef(null);
                this.displayedColumns = [...new Set([...this.callsColumns.filter(c => c?.ds == true).map(c => c.columnDef), ...this.callsColumns.filter(c => c.children?.ds == true).map(c => c.columnDef)])];
                return data.list.table || [];
              }
            }
            return [];
          }),
          catchError(() => {
            this.isCallsLoadingResults = false;
            this.resultsLength = 0;
            // Catch if the API has reached its rate limit. Return empty data.
            this.isRateLimitReached = true;
            return observableOf([]);
          })
        ).subscribe(data => {
          this.callHistoryCallbackList(data).subscribe(
            item => {
              this.callHistorySetDataSource(item);
            },
            error => this.callHistorySetDataSource([])
          )
        }, error => console.log(error)
      );
    }
  }

  callHistoryGetParams(): {} {
    let params = {}; // 0 - AND, 1 - OR
    let res = Object.assign({}, this.filterForm.value);
    if (res.start_dt) params['start_dt'] = moment(res.start_dt).unix() - getOffset(this.timezone);
    if (res.end_dt) params['end_dt'] = moment(res.end_dt).unix() - getOffset(this.timezone);
    params['src_or_dst_list'] = [...this.form['controls'].phone_list['controls'].filter(c => c.valid).reduce(
      (arr, c) => {
        arr.push(
          c.value.value,
          '+' + c.value.value,
          '8' + c.value.value,
          '7' + c.value.value,
          '+7' + c.value.value
        );
        return arr;
      }, []
    )];
    return params;
  }

  callHistoryChangePeriod(val) {
    let period;

    switch (val) {
      case 0:
        period = null;
        break;
      case 1:
        period = {
          start_dt: moment.utc().add(-1, 'days').startOf('day'),
          end_dt: moment.utc().add(-1, 'days').endOf('day')
        }
        break;
      case 3:
        period = {
          start_dt: moment.utc().startOf('day'),
          end_dt: moment.utc().endOf('day')
        }
        break;
      case 4:
        period = {
          start_dt: moment.utc().startOf('week'),
          end_dt: moment.utc().endOf('week')
        }
        break;
      case 5:
        period = {
          start_dt: moment.utc().startOf('month'),
          end_dt: moment.utc().endOf('month')
        }
        break;
      case 6:
        period = {
          start_dt: moment.utc().startOf('year'),
          end_dt: moment.utc().endOf('year')
        }
        break;
    }

    if (!period) return;

    this.filterForm.patchValue(period);

    this.callHistoryApplyFilter();
  }

  callHistoryApplyFilter() {
    if (this.callsPaginator) {
      if (this.callsPaginator.pageIndex != 0) this.callsPaginator.pageIndex = 0; // при поиске возвращаю пользователя на первую страницу
      this.callsPaginator._changePageSize(this.callsPaginator.pageSize);
    }
  }

  addEntity(entity_type: number) {
    let entityModel = new DomainCRMEntity();
    // @ts-ignore;
    entityModel.phone_list = new FormArray([]);
    // @ts-ignore;
    entityModel.email_list = new FormArray([]);
    // @ts-ignore;
    entityModel.address_list = new FormArray([]);
    // @ts-ignore;
    entityModel.dfields = new FormArray([]);
    this.newEntityForm = this.fb.formGroup(entityModel);
    this.newEntityForm.removeControl('id');
    this.newEntityForm.patchValue({
      entity_type: entity_type, // контакт
      responsible_user_id: (this.id ? this.form.value.responsible_user_id : this.responsibleUserID) // ответственный
    });

    if (entity_type == 1) { // создаю контакт; он находится в верстке компании
      this.showNewContactCard = true;
      this.newEntityForm.patchValue({parent_id_list: [this.id], /* ID компании */});
    } else { // создаю компанию в диалоговом окне
      this.dialogRef = this.dialog.open(this.newCompanyCard, {
        // panelClass: 'dialog-compact',
        minWidth: '350px',
        data: {
          entity_type: entity_type,
          responsible_user_id: (this.id ? this.form.value.responsible_user_id : this.responsibleUserID)
        }
      });
    }
  }

  onNewEntitySubmit(submit_btn?: MatButton) {
    this.newEntityForm.updateValueAndValidity();
    this.newEntityForm.markAllAsTouched();
    if (this.newEntityForm.valid) {
      if (submit_btn) submit_btn.disabled = true;
      this.api.save(this.newEntityForm.value).subscribe(
        (data) => {
          if (submit_btn) submit_btn.disabled = false;
          this.notifyService.showRequestResult(data, true); // отображаю код 200 или предупреждения
          this.showNewContactCard = false;
          this.getContactsOrCompanies();
          if (data.body.entity_type == 0) {
            this.dialogRef?.close();
            this.form.value.parent_id_list.push(data.body.id);
            this.form.patchValue({parent_id_list: this.form.value.parent_id_list});
          }
        },
        resp => {
          if (submit_btn) submit_btn.disabled = false;
          this.isServerAvailable = this.notifyService.setFormErrors(resp, this.newEntityForm) != 502;
        }
      );
    } else this.notifyService.message('NOTIFY.REQUIRED');
  }

  onNewEntityCancel() {
    this.showNewContactCard = false;
    this.newEntityForm = null;
  }

  callbackSaveSuccess(data, submit_btn) {
    super.callbackSaveSuccess(data, submit_btn);

    if (this.entity) {
      this.updateInitialData(this.form.value);
      this.form.markAsPristine();
      // this.id = data.body.id;

      let desktopCRMEntityList: any[] = localStorage.getItem(this.lsKey) != null ? JSON.parse(localStorage.getItem(this.lsKey)) : [];
      if (!this.entity?.id) {
        let clientIndex = desktopCRMEntityList.findIndex(v => v.calls.find(c => c.callID == this.entity.calls[0].callID));
        if (clientIndex != -1) {
          // неизвестный вызов становится известным клиентом
          desktopCRMEntityList[clientIndex] = {
            ...desktopCRMEntityList[clientIndex],
            id: data.body.id,
            name: this.form.value.name,
            entity_type: this.form.value.entity_type
          };
          if (this.entity.calls.length > 0 && this.entity.calls[0].phone == 'new_entity') {
            this.entity.calls = [];
            desktopCRMEntityList[clientIndex].calls = [];
          }
        }
        localStorage.setItem(this.lsKey, JSON.stringify(desktopCRMEntityList));
        // отрисовываю вкладку в окне CRM Рабочего стола с этим клиентом (контактом/компанией)
        this.CTI.crmEntity$.next(desktopCRMEntityList[clientIndex]);
      } else {
        let clientIndex = desktopCRMEntityList.findIndex(v => v.id == this.entity.id);
        if (clientIndex != -1 && desktopCRMEntityList[clientIndex].name != this.form.value.name) {
          // отрисовываю вкладку в окне CRM Рабочего стола с АКТУАЛЬНЫМ именем
          this.renamed.emit({id: this.entity.id, name: this.form.value.name});
        }
      }
    } else if (!this.id) {
      this.router.navigate([this.navigate, 'edit', data.body.id]);
    }
  }

  // onSubmit(submit_btn?: MatButton) {
  //   this.entityEvents.updateValueAndValidity();
  //   this.entityEvents.markAllAsTouched();
  //   if (this.entityEvents.valid) super.onSubmit(submit_btn);
  //   else this.notifyService.message('NOTIFY.REQUIRED');
  // }

  onCancel() {
    if (!this.entity) this.router.navigate([this.navigate]);
    else {
      (this.form.get('phone_list') as FormArray).clear();
      (this.form.get('email_list') as FormArray).clear();
      (this.form.get('address_list') as FormArray).clear();
      (this.form.get('dfields') as FormArray).clear();

      this.responsibleUserID = this.initialData.responsible_user_id;
      this.form.patchValue(this.initialData);

      for (let oData of this.initialData.phone_list) {
        let oRx = this.fb.formGroup(new CRMFieldPhone());
        oRx.patchValue(oData);
        (this.form.get('phone_list') as FormArray).push(oRx);
      }

      for (let oData of this.initialData.email_list) {
        let oRx = this.fb.formGroup(new CRMFieldEmail());
        oRx.patchValue(oData);
        (this.form.get('email_list') as FormArray).push(oRx);
      }

      for (let oData of this.initialData.address_list) {
        let oRx = this.fb.formGroup(new CRMFieldAddress());
        oRx.patchValue(oData);
        (this.form.get('address_list') as FormArray).push(oRx);
      }

      this.initialData.dfields.forEach(
        (oData) => {
          let oRx = this.fb.formGroup(new DomainCRMEntityDField());
          oRx.patchValue(Object.assign({}, oData, oData.list_val?.length > 0 ? {list_val: [...oData.list_val]} : (oData.dt_val ? {dt_val: moment(oData.dt_val * 1000)} : {})));
          this.setFieldValidators(this.dfields.find(f => f.id == oData.field_id), oRx);
          (this.form.get('dfields') as FormArray).push(oRx);
        }
      );
      this.dfields.forEach(dfield => {
        if (dfield.required && this.getEntityExistedDFields(dfield.id).length == 0) this.addDField(dfield);
      });

      this.form.markAsPristine();
    }
  }

  onDelete(btn: MatButton) {
    const confirmDialog = this.dialog.open(ConfirmComponent, {
      data: {
        title: 'DIALOG.CONFIRM_TITLE',
        message: this.entity_type == 0 ? 'DIALOG.CONFIRM_MESSAGE_COMPANY' : 'DIALOG.CONFIRM_MESSAGE_CONTACT'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        btn.disabled = true;
        this.api.delete(this.id).subscribe(
          data => {
            this.notifyService.showRequestResult(data, true);
            window.setTimeout(() => this.router.navigate([this.navigate]), 2000);
          },
          resp => {
            btn.disabled = false;
            this.isServerAvailable = this.notifyService.setFormErrors(resp, this.form) != 502;
          });
      }
    });
  }
}
