import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {ENTER} from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef, Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {MatAccordion, MatExpansionPanel} from '@angular/material/expansion';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {NotifyService} from '../../../_helpers/notify.service';
import {RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {AuthenticationService} from '../../../auth/authentication.service';
import {BaseFormComponent} from '../../../_helpers/base-form.component';
import {JsonAppConfigService} from '../../../config/json-app-config.service';
import {
  DomainDialogScriptForm,
  DDSFieldFilter,
  DDSFormFieldBase,
  DDSFormFieldBoolean,
  DDSFormFieldDate,
  DDSFormFieldDateTime,
  DDSFormFieldDecimal,
  DDSFormFieldEmail,
  DDSFormFieldInt,
  DDSFormFieldLine,
  DDSFormFieldLongString,
  DDSFormFieldCheckBox,
  DDSFormFieldPhone,
  DDSFormFieldRadio,
  DDSFormFieldSelect,
  DDSFormFieldShortString,
  DDSFormFieldText,
  DomainScriptFieldData,
  DDSFieldFilterValue,
  DomainDialogScriptFormPage,
  DSSFormFieldChoice,
  DDSFormFieldSingleButton,
  DDSFormFieldUser,
  DDSFormFieldExtUser,
  DDSFormFieldQueue,
  DDSFormFieldExtQueue,
  DomainDialogScriptFormEndReason
} from './domain-dialog-script-form';
import {DomainDialogScriptFormService} from './domain-dialog-script-form.service';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {DDSFieldTypes} from "../../../_helpers/constant";
import {RxFormHelpers} from "../../../_helpers/form.helpers";
import {UserService} from "../../../users/user.service";
import {QueueService} from "../../../queues/queue.service";
import {CTIService} from "../../../cti-panel/cti-panel.service";
import {EapiUserService} from "../../../users/eapi-user.service";
import {EapiQueueService} from "../../../queues/eapi-queue.service";
declare var require: any;
const jsonpath = require('jsonpath-plus');


@Component({
  selector: 'app-dds-form-form',
  templateUrl: './domain-dialog-script-form-form.component.html',
  styles: [`
    ng-select.ng-invalid + .mat-error {
      position: relative;
      top: 10px;
      line-height: 0;
    }

    input[type="color"]::-webkit-color-swatch {
        border: none;
    }
    input[type="color"]:invalid {
        background-color: red!important;
    }
  `]
})

export class DomainDialogScriptFormFormComponent extends BaseFormComponent<DomainDialogScriptForm> implements OnInit, OnDestroy {
  public collapseAll: boolean = true;
  public pageCollapseAll: boolean[] = [];
  public separatorKeysCodes = [ENTER];
  public formFields = [];
  public JSONPath = jsonpath.JSONPath;
  public condition_types = [
    {label: 'CONDITION_TYPE_EQUAL', symbol: '='},
    {label: 'CONDITION_TYPE_STARTSWITH', symbol: '^'},
    {label: 'CONDITION_TYPE_ENDSWITH', symbol: '$'},
    {label: 'CONDITION_TYPE_LIKE', symbol: '%'},
    {label: 'CONDITION_TYPE_MORE', symbol: '>'},
    {label: 'CONDITION_TYPE_MORE_OR_EQUAL', symbol: '>='},
    {label: 'CONDITION_TYPE_LESS', symbol: '<'},
    {label: 'CONDITION_TYPE_LESS_OR_EQUAL', symbol: '<='},
    {label: 'CONDITION_TYPE_NOT_EQUAL', symbol: '!='},
    {label: 'CONDITION_TYPE_IN', symbol: '&isin;'},
    {label: 'CONDITION_TYPE_NOT_IN', symbol: '&notin;'},
  ];
  public oldFieldId: string = null;
  public oldChoiceId: string = null;

  public ngOptions = {
    DDSFormFieldUser: {
      list: [],
      total: null,
      loaded: 0,
      loading: false,
      action: 'select_with_detail',
      service: null
    },
    DDSFormFieldExtUser: {
      list: [],
      total: null,
      loaded: 0,
      loading: false,
      action: 'select_with_detail',
      service: null
    },
    DDSFormFieldQueue: {
      list: [],
      total: null,
      loaded: 0,
      loading: false,
      action: 'select_with_detail',
      service: null
    },
    DDSFormFieldExtQueue: {
      list: [],
      total: null,
      loaded: 0,
      loading: false,
      action: 'select_with_detail',
      service: null
    }
  };

  @ViewChild('endReasonsPanel') endReasonsPanel: MatExpansionPanel;
  @ViewChild('endReasonsPanelBody') endReasonsPanelBody: ElementRef;
  @ViewChild('pageAccordion') pageAccordion: MatAccordion;
  @ViewChildren('fieldAccordion') fieldAccordion: QueryList<MatAccordion>;
  @ViewChildren('pagePanel') pagePanelList: QueryList<MatExpansionPanel>;
  @ViewChildren('fieldPanel') fieldPanelList: QueryList<MatExpansionPanel>;
  @ViewChildren('pagesContainer') pagesElement: QueryList<ElementRef>;
  @ViewChildren('fieldsContainer') fieldsElement: QueryList<ElementRef>;

  constructor(
    api: DomainDialogScriptFormService,
    fb: RxFormBuilder,
    route: ActivatedRoute,
    router: Router,
    http: HttpClient,
    notifyService: NotifyService,
    authenticationService: AuthenticationService,
    public dialog: MatDialog,
    public userService: UserService,
    public queueService: QueueService,
    public eapiUserService: EapiUserService,
    public eapiQueueService: EapiQueueService,
    protected AppConfig: JsonAppConfigService,
    public CTI: CTIService
  ) {
    super(DomainDialogScriptForm, api, fb, route, router, http, notifyService, authenticationService);
    super.navigate = 'domain-catalogs/dialog-script/form';
    this.formT.body = new FormArray([]);
    this.formT.end_reason = new FormArray([]);
    this.extParams = {id: this.route.snapshot.paramMap.get('id'), ...(this.route.snapshot.paramMap.get('ver') ? {ver: +this.route.snapshot.paramMap.get('ver')} : {})};
    this.ngOptions['DDSFormFieldUser'].service = userService;
    this.ngOptions['DDSFormFieldExtUser'].service = eapiUserService;
    this.ngOptions['DDSFormFieldQueue'].service = queueService;
    this.ngOptions['DDSFormFieldExtQueue'].service = eapiQueueService;
  }

  ngOnInit() {
    super.ngOnInit();
    this.form.patchValue({domain_id: this.authenticationService.currentUserValue.domain_id});
    if (!this.id) {
      this.addReason();
      this.addPage();
    }
  }

  callbackFormPatch(data: DomainDialogScriptForm) {
    let user_ids = [], queue_ids = [], ext_user_ids = [], ext_queue_ids = [];

    if (data.body.length > 0) {
      for (let pageData of data.body) {
        this.addPage(pageData);

        user_ids.push(...pageData.body.filter(v => v.model_name == 'DDSFormFieldUser' && v['data_source']?.path).map(v => v['data_source'].path));
        ext_user_ids.push(...pageData.body.filter(v => v.model_name == 'DDSFormFieldExtUser' && v['data_source']?.path).map(v => v['data_source'].path));
        queue_ids.push(...pageData.body.filter(v => v.model_name == 'DDSFormFieldQueue' && v['data_source']?.path).map(v => v['data_source'].path));
        ext_queue_ids.push(...pageData.body.filter(v => v.model_name == 'DDSFormFieldExtQueue' && v['data_source']?.path).map(v => v['data_source'].path));
      }
      this.updateFormAutoComplete();
    }

    if (user_ids.length > 0) this.optionsScrollEnd(user_ids, 'DDSFormFieldUser');
    if (ext_user_ids.length > 0) this.optionsScrollEnd(ext_user_ids, 'DDSFormFieldExtUser');
    if (queue_ids.length > 0) this.optionsScrollEnd(queue_ids, 'DDSFormFieldQueue');
    if (ext_queue_ids.length > 0) this.optionsScrollEnd(ext_queue_ids, 'DDSFormFieldExtQueue');

    this.toggleAll();

    if (data.end_reason?.length > 0) {
      for (let reasonData of data.end_reason) {
        this.addReason(reasonData);
      }
    } else this.addReason();

    super.callbackFormPatch(data);
  }

  optionsScrollEnd(ids: number[] = [], model_name: string = '') {
    if (this.ngOptions[model_name].total === null || (this.ngOptions[model_name].loaded < this.ngOptions[model_name].total) || ids.length > 0) {
      if (this.ngOptions[model_name].service) {
        this.ngOptions[model_name].loading = true;

        this.ngOptions[model_name].service.list({
          ...(ids.length > 0 ? {
            filter: {field_list: [
              {
                field: (model_name == 'DDSFormFieldUser' || model_name == 'DDSFormFieldExtUser') ? 'domain_user.user_id.id' : 'id',
                value: ids,
                condition_type: 9
              }
            ], type: 0}
          } : {}),
          sort: {name: '+', surname: '+'},
          limit: 200,
          offset: this.ngOptions[model_name].loaded
        }, this.ngOptions[model_name].action).subscribe(
          (data) => {
            if (this.ngOptions[model_name].total === null && ids.length == 0) this.ngOptions[model_name].total = data.total_count;

            if (model_name == 'DDSFormFieldUser' || model_name == 'DDSFormFieldExtUser') {
              for (let user of data.list) {
                let username = user.name + (user.surname ? ' ' + user.surname : '');

                if (ids.length == 0 && this.ngOptions[model_name].list.findIndex(v => v.user_id == user.id) == -1) this.ngOptions[model_name].loaded += 1;

                for (let uid of user.numbers) {
                  if (this.ngOptions[model_name].list.findIndex(v => v.uid == uid) == -1) {
                    let domain_user = user.number_list[uid];
                    let tooltip = '', color = '', active = 0;
                    if (
                      domain_user.reg_detail &&
                      (!('logout_dt' in domain_user.reg_detail) || domain_user.reg_detail['logout_dt']) &&
                      domain_user.reg_detail.has_transfer && this.CTI.mynumber != uid
                    ) {
                      color = 'text-blue';
                      tooltip = 'CTI.USER_TRANSFER';
                      active = 1;
                    } else if (!domain_user.reg_detail || !('logout_dt' in domain_user.reg_detail) || domain_user.reg_detail['logout_dt']) {
                      if (domain_user.status == 0) {
                        // color = 'bg-phone-no-active mat-elevation-z1';
                        color = 'text-mute';
                        tooltip = 'CTI.USER_OFFLINE';
                        active = 0;
                      } else {
                        // color = 'bg-phone-no-active mat-elevation-z1';
                        color = 'text-mute';
                        tooltip = 'USER.NO_REG';
                        active = 0;
                      }
                    } else if (domain_user.calls?.length > 0) {
                      color = 'text-yellow';
                      tooltip = 'USER.TALKING';
                      active = 0;
                    } else if (this.CTI.getAgentStatusById(domain_user.agent_status).base_status_id != -1) {
                      if (this.CTI.getAgentStatusById(domain_user.agent_status).base_status_id == -3) {
                        color = 'text-muted';
                        tooltip = 'AGENT.ON_BREAK';
                      } else {
                        color = 'text-danger';
                        tooltip = 'AGENT.LOGGED_OUT';
                      }
                      active = 0;
                    } else {
                      color = 'text-green';
                      // color = 'bg-phone-active';
                      tooltip = this.CTI.mynumber != uid ? 'USER.REG' : 'USER.SELF_CALL_DENIED';
                      active = this.CTI.mynumber != uid ? 1 : 0;
                    }

                    this.ngOptions[model_name].list.push({
                      id: domain_user.id,
                      uid: uid,
                      name: username,
                      agent_status: domain_user.agent_status,
                      class: color,
                      tooltip: tooltip,
                      active: active,
                      user_id: user.id,
                      user: user
                    });
                  }
                }
              }
            } else {
              for (let queue of data.list) {
                if (this.ngOptions[model_name].list.findIndex(v => v.id == queue['id']) == -1) {
                  let tooltip = '', color = '', active = 0;
                  if (queue.status == 0) {
                    color = 'text-muted';
                    tooltip = 'DISABLED2';
                    active = 0;
                  } else if (!queue.dp_number) {
                    color = 'text-muted';
                    tooltip = 'QUEUE.DP_NUMBER_NOT_SET';
                    active = 0;
                  } else if (queue.count_available_agents > 0) {
                    color = 'text-green';
                    tooltip = 'ENABLED2';
                    active = 1;
                  } else if (queue.count_busy_agents > 0) {
                    color = 'text-yellow';
                    tooltip = 'NOT_AVAILABLE_AGENT';
                    active = 0;
                  } else {
                    color = 'text-muted';
                    tooltip = queue.count_total_agents > 0 ? 'NOT_REGISTERED_AGENT' : 'NOT_AGENTS';
                    active = 0;
                  }

                  this.ngOptions[model_name].list.push({
                    id: queue.id,
                    name: queue.name,
                    count_available_agents: queue.count_available_agents,
                    count_busy_agents: queue.count_busy_agents,
                    count_total_agents: queue.count_total_agents,
                    dp_number: queue.dp_number,
                    class: color, tooltip: tooltip, active: active
                  });

                  if (ids.length == 0) this.ngOptions[model_name].loaded += 1;
                }
              }
            }

            this.ngOptions[model_name].list.sort((a,b) => (a.name < b.name) ? -1 : (a.name > b.name ? 1 : 0));
            this.ngOptions[model_name].list = [...this.ngOptions[model_name].list];
            this.ngOptions[model_name].loading = false;
          },
          resp => {
            this.ngOptions[model_name].loading = false;
            this.notifyService.setFormErrors(resp);
          }
        );
      }
    }
  }

  addReason(data: any = null) {
    let reason = this.fb.formGroup(new DomainDialogScriptFormEndReason());
    if (data) reason.patchValue(data);
    (this.form.get('end_reason') as FormArray).push(reason);
  }

  deleteReason(ind: number) {
    (this.form.get('end_reason') as FormArray).removeAt(ind);
  }

  getFieldPanelTitle(fieldValue) {
    if (fieldValue.model_name in this.ngOptions && fieldValue.data_source?.path) {
      return this.ngOptions[fieldValue.model_name].list.find(v => v.id == fieldValue.data_source.path)?.name || '';
    }
    return '';
  }

  toggleAll() {
    // скрываю/показываю все панели, только если они все уже отрисованы!
    if (this.fieldAccordion && this.form?.value?.id && (this.form.value.body.length == 0 || this.pagesElement.length == this.form.value.body.length)) {
      if (this.collapseAll) this.pageAccordion.closeAll();
      else this.pageAccordion.openAll();

      if (this.collapseAll) this.fieldAccordion.forEach(acc => acc.closeAll());
      else this.fieldAccordion.forEach(acc => acc.openAll());
    } else setTimeout(() => this.toggleAll(), 350);
  }

  getFieldData(model_name) {
    return DDSFieldTypes.find(ft => ft.model_name == model_name);
  }

  getFieldModel(model_name, data) {
    let rxModel;
    switch (model_name) {
      case 'DDSFormFieldLine': rxModel = this.fb.formGroup(new DDSFormFieldLine()); break;
      case 'DDSFormFieldText': rxModel = this.fb.formGroup(new DDSFormFieldText()); break;

      case 'DDSFormFieldShortString': rxModel = this.fb.formGroup(new DDSFormFieldShortString()); break;
      case 'DDSFormFieldLongString': rxModel = this.fb.formGroup(new DDSFormFieldLongString()); break;
      case 'DDSFormFieldRadio': rxModel = this.fb.formGroup(new DDSFormFieldRadio()); break;
      case 'DDSFormFieldSingleButton': rxModel = this.fb.formGroup(new DDSFormFieldSingleButton()); break;
      case 'DDSFormFieldCheckBox': rxModel = this.fb.formGroup(new DDSFormFieldCheckBox()); break;
      case 'DDSFormFieldSelect': rxModel = this.fb.formGroup(new DDSFormFieldSelect()); break;
      case 'DDSFormFieldBoolean': rxModel = this.fb.formGroup(new DDSFormFieldBoolean()); break;
      case 'DDSFormFieldInt': rxModel = this.fb.formGroup(new DDSFormFieldInt()); break;
      case 'DDSFormFieldDecimal': rxModel = this.fb.formGroup(new DDSFormFieldDecimal()); break;
      case 'DDSFormFieldEmail': rxModel = this.fb.formGroup(new DDSFormFieldEmail()); break;
      case 'DDSFormFieldPhone': rxModel = this.fb.formGroup(new DDSFormFieldPhone()); break;
      case 'DDSFormFieldDate': rxModel = this.fb.formGroup(new DDSFormFieldDate()); break;
      case 'DDSFormFieldDateTime': rxModel = this.fb.formGroup(new DDSFormFieldDateTime()); break;
      case 'DDSFormFieldUser': rxModel = this.fb.formGroup(new DDSFormFieldUser()); break;
      case 'DDSFormFieldExtUser': rxModel = this.fb.formGroup(new DDSFormFieldExtUser()); break;
      case 'DDSFormFieldQueue': rxModel = this.fb.formGroup(new DDSFormFieldQueue()); break;
      case 'DDSFormFieldExtQueue': rxModel = this.fb.formGroup(new DDSFormFieldExtQueue()); break;
      default: rxModel = this.fb.formGroup(new DDSFormFieldBase());
    }

    if (model_name != 'DDSFormFieldText' && model_name != 'DDSFormFieldLine') {
      if (data.data_source && Object.keys(data.data_source).length > 0) {
        rxModel.setControl('data_source', this.fb.formGroup(new DomainScriptFieldData()));
        rxModel.patchValue(data.data_source);
      } else if (['DDSFormFieldUser', 'DDSFormFieldQueue', 'DDSFormFieldExtUser', 'DDSFormFieldExtQueue'].indexOf(model_name) != -1) this.addDataSource(rxModel);

      if (data?.condition?.field_list?.length > 0) {
        this.addFormFieldFilter(rxModel, 'condition', data.condition);
      }

      if (model_name == 'DDSFormFieldRadio' || model_name == 'DDSFormFieldSingleButton' || model_name == 'DDSFormFieldCheckBox' || model_name == 'DDSFormFieldSelect') {
        rxModel.setControl('choices', new FormArray([]));
        if (data.choices?.length > 0) {
          for (let choiceData of data.choices) {
            this.addChoice(rxModel, choiceData);
          }
        } else this.addChoice(rxModel);
      }
    }

    return rxModel;
  }

  addPage(data: any = {}, rbInd: number|null = null, goToLast: boolean = false) {
    let pageModel = new DomainDialogScriptFormPage();
    // @ts-ignore
    pageModel.body = new FormArray([]);
    let page = this.fb.formGroup(pageModel);
    if (!data['name']) {
      let lastID = this.form.value.body
        .filter(f => f.name.startsWith('Страница №'))
        .sort((f1, f2) => f1.name.replace('Страница №', '') > f2.name.replace('Страница №', '') ?
          1 :
          (f1.name.replace('Страница №', '') < f2.name.replace('Страница №', '') ? -1 : 0))
        .slice(-1).pop()?.name?.replace('Страница №', '');
      lastID = lastID ? (+lastID)+1 : 1;
      data['name'] = `Страница №${lastID}`;
    }
    page.patchValue(data);

    if (data?.condition?.field_list?.length > 0) {
      this.addFormFieldFilter(page, 'condition', data.condition);
    }

    if (rbInd == null) rbInd = this.form.value.body.length;
    (this.form.get('body') as FormArray).insert(rbInd, page);

    if (data['body']?.length > 0) {
      for (let fieldData of data['body']) {
        this.addField(rbInd, fieldData, null, goToLast);
      }
    } else this.addField(rbInd, {}, null, goToLast);

    this.form.get('body').markAsDirty();
    this.pageCollapseAll.splice(rbInd, 0, true);
    // скролл к последнему элементу при добавлении
    if (goToLast) setTimeout(() => this.fieldsElement.last.nativeElement.parentElement.lastElementChild.scrollIntoView(), 100);
  }

  removePage(index){
    let pageFieldIds = [];
    let curPage = (this.form.get('body') as FormArray).at(index);
    for (let fieldInd = 0; fieldInd < curPage.value.body.length; fieldInd++) {
      pageFieldIds.push(curPage.value.body[fieldInd].id || `${index}#__#${fieldInd}`);
    }

    // прохожу по страницам, которые ниже, и удаляю из их фильтров фильтры с полями удаляемой страницы
    for (let pageInd = index + 1; pageInd < this.form.value.body.length; pageInd++) {
      this.updateFormFieldFilter(pageInd, pageFieldIds);
    }

    (this.form.get('body') as FormArray).removeAt(index);
    (this.form.get('body') as FormArray).markAsDirty();
    this.pageCollapseAll.splice(index, 0);
  }

  movePage(event: CdkDragDrop<DomainDialogScriptFormPage[]>) {
    const curPage = (this.form.get('body') as FormArray).at(event.previousIndex) as FormGroup;
    (this.form.get('body') as FormArray).removeAt(event.previousIndex);
    (this.form.get('body') as FormArray).insert(event.currentIndex, curPage);

    this.updateFormAutoComplete();

    if (event.previousIndex > event.currentIndex) {
      // Страница перемещена ВВЕРХ - ОБНОВЛЯЮ ВРЕМЕННЫЕ ИДЕНТИФИКАТОРЫ (меняю индекс страницы),
      // т.к. поля страниц выше итак не могли использовать поля страниц ниже.

      let fieldIds = [];

      // прохожу по полям перемещенной страницы, и обновляю в фильтрах временные идентификаторы
      for (let fieldInd = 0; fieldInd < curPage.value.body.length; fieldInd++) {
        if (!curPage.value.body[fieldInd].id) {
          // в самой странице
          this.updateFormFieldFilter(
            event.currentIndex,
            `${event.currentIndex}#__#${fieldInd}`,
            `${event.previousIndex}#__#${fieldInd}`,
            `${event.currentIndex}#__#${fieldInd}`
          );

          // в других страницах, находившихся до перемещения ниже текущей страницы
          for (let pageInd = event.previousIndex + 1; pageInd < this.form.value.body.length; pageInd++) {
            this.updateFormFieldFilter(
              pageInd,
              `${event.currentIndex}#__#${fieldInd}`,
              `${event.previousIndex}#__#${fieldInd}`,
              `${event.currentIndex}#__#${fieldInd}`
            );
          }
        }
      }

      // прохожу по страницам, которые были расположены выше перемещенной, но после перемещения стали ниже,
      // и удаляю их поля из фильтров перемещенной страницы, т.к. !верхние поля отображаются в параметре "Путь до поля"
      for (let pageInd = event.currentIndex + 1; pageInd < event.previousIndex; pageInd++) {
        for (let fieldInd = 0; fieldInd < this.form.value.body[pageInd].body.length; fieldInd++) {
          fieldIds.push(this.form.value.body[pageInd].body[fieldInd].id || `${pageInd}#__#${fieldInd}`);
        }
      }
      this.updateFormFieldFilter(event.currentIndex, fieldIds);
    } else if (event.previousIndex < event.currentIndex) {
      // Страница перемещена ВНИЗ

      let prevPageFieldIds = [];
      for (let fieldInd = 0; fieldInd < curPage.value.body.length; fieldInd++) {
        prevPageFieldIds.push(curPage.value.body[fieldInd].id || `${event.previousIndex}#__#${fieldInd}`);

        if (!curPage.value.body[fieldInd].id) {
          // прохожу по самой странице и по страницам ниже, и обновляю временные идентификаторы
          for (let pageInd = event.currentIndex; pageInd < this.form.value.body.length; pageInd++) {
            this.updateFormFieldFilter(
              pageInd,
              `${event.currentIndex}#__#${fieldInd}`,
              `${event.previousIndex}#__#${fieldInd}`,
              `${event.currentIndex}#__#${fieldInd}`
            );
          }
        }
      }

      // прохожу по страницам, которые после перемещения стали выше, и удаляю из их фильтров фильтры с полями перемещенной страницы
      for (let pageInd = event.previousIndex; pageInd < event.currentIndex; pageInd++) {
        this.updateFormFieldFilter(pageInd, prevPageFieldIds);
      }
    }
  }

  addField(pageInd: number, data: {} = {}, rbInd: number|null = null, goToLast: boolean = false){
    let pageBody = (this.form.get('body') as FormArray).at(pageInd).get('body') as FormArray;
    let rxM = this.getFieldModel(data['model_name'], data);
    if (rxM.value.model_name && ['DDSFormFieldLine', 'DDSFormFieldText'].indexOf(rxM.value.model_name) == -1 && data['id'] == null) {
      let shortModelName = rxM.value.model_name.replace('DDSFormField', '');
      let lastID = this.form.value.body.reduce((acc, p) => [...acc, ...p.body], [])
        .filter(f => f.model_name == rxM.value.model_name)
        .sort((f1, f2) => f1.id.replace(shortModelName, '') > f2.id.replace(shortModelName, '') ?
          1 :
          (f1.id.replace(shortModelName, '') < f2.id.replace(shortModelName, '') ? -1 : 0))
        .slice(-1).pop()?.id?.replace(shortModelName, '');
      lastID = lastID ? (+lastID)+1 : 1;
      data['id'] = `${rxM.value.model_name.replace('DDSFormField', '')}${lastID}`;
    }
    if (['DDSFormFieldUser', 'DDSFormFieldExtUser', 'DDSFormFieldQueue', 'DDSFormFieldExtQueue'].indexOf(data['model_name']) != -1 && data['data_source']?.path) data['data_source']['path'] = +data['data_source']['path'];
    rxM.patchValue(data, {emitEvent: false});
    if (rxM.value.model_name == 'DDSFormFieldLine' && !data['color']) rxM.patchValue({color: this.AppConfig.getValue('dpBorderColor')});
    if (rbInd == null) rbInd = pageBody.value.length;
    pageBody.insert(rbInd, rxM);
    pageBody.markAsDirty();
    // скролл к последнему элементу при добавлении
    if (goToLast) setTimeout(() => this.pagesElement.get(pageInd).nativeElement.parentElement.querySelector('.mat-accordion.cdk-drop-list .cdk-drag:last-of-type')?.scrollIntoView({block: "center"}), 100);
  }

  removeField(pageBody: FormArray, curPageInd, curFieldInd){
    for (let pageInd = curPageInd; pageInd < this.form.value.body.length; pageInd++) {
      this.updateFormFieldFilter(pageInd, pageBody.value[curFieldInd].id || `${curPageInd}#__#${curFieldInd}`);
    }

    pageBody.removeAt(curFieldInd);
    pageBody.markAsDirty();
    this.updateFormAutoComplete();
  }

  changeFieldModel(curPageInd: number, model_name, rbInd) {
    let pageBody = (this.form.get('body') as FormArray).at(curPageInd).get('body') as FormArray;
    for (let pageInd = curPageInd; pageInd < this.form.value.body.length; pageInd++) {
      this.updateFormFieldFilter(pageInd, pageBody.value[rbInd].id || `${curPageInd}#__#${rbInd}`);
    }
    pageBody.removeAt(rbInd);
    this.addField(curPageInd, {model_name: model_name}, rbInd);
  }

  changeFormFieldId(curPageInd, fieldInd, oldId, newId) {
    this.updateFormAutoComplete();

    for (let pageInd = curPageInd; pageInd < this.form.value.body.length; pageInd++) {
      this.updateFormFieldFilter(pageInd, newId, oldId, `${curPageInd}#__#${fieldInd}`);
    }
    this.oldFieldId = null;
  }

  moveField(curPageInd: number, pageBody: FormArray, event: CdkDragDrop<DDSFormFieldBase[]>) {
    if (event.previousContainer === event.container && event.previousIndex === event.currentIndex) return;

    let fieldData, rxM;

    if (event.previousContainer === event.container) {
      fieldData = {...pageBody.at(event.previousIndex).value};
      rxM = this.getFieldModel(fieldData['model_name'], fieldData);
      rxM.patchValue(fieldData, {emitEvent: false});

      pageBody.removeAt(event.previousIndex);
      pageBody.insert(event.currentIndex, rxM);

      this.updateFormAutoComplete();
      this.updateFormFieldFilter(
        curPageInd,
        fieldData.id || `${curPageInd}#__#${event.currentIndex}`,
        `${curPageInd}#__#${event.previousIndex}`,
        `${curPageInd}#__#${event.currentIndex}`
      );
    } else {
      // @ts-ignore
      let previousContainerIndex = [...event.previousContainer.element.nativeElement.parentElement.parentElement.parentElement.parentElement.children]
        .findIndex(item => item.querySelector('mat-accordion [id="'+event.previousContainer.element.nativeElement.id+ '"]'));
      // @ts-ignore
      let containerIndex = [...event.container.element.nativeElement.parentElement.parentElement.parentElement.parentElement.children]
        .findIndex(item => item.querySelector('mat-accordion [id="'+event.container.element.nativeElement.id+ '"]'));

      fieldData = {...((this.form.get('body') as FormArray).at(previousContainerIndex).get('body') as FormArray).at(event.previousIndex).value};
      rxM = this.getFieldModel(fieldData['model_name'], fieldData);
      rxM.patchValue(fieldData, {emitEvent: false});

      ((this.form.get('body') as FormArray).at(previousContainerIndex).get('body') as FormArray).removeAt(event.previousIndex);
      ((this.form.get('body') as FormArray).at(containerIndex).get('body') as FormArray).insert(event.currentIndex, rxM);

      this.updateFormAutoComplete();
      for (let pageInd = previousContainerIndex; pageInd <= containerIndex; pageInd++) {
        this.updateFormFieldFilter(
          pageInd,
          fieldData.id || `${containerIndex}#__#${event.currentIndex}`,
          `${previousContainerIndex}#__#${event.previousIndex}`,
          `${containerIndex}#__#${event.currentIndex}`
        );
      }
    }
  }

  updateFormAutoComplete() {
    this.formFields = [];
    this.form.value.body.forEach((page, pageInd) => {
      for (let fieldInd in page.body) {
        let f = page.body[fieldInd];
        this.formFields.push({
          id: [
            'DDSFormFieldLine', 'DDSFormFieldText', 'DDSFormFieldUser', 'DDSFormFieldExtUser', 'DDSFormFieldQueue', 'DDSFormFieldExtQueue'
          ].indexOf(f.model_name) == -1 ?
            f.id || `${pageInd}#__#${fieldInd}` :
            null,
          model_name: f.model_name,
          name: f.question,
          pageInd: pageInd,
          fieldInd: fieldInd
        });
      }
    });
  }

  getFieldById(fieldId) {
    for (let p of this.form.value.body) {
      for (let f of p.body) {
        if (f.id == fieldId) return f;
      }
    }
    return {};
  }

  addDataSource(field){
    field.setControl('data_source', this.fb.formGroup(new DomainScriptFieldData()));

    if (field.value.model_name == 'DDSFormFieldUser' || field.value.model_name == 'DDSFormFieldQueue') field.get('data_source').patchValue({source: 3});
    if (field.value.model_name == 'DDSFormFieldExtUser' || field.value.model_name == 'DDSFormFieldExtQueue') field.get('data_source').patchValue({source: 4});
  }

  removeDataSource(field){
    field.removeControl('data_source');
  }

  addChoice(field, data: any = {}, ind: number = null) {
    let choice = this.fb.formGroup(new DSSFormFieldChoice());
    if (data) choice.patchValue(data);
    if (!ind) ind = field.value.choices.length;
    (field.get('choices') as FormArray).insert(ind, choice);

    if (data?.condition?.field_list?.length > 0) {
      this.addFormFieldFilter(choice, 'condition', data.condition);
    }
    this.updateFormAutoComplete();
  }

  removeChoice(curPageInd, fieldInd, choiceInd) {
    let field = this.form.get('body')['controls'][curPageInd].get('body').at(fieldInd);
    this.changeChoiceId(curPageInd, fieldInd, choiceInd, null, field.value.choices[choiceInd].id);
    (field.get('choices') as FormArray).removeAt(choiceInd);
  }

  changeChoiceId(curPageInd, fieldInd, choiceInd, oldId, newId) {
    let field = this.form.value.body[curPageInd].body[fieldInd];
    let tmpChoiceId = newId || `${curPageInd}#__#${fieldInd}#__#${choiceInd}`;

    if (oldId === '') oldId = `${curPageInd}#__#${fieldInd}#__#${choiceInd}`;

    field.choices = field.choices.map(c => {
      return {...c, id: c.id ? c.id : tmpChoiceId};
    });

    for (let pageInd = curPageInd; pageInd < this.form.value.body.length; pageInd++) {
      // ...обновление ВСЕХ значений фильтров при изменении/перемещении поля-выбора...
      let page = (this.form.get('body') as FormArray).at(pageInd) as FormGroup;

      // проверка наличия редактируемого/удаляемого чойса в условиях СТРАНИЦЫ
      if (pageInd && page.value.condition?.field_list?.length > 0) {
        // Меняю Путь до поля на актуальный или временный (если идентиффикатор = пустой строке)
        this.updateFormFieldFilterChoiceValue(page, 'condition', field.id, oldId, tmpChoiceId);
      }

      // проверка наличия удаляемого поля в условиях ПОЛЕЙ страницы
      for (let fieldInd = 0; fieldInd < page.get('body')['controls'].length; fieldInd++) {
        let formField = (page.get('body') as FormArray).at(fieldInd) as FormGroup;

        if (formField.value.condition?.field_list?.length > 0) {
          // Меняю Путь до поля на актуальный или временный (если идентиффикатор = пустой строке)
          this.updateFormFieldFilterChoiceValue(formField, 'condition', field.id, oldId, tmpChoiceId);
        }

        // проверка наличия удаляемого поля в условиях ВАРИАНТА поля страницы
        if (formField.value.choices?.length > 0) {
          for (let choiceInd = 0; choiceInd < formField.get('choices')['controls'].length; choiceInd++) {
            let fieldChoice = (formField.get('choices') as FormArray).at(choiceInd) as FormGroup;
            if (fieldChoice.value.condition?.field_list?.length > 0) {
              // Меняю Путь до поля на актуальный или временный (если идентиффикатор = пустой строке) идентификатор
              this.updateFormFieldFilterChoiceValue(fieldChoice, 'condition', field.id, oldId, tmpChoiceId);
            }
          }
        }
      }
    }
    this.oldFieldId = null;
  }

  createCondition(field) {
    field.setControl('condition', this.fb.formGroup(new DDSFieldFilter()));
    (field.get('condition') as FormGroup).setControl('field_list', new FormArray([]));
    this.addFormFieldFilterField(field.get('condition.field_list') as FormArray);
  }

  addFormFieldFilter(condition: FormGroup, conditionGrpName: string = 'filter', data: any = {}) {
    condition.setControl(conditionGrpName, this.fb.formGroup(new DDSFieldFilter()));
    let conditionGrp = condition.get(conditionGrpName) as FormGroup;
    conditionGrp.patchValue(data);
    conditionGrp.setControl('field_list', new FormArray([]));
    if (data.field_list?.length > 0) {
      for (let c of data.field_list) {
        this.addFormFieldFilterField(conditionGrp.get('field_list') as FormArray, c);
      }
    } else this.addFormFieldFilterField(conditionGrp.get('field_list') as FormArray);
    if (data.filter) {
      this.addFormFieldFilter(conditionGrp, 'filter', data.filter);
    }
  }

  removeFormFieldFilter(conditionGrp: FormGroup, name) {
    conditionGrp.removeControl(name);
  }

  updateFormFieldFilter(pageInd, fieldId, oldFieldId: string = null, tmpFieldId: string = null) {
    // ...обновление ВСЕХ фильтров при изменении, перемещении поля...

    // прохожу по всем страницам, начиная со страницы, на которой находится поле, т.к. оно не м.б. на предыдущих страницах,
    // и смотрю их условия, условия полей и условия вариантов в полях с выбором (чекбокс, радио, селект)
    let indexes = tmpFieldId?.includes('#__#') ? tmpFieldId.split('#__#') : null; // при переносе поля не будет пустым, в нем будут <индекс страницы>#__#<индекс поля>
    let oldIndexes = oldFieldId?.includes('#__#') ? oldFieldId.split('#__#') : null; // при переносе поля не будет пустым, в нем будут <прежний индекс страницы>#__#<прежний индекс поля>

    let page = (this.form.get('body') as FormArray).at(pageInd) as FormGroup;

    // проверка наличия удаляемого поля в условиях СТРАНИЦЫ
    if (page.value.condition?.field_list?.length > 0) {
      if (indexes && pageInd <= +indexes[0]) {
        // Поле перемещено ниже данного -> удаляю его из фильтра.
        // При этом, если поле пустое, то передаю старые позиции поля, т.к. они не заменены на новые
        this.updateFormFieldFilterField(page, 'condition', fieldId || oldFieldId);
      } else {
        // Меняю Путь до поля на актуальный или временный (если идентиффикатор = пустой строке)
        this.updateFormFieldFilterField(page, 'condition', fieldId, oldFieldId, tmpFieldId);
      }
    }

    // проверка наличия удаляемого поля в условиях ПОЛЕЙ страницы
    for (let fieldInd = 0; fieldInd < page.get('body')['controls'].length; fieldInd++) {
      let formField = (page.get('body') as FormArray).at(fieldInd) as FormGroup;

      if (formField.value.condition?.field_list?.length > 0) {
        if (indexes && (pageInd < +indexes[0] || (pageInd == indexes[0] && fieldInd < +indexes[1]))) {
          // Поле перемещено ниже данного -> удаляю его из фильтра.
          // При этом, если поле пустое, то передаю старые позиции поля, т.к. они не заменены на новые
          this.updateFormFieldFilterField(formField, 'condition', fieldId || oldFieldId);
        } else {
          // Меняю Путь до поля на актуальный или временный (если идентиффикатор = пустой строке)
          this.updateFormFieldFilterField(formField, 'condition', fieldId, oldFieldId, tmpFieldId);
        }
      }

      // проверка наличия удаляемого поля в условиях ВАРИАНТА поля страницы
      if (formField.value.choices?.length > 0) {
        for (let choiceInd = 0; choiceInd < formField.get('choices')['controls'].length; choiceInd++) {
          let fieldChoice = (formField.get('choices') as FormArray).at(choiceInd) as FormGroup;
          if (fieldChoice.value.condition?.field_list?.length > 0) {
            if (indexes && (pageInd < +indexes[0] || (pageInd == indexes[0] && fieldInd < +indexes[1]))) {
              // Поле перемещено ниже данного -> удаляю его из фильтра.
              // При этом, если поле пустое, то передаю старые позиции поля, т.к. они не заменены на новые
              this.updateFormFieldFilterField(fieldChoice, 'condition', fieldId || oldFieldId);
            } else {
              // Меняю Путь до поля на актуальный или временный (если идентиффикатор = пустой строке) идентификатор
              this.updateFormFieldFilterField(fieldChoice, 'condition', fieldId, oldFieldId, tmpFieldId);
            }
          }
        }
      }
    }
  }

  addFormFieldFilterField(field_list, data: any = {}, ind: number = null) {
    let filter_field = this.fb.formGroup(new DDSFieldFilterValue());
    filter_field.setControl('field', this.fb.formGroup(new DomainScriptFieldData()));
    if (data) filter_field.patchValue(data);
    if (!ind) ind = field_list.value.length;

    if (data?.field?.source == 0) {
      let formField = this.getFieldById(data.field.path);
      if (formField?.model_name == 'DDSFormFieldInt') {
        filter_field.get('value').setValidators([RxwebValidators.digit()]);
      } else if (formField?.model_name == 'DDSFormFieldDecimal') {
        filter_field.get('value').setValidators([RxwebValidators.numeric({allowDecimal: true, message: 'VAR_TYPE_DECIMAL'})]);
      } else if (formField?.model_name == 'DDSFormFieldPhone') {
        filter_field.get('value').setValidators([RxwebValidators.pattern({expression: {onlyAlpha: RxFormHelpers.phone}, message: 'ERROR.PHONE'})]);
      }  else if (formField?.model_name == 'DDSFormFieldEmail') {
        filter_field.get('value').setValidators([RxwebValidators.pattern({expression: {onlyAlpha: RxFormHelpers.email}, message: 'ERROR.EMAIL'})]);
      } else {
        filter_field.get('value').setValidators([]);
      }
      filter_field.get('value').updateValueAndValidity();
    }

    field_list.insert(ind, filter_field);
  }

  removeFormFieldFilterField(field_list: FormArray, ind: number) {
    field_list.removeAt(ind);
  }

  updateFormFieldFilterField(condition: FormGroup, conditionGrpName: string = 'filter', fieldId, oldFieldId: string = null, tmpFieldId: string = null) {
    // ...обновление КОНКРЕТНОЙ ГРУППЫ УСЛОВИЙ, которая можнет иметь свои группу условий, которая рекурсивно будет проверена...
    // на вход передаются:
    //  - condition - группа условий (фильтров);
    //  - conditionGrpName - название группы в таблице (condition или filter);
    //  - fieldId - идентификатор поля (или список идентификаторов на удаление), который установлен в качестве "Пути до переменной", если "Источник данных" = "Форма",
    //  - oldFieldId - предыдущий идентификатор поля. Передается при изменении айдишника поля, а также при перетаскивани поля,
    //                 если оно пустое, чтобы найти и заменить старые индексы страницы и поля, на новые индексы;
    //  - tmpFieldId - временный идентификатор поля, который формируется из <индекса страницы> + '#__#' + <индекса поля>. Нужен в 2 случаях:
    //                 1. При переименовании идентификатора поля, когда оно становится пустым и теряется фокус,
    //                    все пути в фильтрах меняются на этот идентификатор, чтобы при изменении еще какого-то поля на пустое, они не пересеклись.
    //                 2. При перемещении поля из данного идентифкатора получаем индекс страницы и индекс поля, куда оно было перемещено.
    //                    При этом из фильтров полей, которые стали расположены выше данного, будут удалены строки с данным полем,
    //                    а в полях, расположенных ниже, если идентификатор пустой, произойдет замена идентификатора на этот (временный).

    if (condition.value[conditionGrpName]?.filter?.field_list?.length > 0) {
      this.updateFormFieldFilterField(condition.get(conditionGrpName) as FormGroup, 'filter', fieldId, oldFieldId, tmpFieldId);
    }
    for (let filterFieldInd = condition.value[conditionGrpName]?.field_list.length - 1; filterFieldInd >= 0; filterFieldInd--) {
      let filterField = condition.value[conditionGrpName]?.field_list[filterFieldInd];
      if (filterField.field.source == 0) {
        if (oldFieldId != null) {
          // поле переименовано или перемещено, но не ниже проверяемых поля/его чойсов/страницы
          // (а иначе fieldId, oldFieldId, tmpFieldId - будут = null) -> ПЕРЕИМЕНОВЫВАЮ ПУТЬ до него во всех фильтрах

          if (filterField.field.path == oldFieldId || filterField.field.path == tmpFieldId) {
            (condition.get([conditionGrpName, 'field_list']) as FormArray).at(filterFieldInd).get('field').patchValue({path: fieldId || tmpFieldId});
          }
        } else if ((typeof fieldId == 'string' && filterField.field.path == fieldId) || (fieldId.indexOf(filterField.field.path) != -1)) {
          // поле удалено или поменялся его тип или перемещено ниже данного объекта (поля/его чойсов/страницы) -> УДАЛЯЮ ПОЛЕ из всех фильтров

          if (condition.value[conditionGrpName]?.field_list?.length > 1) (condition.get([conditionGrpName, 'field_list']) as FormArray).removeAt(filterFieldInd);
          else {
            if (condition.get([conditionGrpName, 'filter'])) {
              condition.setControl(conditionGrpName, condition.get([conditionGrpName, 'filter']));
            } else condition.removeControl(conditionGrpName);
          }
        }
      }
    }
  }

  updateFormFieldFilterChoiceValue(condition: FormGroup, conditionGrpName: string = 'filter', fieldId, oldChoiceId: string = null, choiceId: string = null) {
    // ...обновление КОНКРЕТНОЙ ГРУППЫ УСЛОВИЙ, которая можнет иметь свои группу условий, которая рекурсивно будет проверена...
    // на вход передаются:
    //  - condition - группа условий (фильтров);
    //  - conditionGrpName - название группы в таблице (condition или filter);
    //  - fieldId - идентификатор поля (или список идентификаторов на удаление), который установлен в качестве "Пути до переменной", если "Источник данных" = "Форма",
    //  - oldChoiceId - предыдущий идентификатор варианта. Передается при изменении айдишника варианта, если оно пустое,
    //                 чтобы найти и заменить старые индексы страницы, поля и варианта, на новые индексы;
    //  - tmpChoiceId - временный идентификатор поля, который формируется из <индекса страницы> + '#__#' + <индекса поля> + '#__#' + <индекса варианта>.

    if (condition.value[conditionGrpName]?.filter?.field_list?.length > 0) {
      this.updateFormFieldFilterChoiceValue(condition.get(conditionGrpName) as FormGroup, 'filter', fieldId, oldChoiceId, choiceId);
    }

    for (let filterFieldInd = condition.value[conditionGrpName]?.field_list.length - 1; filterFieldInd >= 0; filterFieldInd--) {
      let filterField = condition.value[conditionGrpName]?.field_list[filterFieldInd];
      if (filterField.field.source == 0) {
        if (oldChoiceId != null) {
          // поле переименовано -> ПЕРЕИМЕНОВЫВАЮ значение
          if (typeof filterField.value == 'string' && filterField.value == oldChoiceId) {
            (condition.get([conditionGrpName, 'field_list']) as FormArray).at(filterFieldInd).patchValue({value: choiceId});
          } else if (typeof filterField.value == 'object' && filterField.value.indexOf(oldChoiceId) != -1) {
            let ff = (condition.get([conditionGrpName, 'field_list']) as FormArray).at(filterFieldInd);
            ff.patchValue({value: ff.value.value.map(v => v == oldChoiceId ? choiceId : v)});
          }
        } else {
          // вариант удален -> удаляю значение из списка (если это список) значений или удаляю строку фильтра
          if (typeof filterField.value == 'string' && filterField.value == choiceId) {
            (condition.get([conditionGrpName, 'field_list']) as FormArray).removeAt(filterFieldInd);
          } else if (filterField.value && typeof filterField.value == 'object' && filterField.value.indexOf(choiceId) != -1) {
            let filterField = (condition.get([conditionGrpName, 'field_list']) as FormArray).at(filterFieldInd);
            filterField.patchValue({value: filterField.value.value.filter(v => v != choiceId)});
            if (filterField.value.length == 0) {
              if (condition.get([conditionGrpName, 'filter'])) {
                condition.setControl(conditionGrpName, condition.get([conditionGrpName, 'filter']));
              } else condition.removeControl(conditionGrpName);
            }
          }
        }
      }
    }
  }

  setFormFieldFilterFieldValue(filterField, fieldId) {
    let formField = this.getFieldById(fieldId);
    filterField.patchValue({
      value: formField?.model_name == 'DDSFormFieldBoolean' ? false : (filterField.value.condition_type < 9 ? null : []),
      condition_type: formField?.model_name != 'DDSFormFieldCheckBox' ? 0 : 9
    });

    if (formField?.model_name == 'DDSFormFieldInt') {
      filterField.get('value').setValidators([RxwebValidators.digit()]);
    } else if (formField?.model_name == 'DDSFormFieldDecimal') {
      filterField.get('value').setValidators([RxwebValidators.numeric({allowDecimal: true, message: 'VAR_TYPE_DECIMAL'})]);
    } else if (formField?.model_name == 'DDSFormFieldPhone') {
      filterField.get('value').setValidators([RxwebValidators.pattern({expression: {onlyAlpha: RxFormHelpers.phone}, message: 'ERROR.PHONE'})]);
    }  else if (formField?.model_name == 'DDSFormFieldEmail') {
      filterField.get('value').setValidators([RxwebValidators.pattern({expression: {onlyAlpha: RxFormHelpers.email}, message: 'ERROR.EMAIL'})]);
    } else {
      filterField.get('value').setValidators([]);
    }
    filterField.get('value').updateValueAndValidity();
  }

  setFormFieldFilterFieldValueByConditionType(filterField, fieldId, condition_type) {
    filterField.patchValue({value: this.getFieldById(fieldId)?.model_name == 'DDSFormFieldBoolean' ? false : (condition_type < 9 ? null : [])});
  }

  previewForm() {
    let formData: DomainDialogScriptForm = {...this.form.value, body: []};
    for (let p of this.form.value.body) {
      let page = {...p, body: []};
      for (let f of p.body) {
        page.body.push({...f});
      }
      formData.body.push(page);
    }

    const dialogRef = this.dialog.open(DomainDialogScriptFormViewDialogComponent, {
      panelClass: 'dialog-size-full',
      // width: 'calc(100%-200px)',
      // height: 'calc(100%-100px)',
      data: {
        id: this.id,
        ver: this.extParams['ver'],
        formData: formData
      }
    });

    // dialogRef.componentInstance.id = this.id;
    // dialogRef.componentInstance.ver = this.extParams['ver'];
    // dialogRef.componentInstance.formData = formData;
  }

  onSubmit(submit_btn?: MatButton) {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();

    if (!this.form.get('end_reason').valid && this.endReasonsPanel?.closed) {
      this.endReasonsPanel.open();
      this.endReasonsPanelBody.nativeElement.scrollIntoView({block: "center"});
    } else if (
      !this.form.get('body').valid &&
      this.form.get('body')['controls'].filter(page => !page.valid).length > 0
    ) {
      this.form.get('body')['controls'].forEach((page, i) => {
        if (!page.valid) {
          this.pagePanelList.get(i).open();
          let firstIncorrectField = page.get('body')['controls'].findIndex(f => !f.valid);
          if (firstIncorrectField != -1) {
            console.log(this.pagesElement.get(i).nativeElement.parentElement.children[1].children[firstIncorrectField]);
            setTimeout(() => this.pagesElement.get(i).nativeElement.parentElement.children[1].children[firstIncorrectField].scrollIntoView({block: "center"}), 250);
          } else {
            console.log(page);
            this.pagesElement.get(i).nativeElement.parentElement.firstChild.scrollIntoView({block: "center"});
          }
        }
        page.get('body')['controls'].forEach((field, j) => {if (!field.valid) this.fieldPanelList.get(j).open();});
      });
      return;
    }

    super.onSubmit(submit_btn);
  }

  protected readonly DDSFieldTypes = DDSFieldTypes;
}


@Component({
  selector: 'app-dds-form-view-dialog',
  template: `<app-dds-form-view [id]="data.id" [ver]="data.ver" [formData]="data.formData" [mode]="'dialog'"></app-dds-form-view>`
})
export class DomainDialogScriptFormViewDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {id: string, ver: number, formData: any},
    public dialog: MatDialog
  ) {}
}
