import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {DomainDialogScriptFormService} from "./domain-dialog-script-form.service";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {AuthenticationService} from "../../../auth/authentication.service";
import {NotifyService} from "../../../_helpers/notify.service";
import {MatDialog} from "@angular/material/dialog";
import {
  DDSFieldFilter,
  DDSFieldFilterValue,
  DDSFormFieldBase,
  DDSFormFieldBoolean,
  DDSFormFieldCheckBox,
  DDSFormFieldDate,
  DDSFormFieldDateTime,
  DDSFormFieldDecimal,
  DDSFormFieldEmail,
  DDSFormFieldExtQueue,
  DDSFormFieldExtUser,
  DDSFormFieldInt,
  DDSFormFieldLine,
  DDSFormFieldLongString,
  DDSFormFieldPhone,
  DDSFormFieldQueue,
  DDSFormFieldRadio,
  DDSFormFieldSelect,
  DDSFormFieldShortString,
  DDSFormFieldSingleButton,
  DDSFormFieldText,
  DDSFormFieldUser,
  DomainDialogScriptForm,
  DomainDialogScriptFormPage,
  DomainDialogScriptFormResult,
  DomainScriptFieldData,
  DSSFormFieldChoice,
  FormQuestionFieldBase
} from "./domain-dialog-script-form";
import {RxFormBuilder, RxwebValidators} from "@rxweb/reactive-form-validators";
import {JsonAppConfigService} from "../../../config/json-app-config.service";
import {RxFormHelpers} from "../../../_helpers/form.helpers";
import {forkJoin, of as observableOf, throwError} from 'rxjs';
// @ts-ignore
// tslint:disable-next-line:no-duplicate-imports
// @ts-ignore
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import {catchError, concatMap, map} from "rxjs/operators";
import {DomainDialogScriptResultService} from "../scripts/domain-dialog-script-result.service";
import {DomainDialogScriptFormResultService} from "./domain-dialog-script-form-result.service";
import {MatButton} from "@angular/material/button";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../../users/user.service";
import {QueueService} from "../../../queues/queue.service";
import {EapiUserService} from "../../../users/eapi-user.service";
import {EapiQueueService} from "../../../queues/eapi-queue.service";
import {CTIService} from "../../../cti-panel/cti-panel.service";
import {AgentService} from "../../../queues/agent.service";
import {DomainService} from "../../domain.service";
import {EapiAgentService} from "../../../queues/eapi-agent.service";

declare var require: any;
const jsonpath = require('jsonpath-plus');
const moment = _rollupMoment || _moment;


@Component({
  selector: 'app-dds-form-view',
  templateUrl: './domain-dialog-script-form-view.component.html',
  styles: [`
    ::ng-deep .ng-dropdown-panel {
      z-index: 9999999!important;
    }
  `],
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DomainDialogScriptFormViewComponent implements OnInit {
  @Input() id: string = null;
  @Input() ver: number = null;
  @Input() formData: DomainDialogScriptForm;
  @Input() mode: string = 'card'; // card - отображение в диалоговом скрипте; dialog - форма Предпросмотра.
  @Input() dialogScript: any;
  @Input() formResultData: DomainDialogScriptFormResult;
  @Input() hasPrevDialogScriptStep: boolean = false;
  @Input() hasNextDialogScriptStep: boolean = false;
  @Output() onSave = new EventEmitter<any>();
  @Output() getDialogScriptStep = new EventEmitter<number>();
  @Output() endDialogScript = new EventEmitter<number|null>();

  public rxFormHelpers: RxFormHelpers = new RxFormHelpers();
  public form: FormGroup;
  public formPageIndex: number = null;
  public isLoadingResults = true;
  public isServerAvailable = true;
  public JSONPath = jsonpath.JSONPath;
  public abortScriptBtns = [];
  public successScriptBtns = [];
  public sidePanelOpened: boolean = true;

  public ngOptions = {
    DDSFormFieldUser: {
      list: [],
      total: null,
      loaded: 0,
      loading: false,
      action: 'select_with_detail',
      service: null
    },
    DDSFormFieldExtUser: {
      list: [],
      total: null,
      loaded: 0,
      loading: false,
      action: 'select_with_detail',
      service: null
    },
    DDSFormFieldQueue: {
      list: [],
      total: null,
      loaded: 0,
      loading: false,
      action: 'select_with_detail',
      service: null,
      tier_service: null
    },
    DDSFormFieldExtQueue: {
      list: [],
      total: null,
      loaded: 0,
      loading: false,
      action: 'select_with_detail',
      service: null,
      tier_service: null
    }
  };

  public isTierLoadingResults: boolean = false;
  public tiers: any[] = [];
  public selected_queue: number = null;

  public sourcesData: any = null;
  @ViewChild('sbmtBtn') sbmtBtn: MatButton;

  constructor(
    public api: DomainDialogScriptFormService,
    public auth: AuthenticationService,
    public notifyService: NotifyService,
    private domainDialogScriptResultService: DomainDialogScriptResultService,
    private domainDialogScriptFormResultService: DomainDialogScriptFormResultService,
    public userService: UserService,
    public queueService: QueueService,
    public tierService: AgentService,
    public eapiTierService: EapiAgentService,
    public eapiUserService: EapiUserService,
    public eapiQueueService: EapiQueueService,
    public domainService: DomainService,
    public CTI: CTIService,
    public dialog: MatDialog,
    public AppConfig: JsonAppConfigService,
    public translate: TranslateService,
    public fb: RxFormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.ngOptions['DDSFormFieldUser'].service = userService;
    this.ngOptions['DDSFormFieldExtUser'].service = eapiUserService;
    this.ngOptions['DDSFormFieldQueue'].service = queueService;
    this.ngOptions['DDSFormFieldQueue'].tier_service = tierService;
    this.ngOptions['DDSFormFieldExtQueue'].service = eapiQueueService;
    this.ngOptions['DDSFormFieldExtQueue'].tier_service = eapiTierService;
  }

  isOver(): boolean {
    return window.matchMedia('(max-width: 960px)').matches;
  }

  ngOnInit() {
    if (this.id) {
      forkJoin([
        this.formData ? observableOf(this.formData) : this.api.get(null, {id: this.id, ver: this.ver}),
        this.dialogScript?.call_uuid ? this.domainService.get_data({source_type: 'call', obj_ident: this.dialogScript.call_uuid}).pipe(catchError(resp => observableOf({}))) : observableOf({})
      ]).subscribe(
        results => {
          this.sourcesData = results[1].body;

          this.formData = results[0];
          if (this.formData.end_reason.length > 0) {
            for (let btn of this.formData.end_reason) {
              if ([0, 2, 3].indexOf(btn.reason) != -1) this.successScriptBtns.push(btn);
              else this.abortScriptBtns.push(btn);
            }
          }

          this.form = this.fb.formGroup(new DomainDialogScriptForm());
          this.form.patchValue(this.formData);
          this.form.setControl('body', new FormArray([]));

          if (results[0].body.length > 0) {
            for (let pageInd = 0; pageInd < results[0].body.length; pageInd++) {
              this.addPage(results[0].body[pageInd], pageInd);
            }

            if (this.mode == 'dialog' || (Object.keys(this.formResultData?.body || {}).length == 0)) { // P.S. для !Предпросмотра! формы первой автоматически становится первая страница.)
              this.formPageIndex = 0;
            } else for (let pageInd = results[0].body.length - 1; pageInd >= 0; pageInd--) {
              // Ищу первую страницу для отображения, которая должна быть либо первой страницей при Предпросмотре
              // или же последняя заполненная страница, она при этом точно валидна, иначе бы не была сохранена
              let page = this.getPage(pageInd);
              if (
                (!results[0].body[pageInd].condition || this.checkCondition(page, page.value)) &&
                (page.value.body.some(f => f.id && f.id in this.formResultData.body) || pageInd == 0)
              ) this.formPageIndex = pageInd;

              if (this.formPageIndex != null) break;
            }

            if (this.formPageIndex != null) {
              // зная текущую страницу, навешиваю на ее поля валидаторы
              if (results[0].body[this.formPageIndex]['body']?.length > 0) this.validateCurrentPage(this.getPage(this.formPageIndex).get('body') as FormArray, 0);

              this.getPageOptions(this.formPageIndex);
            }
          }

          this.isLoadingResults = false;
          this.cdr.detectChanges();
        },
        resp => {
          this.isLoadingResults = false;
          this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502;
          this.cdr.detectChanges();
        }
      );
    }
  }

  optionsScrollEnd(ids: number[] = [], model_name: string = '') {
    if (this.ngOptions[model_name].total === null || (this.ngOptions[model_name].loaded < this.ngOptions[model_name].total) || ids.length > 0) {
      if (this.ngOptions[model_name].service) {
        this.ngOptions[model_name].loading = true;

        this.ngOptions[model_name].service.list({
          ...(ids.length > 0 ? {
            filter: {field_list: [
                {
                  field: (model_name == 'DDSFormFieldUser' || model_name == 'DDSFormFieldExtUser') ? 'domain_user.user_id.id' : 'id',
                  value: ids,
                  condition_type: 9
                }
              ], type: 0}
          } : {}),
          sort: {name: '+', surname: '+'},
          limit: 200,
          offset: this.ngOptions[model_name].loaded
        }, this.ngOptions[model_name].action).subscribe(
          (data) => {
            if (this.ngOptions[model_name].total === null && ids.length == 0) this.ngOptions[model_name].total = data.total_count;

            if (model_name == 'DDSFormFieldUser' || model_name == 'DDSFormFieldExtUser') {
              for (let user of data.list) {
                let username = user.name + (user.surname ? ' ' + user.surname : '');

                if (ids.length == 0 && this.ngOptions[model_name].list.findIndex(v => v.user_id == user.id) == -1) this.ngOptions[model_name].loaded += 1;

                for (let uid of user.numbers) {
                  if (this.ngOptions[model_name].list.findIndex(v => v.uid == uid) == -1) {
                    let domain_user = user.number_list[uid];
                    let tooltip = '', color = '', active = 0;
                    if (
                      domain_user.reg_detail &&
                      (!('logout_dt' in domain_user.reg_detail) || domain_user.reg_detail['logout_dt']) &&
                      domain_user.reg_detail.has_transfer && this.CTI.mynumber != uid
                    ) {
                      color = 'text-blue';
                      tooltip = 'CTI.USER_TRANSFER';
                      active = 1;
                    } else if (!domain_user.reg_detail || !('logout_dt' in domain_user.reg_detail) || domain_user.reg_detail['logout_dt']) {
                      if (domain_user.status == 0) {
                        // color = 'bg-phone-no-active mat-elevation-z1';
                        color = 'text-mute';
                        tooltip = 'CTI.USER_OFFLINE';
                        active = 0;
                      } else {
                        // color = 'bg-phone-no-active mat-elevation-z1';
                        color = 'text-mute';
                        tooltip = 'USER.NO_REG';
                        active = 0;
                      }
                    } else if (domain_user.calls?.length > 0) {
                      color = 'text-yellow';
                      tooltip = 'USER.TALKING';
                      active = 0;
                    } else if (this.CTI.getAgentStatusById(domain_user.agent_status).base_status_id != -1) {
                      if (this.CTI.getAgentStatusById(domain_user.agent_status).base_status_id == -3) {
                        color = 'text-muted';
                        tooltip = 'AGENT.ON_BREAK';
                      } else {
                        color = 'text-danger';
                        tooltip = 'AGENT.LOGGED_OUT';
                      }
                      active = 0;
                    } else {
                      color = 'text-green';
                      // color = 'bg-phone-active';
                      tooltip = this.CTI.mynumber != uid ? 'USER.REG' : 'USER.SELF_CALL_DENIED';
                      active = this.CTI.mynumber != uid ? 1 : 0;
                    }

                    this.ngOptions[model_name].list.push({
                      id: domain_user.id,
                      uid: uid,
                      name: username,
                      agent_status: domain_user.agent_status,
                      class: color,
                      tooltip: tooltip,
                      active: active,
                      user_id: user.id,
                      user: user
                    });
                  }
                }
              }
            } else {
              for (let queue of data.list) {
                if (this.ngOptions[model_name].list.findIndex(v => v.id == queue['id']) == -1) {
                  let tooltip = '', color = '', active = 0;
                  if (queue.status == 0) {
                    color = 'text-muted';
                    tooltip = 'DISABLED2';
                    active = 0;
                  } else if (!queue.dp_number) {
                    color = 'text-muted';
                    tooltip = 'QUEUE.DP_NUMBER_NOT_SET';
                    active = 0;
                  } else if (queue.count_available_agents > 0) {
                    color = 'text-green';
                    tooltip = 'ENABLED2';
                    active = 1;
                  } else if (queue.count_busy_agents > 0) {
                    color = 'text-yellow';
                    tooltip = 'NOT_AVAILABLE_AGENT';
                    active = 0;
                  } else {
                    color = 'text-muted';
                    tooltip = queue.count_total_agents > 0 ? 'NOT_REGISTERED_AGENT' : 'NOT_AGENTS';
                    active = 0;
                  }

                  this.ngOptions[model_name].list.push({
                    id: queue.id,
                    name: queue.name,
                    count_available_agents: queue.count_available_agents,
                    count_busy_agents: queue.count_busy_agents,
                    count_total_agents: queue.count_total_agents,
                    dp_number: queue.dp_number,
                    class: color, tooltip: tooltip, active: active
                  });

                  if (ids.length == 0) this.ngOptions[model_name].loaded += 1;
                }
              }
            }

            this.ngOptions[model_name].list.sort((a,b) => (a.name < b.name) ? -1 : (a.name > b.name ? 1 : 0));
            this.ngOptions[model_name].list = [...this.ngOptions[model_name].list];
            this.ngOptions[model_name].loading = false;
            this.cdr.detectChanges();
          },
          resp => {
            this.ngOptions[model_name].loading = false;
            this.notifyService.setFormErrors(resp);
            this.cdr.detectChanges();
          }
        );
      }
    }
  }

  getQueueTiers(queue_ids, model_name, preset_value) {
    this.selected_queue = null;
    this.tiers = this.tiers.filter(tier=> !queue_ids.includes(tier.queue_id));

    if (queue_ids?.length>0) {
      this.isTierLoadingResults = true;
      this.ngOptions[model_name].tier_service.list(
        {
          filter: {field_list: [
              {
                field: 'queue_id',
                value: queue_ids,
                condition_type: 9
              }
            ], type: 0}, limit: 500
        }, 'select_with_detail')
        .subscribe(
        tiersData => {
          for (let tier of tiersData.list) {
            // if (!this.tiers.find(v => v.id == tier.id)) {
            let tooltip = '', color = '', active = 0;
            if (
              tier['reg_detail']?.length > 0 &&
              (!('logout_dt' in tier['reg_detail'][0]) || tier['reg_detail'][0]['logout_dt']) &&
              tier['reg_detail'][0].has_transfer && this.CTI.mynumber != tier['uid']
            ) {
              color = 'text-blue';
              tooltip = 'CTI.USER_TRANSFER';
              active = 1;
            } else if (!tier['reg_detail']?.length || !('logout_dt' in tier['reg_detail'][0]) || tier['reg_detail'][0]['logout_dt']) {
              if (tier['full_name'].status == 0) {
                // color = 'bg-phone-no-active mat-elevation-z1';
                color = 'text-mute';
                tooltip = 'CTI.USER_OFFLINE';
                active = 0;
              } else {
                // color = 'bg-phone-no-active mat-elevation-z1';
                color = 'text-mute';
                tooltip = 'USER.NO_REG';
                active = 0;
              }
            } else if (tier['calls']?.length > 0) {
              color = 'text-yellow';
              tooltip = 'USER.TALKING';
              active = 0;
            } else if (this.CTI.getAgentStatusById(tier['agent_status']).base_status_id != -1) {
              if (this.CTI.getAgentStatusById(tier['agent_status']).base_status_id == -3) {
                color = 'text-muted';
                tooltip = 'AGENT.ON_BREAK';
              } else {
                color = 'text-danger';
                tooltip = 'AGENT.LOGGED_OUT';
              }
              active = 0;
            } else {
              color = 'text-green';
              // color = 'bg-phone-active';
              tooltip = this.CTI.mynumber != tier['uid'] ? 'USER.REG' : 'USER.SELF_CALL_DENIED';
              active = this.CTI.mynumber != tier['uid'] ? 1 : 0;
            }

            let org = (tier['user_org']?.length > 0 ? '(' + tier['user_org'].map(v => v.unit + ' - ' + v.position).join(', ') + ')' : '');

            const tier_old_index = this.tiers.findIndex(ind => ind.id == tier.id);

            const tier_new = {
              id: tier.id,
              agent_id: tier.agent_id,
              queue_id: tier.queue_id,
              uid: tier['uid'],
              name: tier['full_name'].name + (tier['full_name']['surname'] ? ' ' + tier['full_name']['surname'] : ''),
              org: org,
              agent_status: tier['agent_status'],
              class: color,
              tooltip: tooltip,
              active: active,
              user_id: tier['full_name'].id,
              user: tier['full_name']
            }

            if (tier_old_index>-1) {
              this.tiers[tier_old_index] = tier_new;
            } else {
              this.tiers.push(tier_new);
            }
          }

          this.isTierLoadingResults = false;
          this.cdr.detectChanges();
        },
        resp => {
          this.isTierLoadingResults = false;
          this.isServerAvailable = this.notifyService.setFormErrors(resp, this.form) != 502;
          this.cdr.detectChanges();
        }
      );
    }
  }

  getPageOptions(formPageIndex) { // запрашиваю для страницы данные по сотрудникам/очередям.
    let user_ids = [], queue_ids = [], ext_user_ids = [], ext_queue_ids = [];
    let page = this.getPage(formPageIndex);

    (page.get('body') as FormArray).controls.forEach((field) => {
      if (!field.value.hidden && (!field.value.condition || this.checkCondition(field, field.value))) {

        if (field.value.model_name == 'DDSFormFieldUser' && field.value['data_source']?.path) {
          user_ids.push(field.value['data_source'].path);
        }

        if (field.value.model_name == 'DDSFormFieldExtUser' && field.value['data_source']?.path) {
          ext_user_ids.push(field.value['data_source'].path);
        }

        if (field.value.model_name == 'DDSFormFieldQueue' && field.value['data_source']?.path) {
          queue_ids.push(field.value['data_source'].path);
        }

        if (field.value.model_name == 'DDSFormFieldExtQueue' && field.value['data_source']?.path) {
          ext_queue_ids.push(field.value['data_source'].path);
        }

      }

    });

    if (user_ids.length > 0) this.optionsScrollEnd(user_ids, 'DDSFormFieldUser');
    if (ext_user_ids.length > 0) this.optionsScrollEnd(ext_user_ids, 'DDSFormFieldExtUser');
    if (queue_ids.length > 0) {
      this.optionsScrollEnd(queue_ids, 'DDSFormFieldQueue');
      this.getQueueTiers(queue_ids, 'DDSFormFieldQueue', 0);
    }
    if (ext_queue_ids.length > 0) {
      this.optionsScrollEnd(ext_queue_ids, 'DDSFormFieldExtQueue');
      this.getQueueTiers(ext_queue_ids, 'DDSFormFieldExtQueue', 0);
    }
  }

  getFieldPanelTitle(fieldValue) {
    if (fieldValue.model_name in this.ngOptions && fieldValue.data_source?.path) {
      let option = this.ngOptions[fieldValue.model_name].list.find(v => v.id == +fieldValue.data_source?.path);
      let option_str = '';
      if (['DDSFormFieldQueue', 'DDSFormFieldExtQueue'].indexOf(fieldValue.model_name) != -1) {
        option_str = (option.dp_number ? '<span class="text-success">' + (option['dp_number'] ? option['dp_number'] + ' ' : '') +'</span>' : '') + option.name;
      } else {
        option_str = '<span class="text-success">' + option.uid + ' </span>' + option.name + (option.org ? '<span class="text-muted m-l-5">' + option.org + '</span>' : '');
      }
      return option_str;
    }
    return '';
  }

  getOperatorById(fieldValue) {
    if (fieldValue) {
      let tier = this.tiers.find(v => v.agent_id == +fieldValue.value);
      return tier ? ('<span class="text-success">' + tier.uid + ' </span>' + tier.name + (tier.org ? '<span class="text-muted m-l-5">' + tier.org + '</span>' : '')) : '';
    }
    return '';
  }

  transferCallByField(fieldValue) {
    let number;
    if (typeof fieldValue == 'object') {
      let option = this.ngOptions[fieldValue.model_name].list.find(v => v.id == fieldValue.preset_value);
      number = option?.uid || option?.dp_number;
    } else number = fieldValue;

    if (number?.length > 0) this.CTI.ctiActions$.next({
      obj: 'call',
      action: 'transferCall',
      number: number,
      isBlind: false // перевод всегда с сопровождением, особенно актуально для очереди, чтобы узнать кто принял вызов и выбрать его для сохранения в результат скрипта
    });
  }

  addFormFieldFilter(condition: FormGroup, conditionGrpName: string = 'filter', data: any = {}) {
    condition.setControl(conditionGrpName, this.fb.formGroup(new DDSFieldFilter()), {emitEvent: false});
    let conditionGrp = condition.get(conditionGrpName) as FormGroup;
    conditionGrp.patchValue(data, {emitEvent: false, onlySelf: true});
    conditionGrp.setControl('field_list', new FormArray([]), {emitEvent: false});
    if (data.field_list?.length > 0) {
      for (let c of data.field_list) {
        this.addFormFieldFilterField(conditionGrp.get('field_list') as FormArray, c);
      }
    } else this.addFormFieldFilterField(conditionGrp.get('field_list') as FormArray);
    if (data.filter) {
      this.addFormFieldFilter(conditionGrp, 'filter', data.filter);
    }
  }

  addFormFieldFilterField(field_list, data = {}, ind: number = null) {
    let filter_field = this.fb.formGroup(new DDSFieldFilterValue());
    filter_field.setControl('field', this.fb.formGroup(new DomainScriptFieldData()), {emitEvent: false});
    if (data) filter_field.patchValue(data, {emitEvent: false, onlySelf: true});
    if (!ind) ind = field_list.value.length;
    field_list.insert(ind, filter_field);
  }

  validateCurrentPage(pageBody, fieldInd) {
    // при изменении поля страницы, будут заново провалидированы поля
    if (fieldInd < pageBody.value.length) {
      for (let ind = fieldInd; ind < pageBody.value.length; ind++) {
        // перевалидирую поля, т.к. при заполнении полей другие поля могут стать валидными и тогда надо навесить на них обязательность заполнения.
        this.setFieldValidators(this.formPageIndex, ind);
      }
      // console.log('%cПоля провалидированы.', 'color: darkorange; font-weight: 700px;');
      pageBody.markAsUntouched();
    }
  }

  getPage(ind: number) {
    return (this.form.get('body') as FormArray).at(ind);
  }

  addPage(data: any = {}, pageInd: number|null = null, goToLast: boolean = false) {
    let pageModel = new DomainDialogScriptFormPage();
    // @ts-ignore
    pageModel.body = new FormArray([]);
    let page = this.fb.formGroup(pageModel);
    page.patchValue(data);

    if (data?.condition?.field_list?.length > 0) {
      this.addFormFieldFilter(page, 'condition', data.condition);
    }

    if (pageInd == null) pageInd = this.form.value.body.length;
    (this.form.get('body') as FormArray).insert(pageInd, page);

    if (data['body']?.length > 0) {
      for (let fieldInd = 0; fieldInd < data['body'].length; fieldInd++) {
        this.addField(pageInd, data['body'][fieldInd], fieldInd);
      }
    }

    this.form.get('body').markAsDirty();
  }

  setFieldValidators(pageInd, fieldInd) {
    let field = (this.getPage(pageInd).get('body') as FormArray).at(fieldInd);

    if (field.value.model_name != 'DDSFormFieldText' && field.value.model_name != 'DDSFormFieldLine') {
      let fieldData = this.formData.body[pageInd].body[fieldInd] as FormQuestionFieldBase;
      let validators = [];

      if (fieldData?.required && !fieldData?.hidden && (!fieldData.condition || this.checkCondition(field, field.value))) {
        validators.push(RxwebValidators.required({message: 'ERROR.REQUIRED'}));
        //console.log('%cfield: ' + field.value.question + ' is required', 'color: darkorange; font-weight: 700px;');
      }

      switch (field.value.model_name) {
        case 'DDSFormFieldInt': validators.push(RxwebValidators.digit()); break;
        case 'DDSFormFieldDecimal': validators.push(RxwebValidators.numeric({allowDecimal: true, message: 'VAR_TYPE_DECIMAL'})); break;
        case 'DDSFormFieldEmail': validators.push(RxwebValidators.pattern({expression: {onlyAlpha: RxFormHelpers.email}, message: 'ERROR.EMAIL'})); break;
        case 'DDSFormFieldPhone': validators.push(RxwebValidators.pattern({expression: {onlyAlpha: RxFormHelpers.phone}, message: 'ERROR.PHONE'})); break;
      }

      if (validators.length > 0) field.get('value').setValidators(validators);
      else field.get('value').clearValidators();
      field.get('value').updateValueAndValidity({emitEvent: false, onlySelf: false});
    }
  }

  getFieldModel(model_name, data) {
    let rxModel;
    switch (model_name) {
      case 'DDSFormFieldLine': rxModel = this.fb.formGroup(new DDSFormFieldLine()); break;
      case 'DDSFormFieldText': rxModel = this.fb.formGroup(new DDSFormFieldText()); break;

      case 'DDSFormFieldShortString':
        //if (data.default_value) console.log('DEFAULT_VALUE', data.default_value)
        rxModel = this.fb.formGroup(new DDSFormFieldShortString());
        rxModel.setControl('value', new FormControl(data.default_value || ''));
        break;
      case 'DDSFormFieldLongString':
        rxModel = this.fb.formGroup(new DDSFormFieldLongString());
        rxModel.setControl('value', new FormControl(''));
        break;
      case 'DDSFormFieldRadio':
        rxModel = this.fb.formGroup(new DDSFormFieldRadio());
        rxModel.setControl('value', new FormControl(null));
        break;
      case 'DDSFormFieldSingleButton':
        rxModel = this.fb.formGroup(new DDSFormFieldSingleButton());
        rxModel.setControl('value', new FormControl(null));
        break;
      case 'DDSFormFieldCheckBox':
        rxModel = this.fb.formGroup(new DDSFormFieldCheckBox());
        rxModel.setControl('value', new FormControl([]));
        break;
      case 'DDSFormFieldSelect':
        rxModel = this.fb.formGroup(new DDSFormFieldSelect());
        rxModel.setControl('value', new FormControl(null));
        break;
      case 'DDSFormFieldBoolean':
        rxModel = this.fb.formGroup(new DDSFormFieldBoolean());
        rxModel.setControl('value', new FormControl(false));
        break;
      case 'DDSFormFieldInt':
        rxModel = this.fb.formGroup(new DDSFormFieldInt());
        rxModel.setControl('value', new FormControl(null, RxwebValidators.digit()));
        break;
      case 'DDSFormFieldDecimal':
        rxModel = this.fb.formGroup(new DDSFormFieldDecimal());
        rxModel.setControl('value', new FormControl(null, RxwebValidators.numeric({allowDecimal: true, message: 'VAR_TYPE_DECIMAL'})));
        break;
      case 'DDSFormFieldEmail':
        rxModel = this.fb.formGroup(new DDSFormFieldEmail());
        rxModel.setControl('value', new FormControl(null, RxwebValidators.pattern({expression: {onlyAlpha: RxFormHelpers.email}, message: 'ERROR.EMAIL'})));
        break;
      case 'DDSFormFieldPhone':
        rxModel = this.fb.formGroup(new DDSFormFieldPhone());
        rxModel.setControl('value', new FormControl(null, RxwebValidators.pattern({expression: {onlyAlpha: RxFormHelpers.phone}, message: 'ERROR.PHONE'})));
        break;
      case 'DDSFormFieldDate':
        rxModel = this.fb.formGroup(new DDSFormFieldDate());
        rxModel.setControl('value', new FormControl(null));
        break;
      case 'DDSFormFieldDateTime':
        rxModel = this.fb.formGroup(new DDSFormFieldDateTime());
        rxModel.setControl('value', new FormControl(null));
        break;
      case 'DDSFormFieldUser':
        rxModel = this.fb.formGroup(new DDSFormFieldUser());
        // Сотрудник будет сохранен в результат диалоговой формы, если оператор кабинета подтвердит это по кнопке
        rxModel.setControl('value', new FormControl(null));
        rxModel.setControl('preset_value', new FormControl(+data.data_source?.path || null)); // предустановленное значение - это еще не значение, которое нужно записать в результат
        break;
      case 'DDSFormFieldExtUser':
        rxModel = this.fb.formGroup(new DDSFormFieldExtUser());
        // Сотрудник будет сохранен в результат диалоговой формы, если оператор кабинета подтвердит это по кнопке
        rxModel.setControl('value', new FormControl(null));
        rxModel.setControl('preset_value', new FormControl(+data.data_source?.path || null)); // предустановленное значение - это еще не значение, которое нужно записать в результат
        break;
      case 'DDSFormFieldQueue':
        rxModel = this.fb.formGroup(new DDSFormFieldQueue());
        rxModel.setControl('value', new FormControl(null)); // сотрудник из очереди, принявший вызов, он будет сохранен в результат диалоговой формы
        rxModel.setControl('preset_value', new FormControl(+data.data_source?.path || null)); // предустановленное значение - это еще не значение, которое нужно записать в результат
        break;
      case 'DDSFormFieldExtQueue':
        rxModel = this.fb.formGroup(new DDSFormFieldExtQueue());
        rxModel.setControl('value', new FormControl(null)); // сотрудник из очереди, принявший вызов, он будет сохранен в результат диалоговой формы
        rxModel.setControl('preset_value', new FormControl(+data.data_source?.path || null)); // предустановленное значение - это еще не значение, которое нужно записать в результат
        break;
      default: rxModel = this.fb.formGroup(new DDSFormFieldBase());
    }

    if (model_name != 'DDSFormFieldText' && model_name != 'DDSFormFieldLine') {
      if (data.data_source && Object.keys(data.data_source).length > 0) {
        rxModel.setControl('data_source', this.fb.formGroup(new DomainScriptFieldData()));
        rxModel.patchValue(data.data_source);

        if (data.data_source.source == 0) rxModel.patchValue({value: this.JSONPath(`$.body.*.body[?(@.id=='${data.data_source.path}')].value`, this.formData)[0]});
        else if (data.data_source.source == 1 && this.sourcesData?.call) {
          rxModel.patchValue({value: this.JSONPath(data.data_source.path, this.sourcesData?.call)[0]});
        } else if (data.data_source.source == 2 && this.sourcesData?.omni_dialog) {
          rxModel.patchValue({value: this.JSONPath(data.data_source.path, this.sourcesData?.omni_dialog)[0]});
        }
      }

      if (data?.condition?.field_list?.length > 0) {
        this.addFormFieldFilter(rxModel, 'condition', data.condition);
      }

      if (model_name == 'DDSFormFieldRadio' || model_name == 'DDSFormFieldSingleButton' || model_name == 'DDSFormFieldCheckBox' || model_name == 'DDSFormFieldSelect') {
        rxModel.setControl('choices', new FormArray([]));
        if (data.choices?.length > 0) {
          for (let choiceData of data.choices) {
            this.addChoice(rxModel, choiceData);
          }
        } else this.addChoice(rxModel);
      }
    }

    return rxModel;
  }

  addField(pageInd: number, data: any = {}, fieldInd: number|null = null, goToLast: boolean = false){
    let rxM = this.getFieldModel(data['model_name'], data);
    rxM.patchValue(data);

    if (
      (data?.question || ['DDSFormFieldUser', 'DDSFormFieldExtUser', 'DDSFormFieldQueue', 'DDSFormFieldExtQueue'].indexOf(data['model_name']) != -1) &&
      this.formResultData?.body?.[data.id] != null
    ) rxM.patchValue({value: this.formResultData.body[data.id]});
    if (rxM.value.model_name == 'DDSFormFieldLine' && !data['color']) rxM.patchValue({color: this.AppConfig.getValue('dpBorderColor')});
    let pageBody = this.getPage(pageInd).get('body') as FormArray;
    if (fieldInd == null) fieldInd = pageBody.value.length;
    pageBody.insert(fieldInd, rxM);
    pageBody.markAsDirty();
  }

  onFieldChangeValue(fieldInd) {
    let field = (this.getPage(this.formPageIndex).get('body') as FormArray).at(fieldInd);
    if (this.isLastCheckedFieldIndex(fieldInd) && field.valid && this.hasNextPage()) this.getNextPage();
    else this.validateCurrentPage(this.getPage(this.formPageIndex).get('body') as FormArray, 0);
  }

  addChoice(field, data: any = {}) {
    let choice = this.fb.formGroup(new DSSFormFieldChoice());
    if (data) choice.patchValue(data);
    (field.get('choices') as FormArray).push(choice);

    if (data?.condition?.field_list?.length > 0) {
      this.addFormFieldFilter(choice, 'condition', data.condition);
    }
  }

  getChoiceValue(id, choices) {
    return choices.find(choice => choice.id == id).value;
  }

  hasFormResult() {
    return (this.form.get('body') as FormArray).controls.some(page => {
      return page.value.show_result &&
        (this.mode == 'dialog' || !page.value.condition || this.checkCondition(page, page.value)) &&
        page.value.body.some(v => v.value != null && v.value != undefined && v.value != '');
    });
  }

  changeCheckboxState(field, event) {
    if (event.checked) field.patchValue({value: [...field.value.value, event.source.value]});
    else field.patchValue({value: [...field.value.value.filter(choice_id => choice_id != event.source.value)]});
  }

  hasPrevPage() {
    // Если есть страницы ПЕРЕД этой и хотя бы одна из них по условиям отображения прошла проверку.
    // При этом первая страница здесь не проверяется, если это Предпросмотр (this.mode = 'dialog'),
    // т.к. для нее нужны данные диалога или вызова, а они на форму Предпросмотра не передаются.
    return this.formPageIndex > 0 ?
      this.form.get('body')['controls'].slice(0, this.formPageIndex).some((page, ind) => (ind == 0 && this.mode == 'dialog') || (page.value.condition ? this.checkCondition(page, page.value) : true)) :
      false;
  }

  hasNextPage() {
    // Если ПОСЛЕ текущей есть другие страницы и хотя бы одна из них по условиям отображения прошла проверку.
    return (this.formPageIndex != this.form.value.body.length - 1) ?
      this.form.get('body')['controls'].slice(this.formPageIndex + 1 /* со следующей, после текущей */).some(page => page.value.condition ? this.checkCondition(page, page.value) : true) :
      false;
  }

  getPrevPage() {
    // Проверяю страницы с первой и до текущей в обратном порядке, что они прошли проверку по условиям отображения.
    // При этом первую страницу отдаю без проверок, если это Предпросмотр (this.mode = 'dialog'), т.к. для нее нужны данные диалога или вызова, а они на форму Предпросмотра не передаются.
    this.formPageIndex = this.formPageIndex - 1 -
      this.form.get('body')['controls'].slice(0, this.formPageIndex)
        .reverse()
        .findIndex((page, ind) => (ind == this.formPageIndex - 1 && this.mode == 'dialog') || (page.value.condition ? this.checkCondition(page, page.value) : true));

    this.getPageOptions(this.formPageIndex);

    // подписываюсь на изменения полей на страницы, чтобы навешивать на них валидатор Required
    this.validateCurrentPage(this.getPage(this.formPageIndex).get('body') as FormArray, 0);
    this.cdr.detectChanges();
  }

  getNextPage() {
    // сохраняю данные страницы, если она валидна, чтобы при переходе куда-либо и возврате к скрипту, открылась следующая страница
    let page = this.getPage(this.formPageIndex);
    this.validateCurrentPage(page.get('body') as FormArray, 0);
    page.markAllAsTouched();
    page.updateValueAndValidity();
    if (page.valid) {
      if (this.mode == 'card') this.onSubmit(this.sbmtBtn, this.formPageIndex + 1);

      // перехожу на следующую страницу, после удачного сохранения текущей
      this.formPageIndex = this.formPageIndex
        + 1
        + this.form.get('body')['controls'].slice(this.formPageIndex + 1).findIndex(page => page.value.condition ? this.checkCondition(page, page.value) : true);

      this.getPageOptions(this.formPageIndex);

      let page = this.getPage(this.formPageIndex);
      // делаю валидацию полей следующей страницы, т.к. поля следующей страницы могут иметь фильтры с полями предыдущей страницы
      if (page.value.body.length > 0) this.validateCurrentPage(page.get('body') as FormArray, 0);
    } else this.notifyService.message('NOTIFY.REQUIRED');
    this.cdr.detectChanges();
  }

  getLastCheckedPageIndex() {
    return this.form.get('body')['controls'].findLastIndex((page, ind) => (ind == 0 && this.mode == 'dialog') || (page.value.condition ? this.checkCondition(page, page.value) : true));
  }

  isLastCheckedFieldIndex(fieldInd) {
    return this.form.get('body')['controls'][this.formPageIndex].get('body')['controls'].findLastIndex((field, ind) => (ind == 0) || (field.value.condition ? this.checkCondition(field, field.value) : true)) == fieldInd;
  }

  checkCondition(control, obj, condition: string = 'condition'): boolean {
    let exprs = [];

    for (let filterField of obj[condition].field_list) {
      // если данные берутся из формы, то ищу нужное поле формы по идентификатору и беру поле value - то, что введено в поле,
      // иначе беру просто путь до переменной (в данных из диалога или фрисвичовой сессии) и не имея этих данных здесь - в форме просмотра - подставляю пустой объект
      let formField = filterField.field.source == 0 ? this.JSONPath(`$.body.*.body[?(@.id=='${filterField.field.path}')]`, this.form.value)[0] : this.JSONPath(filterField.field.path, {})[0];
      let comparingValue = '';

      if (filterField.field.source == 0) {
        comparingValue = this.JSONPath(`$.body.*.body[?(@.id=='${filterField.field.path}')]`, this.form.value)[0]?.value || '';
        if (['DDSFormFieldDate', 'DDSFormFieldDateTime'].indexOf(formField.model_name) != -1) {
          // @ts-ignore
          comparingValue = comparingValue.toISOString();
        }
      } else if (filterField.field.source == 1 && this.sourcesData?.call) {
        comparingValue = this.JSONPath(filterField.field.path, this.sourcesData?.call)[0] || '';
      } else if (filterField.field.source == 2 && this.sourcesData?.omni_dialog) {
        comparingValue = this.JSONPath(filterField.field.path, this.sourcesData?.omni_dialog)[0] || '';
      }

      switch (filterField.condition_type) {
        case 0:
          //console.log('==', comparingValue, filterField.value);
          exprs.push(comparingValue == filterField.value || (!comparingValue && !filterField.value));
          break;
        case 1:
          exprs.push(comparingValue.startsWith(filterField.value));
          break;
        case 2:
          exprs.push(comparingValue.endsWith(filterField.value));
          break;
        case 3:
          exprs.push(comparingValue.includes(filterField.value));
          break;
        case 4:
          exprs.push(comparingValue > filterField.value);
          break;
        case 5:
          exprs.push(comparingValue >= filterField.value);
          break;
        case 6:
          exprs.push(comparingValue < filterField.value);
          break;
        case 7:
          exprs.push(comparingValue <= filterField.value);
          break;
        case 8:
          // console.log('!=', comparingValue, filterField.value);
          exprs.push(comparingValue != filterField.value);
          break;
        case 9:
          exprs.push(
            filterField.field.source == 0 && formField.model_name == 'DDSFormFieldCheckBox' ?
              comparingValue.length > 0 && filterField.value.every(v => comparingValue.indexOf(v) != -1) :
              filterField.value.indexOf(comparingValue) != -1
          );
          break;
        case 10:
          exprs.push(
            filterField.field.source == 0 && formField.model_name == 'DDSFormFieldCheckBox' ?
              comparingValue.length > 0 && filterField.value.every(v => comparingValue.indexOf(v) == -1) :
              filterField.value.indexOf(comparingValue) == -1
          );
          break;
      }
    }

    let res: boolean;
    if (obj[condition].type == 0) res = exprs.every(expr => !!expr) && (!obj[condition].filter || this.checkCondition(control, obj[condition], 'filter'));
    else res = exprs.some(expr => !!expr) || (obj[condition].filter && this.checkCondition(control, obj[condition], 'filter'));

    if (!res) {
      // очищаю введенные данные объекта (страницы/поля), если он не прошел проверку на отображение

      // начинаю с самого маленького объекта - варианта (чойса)
      if (['DDSFormFieldRadio', 'DDSFormFieldSingleButton', 'DDSFormFieldCheckBox', 'DDSFormFieldSelect'].indexOf(control.value.model_name) != -1 && Object.keys(obj).length <= 3) {
        if (control.value.model_name == 'DDSFormFieldCheckBox') control.patchValue({value: control.value.value.filter(v => v != obj.id)});
        else if (control.value.value == obj.id) control.patchValue({value: null});
      } else if ('value' in control.value) { // далее очищаю поле
        control.get('value').setValue(control.value.model_name == 'DDSFormFieldCheckBox' ? [] : (control.value.model_name == 'DDSFormFieldBoolean' ? false : null));
      } else if ('body' in control.value) { // и уже в самом конце очищаю страницу
        control.get('body')['controls'].forEach(f => {
          f.get('value')?.setValue(f.value.model_name == 'DDSFormFieldCheckBox' ? [] : (f.value.model_name == 'DDSFormFieldBoolean' ? false : null));
        });
      }
    }

    return res;
  }

  checkFormResult() {
    return typeof this.formResultData != 'number';
  }

  getOrCreateScriptResult() {
    return this.domainDialogScriptResultService.list({
      filter: {field_list: [{field: 'call_id', value: this.dialogScript.call_uuid, condition_type: 0}], type: 0},
      limit: 1
    }).pipe(
      catchError(resp => resp.notifies?.[0]['msg_id'] == 10002 ? observableOf(null) : throwError(resp)),
      concatMap(script_dialog => {
        return !script_dialog?.list?.length ?
          this.domainDialogScriptResultService.save({
            call_id: this.dialogScript.call_uuid,
            script_id: this.dialogScript.id,
            script_ver: this.dialogScript.ver,
            owner_id: this.api.getUser().user_id,
            // @ts-ignore
            body: []
          }) :
          observableOf(script_dialog.list[0]);
      })
    )
  }

  onSubmit(btn: MatButton, until_page: number = null, end_reason_id: string = null) {

    let end_reason = end_reason_id != null ? this.formData.end_reason.find(v => v.id == end_reason_id) : null;

    // валидирую все страницы, если не передана страница, до которой нужно валидировать и скрипт не завершается аварийно
    if (until_page == null && end_reason?.reason != 1) {
      // валидирую страницы, если скрипт не прерван,
      // чтобы в скрытых страницах не было обязательных полей,
      // т.к. если страница была валидна и в ней были обязательные поля, а потом страница стала невалидной, поля не перечитаются
      for (let pageInd = 0; pageInd < this.form.value.body.length; pageInd++) {
        let page = this.getPage(pageInd);
        if (page?.value.condition && !this.checkCondition(page, page.value)) {
          // все поля скрытой страницы делаю необязательными
          (page.get('body') as FormArray).controls.forEach((field) => {
            field.get('value').clearValidators();
            field.get('value').updateValueAndValidity({emitEvent: false, onlySelf: false});
          });
        }
      }

      this.form.updateValueAndValidity({emitEvent: false, onlySelf: false});
      this.form.markAllAsTouched();
    }

    //console.log('FORM', this.form.get('body').value);

    if (
      end_reason?.reason == 1 || // нужно прервать скрипт, значит форму сохраняю как есть, без валидации
      (
        until_page != null &&
        !this.form.get('body')['controls'].slice(0, until_page).filter(p => !p.valid && (!p.value.condition || this.checkCondition(p, p.value))).length
      ) ||
      (until_page == null && this.form.valid)
    ) {
      if (btn) btn.disabled = true;

      (!this.dialogScript?.script_result?.id ?
          this.getOrCreateScriptResult().pipe(
            map(script_result => {
              if (script_result) this.dialogScript = {...this.dialogScript, script_result: script_result};
              return script_result;
            })
          ) :
          observableOf(this.dialogScript.script_result)
      ).subscribe(
        script_result => {
          if (this.dialogScript?.script_result?.id) {
            let body = {};
            for (let pageInd = 0; pageInd < (until_page != null ? until_page : this.form.value.body.length); pageInd++) {
              let page = this.getPage(pageInd);
              if (page?.value.condition && !this.checkCondition(page, page.value)) {
                // пропускаю все скрытые страницы, их поля не должны попасть в запрос
                continue;
              }
              for (let f of (page.get('body') as FormArray)['controls']) { // собираю данные формы по текущую страницу, чтобы потом открыть последнюю заполненную страницу
                let condition = true; // поля, для которых не выполнились условия будут пропущены
                if (f.value.condition) {
                  condition = this.checkCondition(f, f.value);
                  // если есть условие и в него не попал, то не отправлять (игнорирую значение и отправлять пустой)
                }


                if (
                  (f.value.model_name != 'DDSFormFieldText' || f.value.model_name != 'DDSFormFieldLine') &&
                  condition &&
                  (f.value.value || f.value.blank_store) &&
                  (f.value.model_name != 'DDSFormFieldCheckBox' || f.value.value.length > 0)
                ) {
                  if (f.value.id == 'Category') {
                    console.log('Category (val|default):  ', f.value.value,  f.value.default_value)
                  }
                  body[f.value.id] = f.value.value;

                } else if (f.value.model_name=='DDSFormFieldShortString' && f.value.hidden && (f.value.value == null || f.value.value == '') && f.value.default_value.length > 0) {
                  if (f.value.id == 'Category') {
                    console.log('Category (val|default):  ', f.value.value,  f.value.default_value)
                  }
                  body[f.value.id] = f.value.default_value;

                }
              }
              this.getPage(pageInd).markAsPristine();
            }

            //console.log('BODY', body)
            // сохраняю результаты формы
            this.domainDialogScriptFormResultService.save({
              id: this.formResultData?.id,
              form_id: this.id,
              form_ver: this.form.value.ver,
              script_result_id: this.dialogScript.script_result.id,
              body: body,
              end_reason: end_reason_id
            }).subscribe(
              form_result => {
                if (!this.formResultData?.id) { // сохраняю в результат шага Скрипта - идентификатор результата формы, если он еще не сохранен.
                  // Иначе говорить диалоговому скрипту о сохранении шага не нужно, чтобы не было повторных запросов на редактирование,
                  // т.к. в результат скрипта запоминается только ID результата формы
                  this.formResultData = {
                    form_id: this.id,
                    form_ver: this.form.value.ver,
                    script_result_id: this.dialogScript.script_result.id,
                    body: body,
                    id: form_result.body.id, // чтобы каждый раз не создавать, а редактировать, запоминаю ID результата формы
                    end_reason: end_reason_id
                  };

                  this.onSave.emit({
                    script_result: this.dialogScript.script_result,
                    step_result: form_result.body.id,
                    step_form_result: {...this.formResultData},
                    // перехожу на следующий шаг скрипта, если это последняя страница формы и это не прерывание скрипта
                    need_get_next_step: (until_page == null) && (end_reason?.reason != 1)
                  });
                }

                if (end_reason?.reason == 1) this.endDialogScript.emit(end_reason.reason); // прерывание скрипта
                else if (until_page == null) { // сохранение формы на последнем шаге
                  this.form.markAsPristine();

                  if (this.hasNextDialogScriptStep) this.getDialogScriptStep.emit(1); // маршрут не пройден, беру следующий шаг
                  else {
                    // завершение скрипта с завершением вызова => завершаю вызов на текущей линии
                    if (end_reason?.reason == 2 || end_reason?.reason  == 3) this.CTI.ctiActions$.next({
                      obj: 'call',
                      action: 'endCall'
                    });

                    // завершаю скрипт
                    this.endDialogScript.emit(end_reason?.reason || null);
                  } // маршрут пройден, завершаю скрипт
                }

                if (btn) btn.disabled = false;
              },
              resp => {
                this.notifyService.setFormErrors(resp, this.form) != 502;
                if (btn) btn.disabled = false;
              }
            );
          } else {
            this.notifyService.message('DOMAINDIALOGSCRIPTRESULT.GET_FAILED');
            if (btn) btn.disabled = false;
          }
          this.cdr.detectChanges();
        },
        resp => {
          this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502;
          if (btn) btn.disabled = false;
          this.cdr.detectChanges();
        }
      );
    } else {
      Object.entries(this.form.controls).filter(filter => filter[1]['status']==='INVALID').forEach(item => {
        this.form.controls[`${item[0]}`].updateValueAndValidity({emitEvent: false, onlySelf: false});
        this.form.controls[`${item[0]}`].markAllAsTouched();
        console.log('invalid field: ', item);
      });

      this.notifyService.message('NOTIFY.REQUIRED');
      this.cdr.detectChanges();
    }
  }

  getTiers(path: number) {
    return this.tiers.filter(tier => tier.queue_id == path)
  }
}
