import {
  propObject,
  prop,
  alphaNumeric, alpha, compare, contains, creditCard, digit, email, greaterThanEqualTo,
  greaterThan, hexColor, json, lessThanEqualTo, lowerCase, maxLength, maxNumber,
  minNumber, password, pattern,
  lessThan, range, required, time, upperCase, url, propArray, minLength, minDate, maxDate, toBoolean, unique, port, oneOf, disable, choice
} from '@rxweb/reactive-form-validators';
import {
  HTTPManualService,
  HTTPService,
  TFTPManualService,
  TFTPService
} from '../../../domains/domain-devices/domain-auto-provision-service/domain-auto-provision-service';

export class DPCConditionRouterRowCallCount {
  @required()
  model_name: string = 'DPEConditionRouterRowCallCount';
  @required() @digit() max_calls: number;
  @required() status_list: number[];
  @required() @digit() period: number;
}

export class DPCConditionRouterRowIGCallSessionCompare {
  @required()
  model_name: string = 'DPEConditionRouterRowIGCallSessionCompare';
  @required()
  pm_name: string;
  @required()
  json_path: string;

  @required() @digit()
  type: number = 1; // VAR_TYPE_INT = 0 VAR_TYPE_STR = 1 # VAR_TYPE_LIST = 2 # VAR_TYPE_DICT = 3
  @required()
  value: string = '';
  @required() @digit()
  condition: number = 0;
  // CONDITION_TYPE_EQUAL=0, CONDITION_TYPE_LIKE=1, CONDITION_TYPE_ENDSWITH=2, CONDITION_TYPE_STARTSWITH=3,
  // CONDITION_TYPE_MORE=4, CONDITION_TYPE_MORE_OR_EQUAL=5, CONDITION_TYPE_LESS=6,
  // CONDITION_TYPE_LESS_OR_EQUAL=7, CONDITION_TYPE_NOT_EQUAL=8

}

export class DPCConditionRouterRowQueueLen {
  @required()
  model_name: string = 'DPEConditionRouterRowQueueLen';
  @required() @digit() @maxNumber({value: 1000}) max_len: number;
  @required() @digit()
  queue_id: number[];
}

export class DPCConditionRouterRowQueueAgentAvailable {
  @required()
  model_name: string = 'DPEConditionRouterRowQueueAgentAvailable';
  @required() @digit()
  queue_id: number[];
}

export class DPCConditionRouterRowUserAvailable {
  @required()
  model_name: string = 'DPEConditionRouterRowUserAvailable';
  @required()
  value: string;
}

export class DPCConditionRouterRowValueCompare {
  @required()
  model_name: string = 'DPEConditionRouterRowValueCompare';
  @required()
  name: string = '';
  @required() @digit()
  type: number = 1; // VAR_TYPE_INT = 0 VAR_TYPE_STR = 1 # VAR_TYPE_LIST = 2 # VAR_TYPE_DICT = 3
  @required()
  value: string = '';
  @required() @digit()
  condition: number = 0;
  // CONDITION_TYPE_EQUAL=0, CONDITION_TYPE_LIKE=1, CONDITION_TYPE_ENDSWITH=2, CONDITION_TYPE_STARTSWITH=3,
  // CONDITION_TYPE_MORE=4, CONDITION_TYPE_MORE_OR_EQUAL=5, CONDITION_TYPE_LESS=6,
  // CONDITION_TYPE_LESS_OR_EQUAL=7, CONDITION_TYPE_NOT_EQUAL=8
}

export class DPCConditionRouterRowQueueSL {
  @required()
  model_name: string = 'DPEConditionRouterRowQueueSL';
  @required() @digit() @minNumber({value: 1}) @maxNumber({value: 100}) sl_perc: number;
  @required() @digit() @minNumber({value: 1}) @maxNumber({value: 3600}) sl_time: number;
  @required() @digit() @minNumber({value: 5}) @maxNumber({value: 60}) range_time: number;
  @required() @digit()
  queue_id: number[];
}

export class DPCConditionRouter {
  @prop() name: string = '';
  @required()
  @propObject(undefined, {entityProvider: function() {
      let entity;
      switch (this.value.model_name) {
        case 'DPEConditionRouterRowQueueLen': entity = DPCConditionRouterRowQueueLen; break;
        case 'DPEConditionRouterRowIGCallSessionCompare': entity = DPCConditionRouterRowIGCallSessionCompare; break;
        case 'DPEConditionRouterRowQueueAgentAvailable': entity = DPCConditionRouterRowQueueAgentAvailable; break;
        case 'DPEConditionRouterRowUserAvailable': entity = DPCConditionRouterRowUserAvailable; break;
        case 'DPEConditionRouterRowCallCount': entity = DPCConditionRouterRowCallCount; break;
        case 'DPEConditionRouterRowValueCompare': entity = DPCConditionRouterRowValueCompare; break;
        case 'DPEConditionRouterRowQueueSL': entity = DPCConditionRouterRowQueueSL; break;
      }
      return entity;
    }}) value: DPCConditionRouterRowQueueLen | DPCConditionRouterRowQueueAgentAvailable | DPCConditionRouterRowCallCount | DPCConditionRouterRowUserAvailable
    | DPCConditionRouterRowValueCompare | DPCConditionRouterRowQueueSL | DPCConditionRouterRowIGCallSessionCompare;


}

export let DPCondition = [
  {id: 'DPEConditionRouterRowCallCount', name: 'DPE.CALL_COUNT'},
  {id: 'DPEConditionRouterRowQueueLen', name: 'DPE.QUEUE_LEN'},
  {id: 'DPEConditionRouterRowQueueAgentAvailable', name: 'DPE.QUEUE_FREE_OPERATOR'},
  {id: 'DPEConditionRouterRowUserAvailable', name: 'DPE.QUEUE_USER_AVAILABLE'},
  {id: 'DPEConditionRouterRowValueCompare', name: 'DPE.VALUE_COMPARE'},
  {id: 'DPEConditionRouterRowIGCallSessionCompare', name: 'DPE.INTEGRATION_COMPARE'}
];

export let DPConditionVarType = [
  {id: 0, name: 'VAR_TYPE_INT'},
  {id: 1, name: 'VAR_TYPE_STR'},
  {id: 2, name: 'VAR_TYPE_LIST'},
  {id: 3, name: 'VAR_TYPE_DICT'},
  {id: 4, name: 'VAR_TYPE_DECIMAL'},
];

export let DPConditionType = [
  {id: 0, name: 'CONDITION_TYPE_EQUAL'},
  {id: 1, name: 'CONDITION_TYPE_STARTSWITH'},
  {id: 2, name: 'CONDITION_TYPE_ENDSWITH'},
  {id: 3, name: 'CONDITION_TYPE_LIKE'},
  {id: 4, name: 'CONDITION_TYPE_MORE'},
  {id: 5, name: 'CONDITION_TYPE_MORE_OR_EQUAL'},
  {id: 6, name: 'CONDITION_TYPE_LESS'},
  {id: 7, name: 'CONDITION_TYPE_LESS_OR_EQUAL'},
  {id: 8, name: 'CONDITION_TYPE_NOT_EQUAL'}
  // {id: 9, name: 'CONDITION_TYPE_IN'}
  // {id: 10, name: 'CONDITION_TYPE_NOT_IN'}
];
