import {ElementRef, Injectable, isDevMode} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {generateGUID} from '../../vertojs';
import {BehaviorSubject, forkJoin, Observable, of as observableOf, Subject} from 'rxjs';
import {IResponseListObject} from '../_helpers/api.helpers';
import {catchError, concatMap, map, switchMap, take, takeUntil} from 'rxjs/operators';
import {NotifyService} from '../_helpers/notify.service';
import {VertoService} from "../verto/verto.service";
import {AGENT_STATUS_LIST, PERM_DLIST_LIST} from "../_helpers/constant";
import {FormControl} from "@angular/forms";
import {DOCAgentService} from "../domains/domain-omni-channel/doc-agent/doc-agent.service";
import {UserService} from "../users/user.service";
import {QueueService} from "../queues/queue.service";
import {DialplanService} from "../dialplans/dialplan.service";
import {DomainAgentStatusService} from "../domains/domain-agent-status/domain-agent-status.service";
import {NotificationBarService} from "../layouts/full/notification-bar/notification-bar.service";
import {DomainAddressBookService} from "../domains/domain-address-books/domain-address-book.service";
import {DomainAddressBookGroupService} from "../domains/domain-address-book-group/domain-address-book-group.service";
import {DesktopDefService} from "../__PROJECTS__/def/desktop/desktop-def.service";
import {DomainUserService} from "../users/domain-user.service";
import {CTISettings as UserProfileCTISettings} from "../user-profile/user-profile";
import {DomainCTI} from "../domains/domain-settings/cti-settings/cti-settings";
import {DomainCRMEntityService} from "../domains/domain-crm/entities/entity.service";
import {DomainDialogScriptService} from "../domains/domain-dialog-scripts/scripts/domain-dialog-script.service";
import {AuthenticationService} from "../auth/authentication.service";

export class LastCallsReport {
  start: string;
  transfer: string;
  transfer_status: number; // 0 - попытка, 1-active, 2-answer, 3-слепой соединил, 4-соединил не слепой, 5-отменил
  number: string;
  name: string;
  direction: number;
  uuid: string;
  hasActive: boolean;
  hasHold: boolean;
  performance: string;
  abonent: string;
  callee_id_number: string;
  callee_id_name: string;
  caller_id_number: string;
  caller_id_name: string;
}



@Injectable({ providedIn: 'root' })
export class CTIService {
  /**
   * id,
   * caller_id_number, caller_id_name,
   * callee_id_number, callee_id_name,
   * direction: 0 - исх. (outbound) | 1 - вх. (inbound),
   * dt_start: дата начала разговора,
   * status:
   *  - attached - прикреплен из сокета после обновления страницы;
   *  - ringing - звонит;
   *  - active - идет разговор;
   *  - held - на удержании;
   *  - transfering - перевод;
   *  - ended - завершен.
   */
  public ctiCalls$ = new BehaviorSubject<any[]>([]);
  public crmEntity$ = new BehaviorSubject<any>(null); // сюда падают контакты\компании CRM, от которых пришел вызов; отображаются в Рабочем столе, в окне CRM
  public crmKnoweldge$ = new Subject<any>(); // сюда падают данные статьи, по которой обратился клиент CRM; отображаются в Активности, в последнем событии "Звонок"
  public shareCalls$ = new BehaviorSubject<any[]>([]);
  public wbCalls$ = new BehaviorSubject<any[]>([]);
  public currentLine$ = new BehaviorSubject<any>(0);

  public ctiState$ = new BehaviorSubject<any>('noReg');
  public ctiUser$ = new BehaviorSubject<any>(null);
  public chatEvent$ = new BehaviorSubject<any>(null);
  public chatEventMessages$ = new BehaviorSubject<any>(null);
  public innerChatEventMessages$ = new BehaviorSubject<any>(null);

  public chatEventDialogs$ = new BehaviorSubject<any>(null);
  public innerChatEventDialogs$ = new BehaviorSubject<any>(null);
  public currentQueue$ = new BehaviorSubject<any>(null);
  public ctiActions$ = new Subject<any>();
  public ctiUpdate$ = new Subject<any>();

  public mainMediaStream = null;

  public chatAlarmSource;
  public phoneAlarmSource;
  public volume: number = 0.7;
  public logining: boolean = false; // процесс авторизации в телефоне от момента нажатия на кн. "Включить" до получения состояния ClientReady

  public skinId: number|string = "";
  private audioContext;
  readonly destroy$ = new Subject();
  public attachedTransferingCall = null;
  public autocall: boolean = false;
  public autocallTimeoutNumber: number = null;
  public notify: boolean = false;
  public videocall: boolean = false;
  public isMuted: boolean = false;
  public isCam: boolean = true;

  public denyAudio: boolean = false;
  public denyCam: boolean = false;
  public isVirtualBg: boolean = false;
  public autowait: number = 5;
  public dnd: boolean = false;
  public show_video_call: boolean = false;

  public userStateError: boolean = false;
  public userStateErrorSub;

  public userStates = {
    offline: {label: 'CTI.USER_OFFLINE', icon: 'cancel', color: 'primary'},
    online: {label: 'CTI.USER_ONLINE', icon: 'check_circle_outline', color: 'primary'},
    autoAnswer: {label: 'CTI.AUTO_ANSWER', icon: 'motion_photos_auto', color: 'primary'},
    dnd: {label: 'CTI.DND', icon: 'do_not_disturb_on', color: 'warn'}
  };


  public cmd$ = new Subject<any>();
  public init$ = new Subject<any>();

  public deny_inbound_call: boolean = false; // одноканальность

  public autologin: boolean = false;
  public punt: boolean = false;
  public needReconnect: number = 0; // 0 - не нужно переподключаться, 1 - нужно переподключение
  public callHotKeys: any = {};
  public chatHotKeys: any = {};
  public favorite_settings: UserProfileCTISettings = null;
  public ctiSettings: DomainCTI = null;
  public org_unit_and_position_list: {id: number, unit_id: number, position_id: number}[] = [];
  debug: boolean = isDevMode();

  public lines_calls = []; // 2 линии
  public outbound_calls = []; // массив для исходящих вызовов, для контроля двойного нажатия на кнопку "Позвонить"
  public curLine = 0;
  public currentVideoSrc = null;
  public timers = {};
  public entityTypes: number[] = [1, 2, 3, 4, 5];
  public filterEntityType: FormControl = new FormControl(0);
  public filterToCallEntityType: FormControl = new FormControl(0);
  public blindTransferMode: FormControl = new FormControl([true]);
  public transportConfig: any = {
    socketUrl: '',
    login: '',
    passwd: '',
    funcBtn: '#1',
    sessid: generateGUID()
  };
  public onDialogSub;
  public ctiActionsSub;

  public search$: Subject<any> = new Subject<any>();
  public searchSub;
  public searchToCall$: Subject<any> = new Subject<any>();
  public searchToCallSub;
  public searchOnlyUserToCall$: Subject<any> = new Subject<any>();
  public searchOnlyUserToCallSub;
  public domain_users: any[] = [];
  public domain_queues: any[] = [];
  public domain_address_book_groups: any[] = [];
  public domain_address_books: any[] = [];
  public domain_dialplans: any[] = [];
  public curr_count_domain_users: number = 0; // количество загруженных !сотрудников (не абонентов)
  public count_domain_users: number|null = null; // количество всего сотрудников
  public count_domain_queues: number|null = null;
  public count_domain_address_book_groups: number|null = null;
  public count_domain_address_books: number|null = null;
  public count_domain_dialplans: number|null = null;
  public active_address_book_group = null;
  public last_calls = [];
  public last_calls_from_ls: {number: string, name: string, direction: number}[] = []; // список последних вызовов
  public last_calls_for_report: LastCallsReport[] = []; // список вызовов для рабочего стола
  public agent_status_list = [];
  public omni_agent_status_list = [];
  public agentStatus: FormControl = new FormControl(null);
  public agentStatusPrev: number | null = null; // предыдущий статус агента при его смене непосредственно оператором
  public isStatusChanging: boolean = false;
  public checkAgentStatusAfterUpdate: boolean = false; // проверить статус агента по окончании выполнения запроса на его установку
  public du_agent_status_on_outbound_call_prev: number|null; // статус агента !до любых вызовов и чатов!, который должен быть выставлен при их завершении
  public du_agent_status_on_outbound_call: number|null; // перерывный статус агента при исх. вызове, чтобы не шли вызовы из очереди
  public du_agent_status_on_inbound_call: number|null; // перерывный статус агента при прямом вх. вызове, чтобы не шли вызовы из очереди
  public du_agent_status_on_chat: number|null; // перерывный статус агента при принятии диалога

  public hasOmniAgentPerm = false;
  public omniAgent: FormControl;
  public omniAgentStatusPrev: number | null = null; // предыдущий статус ОМНИ-агента
  public checkOmniAgentStatusAfterUpdate: boolean = false;
  public isOmniStatusChanging: boolean = false;
  public omni_agent_status_on_call: number|null; // перерывный статус ОМНИ-агента при вх. вызове
  public omni_agent_status_on_call_prev: number|null; // предыдущий статус ОМНИ-агента при его автоматической смене при вх. вызове

  public loadingDomainUsers: boolean = false;
  public hasPhoneProfile = false;

  public mynumber = null;
  public number = '';
  public name = '';

  public tz = 'Europe/Moscow';

  public wrap_up_time: number = 0;
  public showDND: boolean = false;
  public showVideoCall: boolean = false;
  public showTransfer: boolean = true;
  public showEavesdropBtns: string[] = [];

  audioContextList = [];
  setSourcePhone = (): void=>{this.createAudioSource(this.alarm).then(_=>{
    if (this.phoneAlarmSource) {
      this.phoneAlarmSource.stop();
    }
    this.phoneAlarmSource = _;
  })};

  is_moderator = false;
  myCanvasID = 0;

  canvasData = [];
  confLayouts = [];
  public ice_server_list = [];
  public host_source_link: string = '';
  public alarm =  this.host_source_link + '/assets/sounds/bell_ring2.mp3';
  public alarmChat =  this.host_source_link + '/assets/sounds/1.mp3';

  public confMembers$ =new BehaviorSubject<any>(null);

  public video = null;
  public proxy = null;
  public elemRef: ElementRef = null;

  constructor(
    public verto: VertoService,
    public notifyService: NotifyService,
    public translate: TranslateService,
    public docAgentService: DOCAgentService,
    public userService: UserService,
    public domainUserService: DomainUserService,
    public queueService: QueueService,
    public addressBookService: DomainAddressBookService,
    public addressBookGroupService: DomainAddressBookGroupService,
    public dialplanService: DialplanService,
    public agentStatusService: DomainAgentStatusService,
    public domainCRMService: DomainCRMEntityService,
    public domainDialogScriptService: DomainDialogScriptService,
    public notificationBarService: NotificationBarService,
    public defApi: DesktopDefService,
    public auth: AuthenticationService
  ) {
    this.createAudioContext();
  }

  toHHMMSS(s: number): string {
    if (s == -1) return '-';
    let sec_num = Math.floor(s);
    let hours: string | number = Math.floor(sec_num / 3600);
    let minutes: string | number = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds: string | number = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = '0'+hours;}
    if (minutes < 10) {minutes = '0'+minutes;}
    if (seconds < 10) {seconds = '0'+seconds;}
    return hours+':'+minutes+':'+seconds;
  }

  setSourceChat = (): void=>{this.createAudioSource(this.alarmChat).then(_=>{
    if (this.chatAlarmSource) {
      this.chatAlarmSource.stop();
    }
    this.chatAlarmSource = _;
  })};

  addICEServer(urls) {
    this.ice_server_list.push(urls);
  }

  clearICEServer() {
    this.ice_server_list = [];
  }

  async createAudioSource(url, start = true, loop = false) {
    await this.setSkinId();
    const gainNode = this.audioContext.createGain();
    gainNode.gain.value = this.volume; // setting it to 10%
    gainNode.connect(this.audioContext.destination);

    const source = this.audioContext.createBufferSource();
    const audioBuffer = await fetch(url)
      .then(res => res.arrayBuffer())
      .then(ArrayBuffer => this.audioContext.decodeAudioData(ArrayBuffer));
    source.buffer = audioBuffer;
    source.connect(gainNode);
    // source.loop = true;
    if (start) source.start();
    if (loop) source.loop = true;
    return source;
  };

  createAudioContext() {
    this.audioContext = new AudioContext();
  }
  async getAudioOutputs() {
    try {
      // More audio outputs are available when user grants access to the microphone.
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
    } finally {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioOutputs = devices.filter((device) => device.kind == "audiooutput");
      this.audioContextList = audioOutputs.map((device) => {
        return {id: device.deviceId, name: device.label}
      })
      // this.audioContextList.push({ id: "silent", name: "No output" });
    }
  }

  deviceIdToSinkId(deviceId) {
    if (deviceId === "default") return "";
    if (deviceId === "silent") return { type: "none" };
    return deviceId;
  }

  async setSkinId() {
    const sinkId = this.deviceIdToSinkId(this.skinId);
    if (this.isSkinId()) {
      try {
        await this.audioContext.setSinkId(sinkId);
      } catch (e) {
        await this.audioContext.setSinkId('');
      }

    }
  }

  isSkinId() {
    return 'setSinkId' in AudioContext.prototype;
  }

  checkSessionExists(needConnect: boolean = false) {
    let calls = localStorage.getItem('calls_dt_start') ? Object.keys(JSON.parse(localStorage.getItem('calls_dt_start'))) : {};
    // проверяю, что предыдущая сессия не активна
    this.verto.get_verto_mode({
      login: this.transportConfig.login, channels_for_check: calls, sessid: this.transportConfig.sessid
    }).subscribe(
      (data) => {
        // mode: 0 - активируем телефон в последней вкладке, 1 - держим активность в первой
        if (this.autologin || needConnect) {
          if (data && data.code == 200) {
            // регистрирую телефон, если указан режим регистрации на последней вкладке или нет регистраций, или при перерегистрации (для получения вызовов)
            if (data.body.verto_mode == 0 || data.body.session_ids?.filter(sessid => sessid.endsWith(this.transportConfig.sessid)).length == 0 || needConnect) {
              let calls_dt_start = localStorage.getItem('calls_dt_start') ? JSON.parse(localStorage.getItem('calls_dt_start')) : {};
              let calls_on_hold = localStorage.getItem('calls_on_hold') ? JSON.parse(localStorage.getItem('calls_on_hold')) : {};
              let cachedCalls = localStorage.getItem('cachedCall') ? JSON.parse(localStorage.getItem('cachedCall')) : [];

              for (let ch in data.body.channels) {
                if (!data.body.channels[ch]) {
                  if (calls_dt_start[ch]) delete calls_dt_start[ch];
                  if (calls_on_hold[ch]) delete calls_on_hold[ch];
                  cachedCalls = cachedCalls.filter(c => c.id != ch);
                }
              }
              localStorage.setItem('calls_dt_start', JSON.stringify(calls_dt_start));
              localStorage.setItem('calls_on_hold', JSON.stringify(calls_on_hold));
              localStorage.setItem('cachedCall', JSON.stringify(cachedCalls));

              if (
                data.body.session_ids?.filter(sessid => sessid.endsWith(this.transportConfig.sessid)).length > 0 &&
                localStorage.getItem('calls_dt_start') && Object.keys(JSON.parse(localStorage.getItem('calls_dt_start'))).length > 0
              ) {
                // говорю телефону, что он регается вместо другого, т.е. выключает телефон на другой вкладке и при этом в нем есть вызовы, которые нужно получить,
                // повторно зарегистрировавшись в телефоне (фрисвитч 6 версии отдает вызовы со второй регистрации)
                // this.needReconnect = 1; // на pbx после открытия второй вкладки будет виснуть вызов, т.к. аттач летит сразу, а в других кабинетах - после второй регистрации телефона, поэтому комментирую
              }

              this.onLogin();
            }
          } else this.onLogin();
        }
      });
  }

  buildCanvasesData() {
    // this.verto.canvases = [{ id: 1, name: 'Super Canvas' }];
    // for (var i = 1; i < this.verto.conf.canvasCount; i++) {
    //   this.verto.canvases.push({ id: i+1, name: 'Canvas ' + (i+1) });
    // }
  }

  // Начало блока конференций
  startConference(call, pvtData) {
    this.buildCanvasesData(); //

    console.log('call.video', 'video');
    console.log('call.conference', 'conference');
    this.verto.chattingWith = pvtData.chatID;
    this.verto.confRole = pvtData.role;

    // pvtData.callID: "56107ae1-68f2-40f3-b19a-041a12ccfe33"
    // pvtData.canvasCount: 1
    // pvtData.laName: 34_222

    this.verto.conferenceMemberID = pvtData.conferenceMemberID;
    this.verto.conf = this.verto.createConf(call, {
      hasVid: this.videocall, //storage.data.useVideo,
      laData: pvtData,
    });

    this.verto.conf.members$.subscribe(_=>this.confMembers$.next(_))

    this.verto.phone.subscribe(pvtData.infoChannel, {})
    this.verto.phone.subscribe(pvtData.modChannel, {})
    this.verto.phone.subscribe(pvtData.chatChannel, {})
    this.verto.phone.subscribe(pvtData.laChannel, {}) // участники


    if (this.verto.confRole == "moderator") {
      console.log('>>> conf.listVideoLayouts();');
      console.log('I am Moderator');
      this.verto.conf.modCommand("list-videoLayouts", null, null);

      //conf.listVideoLayouts();
      //conf.modCommand('canvasInfo');
    }
  }

  stopConference() {
    console.log('stopConference()');
    if (this.verto?.conf) {
      this.verto.conf.members$.unsubscribe();
      this.verto.conf.destroy();
      this.verto.conf = null;
    }

    this.confMembers$.next(null);
  }

  updateVideoSize() {

  }

  hasVideoCall() {
    return this.lines_calls.filter(call=>!!call?.video).length > 0; // this.video
  }

  getLocalStream() {
    return this.lines_calls.find(call=>!!(call?.call?.local_stream))?.call?.local_stream;
  }

  setVideoLayout(layout) {
    if (this.verto.conf) {
      this.verto.conf.modCommand("vid-layout", null, layout);

    }
  }

  isModerator() {
    return this.verto.confRole == 'moderator' && !!this.verto.conf
  }

  confDeaf(memberID) {
    if(this.isModerator()) {
      this.verto.conf.deaf(memberID);
    }
  }

  confUnDeaf(memberID) {
    if(this.isModerator()) {
      this.verto.conf.undeaf(memberID);
    }
  }

  confMuteMic(memberID) {
    if(this.isModerator()) {
      this.verto.conf.muteMic(memberID);
    }
  }

  confKick(memberID) {
    if(this.isModerator()) {
      this.verto.conf.kick(memberID);
    }
  }

  confMuteVideo(memberID) {
    if(this.isModerator()) {
      this.verto.conf.muteVideo(memberID);
    }
  }

  vidFloor(memberID) {
    if(this.verto.confRole == 'moderator' && this.verto.conf) {
      this.verto.conf.vidFloor(memberID);
    }
  }

  vidResId(memberID, resolution) {
    if(this.verto.confRole == 'moderator' && this.verto.conf) {
      this.verto.conf.vidResId(memberID, resolution);
    }
  }

  async hangupScreen(call_id, params: any = {}) {
    if (call_id) {
      this.verto.phone.hangup(call_id, {screenShare: true, ...params});
      let shareCalls = (this.shareCalls$.value || []).filter(c => c.call.id !== call_id)
      this.shareCalls$.next(shareCalls)
    }
  }

  async hangupWB(call_id, params: any = {}) {
    if (call_id) {
      this.verto.phone.hangup(call_id, {screenShare: true, ...params});
      let shareCalls = (this.wbCalls$.value || []).filter(c => c.call.id !== call_id)
      this.wbCalls$.next(shareCalls)
    }
  }

  async hangupAllScreen(params: any = {}) {
      (this.shareCalls$.value || []).forEach(shareCall=>{
        this.verto.phone.hangup(shareCall.call.id, {screenShare: true, ...params})
      });
      this.shareCalls$.next([]);
  }

  async hangupAllWB(params: any = {}) {
    (this.wbCalls$.value || []).forEach(shareCall=>{
      this.verto.phone.hangup(shareCall.call.id, {screenShare: true, ...params})
    });
    this.wbCalls$.next([])
  }

  async captureScreen() {
    let screenStream = null;
    let screenCall = null; // Добавляем переменную для хранения вызова экрана

    try {
      // @ts-ignore
      screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          cursor: "always"
        } as MediaTrackConstraints,
        audio: true
      });

      screenCall = this.verto.phone.call(screenStream.getTracks(), 'screen', { screenShare: true });
      screenCall.local_stream = screenStream;

      screenCall.options.callee_id_number = 'screen';
      screenCall.options.callee_id_name = 'Screen Share';
      screenCall.status = 'ringing';
      screenCall.direction = 0;

      screenCall.subscribeEvent('track', (track: any) => {
        let stream = new MediaStream();
        stream.addTrack(track);
        console.log('TRACK SCREEN SHARE >>>>>>>>>>' + track.kind);

        let shareArray = this.shareCalls$.value || [];
        shareArray.push({ call: screenCall });
        this.shareCalls$.next(shareArray);

        this.ctiUpdate$.next(null);
      });

      screenStream.addEventListener('inactive', () => {
        console.log('Screen sharing stream became inactive');
        this.hangupAllScreen();
      });

      this.ctiUpdate$.next(null);

    } catch (ex) {
      console.log("Error occurred", ex);
    }
  }

  async whiteBoard(screenStream) {

    try {
      // @ts-ignore

      let screenCall = this.verto.phone.call(screenStream.getTracks(), 'whiteboard', {
        screenShare: true

      })
      screenCall.local_stream = screenStream;


      screenCall.options.callee_id_number ='whiteboard';
      screenCall.options.callee_id_name = 'White Board';
      screenCall.status = 'ringing';
      screenCall.direction = 0;

      screenCall.subscribeEvent('track', (track: any) => {
        let stream = new MediaStream();
        stream.addTrack(track);
        console.log('TRACK WHITEBOARD SHARE >>>>>>>>>>'+track.kind);

        let shareArray = this.wbCalls$.value || [];
        shareArray.push({call: screenCall})
        this.wbCalls$.next(shareArray);

        this.ctiUpdate$.next(null);
      });


      //mediaStream.getTracks()?.forEach(track=>this.getLocalStream().addTrack(track))
      this.ctiUpdate$.next(null);

    } catch (ex) {
      console.log("Error occurred", ex);
    }

  }

  // Окончание блока конференций
  onLogin() {
    this.logining = true;
    this.lines_calls = [];
    this.outbound_calls = [];
    this.curLine = 0;
    this.currentLine$.next(0);

    let VertoOptions = {
      rtcConfig: {},
      transportConfig: this.transportConfig,
      caller_id_name: this.transportConfig.login.split('@')[0],
      caller_id_number: this.transportConfig.login.split('@')[0],
      debug: true,
      ice_timeout: 1500,
      deny_inbound_call: this.deny_inbound_call
    };

    if (this.ice_server_list.length > 0) {
      VertoOptions.rtcConfig['iceServers'] = this.ice_server_list;
    }

    this.verto.init(VertoOptions);
    this.init$.next(null);
    this.verto.login().then(resp => {
      if (this.debug) console.log('resp', resp);
      this.logining = false;
      this.stopAgentStatusTimer();
      this.ctiUpdate$.next(null);
    });
  }

  onLogout() {
    if (this.verto.isLogged()) {
      this.ctiState$.next((this.transportConfig.login && this.transportConfig.passwd && this.transportConfig.socketUrl) ? 'offline' : 'noReg');
      this.verto.logout().then();

      this.ctiUser$.next(this.mynumber);
      this.lines_calls = [];
      this.outbound_calls = [];
      this.refreshCTICalls();

      if (this.ctiActionsSub) this.ctiActionsSub.unsubscribe();

      this.stopAgentStatusTimer();

      if (this.needReconnect == 1) { // если разрегистрация вызвана перерегистрацией для получения вызовов, то делаю регистрацию для их получения
        this.needReconnect = 0;
        this.ctiUpdate$.next(null);
        this.onLogin();


      } else {
        if (this.omniAgent) {
          if (this.isOmniStatusChanging) {
            // не могу сейчас установить статус, т.к. тот, что текущий, возможно неактуальный
            this.checkOmniAgentStatusAfterUpdate = true; // говорю проверить по окончании смены статуса
            this.ctiUpdate$.next(null);
          } else {
            this.checkOmniAgentStatusAfterUpdate = false;
            this.ctiUpdate$.next(null);
            this.checkAndUpdateOmniAgentStatus();
          }
        }
      }


      // if (this.messagesSub) this.messagesSub.unsubscribe();
      if (this.onDialogSub) this.onDialogSub.unsubscribe();

      this.ctiUpdate$.next(null);
    }
  }

  async virtualConfCall(number='call', media = null, audio = null, codec = 'audio/PCMA') {
    if (number.length > 0) {
      if (this.verto?.phone?.logged_in) {

        if (media && audio ) {
          audio?.getAudioTracks()?.forEach(track=>media.addTrack(track))
        }

        let local_stream = (media)? media : await navigator.mediaDevices.getUserMedia({audio: true, video: true})

        let call = this.verto.phone.call(local_stream.getTracks(), number.trim(), {codec: codec});
        call.local_stream = local_stream;
        this.outbound_calls.push({call: call});

        call.options.callee_id_number = number;
        call.options.callee_id_name = name;
        call.status = 'ringing';
        call.direction = 0;
        this.lines_calls.push({call: call});
        this.refreshCTICalls();
        this.refreshLocalStorageCalls();
        let lineNumber = this.lines_calls.findIndex(c => c.call.id == call.id);
        this.line(lineNumber);

          if (this.isStatusChanging) {
            // не могу сейчас установить статус, т.к. тот, что текущий, возможно неактуальный
            this.checkAgentStatusAfterUpdate = true; // говорю проверить по окончании смены статуса
            if (this.debug) console.log('Изменение статуса агента при исх. вызове на первой линии: установлен флаг проверки статуса агента.');
            this.ctiUpdate$.next(null);
          } else {
            this.checkAgentStatusAfterUpdate = false;
            this.ctiUpdate$.next(null);

            if (this.debug) console.log('Изменение статуса агента при исх. вызове на первой линии: обновление статуса агента...');
            this.checkAndUpdateAgentStatus();
          }

          call.subscribeEvent('track', (track: any) => {
            let stream = new MediaStream();
            stream.addTrack(track);
            console.log('TRACK >>>>>>>>>>'+track.kind);
            let c = this.lines_calls.find(c => c.call.id == call.id);
            if (track.kind == 'video') {
              this.video = stream;
              if (c) { c.video = stream; }
            } else if (track.kind == 'audio') {

              if (c) {
                c.stream = stream;
                c.track = track;
                this.refreshCTICalls();
              }
              this.outbound_calls = this.outbound_calls.filter(c => c.call.id != call.id);
            } else {
              // Здесь будет kind == screen
            }

            this.ctiUpdate$.next(null);
          });
        }
      }
  }

  async onCall(prenumber: any, name: any = null, video = false, codec = 'audio/PCMA') {
    let number = prenumber ? prenumber.trim().replace(/[^+0-9]+/g, '') : prenumber;
    switch (prenumber) {
      case 'call':
      case 'screen':
        number = prenumber
        break;
    }

    if (number.length > 0) {
      if (this.verto?.phone?.logged_in) {

        const performanceTest = {};
        const  call_start = performance.now();

        try {
          const local_stream = this.mainMediaStream
            ? this.mainMediaStream
            : (this.mainMediaStream = await navigator.mediaDevices.getUserMedia({audio: true, video: video && this.videocall}))

          performanceTest['stream'] = Math.round(performance.now()-call_start);
          this.update_last_calls(prenumber, name, 0);
          let call = this.verto.phone.call(local_stream.getTracks(), number.trim(), {codec: codec});
          call.local_stream = local_stream;
          this.outbound_calls.push({call: call});

          call.options.callee_id_number = number;
          call.options.callee_id_name = name;
          call.status = 'ringing';
          call.direction = 0;
          performanceTest['call'] = Math.round(performance.now()-call_start);
          let callReport: LastCallsReport = {
            start: new Date().toLocaleString(),
            direction: call.direction,
            number: number,
            name: name,
            performance: performanceTest,
            callee_id_number: call.options.callee_id_number,
            callee_id_name: call.options.callee_id_name,
            caller_id_number: call.options.caller_id_number,
            caller_id_name: call.options.caller_id_name,
            uuid: call.id,
          } as LastCallsReport


          this.add_calls_for_report(callReport);
          this.lines_calls.push({call: call});
          this.refreshCTICalls();
          this.refreshLocalStorageCalls();
          let lineNumber = this.lines_calls.findIndex(c => c.call.id == call.id);
          this.line(lineNumber);

          if (this.isStatusChanging) {
            // не могу сейчас установить статус, т.к. тот, что текущий, возможно неактуальный
            this.checkAgentStatusAfterUpdate = true; // говорю проверить по окончании смены статуса
            if (this.debug) console.log('Изменение статуса агента при исх. вызове на первой линии: установлен флаг проверки статуса агента.');
            this.ctiUpdate$.next(null);
          } else {
            this.checkAgentStatusAfterUpdate = false;
            this.ctiUpdate$.next(null);

            if (this.debug) console.log('Изменение статуса агента при исх. вызове на первой линии: обновление статуса агента...');
            this.checkAndUpdateAgentStatus();
          }

          if (this.omniAgent) {
            if (this.isOmniStatusChanging) {
              // не могу сейчас установить статус, т.к. тот, что текущий, возможно неактуальный
              this.checkOmniAgentStatusAfterUpdate = true; // говорю проверить по окончании смены статуса
              this.ctiUpdate$.next(null);
            } else {
              this.checkOmniAgentStatusAfterUpdate = false;
              this.ctiUpdate$.next(null);
              this.checkAndUpdateOmniAgentStatus();
            }
          }

          call.subscribeEvent('track', (track: any) => {
            let stream = new MediaStream();
            stream.addTrack(track);
            console.log('TRACK >>>>>>>>>>'+track.kind);
            let c = this.lines_calls.find(c => c.call.id == call.id);
            if (track.kind == 'video') {
              this.video = stream;
              if (c) { c.video = stream; }
              performanceTest['video'] = Math.round(performance.now()-call_start);
            } else if (track.kind == 'audio') {

              if (c) {
                c.stream = stream;
                c.track = track;
                this.refreshCTICalls();
                performanceTest['audio'] = Math.round(performance.now()-call_start);
              }
              this.outbound_calls = this.outbound_calls.filter(c => c.call.id != call.id);
            } else {
              // Здесь будет kind == screen
            }
            performanceTest['answer'] = Math.round(performance.now()-call_start);
            this.update_call_for_report(c.call.id, {performance: performanceTest})
            this.ctiUpdate$.next(null);
          }, error => {
            performanceTest['error'] = Math.round(performance.now()-call_start);
            this.update_call_for_report(call.id, {performance: performanceTest})
          });

        } catch(error) {
          this.userStateError = true;
          this.notifyService.message(this.translate.instant('MICROPHONE_IS_REQUIRED'));
          this.ctiUpdate$.next(null);
        }


      }
    }
  }

  async answer(callID, video = false, crmObj: any = null) {
    const performanceTest = {};
    const  call_start = performance.now();
    console.log('%cПринятие входящего вызова: ' + new Date().toISOString().replace(/[T]/g, ' ').replace('Z', ''), 'background-color: yellow; font_size: 20px;');


    const local_stream = this.mainMediaStream
      ? this.mainMediaStream
      : (this.mainMediaStream = await navigator.mediaDevices.getUserMedia({audio: true, video: video && this.videocall}))


    //let local_stream = await navigator.mediaDevices.getUserMedia({audio: true, video: video && this.videocall})
    if (local_stream) {
        performanceTest['stream'] = Math.round(performance.now()-call_start);
        console.log('%cЗапрос микрофона: ' + new Date().toISOString().replace(/[T]/g, ' ').replace('Z', ''), 'background-color: yellow; font_size: 20px;');
        let call = this.lines_calls.find(call => call.call.id == callID);
        if (call && crmObj) call.crmObj = crmObj;
        call.call.local_stream = local_stream;
        if (this.isMuted) this.toggleMic(true);
        if (this.isCam && video && this.videocall) this.toggleCam(true);
        if (call) {
          call.call.answer(local_stream.getTracks());
          performanceTest['call'] = Math.round(performance.now()-call_start);
          console.log('%ccall.call.answer: ' + new Date().toISOString().replace(/[T]/g, ' ').replace('Z', ''), 'background-color: yellow; font_size: 20px;');
          call.call.subscribeEvent('error', answerResult => {
            performanceTest['error'] = Math.round(performance.now()-call_start);
            this.update_call_for_report(callID, {performance: performanceTest})
            if (call.call.status != 'error' && (answerResult?.message == 'CODEC ERROR' || answerResult?.message == 'ANSWER ACCEPTING TIMEOUT')) {
              this.notifyService.message('ERROR.CALL.NO_CONNECTION');
              this.onHangup(call.call.id);
              call.call.status = 'error'; // он сейчас не удалится, а как придет bye в сокет
            }
          });
          call.call.subscribeEvent('track', (track) => {
            if (track.kind == 'audio') console.log('%cНачался голосовой поток: ' + new Date().toISOString().replace(/[T]/g, ' ').replace('Z', ''), 'background-color: yellow; font_size: 20px;');
            let answerEvent = new CustomEvent("answer", {'detail': {number: call.call.options.caller_id_number, name: call.call.options.caller_id_name}});
            this.elemRef.nativeElement.dispatchEvent(answerEvent);

            console.log('TRACK >>>>>>>>>>'+track.kind);

            if (track.kind == 'video') {
              let stream = new MediaStream();
              stream.addTrack(track);
              this.video = stream;
              call.video = stream;
              performanceTest['video'] = Math.round(performance.now()-call_start);
            }
            else if (track.kind == 'audio') {
              console.log('%cnew MediaStream(): ' + new Date().toISOString().replace(/[T]/g, ' ').replace('Z', ''), 'background-color: yellow; font_size: 20px;');

              let stream = new MediaStream();
              stream.addTrack(track);
              console.log('%cstream.addTrack(track): ' + new Date().toISOString().replace(/[T]/g, ' ').replace('Z', ''), 'background-color: yellow; font_size: 20px;');

              call.track = track;
              call.stream = stream;
              call.call.status = 'active';
              console.log('%cstatus ACTIVE: ' + new Date().toISOString().replace(/[T]/g, ' ').replace('Z', ''), 'background-color: yellow; font_size: 20px;');
              performanceTest['audio'] = Math.round(performance.now()-call_start);
              this.ctiUpdate$.next(null);
              this.stopPostProcessingTimer();
              this.start_timer(call.call.id);
              this.refreshCTICalls();
              this.refreshLocalStorageCalls();

              console.log('%cОбновляю статусы агентов: ' + new Date().toISOString().replace(/[T]/g, ' ').replace('Z', ''), 'background-color: yellow; font_size: 20px;');

              if (this.du_agent_status_on_inbound_call != null) {
                if (this.isStatusChanging) {
                  // не могу сейчас установить статус, т.к. тот, что текущий, возможно неактуальный
                  this.checkAgentStatusAfterUpdate = true; // говорю проверить по окончании смены статуса
                  if (this.debug) console.log('\tустановлен флаг обновления статуса агента.');
                } else {
                  this.checkAgentStatusAfterUpdate = false;
                  if (this.debug) console.log('\tобновление статус агента по флагу...');
                  this.checkAndUpdateAgentStatus();
                }
              }

              if (this.omniAgent) {
                if (this.isOmniStatusChanging) {
                  // не могу сейчас установить статус, т.к. тот, что текущий, возможно неактуальный
                  this.checkOmniAgentStatusAfterUpdate = true; // говорю проверить по окончании смены статуса
                  this.ctiUpdate$.next(null);
                } else {
                  this.checkOmniAgentStatusAfterUpdate = false;
                  this.ctiUpdate$.next(null);
                  this.checkAndUpdateOmniAgentStatus();
                }
              }
              performanceTest['answer'] = Math.round(performance.now()-call_start);
              console.log('%cВызов отвечен: ' + call.call.id, 'background-color: lightgreen;', 'Данные карточки:', call.crmObj);
              if (call.crmObj) {
                this.openCRMCard(call);
              }
            } else {
              // Здесь будет kind == screen
            }
            this.ctiUpdate$.next(null);
            this.update_call_for_report(callID, {performance: performanceTest, hasActive: true})
          });
        }
      } else {
        this.userStateError = true;
        this.notifyService.message(this.translate.instant('MICROPHONE_IS_REQUIRED'));
        this.ctiUpdate$.next(null);
    }

    this.update_call_for_report(callID, {performance: performanceTest})
  }

  openCRMCard(call){
    if (call?.crmObj && call?.track && this.lines_calls.find(c => c.call.id == call.call.id)) { // call.track - означает вызов точно отвечен, иначе данный метод выполнится в this.answer
      console.log('%cОткрытие карточки: ' + call?.call?.id, 'background-color: lightgreen;', 'Данные карточки:', call?.crmObj);
      this.notificationBarService.closeMessage('answer'+call.call.id);
      (call.call.options.call_uuid ? observableOf({body: call.call.options.call_uuid}) : this.domainCRMService.exchange_call_id({uuid: call.call.id})).pipe(
        catchError(resp => observableOf({body: null})),
        switchMap(data => {1
          return (
            this.domainDialogScriptService.isRoleAdmin() ||
            (this.auth.getUserPerm('DomainDialogScript').indexOf(5) != -1) ||
            (this.auth.getUserPerm('DomainDialogScript').indexOf(6) != -1) ?
              this.domainDialogScriptService.get_from_call({call_uuid: data.body || call.call.id}) :
              observableOf({body: null})
          ).pipe(
            map(
              script_data => {
                // if (script_data.body) {
                //   console.log('%cHAVE SCRIPT, EEEEEh!', 'font-size: 20px; color: green;', script_data.body);
                // }
                return {
                  body: data.body || null,
                  ...(script_data.body ? {
                    script_data: {
                      ...script_data.body,
                      call_uuid: (data.body || call.call.id)
                    }
                  } : {})
                }
              }),
            catchError(resp => observableOf({body: data.body || null, script_data: null})),
          );
        })
      ).subscribe(
        data => {
          // запоминаю его в хранилище, чтобы его отобразить в Рабочем столе, даже после обновления странцы, т.к. оператор лк сам закроет обращение от этого клиента
          let crmEntityData = Object.assign(
            {calls: [{phone: call.call.options.caller_id_number, callID: data?.body || call.call.id}]},
            call.crmObj,
            data?.script_data ? {script_data: data.script_data} : {}
          );
          let lsKey = 'desktopCRMEntityList';
          let desktopCRMEntityList: any[] = localStorage.getItem(lsKey) != null ? JSON.parse(localStorage.getItem(lsKey)) : [];
          let client = desktopCRMEntityList.find(v => (v.id != null && v.id == call.crmObj.id) || v.calls.find(c => (c.callID == call.call.id) || c.phone == (call.call.options.caller_id_number)));
          if (client) {
            // если карточка клиента уже добавлена в хранилище, то запоминаю только его вызов, чтобы создать на него обращение в Активности
            // client.calls.push(crmEntityData.calls[0]);
            client.calls = crmEntityData.calls;
            if (data?.script_data) client.script_data = data.script_data;
          } else desktopCRMEntityList.push(crmEntityData); // если карточки нет в хранилище, то запоминаю все его данные в хранилище
          localStorage.setItem(lsKey, JSON.stringify(desktopCRMEntityList));
          // уведомляю рабочий стол о новом вызове, чтобы перечитались вкладки окна CRM
          this.crmEntity$.next(crmEntityData);
        }
      );
    }
  }

  async onTransfer(callID: string, number: string, isBlind: boolean) {
    number = number.replace(/[^+0-9]+/g, ''); // убираю запрещенные символы
    let transfering_call = this.lines_calls.find(c => c.call.id == callID); // переводимый вызов
    if (number && transfering_call) {
      if (transfering_call.transfering == null) {
        transfering_call.transfering = {number: number};
        this.update_call_for_report(callID, {transfer: number})
        this.update_call_for_report(callID, {transfer_status: 0})
        this.refreshCTICalls();
        if (this.debug) console.log('перевожу вызов:', transfering_call);

        try {
          const local_stream = this.mainMediaStream
            ? this.mainMediaStream
            : (this.mainMediaStream = await navigator.mediaDevices.getUserMedia({
              audio: true
            }))

          let call = this.verto.phone.call(local_stream.getTracks(), number);
          call.local_stream = local_stream;
          call.status = 'ringing';
          if (this.debug) console.log('ИД вызова, на который осуществляется перевод:', call.id);

          transfering_call.transfering = {number: number, call: call, isBlind: isBlind};

          this.refreshCTICalls();
          this.refreshLocalStorageCalls();

          call.subscribeEvent('track', (track) => {
            if (track.kind != 'audio') return;
            let stream = new MediaStream();
            stream.addTrack(track);
            transfering_call.transfering.stream = stream;
            this.refreshCTICalls();
            // ставлю на удержание вызов, который переводится, если он еще не удерживается
            // ('transfer' означает, что вызов поставлен на удержание из-за перевода и в случае отклонения вызова его нужно будет снять)
            if (transfering_call.call.status == 'active') {
              this.toggleHold(transfering_call, 'transfer');
              this.update_call_for_report(callID, {transfer_status: 1})
            }

            this.ctiUpdate$.next(null);
          });

          call.subscribeEvent('answer', () => {
            call.status = 'active';
            this.update_call_for_report(callID, {transfer_status: 2})

            // перенесено сюда, т.к. при переводе на внутренний номер сразу идут гудки,
            // которые воспринимаются за rtp-поток, однако сессии даже еще нет, из-за чего вызов просто сбрасывается
            if (transfering_call && transfering_call.transfering?.isBlind) {
              // слепой перевод: ответ получен => объединяю вызовы, не давая им поговорить
              this.refreshCTICalls();
              call.xref(transfering_call.call.id);
              this.update_call_for_report(callID, {transfer_status: 3})

              // говорю, что вызов принят и удаляю данные о трансфере, т.к. он на данной стадии считается завершенным
              delete transfering_call['transfering'];
              this.refreshCTICalls();

              // если основной вызов не завершен, то показываю уведомление, иначе трансферный вызов будет обычным и показывать уведомление не нужно
              if (this.lines_calls.find(c => c.call.id == transfering_call.call.id)) this.notifyService.message("CTI.TRANSFER_ACCEPTED");
              //this.modalService.close('transferPanel');
              this.cmd$.next('transferPanel::close');
            }
            this.refreshCTICalls();
            this.refreshLocalStorageCalls();
            this.ctiUpdate$.next(null);
          });

          call.subscribeEvent('replace', (v) => {
          });


        } catch (error) {
          delete transfering_call['transfering'];
          this.refreshCTICalls();
          this.userStateError = true;
          this.notifyService.message(this.translate.instant('MICROPHONE_IS_REQUIRED'));
          this.ctiUpdate$.next(null);
        }

      } else this.onTransferUnion(callID);
    }
  }

  onTransferUnion(callID: string) {
    let transfering_call = this.lines_calls.find(c => c.call.id == callID); // переводимый вызов;
    // перевод с сопровождением: ответ получен => поговорили => объединяю вызовы
    transfering_call.transfering.call.xref(transfering_call.call.id);

    // после перевода на сотрудника/очередь из диалогово скрипта, возвращаю режим перевода (слепой/неслепой) в установленный в настройках панели,
    // если кнопка режима перевода скрываемая, иначе оператор сам может установить нужный режим после перевода
    if (this.ctiSettings.default_blind_transfer == 2) { // выключить и скрыть
      this.updateBlindTransferMode(false);
      this.blindTransferMode.setValue([]);
    } else if (this.ctiSettings.default_blind_transfer == 3) { // включить и скрыть
      this.updateBlindTransferMode(true);
      this.blindTransferMode.setValue([true]);
    }

    // говорю, что вызов принят и удаляю данные о трансфере, т.к. он на данной стадии считается завершенным
    delete transfering_call['transfering'];
    this.update_call_for_report(callID, {transfer_status: 4})
    this.refreshCTICalls();
    this.refreshLocalStorageCalls(); // хотя оно дальше уйдет в очистку от звонка и там снова обновит данные в хранилище
    this.notifyService.message("CTI.TRANSFER_ACCEPTED");
    //this.modalService.close('transferPanel');
    this.cmd$.next('transferPanel::close');
  }

  onTransferCancel(callID, params: any = {}) {
    let transferingCall = this.lines_calls.find(c => c.call.id == callID);
    if (transferingCall) {
      transferingCall.transfering.cancel = true;
      this.update_call_for_report(callID, {transfer_status: 5})
      this.onHangup(transferingCall.transfering.call.id, params);
    }

    this.refreshCTICalls();

    // this.modalService.close('transferPanel');
    this.ctiUpdate$.next(null);
  }

  onUnion() {
    if (this.lines_calls.length == 2) {
      const aCall = this.lines_calls[0].call;
      const bCall = this.lines_calls[1].call;
      if (aCall && aCall.xref && bCall) {
        aCall.xref(bCall.id);
        this.notifyService.message('CTI.CALL_UNITED'); // Просто уведомление о том, что вызовы объединены, проверки нет как таковой.

        // очищаю данные о вызовах, т.к. они больше не управляются из этого телефона
        // сначала второй вызов, чтобы не установился перерывный статус, если он исходящий, т.к. он будет на первой линии
        this.clearCall(bCall.id);
        this.clearCall(aCall.id);
      }
    }
  }

  public isCallback(call) {
    return (call?.call.status != 'ringing' && !!call?.call?.options?.is_queue_callback);
  }

  public isEavesdrop(call) {
    return call?.call.status != 'ringing' && (call?.call.options.caller_id_name == 'Eavesdrop' || call?.call.options.callee_id_name == 'Eavesdrop');
  }

  onEavesdrop(s, call) {
    call.call.dtmf(s);
    call.call.eavesdrop = s;
  }

  onCallback(s, call) {
    call.call.dtmf(s);
    call.call.callback = s;
  }

  onHangup(call_id, params: any = {}) {
    let transferingCallID = this.lines_calls.find(c => c.call.id === call_id)?.transfering?.call?.id;
    if (transferingCallID) this.verto.phone.hangup(transferingCallID);
    if (call_id) this.verto.phone.hangup(call_id, params);
    this.refreshCTICalls();
  }

  clearCall(call_id) {
    let transfering_call = this.lines_calls.find(c => c.transfering?.call?.id === call_id);
    const call_has_transfer = this.lines_calls.find(c => c.call?.id === call_id && c.transfering?.call?.id);

    this.refreshCTICalls();

    if (transfering_call) { // отклонен или завершен траснферный вызов
      if (this.attachedTransferingCall?.call?.id == transfering_call.transfering.call.id) this.attachedTransferingCall = null;
      if (transfering_call.transfering.cancel) {
        // перечитываю доступные сущности в поиске
        this.clearEntityCounters();
        this.search$.next('');
        let number = transfering_call.transfering.number;
        setTimeout(() => this.search$.next(number), 301);
      }

      // если трансферные данные в переводимом вызове остались в нем, значит он не был отвечен, следовательно, говорю, что вызов отклонен
      this.notifyService.message(transfering_call.transfering.cancel ? 'CTI.TRANSFER_CANCELED' : 'CTI.TRANSFER_REJECTED');
      delete transfering_call['transfering'];
      this.refreshCTICalls();
      let calls_on_hold = 'calls_on_hold' in localStorage ? JSON.parse(localStorage.getItem('calls_on_hold')) : {};
      // снимаю с удержания, если оно поставлено было трансфером и в настройках панели не включен параметр
      // "Оставлять вызов на удержании после завершения трансфера"
      if (
        transfering_call.call.id in calls_on_hold &&
        calls_on_hold[transfering_call.call.id] == 'transfer' &&
        !this.ctiSettings.keep_hold_after_transfer
      ) this.toggleHold(transfering_call);
      this.refreshCTICalls();
      this.refreshLocalStorageCalls();
    } else { // отклонен или завершен вызов на линии
      if (call_has_transfer) {
        this.cmd$.next('transferPanel::close');
        this.notifyService.message("CTI.TRANSFER_CLIENT_BYE");
        this.lines_calls.push({...call_has_transfer?.transfering});

        this.start_timer(call_has_transfer?.transfering?.call?.id);
        let callTemp = this.lines_calls.find((call) => call.call.id == call_has_transfer?.transfering?.call?.id);

        callTemp.call.options.caller_id_number = this.mynumber;
        callTemp.call.options.caller_id_name = this.ctiUser$.value;
        callTemp.call.options.callee_id_number = call_has_transfer?.transfering?.number;
        callTemp.call.options.callee_id_name =  call_has_transfer?.transfering?.number;
        callTemp.call.direction = 0;
        callTemp.call.status = 'active';
      }

      let callInd = this.lines_calls.findIndex((call) => call.call.id == call_id);
      if (callInd != -1) {
        let call = this.lines_calls[callInd];
        // сначала уведомляю подписчиков о том, что вызов завершен
        let call_prev_status = call.call.status;
        call.call.status = 'ended';
        this.ctiUpdate$.next(null);
        this.refreshCTICalls();
        this.refreshLocalStorageCalls();
        // затем удаляю линию и снова уведомляю подписчиков
        this.lines_calls = this.lines_calls.filter(c => c.call.id != call.call.id);
        this.stop_hold_timer(call.call.id);
        this.stop_timer(call.call.id);
        this.refreshCTICalls();
        this.refreshLocalStorageCalls();

        // очищаю список исходящих (без проверки направления вызова)
        this.outbound_calls = this.outbound_calls.filter(c => c.call.id != call_id);

        if (localStorage) {
          let cachedCalls = 'cachedCall' in localStorage ? JSON.parse(localStorage.getItem('cachedCall')) : [];
          localStorage.setItem('cachedCall', JSON.stringify(cachedCalls.filter(c => c.id != call.id)));
        }

        if (this.lines_calls.length > 0) {
          // завершен вызов на активной линии => перехожу на первую линию, если эта линия с обычным вызовом, а не трансферным, который стал обычным, т.к. у него не было удержания
          if (callInd == this.curLine && (!call_has_transfer || this.lines_calls.length > 1)) {
            let next_call_id = this.lines_calls[0].call.id;
            // после завершения вызова на текущей линии, она будет удалена, а первая линия становится текущей.
            // во избежание словесных проблем ставлю вызов на ручной холд, чтобы пользователь сам его снял и продолжил говорить
            let calls_on_hold = 'calls_on_hold' in localStorage ? JSON.parse(localStorage.getItem('calls_on_hold')) : {};
            if (calls_on_hold[next_call_id] != 'self') {
              if (this.debug) console.log('ставлю на удержание вызов:', next_call_id);
              calls_on_hold[next_call_id] = 'system';
              localStorage.setItem('calls_on_hold', JSON.stringify(calls_on_hold));
            }

            // if (this.isMuted) this.toggleMic(false); // снимаю выключение микрофона на текущей линии, т.к. на другой будет стоять удержание
          }
          // т.к. линий максимум две и завершен вызов на какой-то из них, то по-любому остается одна линия, на которую надо перейти,
          // т.к. она могла быть по счету второй
          this.line(0);
        } else {
          this.curLine = 0;
          this.currentLine$.next(0);

          // Сохранение положения микрофона в настройках панели отключено, поэтому включаю микрофон, естественно, когда все вызовы завершены, как сделано у CISCO, например.
          if (this.isMuted && !this.ctiSettings.keep_microphone_state) this.toggleMic(false);
        }

        // запускаю таймер постобработки, если завершен вызов из очереди и время постобработки больше нуля. а отобразится оно, только если нет других вызовов!
        if (
          call_prev_status != 'ringing' &&
          call.call.options.caller_id_number != this.mynumber &&
          call.call.is_direct != null && !call.call.is_direct &&
          this.wrap_up_time
        ) {
          this.startPostProcessingTimer();
        }
      } else {
        // отклонен вызов, который был сверх лимита или ДНД ничего не делаю,
        // хотя в будущем можно будет уведомить о пропущенном вызове
      }

      this.notificationBarService.closeMessage(call_id); // скрываю уведомление о вызове (если оно есть - скроется)
    }

    if (this.isStatusChanging) {
      // не могу сейчас установить статус, т.к. тот, что текущий, возможно неактуальный
      this.checkAgentStatusAfterUpdate = true; // говорю проверить по окончании смены статуса
      this.ctiUpdate$.next(null);
    } else {
      this.checkAgentStatusAfterUpdate = false;
      this.ctiUpdate$.next(null);
      this.checkAndUpdateAgentStatus();
    }

    if (this.omniAgent) {
      if (this.isOmniStatusChanging) {
        // не могу сейчас установить статус, т.к. тот, что текущий, возможно неактуальный
        this.checkOmniAgentStatusAfterUpdate = true; // говорю проверить по окончании смены статуса
        this.ctiUpdate$.next(null);
      } else {
        this.checkOmniAgentStatusAfterUpdate = false;
        this.ctiUpdate$.next(null);
        this.checkAndUpdateOmniAgentStatus();
      }
    }

    this.ctiUpdate$.next(null);
  }

  toggleMic(v?: boolean) {
    this.isMuted = v != null ? v : !this.isMuted;

    this.lines_calls.forEach(c => {
      c.call.local_stream?.getAudioTracks()?.forEach(track => track.enabled = !this.isMuted);
    });

    if (localStorage) {
      let ctiSettings = localStorage.getItem('ctiSettings') ? JSON.parse(localStorage.getItem('ctiSettings')) : {};
      if (this.isMuted) ctiSettings['isMuted'] = this.isMuted;
      else delete ctiSettings['isMuted'];

      localStorage.setItem('ctiSettings', JSON.stringify(ctiSettings));
    }

    this.ctiUpdate$.next(null);
  }

  toggleCam(v?: boolean) {
    this.isCam = v != null ? v : !this.isCam;

    this.lines_calls.forEach(c => {
      c.call.local_stream?.getVideoTracks()?.forEach(track => track.enabled = this.isCam);
    });

    if (localStorage) {
      let ctiSettings = localStorage.getItem('ctiSettings') ? JSON.parse(localStorage.getItem('ctiSettings')) : {};

      if (this.isCam) ctiSettings['isCam'] = this.isCam;
      else delete ctiSettings['isCam'];

      localStorage.setItem('ctiSettings', JSON.stringify(ctiSettings));
    }

    this.ctiUpdate$.next(null);
  }

  toggleHold(call, status?: string) {
    // self - нажатие кнопки удержания в телефоне;
    // transfer - перевод номера;
    // system - системная установка вызова на удержание (при завершении любого другого вызова);
    // line - переход на другую линию

    let calls_on_hold = 'calls_on_hold' in localStorage ? JSON.parse(localStorage.getItem('calls_on_hold')) : {};

    if (call.call.status != 'held') {
      call?.call?.hold();
      call.call.status = 'held';

      if (calls_on_hold[call.call.id] != 'line') {
        if (this.debug) console.log('ставлю на удержание вызов:', call.call.id);
        calls_on_hold[call.call.id] = status || 'self';
        localStorage.setItem('calls_on_hold', JSON.stringify(calls_on_hold));
        this.update_call_for_report(call.call.id,{hasHold: true})
      }

      this.start_hold_timer(call?.call?.id);
    } else {
      call?.call?.unhold();
      call.call.status = 'active';

      if (call.call.id in calls_on_hold) {
        delete calls_on_hold[call.call.id];
        if (this.debug) console.log('снимаю с удержания вызов:', call.call.id);
        localStorage.setItem('calls_on_hold', JSON.stringify(calls_on_hold));
      }

      this.stop_hold_timer(call?.call?.id);
    }
    this.refreshCTICalls();
    this.refreshLocalStorageCalls();

    this.ctiUpdate$.next(null);
  }

  onBackspace() {
    this.number = this.number.slice(0, -1);
    this.ctiUpdate$.next(null);
  }

  onDigit(evt, isDtmf: boolean = true) {
    let reg = isDtmf ? /[0-9#*+]/ : /[0-9+]/;
    if (!(reg.test(evt.key))) {
      evt.preventDefault();
      return false;
    }
    return true;
  }

  sendDTMF(evt) {
    if (typeof evt == 'string') {
      this.lines_calls[this.curLine]?.call.dtmf(evt);
    } else {
      if (/[0-9*#]/.test(evt.key)) {
        this.lines_calls[this.curLine]?.call.dtmf(evt.key);
      }
    }
  }

  updateBlindTransferMode(checked) {
    if (localStorage) localStorage.setItem('blindTransferMode', checked ? '1' : '0');
    this.ctiUpdate$.next(null);
  }

  line(i) {
    this.curLine = i;
    this.currentLine$.next(i);
    let lineCall = this.lines_calls[i];

    // прохожу по всем вызовам, кроме текущего и ставлю их на удержание, если они еще не на удержании
    this.lines_calls.filter(c => c.call.id != lineCall.call.id && c.call.status != 'ringing').forEach(c => {
      if (localStorage) { // запоминаю в локальное хранилище, что вызов на удержании
        let calls_on_hold = 'calls_on_hold' in localStorage ? JSON.parse(localStorage.getItem('calls_on_hold')) : {};
        if (!(c.call.id in calls_on_hold) || calls_on_hold[c.call.id] != 'self') {
          calls_on_hold[c.call.id] = 'line';
          localStorage.setItem('calls_on_hold', JSON.stringify(calls_on_hold));
          c.call.hold();
        }
      } else c.call.hold();

      c.call.status = 'held';
      this.update_call_for_report(c.call.id,{hasHold: true})
    });
    this.refreshCTICalls();
    this.refreshLocalStorageCalls();

    // если на линии есть вызов, то убираю удержание, если оно поставлено не вручную
    if (lineCall) {
      let calls_on_hold = localStorage && 'calls_on_hold' in localStorage ? JSON.parse(localStorage['calls_on_hold']) : {};
      if (lineCall.call.id in calls_on_hold) {
        if (calls_on_hold[lineCall.call.id] == 'line') { // если удержание установлено при переходе на другую линию, то снимаю его
          lineCall.call.unhold();
          lineCall.call.status = 'active';
          this.stop_hold_timer(lineCall.call.id);

          // удаляю из локального хранилища, что вызов на удержании, если он был на удержании
          if (localStorage && 'calls_on_hold' in localStorage) {
            let calls_on_hold = JSON.parse(localStorage.getItem('calls_on_hold'));
            if (lineCall.call.id in calls_on_hold) {
              delete calls_on_hold[lineCall.call.id];
              localStorage.setItem('calls_on_hold', JSON.stringify(calls_on_hold));
            }
          }
          this.refreshCTICalls();
          this.refreshLocalStorageCalls();
        } else {
          // если удержание было установлено системой, то вызов не снимаю с удержания, т.к. пользователь сам должен его снять,
          // однако, меняю тип удержания на "line", чтобы при бегании по вкладкам, этот вызов не оставался на постоянном удержании
          if (calls_on_hold[lineCall.call.id] == 'system') {
            calls_on_hold[lineCall.call.id] = 'line';
            localStorage.setItem('calls_on_hold', JSON.stringify(calls_on_hold));
          } else if (calls_on_hold[lineCall.call.id] == 'self' && lineCall.call.status != 'held') {
            this.toggleHold(lineCall);
          }
          this.start_hold_timer(lineCall.call.id);
        }
      }
    }

    this.ctiUpdate$.next(null);
  }

  start_timer(callID: string) {
    if (callID) {
      let dt_start = new Date(), time_delta = 0, timer_data = {call_time_start: new Date(), timer: null, time_str: '00:00'};
      this.timers[callID] = timer_data;

      if (localStorage) {
        let calls_dt_start = 'calls_dt_start' in localStorage ? JSON.parse(localStorage.getItem('calls_dt_start')) : {};
        if (callID in calls_dt_start) {
          dt_start = new Date(calls_dt_start[callID]);
        } else {
          dt_start = new Date();
          calls_dt_start[callID] = dt_start;
          localStorage.setItem('calls_dt_start', JSON.stringify(calls_dt_start));
        }
      }

      this.timers[callID].call_time_start = dt_start;

      timer_data.timer = setInterval(() => {
        if (callID && this.timers[callID]) {

          this.timers[callID]['time_str'] = new Date(new Date().getTime() - this.timers[callID].call_time_start.getTime())
            .toTimeString().replace(/.*\d{2}:(\d{2}:\d{2}).*/, '$1');
          this.ctiUpdate$.next(null);
        }
      }, 1000);
    }

    this.ctiUpdate$.next(null);
  }

  stop_timer(callID) {
    if (this.timers[callID]) {
      clearInterval(this.timers[callID].timer);
      delete this.timers[callID];
      if (localStorage && !(this.punt || this.needReconnect)) {
        let calls_dt_start = 'calls_dt_start' in localStorage ? JSON.parse(localStorage.getItem('calls_dt_start')) : {};
        if (callID in calls_dt_start) {
          delete calls_dt_start[callID];
          localStorage.setItem('calls_dt_start', JSON.stringify(calls_dt_start));
        }

        let calls_on_hold = 'calls_on_hold' in localStorage ? JSON.parse(localStorage.getItem('calls_on_hold')) : {};
        if (callID in calls_on_hold) {
          delete calls_on_hold[callID];
          localStorage.setItem('calls_on_hold', JSON.stringify(calls_on_hold));
        }
      }
    }

    this.ctiUpdate$.next(null);
  }

  start_hold_timer(callID: string) {
    if (callID) {
      this.timers[callID]['hold_time_str'] = '00:00';

      let dt_start = new Date();
      this.timers[callID].hold_time_start = dt_start;

      if (localStorage) {
        let calls_hold_start = 'calls_hold_start' in localStorage ? JSON.parse(localStorage.getItem('calls_hold_start')) : {};
        if (callID in calls_hold_start) {
          dt_start = new Date(calls_hold_start[callID]);
        } else {
          dt_start = new Date();
          calls_hold_start[callID] = dt_start;
          localStorage.setItem('calls_hold_start', JSON.stringify(calls_hold_start));
        }
      }

      this.timers[callID].hold_time_start = dt_start;

      this.timers[callID].hold_timer = setInterval(() => {
        if (callID && this.timers[callID]) {
          this.timers[callID]['hold_time_str'] = new Date(new Date().getTime() - this.timers[callID].hold_time_start.getTime())
            .toTimeString().replace(/.*\d{2}:(\d{2}:\d{2}).*/, '$1');
          this.ctiUpdate$.next(null);
        }
      }, 1000);
    }

    this.ctiUpdate$.next(null);
  }

  stop_hold_timer(callID) {
    if (this.timers[callID]?.hold_timer) {
      clearInterval(this.timers[callID].hold_timer);
      delete this.timers[callID].hold_timer;
      delete this.timers[callID].hold_time_str;

      if (localStorage && !(this.punt || this.needReconnect)) {
        let calls_hold_start = 'calls_hold_start' in localStorage ? JSON.parse(localStorage.getItem('calls_hold_start')) : {};
        if (callID in calls_hold_start) {
          delete calls_hold_start[callID];
          localStorage.setItem('calls_hold_start', JSON.stringify(calls_hold_start));
        }
      }

      this.ctiUpdate$.next(null);
    }
  }

  startAgentStatusTimer(state_time: number = 0) {
    this.stopAgentStatusTimer();

    let timer_data = {
      time_start: state_time ? new Date(new Date().getTime() - (Math.floor(state_time) * 1000)) : new Date(), // корректирую дату начала статуса
      timer: null,
      time_str: '00:00'
    };

    let time_str: number|string = Math.floor((new Date().getTime() - timer_data.time_start.getTime())/1000);
    time_str = this.toHHMMSS(time_str) as string;
    time_str = time_str.startsWith('00:') ? time_str.replace(/.*\d{2}:(\d{2}:\d{2}).*/, '$1') : time_str.replace(/(.*\d{2}:\d{2}:\d{2}).*/, '$1');
    timer_data['time_str'] = time_str;

    this.timers['agentStatusTimer'] = timer_data;

    timer_data.timer = setInterval(() => {
      let timer = this.timers['agentStatusTimer'];
      if (timer) {
        let time_str: number|string = Math.floor((new Date().getTime() - timer.time_start.getTime())/1000);
        time_str = this.toHHMMSS(time_str) as string;
        time_str = time_str.startsWith('00:') ? time_str.replace(/.*\d{2}:(\d{2}:\d{2}).*/, '$1') : time_str.replace(/(.*\d{2}:\d{2}:\d{2}).*/, '$1');
        timer['time_str'] = time_str;
        this.ctiUpdate$.next(null);
      }
    }, 1000);

    this.ctiUpdate$.next(null);
  }

  stopAgentStatusTimer() {
    if (this.timers['agentStatusTimer']) {
      if (this.timers['agentStatusTimer']?.timer) clearInterval(this.timers['agentStatusTimer'].timer);
      delete this.timers['agentStatusTimer'];
    }
  }

  startPostProcessingTimer(restart: boolean = false) {
    let timer_data = {dt_start: null, time_start: this.wrap_up_time * 1000, timer: null, time_str: ''};

    if (localStorage) {
      let ctiTimers = 'ctiTimers' in localStorage ? JSON.parse(localStorage.getItem('ctiTimers')) : {};
      if ('postProcessingTimer' in ctiTimers) {
        timer_data.dt_start = new Date(ctiTimers['postProcessingTimer'].dt_start);
      } else if (!restart) {
        timer_data.dt_start = new Date();
        ctiTimers['postProcessingTimer'] = timer_data;
        localStorage.setItem('ctiTimers', JSON.stringify(ctiTimers));
      }
    }

    let dt_diff = timer_data.dt_start ? Math.floor((new Date().getTime() - timer_data.dt_start.getTime()) / 1000) : null;
    if ((dt_diff && dt_diff < this.wrap_up_time) || !restart) {
      timer_data.time_start = (this.wrap_up_time - dt_diff) * 1000;
      timer_data.time_str = new Date(timer_data.time_start).toISOString().slice(14, 19);
      this.timers['postProcessingTimer'] = timer_data;

      timer_data.timer = setInterval(() => {
        let timer = this.timers['postProcessingTimer'];
        if (timer) {
          timer['time_start'] -= 1000;
          timer['time_str'] = new Date(timer.time_start).toTimeString().replace(/.*\d{2}:(\d{2}:\d{2}).*/, '$1');
          if (timer['time_start'] == 0) this.stopPostProcessingTimer();
          this.ctiUpdate$.next(null);
        }
      }, 1000);
    } else this.stopPostProcessingTimer();

    this.ctiUpdate$.next(null);
  }

  stopPostProcessingTimer(break_timer: boolean = false) {
    if (this.timers['postProcessingTimer']) {
      if (this.timers['postProcessingTimer']?.timer) clearInterval(this.timers['postProcessingTimer'].timer);
      delete this.timers['postProcessingTimer'];
    }

    if (localStorage) {
      let ctiTimers = 'ctiTimers' in localStorage ? JSON.parse(localStorage.getItem('ctiTimers')) : {};
      delete ctiTimers['postProcessingTimer'];
      localStorage.setItem('ctiTimers', JSON.stringify(ctiTimers));
    }

    if (break_timer) {
      this.domainUserService.action('wrap_up_time_reset', {uid: this.mynumber}).subscribe(
        data => {},
        resp => this.notifyService.setFormErrors(resp)
      );
    }
  }

  isCallChanged(index, item) {
    return item.call?.id || !!item.call?.track || item?.call?.track?.enabled || !!item.call?.stream;
  }

  async screenCall() {

  }
  getCallByType(direction: number) { // 1 - входящий, 0 - исходящий
    return this.lines_calls.find(call => call.call.direction == direction) || {};
  }
  refreshLocalStorageCalls(){
    let cachedCalls = localStorage.getItem('cachedCall') ? JSON.parse(localStorage.getItem('cachedCall')) : [];
    // запоминаю данные всех или конкретного вызова, чтобы их достать при аттаче
    localStorage.setItem(
      'cachedCall',
      JSON.stringify(
        this.lines_calls.filter(c => c.call?.status != 'ringing').map(c => ({
            ...c.call,
            video: c.video,
            ...(
              c.transfering && c.transfering.call ? {
                transfering: {
                  number: c.transfering.number,
                  call: c.transfering.call,
                  isBlind: c.transfering.isBlind
                }
              } : {}
            )
          })
        )
      )
    );
  }

  setPhoneUserState(state) {
    if (state == 'online') {
      if (this.punt) this.punt = false; // убираю событие разрегистрации из-за регистрации на другой вкладке (оно тут не актуально)

      // проверяю, что при нажатии на кнопку "Включить" нет другой вкладки
      // с включенным телефоном и вызовами в нем, чтобы их забрать себе сюда
      if (!this.verto.isLogged()) this.checkSessionExists(true);
      else if (this.autocall) state = 'autoAnswer';
    } else if (state == 'offline') {
      if (localStorage) {
        // удаляю из локального хранилища вызовы на удержании
        if ('calls_on_hold' in localStorage) localStorage.removeItem('calls_on_hold');
        // удаляю из локального хранилища время начала вызовов
        if ('calls_dt_start' in localStorage) localStorage.removeItem('calls_dt_start');
        // удаляю данные вызовов для attach
        if ('cachedCall' in localStorage) localStorage.removeItem('cachedCall');

        // if (localStorage) localStorage.removeItem('agent_status_on_outbound_call_prev');
      }
      this.onLogout();
    } else if (state.startsWith('autoAnswer')) {
      this.autocall = state == 'autoAnswer';
      this.changeAudioSettings();
      if (state == 'autoAnswerOff') state = 'online';
    } else if (state == 'dndOff') {
      state = this.autocall ? 'autoAnswer' : 'online';
    }

    this.ctiState$.next(state);


    // убираю ДНД при любом другом статусе, включая "Выключение телефона", чтобы при его включении не было этого статуса,
    // а вот автоответ при этом не трогаю, т.е. если выключить и включить телефон, то он будет в статусе Автоответ,
    // если он был выбран до отключения.

    if ((this.ctiState$.value != 'dnd' && this.dnd) || (this.ctiState$.value == 'dnd' && !this.dnd)) {
      this.dnd = this.ctiState$.value == 'dnd';
      if (this.dnd && this.getAgentStatusById(this.agentStatus.value).base_status_id != -3) {
        this.agentStatus.setValue(-3, {emitEvent: true});
        if (this.debug) console.log('DND: смена статуса агента на ' + this.agentStatus.value);
        this.changeAgentStatus(-3);
      }
      if (localStorage) {
        let ctiSettings = localStorage.getItem('ctiSettings') ? JSON.parse(localStorage.getItem('ctiSettings')) : {};
        ctiSettings['dnd'] = this.dnd;
        localStorage.setItem('ctiSettings', JSON.stringify(ctiSettings));
      }
    }
    this.ctiUpdate$.next(null);
  }

  changeAgentStatus(value: any, by: number = 0) {
    // by: 0 - агентом, 1 - автоматически
    if (by == 0) {
      this.checkAgentStatusAfterUpdate = false;
      if (this.debug) console.log('Отправка запроса оператором на изменение статуса... \r\n\tПри этом флаг проверки и предыдущее значение статуса при автоматической смене убраны.');
      // этот статус будет выставлен при завершении всех вызовов и чатов, запоминаю его, при смене оператором и при входе в кабинет
      this.du_agent_status_on_outbound_call_prev = value;
      if (localStorage) localStorage.setItem('agent_status_on_outbound_call_prev', this.du_agent_status_on_outbound_call_prev.toString());
    } else if (this.debug) {
      this.checkAgentStatusAfterUpdate = false;
      console.log('Отправка запроса автоматически на изменение статуса агента...');
    }

    this.isStatusChanging = true;
    this.ctiUpdate$.next(null);
    this.userService.authenticationService.is_token_alive().subscribe(() => {}); // на всякий случай, чтобы либо сработала переадрессация на страницу входа в кабинет, либо продлилась сессия
    this.verto.agent_change_status_verto({status_id: value}).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      ok => {
        // запоминаю новое значение как предыдущее в случае неуспеха в следующий раз
        this.agentStatusPrev = value;
        this.startAgentStatusTimer(); // устанавливаю таймер с новым статусом агента
        if (this.debug) console.log(`\tТекущий статус: ${this.agentStatus.value}, Устанавливаемый: ${value}`);

        if (by == 0) {
          this.isStatusChanging = false;
          if (this.debug) console.log('\tЗапрос изменения статуса агента оператором завершен.');
        } else if (by == 1) {
          if (this.debug) console.log('\tЗапрос изменения статуса агента автоматически завершен.');
          if (this.debug) console.log('%c\tНеобходимо проверить статус снова:'+(this.checkAgentStatusAfterUpdate ? 'да' : 'нет'), 'background: lightblue; font-size: 20px');
          if (this.checkAgentStatusAfterUpdate) {
            this.isStatusChanging = false;
            this.checkAndUpdateAgentStatus();
          } else if (this.agentStatus.value != value) {
            if (this.debug) console.log(`\tЗапрос статуса агента из-за несоответствия текущего и устанавливаемого (${this.agentStatus.value} и ${value}) значений...`);
            this.verto.get_agent_status_verto().pipe(take(1)).toPromise().then(
              data => {
                if (this.debug) console.log(`\t\tЗапрос статуса агента из-за несоответствия завершен.`);
                if (data) {
                  this.agentStatusPrev = data;
                  if (this.agentStatus.value != data) {
                    this.agentStatus.setValue(data, {emitEvent: true});
                    if (this.debug) console.log(`\t\tНовый статус агента: ${this.agentStatus.value}.`);
                    this.startAgentStatusTimer();
                  } else if (this.debug) console.log(`\t\tСтатус не поменялся в итоге: ${this.agentStatus.value}.`);
                }
                this.isStatusChanging = false;

                if (this.debug) console.log(`\t\tЗапрос статуса агента из-за несоответствия: обновление статуса агента по флагу...`);
                if (this.checkAgentStatusAfterUpdate) this.checkAndUpdateAgentStatus();
                this.ctiUpdate$.next(null);
              }
            ).catch(resp => {
              this.isStatusChanging = false;
              this.agentStatus.setValue(null, {emitEvent: true});
              if (this.debug) console.log('\t\tзапрос из-за несоответствия завершен неудачно. Статус агента: ' + this.agentStatus.value);
              this.startAgentStatusTimer();
              this.ctiUpdate$.next(null);
            });
          } else {
            this.isStatusChanging = false;
            if (this.debug) console.log('\tзапрос автоматической смены завершен успешно. Статус агента: ' + this.agentStatus.value);
          }
        }

        this.ctiUpdate$.next(null);
      },
      error => {
        this.agentStatus.setValue(by == 0 ? this.agentStatusPrev : this.du_agent_status_on_outbound_call_prev, {emitEvent: true});
        if (!error?.notifies?.length || (error.notifies[0].msg_id != 10002)) this.notifyService.message(error?.message ? error.message : error);
        this.isStatusChanging = false;
        if (this.debug) console.log('\tзапрос завершен неудачно. Статус агента: ' + this.agentStatus.value);
        this.ctiUpdate$.next(null);
      }
    );
  }

  checkAndUpdateAgentStatus() {
    let is_direct_uuid_reqs = [];
    // Собираю запросы на проверку прямые из очереди эти входящие вызовы.
    for (let call of this.lines_calls) {
      if (call.call?.direction == 1) {
        is_direct_uuid_reqs.push(
          call.call.is_direct == null ?
            this.domainCRMService.is_callcenter_uuid({uuid: call.call.id}).pipe(
              map(data => {
                call.call.is_direct = !data.body;
                return call.call.is_direct;
              })
            ) :
            observableOf(call.call.is_direct)
        );
      }
    }

    if (this.debug) console.log(
      'Проверка и изменение статуса агента. Показатели: \r\n',
      '\tколичество вызовов: ', this.lines_calls.length || this.outbound_calls.length, '\r\n',
      '\tколичество входящих вызовов, проверяемых из очереди они или прямые: ',is_direct_uuid_reqs.length, '\r\n',
      '\tколичество ОМНИ диалогов: '+this.chatEventDialogs$.value, '\r\n',
      '\tколичество ВНУТРЕННИХ диалогов: '+this.innerChatEventDialogs$.value, '\r\n',
      '\tстатус агента: ', this.agentStatus.value, '\r\n',
      '\tменяется ли при этом статус агента: ', this.isStatusChanging ? 'да' : 'нет', '\r\n'
    );

    (
      is_direct_uuid_reqs.length == 0 ?
        observableOf(false) :
        forkJoin(is_direct_uuid_reqs).pipe(
          map(results => results.some(v => v)) // возвращаю true, если есть хоть один прямой вызов
        )
    ).subscribe(
      is_call_direct => { // is_call_direct = true, если есть хоть один прямой вызов
        if (
          // если установлен статус, отличающийся от предыдущего и нет:
          //  - чатов и
          //  - нет статуса du_agent_status_on_outbound_call или исх. вызова на первой линии и
          //  - нет статуса du_agent_status_on_inbound_call или нет входящих !ПРЯМЫХ!,
          //  то устанавливаю статус на тот, что был до изменения по какой-либо причине
          this.outbound_calls.length == 0 && (this.du_agent_status_on_outbound_call == null || this.lines_calls.length == 0 || this.lines_calls[0].call?.direction != 0) &&
          !is_call_direct && // его не будет, если нет статуса в настройках
          (this.chatEventDialogs$.value == 0 || !this.showChat()) && // нет чатов по правам => тогда количество = null
          this.du_agent_status_on_outbound_call_prev != null
          // this.getAgentStatusById(this.agentStatus.value).base_status_id != -1
        ) {
          if (this.agentStatus.value != this.du_agent_status_on_outbound_call_prev) {
            this.agentStatus.setValue(this.du_agent_status_on_outbound_call_prev, {emitEvent: true});
            if (this.debug) console.log('\tустанавливаю статус в тот, что был до изменения по какой-либо причине: ' + this.agentStatus.value);
            this.changeAgentStatus(this.du_agent_status_on_outbound_call_prev, 1);
          }

        } else if ( // если есть "Статус оператора при принятии диалога" и есть чат, то меняю статус оператора на "Статус оператора при принятии диалога"
          this.du_agent_status_on_chat != null &&
          this.chatEventDialogs$.value > 0
        ) {
          if (this.agentStatus.value != this.du_agent_status_on_chat) {
            // // сохраняю предыдущий статус оператора (до вызовов и чатов), если нет вызовов и чатов, которые меняют статус
            // if (
            //   !(this.du_agent_status_on_outbound_call != null && this.lines_calls.length && (this.lines_calls[0].call?.direction == 0)) && // не задан статус или нет исх. на первой линии
            //   !(this.du_agent_status_on_inbound_call != null && is_call_direct) // не задан статус или нет прямых вызовов
            // ) {
            //   this.du_agent_status_on_outbound_call_prev = this.agentStatus.value;
            //   if (localStorage) localStorage.setItem('agent_status_on_outbound_call_prev', this.du_agent_status_on_outbound_call_prev.toString());
            // }

            this.agentStatus.setValue(this.du_agent_status_on_chat, {emitEvent: true});
            if (this.debug) console.log('\tустанавливаю статус в "Статус оператора при принятии диалога": ' + this.agentStatus.value);
            this.changeAgentStatus(this.du_agent_status_on_chat, 1);
          }

        } else if ( // если есть исх. вызов на первой линии, меняю статус на "Статус оператора на первой линии при исходящем вызове"
          this.du_agent_status_on_outbound_call != null &&
          this.lines_calls.length != 0 && this.lines_calls[0].call?.direction == 0
        ) {
          if (this.agentStatus.value != this.du_agent_status_on_outbound_call) {
            // // сохраняю предыдущий статус оператора (до вызовов и чатов), если нет вызовов и чатов, которые меняют статус
            // if (
            //   !(this.du_agent_status_on_chat != null && this.chatEventDialogs$.value) && // не задан статус или нет чатов
            //   !(this.du_agent_status_on_inbound_call != null && is_call_direct) // не задан статус или нет прямых вызовов
            // ) {
            //   this.du_agent_status_on_outbound_call_prev = this.agentStatus.value;
            //   if (localStorage) localStorage.setItem('agent_status_on_outbound_call_prev', this.du_agent_status_on_outbound_call_prev.toString());
            // }

            this.agentStatus.setValue(this.du_agent_status_on_outbound_call, {emitEvent: true});
            if (this.debug) console.log('\tустанавливаю статус в "Статус оператора на первой линии при исходящем вызове": ' + this.agentStatus.value);
            this.changeAgentStatus(this.du_agent_status_on_outbound_call, 1);
          }

        } else if ( // если есть входящий прямой вызов, меняю статус на "Статус оператора при прямом входящем вызове"
          this.du_agent_status_on_inbound_call != null &&
          is_call_direct // есть прямой входящий вызов
        ) {
          if (this.agentStatus.value != this.du_agent_status_on_inbound_call) {
            // // сохраняю предыдущий статус оператора (до вызовов и чатов), если нет вызовов и чатов, которые меняют статус
            // if (
            //   !(this.du_agent_status_on_chat != null && this.chatEventDialogs$.value) && // не задан статус или нет чатов
            //   !(this.du_agent_status_on_outbound_call != null && this.lines_calls.length && (this.lines_calls[0].call?.direction == 0)) // не задан статус или нет исх. на первой линии
            // ) {
            //   this.du_agent_status_on_outbound_call_prev = this.agentStatus.value;
            //   if (localStorage) localStorage.setItem('agent_status_on_outbound_call_prev', this.du_agent_status_on_outbound_call_prev.toString());
            // }

            this.agentStatus.setValue(this.du_agent_status_on_inbound_call, {emitEvent: true});
            if (this.debug) console.log('\tустанавливаю статус в "Статус оператора при прямом входящем вызове": ' + this.agentStatus.value);
            this.changeAgentStatus(this.du_agent_status_on_inbound_call, 1);
          }
        }
      }
    );
  }

  changeOmniAgentStatus(value: any, by: number = 0) {
    // by: 0 - агентом, 1 - автоматически
    if (by == 0) {
      this.checkOmniAgentStatusAfterUpdate = false;
      this.omni_agent_status_on_call_prev = null;
      if (localStorage) localStorage.removeItem('omni_agent_status_on_call_prev');
      if (this.chatEventDialogs$.value > 0 && this.getAgentStatusById(value, true)?.base_status_id != -1) {
        this.notifyService.message('DOMAINOMNI.ON_AGENT_BREAK');
      }
    }
    this.isOmniStatusChanging = true;
    this.ctiUpdate$.next(null);
    this.userService.authenticationService.is_token_alive().subscribe(() => {}); // на всякий случай, чтобы либо сработала переадрессация на страницу входа в кабинет, либо продлилась сессия
    this.docAgentService.agentStatusSwitch(this.userService.authenticationService.getUserId(), this.omniAgent.value, {__ignore__: {10002: true}}).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      ok => {
        // запоминаю новое значение как предыдущее в случае неуспеха в следующий раз
        this.omniAgentStatusPrev = value;

        if (by == 0) {
          this.isOmniStatusChanging = false;
        } else if (by == 1) {
          if (this.checkOmniAgentStatusAfterUpdate) {
            this.isOmniStatusChanging = false;
            this.checkAndUpdateOmniAgentStatus();
          } else if (this.omniAgent.value != value) {
            this.docAgentService.get(this.userService.authenticationService.getUserId(), {__ignore__: {10002: true}}).subscribe(
              data => {
                if (data != null) {
                  this.omniAgent.setValue(data['status_id']);
                  this.omniAgentStatusPrev = data['status_id'];
                }
                this.isOmniStatusChanging = false;
                this.ctiUpdate$.next(null);
              },
              resp => {
                this.isOmniStatusChanging = false;
                this.omniAgent.setValue(null);
                this.ctiUpdate$.next(null);
              }
            );
          } else {
            this.isOmniStatusChanging = false;
          }
        }

        this.ctiUpdate$.next(null);
      },
      error => {
        this.omniAgent.setValue(by == 0 ? this.omniAgentStatusPrev : this.omni_agent_status_on_call_prev, {emitEvent: false});
        if (!error?.notifies?.length || (error.notifies[0].msg_id != 10002)) this.notifyService.message(error?.message ? error.message : error);
        this.isOmniStatusChanging = false;
        this.ctiUpdate$.next(null);
      }
    );
  }

  checkAndUpdateOmniAgentStatus() {
    if ( // если нет вызовов (входящих !отвеченных или любых исходящих), то меняю "перерывный" статус на "готовый"
      this.outbound_calls.length == 0 &&
      this.lines_calls.filter(call => call.call?.status != 'ringing' || call.call.direction == 0).length == 0 &&
      this.omni_agent_status_on_call_prev != null &&
      // this.getAgentStatusById(this.omniAgent.value, true).base_status_id != -1 &&
      this.omniAgent.value != this.omni_agent_status_on_call_prev
    ) {
      this.omniAgent.setValue(this.omni_agent_status_on_call_prev);
      this.changeOmniAgentStatus(this.omni_agent_status_on_call_prev, 1);
    } else if ( // если есть вх. отвеченный или исх. вызовы, то меняю "готовый" статус на перерывный
      this.lines_calls.filter(call => call.call?.status != 'ringing' || call.call.direction == 0).length > 0 &&
      this.omni_agent_status_on_call != null &&
      this.omniAgent.value != this.omni_agent_status_on_call
    ) {
      // if (this.getAgentStatusById(this.omniAgent.value, true).base_status_id == -1) {
        this.omni_agent_status_on_call_prev = this.omniAgent.value;
        if (localStorage) localStorage.setItem('omni_agent_status_on_call_prev', this.omni_agent_status_on_call_prev.toString());
      // }
      this.omniAgent.setValue(this.omni_agent_status_on_call);
      this.changeOmniAgentStatus(this.omni_agent_status_on_call, 1);
    }
  }

  getAgentStatusById(status: number, is_omni: boolean = false) {
    let st = (is_omni ? this.getOmniAgentStatuses() : this.getAgentStatuses()).find(item=>item.id === status);
    if (st && !st.base_status_id) st.base_status_id = st.id;
    return st || {};
  }

  getAgentStatuses() {
    return this.agent_status_list.concat(AGENT_STATUS_LIST.filter(s => s.type === 'user'));
  }

  getOmniAgentStatuses() {
    return this.omni_agent_status_list.concat(AGENT_STATUS_LIST.filter(s => s.type === 'user'));
  }

  checkUserInUnit(status_units: number[] = []) {
    return !status_units.length || this.org_unit_and_position_list.some(user_unit => status_units.indexOf(user_unit.unit_id) != -1);
  }

  clearEntityCounters() {
    this.domain_users = [];
    this.domain_queues = [];
    this.domain_dialplans = [];
    this.domain_address_book_groups = [];
    this.domain_address_books = [];
    this.curr_count_domain_users = 0;
    this.count_domain_users = null;
    this.count_domain_queues = null;
    this.count_domain_dialplans = null;
    this.count_domain_address_book_groups = null;
    this.count_domain_address_books = null;
    this.active_address_book_group = null;
  }

  showChat() {
    if (this.userService.authenticationService.isLic('Built-inCustomerChat') && (
      this.userService.authenticationService.hasPerm('DOCDialog', PERM_DLIST_LIST, true) ||
      this.userService.authenticationService.hasPerm('DOCDialogMessage', PERM_DLIST_LIST, true)
    )) {
      return true;
    } else if (this.userService.authenticationService.isLic('Built-inChat') && (
      this.userService.authenticationService.hasPerm('DICPersonal', PERM_DLIST_LIST, true) ||
      this.userService.authenticationService.hasPerm('DICPersonalMessage', PERM_DLIST_LIST, true) ||
      this.userService.authenticationService.hasPerm('DICGroup', PERM_DLIST_LIST, true) ||
      this.userService.authenticationService.hasPerm('DICGroupMessage', PERM_DLIST_LIST, true)
    )) {
      return true;
    } else {
      return false;
    }
  }

  getLastCalls() {
    this.last_calls = [];
    this.verto.get_call_list_verto().subscribe(
      data => {
        if (data?.list?.length > 0) {
          for (let c of data?.list) {
            let call: any = {direction: c.direction, duration: c.duration};
            if (c.direction == 1) {
              call.number = c.caller_id_number;
              call.icon = 'inbound_call';
            } else {
              call.number = c.callee_id_number;
              call.icon = 'outbound_call';
            }
            call.color = c.duration > 0 ? 'green' : 'red';
            this.last_calls.push(call);
          }
        }
      },
      resp => this.notifyService.setFormErrors(resp)
    );
  }

  // Блок управления звонками для отчета под Рабочий стол

  get_last_calls_report() {
    if (localStorage && localStorage.getItem('last_calls_for_report')) {
      this.last_calls_for_report = JSON.parse(localStorage.getItem('last_calls_for_report')) || [];
    }
  }

  delete_call_report(index: number) {
    this.last_calls_for_report.splice(index, 1);
    this.add_calls_for_report();
  }

  add_calls_for_report(obj: LastCallsReport = null, limit: number = 25){
    if (obj) { // добавляю номер в список 25 номеров, на которые звонили

        this.last_calls_for_report.unshift(obj);
        if (this.last_calls_for_report.length > limit) this.last_calls_for_report.splice(limit);
        localStorage.setItem('last_calls_for_report', JSON.stringify(this.last_calls_for_report));

    } else {
      localStorage.setItem('last_calls_for_report', JSON.stringify(this.last_calls_for_report));
    }
  }

  update_call_for_report(uuid: string, obj: object) {
    let call_by_index = this.last_calls_for_report.findIndex(call => uuid && call.uuid == uuid);
    if (call_by_index>-1) {
      Object.keys(obj).forEach(item=>{
          this.last_calls_for_report[call_by_index][item] = obj[item];
      });
      this.add_calls_for_report();

    }
  }

  // Конец блока управления звонками для отчета под Рабочий стол



  get_last_calls_from_ls() {
    if (localStorage && localStorage.getItem('cti_last_calls')) {
      this.last_calls_from_ls = JSON.parse(localStorage.getItem('cti_last_calls')) || [];
    }
  }

  delete_call_from_last(index: number) {
    this.last_calls_from_ls.splice(index, 1);
    this.update_last_calls();
  }

  update_last_calls(number: string = null, name: string = null, direction: number = null){
    if (number) { // добавляю номер в список 10 номеров, на которые звонили
      let indexInLsLastCalls = this.last_calls_from_ls.findIndex(call => call.number.includes(number));
      if (indexInLsLastCalls == -1) { // его не было в списке, поэтому просто добавляю его в начало списка
        this.last_calls_from_ls.unshift({number: number, name: name, direction: direction});
        if (this.last_calls_from_ls.length > 10) this.last_calls_from_ls.splice(10);
        localStorage.setItem('cti_last_calls', JSON.stringify(this.last_calls_from_ls));
      } else if (indexInLsLastCalls != 0) { // номер был в списке, переношу его в начало
        this.last_calls_from_ls.splice(indexInLsLastCalls, 1);
        this.last_calls_from_ls.unshift({number: number, name: name, direction: direction});
        localStorage.setItem('cti_last_calls', JSON.stringify(this.last_calls_from_ls));
      } else if (
        this.last_calls_from_ls[indexInLsLastCalls].name != name || this.last_calls_from_ls[indexInLsLastCalls].direction != direction
      ) { // иначе номер уже в начале списка, но могло поменяться направление вызова или имя
        this.last_calls_from_ls[indexInLsLastCalls] = {number: number, name: name, direction: direction};
        localStorage.setItem('cti_last_calls', JSON.stringify(this.last_calls_from_ls));
      }
    } else {
      localStorage.setItem('cti_last_calls', JSON.stringify(this.last_calls_from_ls));
    }
  }

  getChildBook(ind: number) {
    let parent  = {...this.active_address_book_group, groups: [], numbers: [], count_groups: null, count_numbers: null, loading: false};
    this.active_address_book_group = this.active_address_book_group.groups[ind];
    this.active_address_book_group.parent = parent;
    this.loadAddressBookGroupData();
  }

  loadAddressBookGroupData() {
    this.active_address_book_group.loading = true;
    (
      this.active_address_book_group.count_groups == null ||
      (this.active_address_book_group.count_groups > this.active_address_book_group.groups.length) ?
        (this.addressBookGroupService.list({
          sort: {name: '+'},
          limit: 100,
          offset: this.active_address_book_group.groups.length,
          filter: {field_list: [{field: 'parent_id', value: this.active_address_book_group.id, condition_type: 0}], type: 1}
        }, 'select') as Observable<IResponseListObject<any>>) :

        observableOf({} as IResponseListObject<any>)
    ).pipe(
      catchError(resp => observableOf({} as IResponseListObject<any>)),
      map(data => {
        if (data?.total_count != null) this.active_address_book_group.count_groups = data.total_count;
        if (data?.list?.length > 0) {
          for (let book_group of data.list) {
            this.active_address_book_group.groups.push({...book_group, groups: [], numbers: [], count_groups: null, count_numbers: null, loading: false});
          }
        }

        return data;
      }),
      concatMap(data => {
        return this.active_address_book_group.count_groups == this.active_address_book_group.groups.length || (this.active_address_book_group.count_numbers == null) ?
          this.addressBookService.list(
            {
              sort: {name: '+'},
              limit: 100,
              offset: this.active_address_book_group.numbers.length,
              filter: {field_list: [{field: 'group_id', value: this.active_address_book_group.id, condition_type: 0}], type: 1}
            }, 'select_with_detail'
          ).pipe(
            map(data2 => {
              if (data2?.total_count != null) this.active_address_book_group.count_numbers = data2.total_count;
              if (data2.list?.length > 0) {
                for (let contact of data2.list) {
                  this.active_address_book_group.numbers.push(contact);
                }
              }
              return data;
            })
          ) : observableOf(data);
      })
    ).subscribe(
      data => this.active_address_book_group.loading = false,
      resp => this.active_address_book_group.loading = false
    );
  }

  getEntityFilter(filterValue, entityType){
    let filter_reqs = [];
    if (
      filterValue ||
      (
        filterValue == '' &&  /*т.к. м.б. null для сработки триггера изменения поиска, чтобы отобразились очереди при смене типа поиска*/
        entityType < 3
      )
    ) { // добавила проверку, что если поиск по всем или по очередям, то если не введено в поиск ничего, взять первые 5 очередей
      switch (entityType) {
        case -1: filter_reqs = [observableOf(null)]; break; // здесь по-любому брейк, т.к. дальше опция "Прямой набор", она не включена в список "Поиск по всем"
        case 0:
        case 1:
          // если окно открыто и фильтр не задан и есть избранные сотрудники, то гружу их с лимитом, установленным в Профиле сотрудника,
          // иначе проверяю наличие фильтра
          if (filterValue || this.favorite_settings?.favorite_users?.length > 0) {
            if (this.entityTypes.indexOf(1) != -1) {
              if (this.count_domain_users == null || this.count_domain_users > this.curr_count_domain_users) {
                filter_reqs.push(
                  (
                    filterValue ?
                      this.userService.list({
                        sort: {name: '+', surname: '+'},
                        limit: 10,
                        offset: this.curr_count_domain_users,
                        filter: {
                          field_list: [
                            {field: 'domain_user.user_id.uid', condition_type: 3, value: filterValue},
                            {field: 'name', condition_type: 3, value: filterValue},
                            {field: 'surname', condition_type: 3, value: filterValue}
                          ],
                          type: 1
                        },
                        with_share: true,
                      }, 'select_with_detail') as Observable<IResponseListObject<any>> :
                      this.domainUserService.list({ // сначала гружу из списка избранные внутренние номера
                        limit: this.favorite_settings.favorite_limit, // для избранных лимит устанавливается в настройках Профиля сотрудника
                        offset: this.curr_count_domain_users,
                        filter: {
                          field_list: [
                            {field: 'id', value: this.favorite_settings.favorite_users, condition_type: 9}
                          ],
                          type: 0
                        },
                        sort: {id: this.favorite_settings.favorite_users},
                        with_share: true
                      }, 'select').pipe(
                        concatMap(domain_users => { // затем гружу их сотрудников, чтобы получить данные о регистрации
                          // сразу сотрудников не гружу, чтобы не поломать последовательность внутренних номеров в таблице избранных
                          let pbx_user_ids = this.domain_users.map(v => v.user_id);
                          let pbx_user_ids_to_load = domain_users.list.map(v => v.user_id).filter(v => pbx_user_ids.indexOf(v) == -1);
                          let loaded_pbx_users = this.domain_users.map(v => v.user);
                          return (
                            pbx_user_ids_to_load.length > 0 ?
                              this.userService.list({
                                filter: {
                                  field_list: [{field: 'id', value: pbx_user_ids_to_load, condition_type: 9}],
                                  type: 0
                                }
                              }, 'select_with_detail') :
                              observableOf({list: loaded_pbx_users})
                          ).pipe(
                            map(pbx_users_data => {
                              console.log('%cdomain_users:', 'color: lightblue; font_size: 20px;', domain_users);
                              let pbx_users = [];
                              let favorite_user_ids = this.favorite_settings?.favorite_users.slice(this.curr_count_domain_users, this.curr_count_domain_users+this.favorite_settings.favorite_limit);
                              domain_users.list = domain_users.list.sort((a, b) => favorite_user_ids.indexOf(a.id) - favorite_user_ids.indexOf(b.id));

                              for (let domain_user of domain_users.list) {
                                let pbx_user = pbx_users_data.list.find(v => v.id == domain_user.user_id);
                                if (!pbx_user) pbx_user = {
                                  ...this.domain_users.find(v => v.user_id == domain_user.user_id).user,
                                  favorite_numbers: []
                                };
                                else pbx_user = {...pbx_user, favorite_numbers: []};
                                pbx_user['favorite_numbers'] = [domain_user.uid];
                                pbx_users.push(pbx_user);
                              }
                              domain_users.list = pbx_users;
                              return domain_users;
                            })
                          );
                        })
                      )
                  ).pipe(
                    catchError(resp => observableOf({} as IResponseListObject<any>)),
                    map(data => {
                      if (data['code'] != 200) throw data;

                      this.count_domain_users = data.total_count;
                      this.curr_count_domain_users += data.list.length;

                      // // уменьшаю количество найденных абонентов, если среди них есть зарегистрированный в CTI-панели и
                      // // у этого сотрудника нет других абонентов.
                      // if (data.list.filter(u => u.numbers.indexOf(this.mynumber) != -1)?.['numbers'].length == 1) {
                      //   this.count_domain_users -= 1;
                      //   this.curr_count_domain_users -= 1;
                      // }

                      for (let user of data.list) {
                        let username = user.name + (user.surname ? ' ' + user.surname : '');
                        if (filterValue) {
                          if (user.numbers.indexOf(filterValue) != -1) user.numbers = [filterValue];
                        } else user.numbers = user.favorite_numbers;
                        for (let uid of user.numbers) {
                          if (username.toLowerCase().includes(filterValue) || uid.includes(filterValue)) {
                            let domain_user = user.number_list[uid];
                            let tooltip = '', color = '', active = 0;
                            if (domain_user.reg_detail && (!('logout_dt' in domain_user.reg_detail) || domain_user.reg_detail['logout_dt']) && domain_user.reg_detail.has_transfer && this.mynumber != uid) {
                              color = 'text-blue';
                              tooltip = 'CTI.USER_TRANSFER';
                              active = 1;
                            } else if (!domain_user.reg_detail || !('logout_dt' in domain_user.reg_detail) || domain_user.reg_detail['logout_dt']) {
                              if (domain_user.status == 0) {
                                // color = 'bg-phone-no-active mat-elevation-z1';
                                color = 'text-mute';
                                tooltip = 'CTI.USER_OFFLINE';
                                active = 0;
                              } else {
                                // color = 'bg-phone-no-active mat-elevation-z1';
                                color = 'text-mute';
                                tooltip = 'USER.NO_REG';
                                active = 0;
                              }
                            } else if (domain_user.calls?.length > 0) {
                              color = 'text-yellow';
                              tooltip = 'USER.TALKING';
                              active = 0;
                            } else if (this.getAgentStatusById(domain_user.agent_status).base_status_id != -1) {
                              if (this.getAgentStatusById(domain_user.agent_status).base_status_id == -3) {
                                color = 'text-muted';
                                tooltip = 'AGENT.ON_BREAK';
                              } else {
                                color = 'text-danger';
                                tooltip = 'AGENT.LOGGED_OUT';
                              }
                              active = 0;
                            } else {
                              color = 'text-green';
                              // color = 'bg-phone-active';
                              tooltip = this.mynumber != uid ? 'USER.REG' : 'USER.SELF_CALL_DENIED';
                              active = this.mynumber != uid ? 1 : 0;
                            }
                            this.domain_users.push({
                              uid: uid,
                              name: uid + ' (' + username + ')',
                              agent_status: domain_user.agent_status,
                              class: color,
                              tooltip: tooltip,
                              active: active,
                              user_id: user.id,
                              user: user
                            });
                          }
                        }
                      }
                      this.domain_users = [...this.domain_users];

                      // if (entityType == 0 && this.curr_count_domain_users >= this.count_domain_users) this.getEntityFilter(filterValue, entityType); // чтобы поиск продолжился дальше
                      return data;
                    })
                  )
                );
              }
            } else filter_reqs.push(observableOf(null));
          }

          if (
            entityType != 0 ||
            // (this.count_domain_users == null && this.entityTypes.indexOf(1) != -1) ||
            (this.curr_count_domain_users < this.count_domain_users)
          ) break;
        case 2:
          // если окно открыто и фильтр не задан и есть избранные очереди, то гружу их с лимитом, установленным в Профиле сотрудника,
          // иначе проверяю наличие фильтра
          if (filterValue || this.favorite_settings?.favorite_users?.length > 0) {
            if (this.entityTypes.indexOf(2) != -1) {
              if (this.count_domain_queues == null || this.count_domain_queues > this.domain_queues.length) {
                filter_reqs.push((this.queueService.list({
                  sort: {name: '+'},
                  limit: filterValue ? 10 : this.favorite_settings.favorite_limit,
                  offset: this.domain_queues.length,
                  filter: {
                    field_list: filterValue ? [
                      {field: 'name', condition_type: 3, value: filterValue},
                      {field: 'domain_queue_extra_option.queue_id.route->>dp_number', condition_type: 3, value: filterValue},
                    ] : [
                      {field: 'id', value: this.favorite_settings?.favorite_queues, condition_type: 9}
                    ],
                    type: 1
                  },
                  with_share: true
                }, 'select_with_detail') as Observable<IResponseListObject<any>>).pipe(
                  catchError(resp => observableOf({} as IResponseListObject<any>)),
                  map(data => {
                    if (data['code'] != 200) throw data;

                    this.count_domain_queues = data.total_count;

                    for (let queue of data.list) {
                      let tooltip = '', color = '', active = 0;
                      if (queue.status == 0) {
                        color = 'text-muted';
                        tooltip = 'DISABLED2';
                        active = 0;
                      } else if (!queue.dp_number) {
                        color = 'text-muted';
                        tooltip = 'QUEUE.DP_NUMBER_NOT_SET';
                        active = 0;
                      } else if (queue.count_available_agents > 0) {
                        color = 'text-green';
                        tooltip = 'ENABLED2';
                        active = 1;
                      } else if (queue.count_busy_agents > 0) {
                        color = 'text-yellow';
                        tooltip = 'NOT_AVAILABLE_AGENT';
                        active = 0;
                      } else {
                        color = 'text-muted';
                        tooltip = queue.count_total_agents > 0 ? 'NOT_REGISTERED_AGENT' : 'NOT_AGENTS';
                        active = 0;
                      }

                      this.domain_queues.push({
                        name: queue.name,
                        count_available_agents: queue.count_available_agents,
                        count_busy_agents: queue.count_busy_agents,
                        count_total_agents: queue.count_total_agents,
                        dp_number: queue.dp_number,
                        class: color,
                        tooltip: tooltip,
                        active: active
                      });
                    }
                    this.domain_queues = [...this.domain_queues];

                    // if (entityType == 0 && this.domain_queues.length == this.count_domain_queues) this.getEntityFilter(filterValue, entityType); // чтобы поиск продолжился дальше
                    return data;
                  })
                ));
              }
            } else filter_reqs.push(observableOf(null));
          }

          if (
            entityType != 0 ||
            // (this.count_domain_queues == null && this.entityTypes.indexOf(2) != -1) ||
            (this.domain_queues.length < this.count_domain_queues) ||
            !filterValue
          ) break;
        case 3:
          if (this.entityTypes.indexOf(3) != -1) {
            if (this.count_domain_dialplans == null || this.count_domain_dialplans > this.domain_dialplans.length) {
              filter_reqs.push((this.dialplanService.list({
                sort: {name: '+'},
                limit: 10, offset: this.domain_dialplans.length,
                filter: {
                  field_list: [
                    {field: 'name', condition_type: 3, value: filterValue},
                    // Внутренние маршруты с условием маршрутизации "Произвольное значение" и выражением "Равен"
                    {field: 'type', value: 2, condition_type: 0},
                    {field: 'condition_source', value: 2, condition_type: 0},
                    {field: 'condition_type', value: 2, condition_type: 0}
                  ],
                  type: 0
                }
              }, 'select_with_detail') as Observable<IResponseListObject<any>>).pipe(
                catchError(resp => observableOf({} as IResponseListObject<any>)),
                map(data => {
                  if (data['code'] != 200) throw data;

                  this.count_domain_dialplans = data.total_count;

                  for (let dp of data.list) {
                    let tooltip = '', color = '', active = 0, condition;
                    condition = dp.condition.length > 0 && dp.condition[0].length > 0 ? dp.condition[0][0] : null;
                    if (dp.status == 0) {
                      color = 'text-muted';
                      tooltip = 'DISABLED3';
                      active = 0;
                    } else if (condition == null) {
                      color = 'text-muted';
                      tooltip = 'DP_NUMBER_NOT_SET';
                      active = 0;
                    } else {
                      color = 'text-green';
                      tooltip = 'ENABLED3';
                      active = 1;
                    }

                    this.domain_dialplans.push({name: dp.name, condition: condition, class: color, tooltip: tooltip, active: active});
                  }
                  this.domain_dialplans = [...this.domain_dialplans];

                  // if (entityType == 0 && this.domain_dialplans.length == this.count_domain_dialplans) this.getEntityFilter(filterValue, entityType); // чтобы поиск продолжился дальше
                  return data;
                })
              ));
            }
          } else filter_reqs.push(observableOf(null));

          if (
            entityType != 0 ||
            // (this.count_domain_dialplans == null && this.entityTypes.indexOf(3) != -1) ||
            (this.domain_dialplans.length < this.count_domain_dialplans)
          ) break;
        case 4:
          if (this.entityTypes.indexOf(4) != -1) {
            if (
              !this.active_address_book_group && (
                this.count_domain_address_book_groups == null ||
                (this.count_domain_address_book_groups > this.domain_address_book_groups.length) ||
                this.count_domain_address_books == null ||
                (this.count_domain_address_books > this.domain_address_books.length)
              )
            ) {
              filter_reqs.push(
                (this.count_domain_address_book_groups == null || (this.count_domain_address_book_groups > this.domain_address_book_groups.length) ? (this.addressBookGroupService.list({
                  sort: {name: '+'},
                  limit: 10,
                  offset: this.domain_address_book_groups.length,
                  filter: {
                    field_list: [
                      {field: 'name', condition_type: 3, value: filterValue},
                      // {field: 'domain_address_book.group_id.name', condition_type: 3, value: filterValue}
                    ],
                    type: 1
                  }
                }, 'select') as Observable<IResponseListObject<any>>) : observableOf({} as IResponseListObject<any>)).pipe(
                  catchError(resp => observableOf({} as IResponseListObject<any>)),
                  map(data => {
                    if (data?.total_count != null) this.count_domain_address_book_groups = data.total_count;
                    if (data?.list?.length > 0) {
                      for (let book_group of data.list) {
                        this.domain_address_book_groups.push({...book_group, groups: [], numbers: [], count_groups: null, count_numbers: null, loading: false});
                      }
                    }

                    return data;
                  }),
                  concatMap(data => {
                    return this.count_domain_address_book_groups == this.domain_address_book_groups.length || (this.count_domain_address_books == null) ?
                      this.addressBookService.list(
                        {
                          sort: {name: '+'},
                          limit: 10,
                          offset: this.domain_address_books.length,
                          filter: {field_list: [{field: 'name', value: filterValue, condition_type: 3}, {field: 'phones@T', value: filterValue, condition_type: 3}], type: 1}
                        }, 'select_with_detail'
                      ).pipe(
                        map(data2 => {
                          if (data2?.total_count != null) this.count_domain_address_books = data2.total_count;
                          if (data2.list?.length > 0) {
                            for (let contact of data2.list) {
                              if (!contact.name.toLowerCase().includes(filterValue)) contact.phones = contact.phones.filter(v => v.includes(filterValue));
                              this.domain_address_books.push(contact);
                            }
                          }

                          // if (entityType == 0 && this.domain_address_book_groups.length == this.count_domain_address_book_groups) this.getEntityFilter(filterValue, entityType); // чтобы поиск продолжился дальше
                          return data;
                        })
                      ) : observableOf(data);
                    })
                )
              );
            } else if (this.active_address_book_group && (this.active_address_book_group.count_groups > this.active_address_book_group.groups.length || this.active_address_book_group.count_numbers > this.active_address_book_group.numbers.length)) {
              this.loadAddressBookGroupData();
            }
          } else filter_reqs.push(observableOf(null));

          if (
            entityType != 0 ||
            // (this.count_domain_address_book_groups == null && this.entityTypes.indexOf(4) != -1) ||
            (this.domain_address_book_groups.length < this.count_domain_address_book_groups)
          ) break;
        case 5: break;
      }
    }
    if (filter_reqs.length > 0) this.loadingDomainUsers = true;
    return forkJoin(filter_reqs);
  }

  findEntities(filterValue, entityType: number) {
    if (
      !filterValue &&  // для сотрудников и очередей фильтр м.б. пустым, потому что у них м.б. Избранные в настройках Профиля сотрудника
      (
        !this.favorite_settings?.favorite_users?.length || // нет избранных внутренних номеров
        entityType > 1 || // или поиск не по всем и не по сотрудникам
        this.entityTypes.indexOf(1) == -1 // или по правам нет поиска по сотрудникам
      ) &&
      (
        !this.favorite_settings?.favorite_queues?.length || // нет избранных очередей
        (entityType != 0 && entityType != 2) ||  // или поиск не по всем и не по очередям
        this.entityTypes.indexOf(2) == -1 // или по правам нет поиска по очередям
      )
    ) {
      this.loadingDomainUsers = false;
      return observableOf([]);
    }
    return this.getEntityFilter(filterValue, entityType);
  }

  updateFilterEntity(key, entityType, number) {
    if (key == 'filterEntityType' && this.filterEntityType) {
      this.filterEntityType.disable();
    } else if (key == 'filterToCallEntityType' && this.filterToCallEntityType) {
      this.filterToCallEntityType.disable();
    }

    if (localStorage) localStorage.setItem(key, entityType);
    this.ctiUpdate$.next(null);
    this.clearEntityCounters();
    if (this[key].value != -1) {
      if (key == 'filterEntityType') {
        this.search$.next(null);
        setTimeout(() => {
          this.search$.next(number);
          this.filterEntityType.enable();
        }, 301);
      } else {
        this.searchToCall$.next(null);
        setTimeout(() => {
          this.searchToCall$.next(number);
          this.filterToCallEntityType.enable();
        }, 301);
      }
    } else this.filterEntityType.enable();
  }

  psYReachEnd(filterValue, entityType: number) {
    this.findEntities(filterValue, entityType).subscribe(
      data => {if (this.debug) console.log('search next'); this.loadingDomainUsers = false; this.ctiUpdate$.next(null);},
      resp => {if (this.debug) console.log('search error:', resp); this.loadingDomainUsers = false; this.ctiUpdate$.next(null);}
    );
  }

  getHotKeyState(direction) {
    return direction == 1 ? 'inbound_answered' : 'outbound_answered';
  }

  getIndicatorName(hk) {
    let name;
    switch(hk.indicator) {
      case 0: name = 'DOMAINCTI.HOT_KEY_ACTION_INDICATOR_QUEUE_FREE_AGENTS'; break;
      default: name = '';
    }
    return name;
  }

  hasHK(state) {
    for (let gr in (this.callHotKeys || {})) {
      if (this.callHotKeys[gr][state].length > 0) return true;
    }
    return false;
  }

  onHotKeyClick(callID, hk) {
    if (hk.value_value != null) {
      if (hk.action == 0) { // перевод по маршруту
        this.onTransfer(callID, hk.value_value, this.blindTransferMode.value.length > 0);
      }
    }
  }

  getCallHotKeys(direction) {
    if (Object.keys(this.callHotKeys || {}).length > 0) {
      let state = this.getHotKeyState(direction);
      this.verto.get_hot_keys({uid: this.mynumber, state: state}, 'get_call_hot_keys').subscribe(
        data => {
          for (let gr in this.callHotKeys) {
            for (let hk of this.callHotKeys[gr]?.[state]) {
              if (data.body.groups[gr]?.[hk.id]) {
                hk.value_value = data.body.groups[gr][hk.id][0]; // куда звонить при клике по кнопке, если это перевод на маршрут
                if (data.body.groups[gr][hk.id].length == 2) hk.indicator_val_val = data.body.groups[gr][hk.id][1]; // индикатор на кнопке, если он установлен
              } else {
                hk.value_value = null;
                hk.indicator_val_val = null;
                hk.hidden = true;
              }
            }
          }
        },
        resp => this.notifyService.setFormErrors(resp)
      );
    }
  }

  getChatHotKeys() {
    if (Object.keys(this.chatHotKeys || {}).length > 0) {
      this.verto.get_hot_keys({uid: this.mynumber}, 'get_chat_hot_keys').subscribe(
        data => {

        },
        resp => this.notifyService.setFormErrors(resp)
      );

      this.verto.get_hot_keys({uid: this.mynumber}, 'get_chat_hot_keys').subscribe(
        data => {
          for (let gr in this.callHotKeys) {
            for (let hk of this.callHotKeys[gr]) {
              if (data.body.groups[gr]?.[hk.id]) {
                hk.value_value = data.body.groups[gr][hk.id][0];
                if (data.body.groups[gr][hk.id].length == 2) hk.indicator_val_val = data.body.groups[gr][hk.id][1]; // индикатор на кнопке, если он установлен
              } else {
                hk.value_value = null;
                hk.indicator_val_val = null;
                hk.hidden = true;
              }
            }
          }
        },
        resp => this.notifyService.setFormErrors(resp)
      );
    }
  }

  changeAudioSettings() {
    if (localStorage) {
      localStorage.setItem('verto_settings', JSON.stringify({
        alarm: this.alarm,
        volume: this.volume,
        autocall: this.autocall,
        videocall: this.videocall,
        notify: this.notify,
        autowait: this.autowait,
        skinId: this.skinId,
      }));
    }

  }

  refreshCTICalls() {
    let calls = [];
    for (let call of this.lines_calls) {
      calls.push({
        call_id: call.call.id,
        caller_id_number: call.call.options.caller_id_number,
        caller_id_name: call.call.options.caller_id_name,
        callee_id_number: call.call.options.callee_id_number,
        callee_id_name: call.call.options.callee_id_name,
        direction: call.call.direction,
        dt_start: this.timers[call.call.id]?.call_time_start || null,
        status: call.call.status,
        transfering: call?.transfering
      });
    }

    this.ctiCalls$.next(calls);
  }

  saveCreadsToLS(key, value) {
    if (localStorage) localStorage.setItem(key, value);

    if (this.ctiState$.value == 'noReg' && this.transportConfig.login && this.transportConfig.passwd && this.transportConfig.socketUrl) {
      this.ctiState$.next('offline')
    }
  }

  checkMic() {
    navigator.mediaDevices.getUserMedia({audio: true}).then(
      (local_stream) => {},
      () => {
        this.userStateError = true;
        this.notifyService.message(this.translate.instant('MICROPHONE_IS_REQUIRED'));
        this.ctiUpdate$.next(null);
      }
    );
  }
}
