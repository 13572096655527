import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {of as observableOf} from 'rxjs';
import {SPEECH_LANG} from '../../../_helpers/constant';
import {RxFormHelpers} from '../../../_helpers/form.helpers';
import {NotifyService} from '../../../_helpers/notify.service';
import {RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {AuthenticationService} from '../../../auth/authentication.service';
import {BaseFormComponent} from '../../../_helpers/base-form.component';
import {ConfirmComponent} from '../../../dialogs/modals/confirm/confirm.component';
import {DomainCRMDFieldConfService} from '../dfields/dfield.service';
import {DomainCRMEvent, DomainCRMEventDField} from './event';
import {DomainCRMEventService} from './event.service';
import {OnInit} from '@angular/core';
// @ts-ignore
import * as moment from 'moment';
import {CTIService} from "../../../cti-panel/cti-panel.service";
import {DomainUserService} from "../../../users/domain-user.service";
import {DomainCrmSettings} from "../domain-crm-settings/domain-crm-settings";
import * as momentTZ from "moment-timezone";


@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['../entities/entity.component.scss']
})

export class DomainCRMEventFormComponent extends BaseFormComponent<DomainCRMEvent> implements OnInit {
  @Input() dfields = [];
  @Input() kw = [];
  @Input() call_link_list = {};
  @Input() crmSettings: DomainCrmSettings = null;
  @Input() entity: any = null;
  @Input() domainUsers = [];
  @Input() responsibleUserID;
  @Input() dfieldsLoading: boolean = false;
  @Input() domainUserLoading: boolean = false;
  @Input() timezone: string = 'Europe/Moscow';
  public today = moment().startOf('day');
  public endDtNotInPast: boolean = true;
  private initialData: any = {};
  @Input('form') eventForm: FormGroup;
  @Output() afterDelete = new EventEmitter<{id: number}>();
  @Output() loadDomainUsers = new EventEmitter();
  @Output() updateFileLinks = new EventEmitter<{id_list: string[]}>();

  constructor(
    api: DomainCRMEventService,
    fb: RxFormBuilder,
    route: ActivatedRoute,
    router: Router,
    http: HttpClient,
    notifyService: NotifyService,
    authenticationService: AuthenticationService,
    public translate: TranslateService,
    private domainCRMDFieldConfService: DomainCRMDFieldConfService,
    private dialog: MatDialog,
    public CTI: CTIService,
    public userDomainService: DomainUserService
  ) {
    super(DomainCRMEvent, api, fb, route, router, http, notifyService, authenticationService);
  }

  onInitial() {
    super.needCheckAuth = false;
    super.onInitial();
  }

  ngOnInit() {
    this.rxFormHelpers = new RxFormHelpers();
    this.form = this.eventForm;
    this.updateInitialData(this.eventForm.value);

    this.id = this.eventForm.value.id;
    if (!this.id) {
      if (this.form.get('id')) { this.form.removeControl('id'); }
      this.isLoadingResults = false;
      this.is_received_data = observableOf(true);
      if (!this.responsibleUserID) {
        this.domainUserLoading = true;
        this.userDomainService.toSelectWithDetail({filter: {type: 0, field_list: [{field: 'uid', value: this.CTI.ctiUser$.value, condition_type: 0}]}}).subscribe(
          data => {
            if (data.length > 0) {
              let user = data[0];
              // кладу в опции пользователей, которых нет еще в списке
              if (!this.domainUsers.find(u => u.id === user.id)) this.domainUsers.push({
                id: user.id,
                name: user['user_name'] + (user['user_surname'] ? ' ' + user['user_surname'] : ''),
                proto: user.proto,
                uid: user.uid
              });
              this.domainUsers.sort((a, b) => (a.uid < b.uid) ? -1 : (a.uid > b.uid ? 1 : (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)));
              this.responsibleUserID = user.id;
              this.form.patchValue({responsible_user_id: this.responsibleUserID, author_user_id: this.responsibleUserID});
            }
            this.domainUserLoading = false;
          },
          resp => {
            this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502;
            this.domainUserLoading = false;
          }
        );
      }
    } else {
      // todo: расскомментировать, если нужно будет запретить редактирование прошедшего события
      // this.endDtNotInPast = !this.initialData.end_dt || moment().isSameOrBefore(this.initialData.end_dt);
    }
  }

  getLocale(){
    let locale = 'Europe/Moscow';
    let lng = SPEECH_LANG.find(l => l.name == this.translate.currentLang.toUpperCase());
    if (lng) locale = lng.id as string;
    return locale;
  }

  getResponsibleUserName(userId) {
    return this.domainUsers.find(u => u.id == userId)
  }

  getDFields(id) {
    return (this.form.get('dfields') as FormArray).controls.filter(df => df.value.field_id == id);
  }

  getDField(id) {
    return this.dfields.find(df => df.id == id) || {};
  }

  setFieldValidators(dfield, rxField) {
    if (dfield) {
      let f: FormControl;
      let validators = [];

      switch (dfield.ftype) {
        case 0:
          f = rxField.get('str_val') as FormControl;
          if (dfield.required) validators = [RxwebValidators.required({message: 'ERROR.REQUIRED'})];
          if (dfield.choices_only) validators.push(RxwebValidators.oneOf({matchValues: dfield.choices, message: 'ERROR.10005.1'}));
          break;
        case 1:
          f = rxField.get('int_val') as FormControl;
          if (dfield.required) validators = [RxwebValidators.required({message: 'ERROR.REQUIRED'})];
          break;
        case 2:
          f = rxField.get('bool_val') as FormControl;
          if (dfield.required) validators = [RxwebValidators.required({message: 'ERROR.REQUIRED'})];
          break;
        case 3:
          f = rxField.get('dt_val') as FormControl;
          if (dfield.required) validators = [RxwebValidators.required({message: 'ERROR.REQUIRED'})];
          break;
        case 4:
          f = rxField.get('list_val') as FormControl;
          if (dfield.required) validators = [RxwebValidators.choice({minLength: 1, message: 'ERROR.REQUIRED'})];
          if (dfield.choices_only) validators.push(RxwebValidators.oneOf({matchValues: dfield.choices, message: 'ERROR.10005.1'}));
          break;
        case 5:
          f = rxField.get('txt_val') as FormControl;
          if (dfield.required) validators = [RxwebValidators.required({message: 'ERROR.REQUIRED'})];
          break;
      }

      f.setValidators(validators);
      f.updateValueAndValidity();
    }
  }

  addDField(dfieldData){
    let rxField = this.fb.formGroup(new DomainCRMEventDField());
    let dFieldId = this.initialData.dfields.find(f => f.field_id == dfieldData.id)?.id;
    rxField.patchValue({
      id: dFieldId || null,
      event_id: this.id,
      field_id: dfieldData.id
    });
    this.setFieldValidators(dfieldData, rxField);
    (this.form.get('dfields') as FormArray).push(rxField);
    this.form.markAsDirty();
  }

  removeDField(data){
    let ind = (this.form.get('dfields') as FormArray).controls.findIndex(df => data.id ? df.value.id == data.id : df.value.field_id == data.field_id);
    if (ind != -1) (this.form.get('dfields') as FormArray).removeAt(ind);
    this.form.markAsDirty();
  }

  updateInitialData(data: DomainCRMEvent) {
    this.initialData = {...data};

    this.initialData.dfields = [];
    for (let v of data.dfields) this.initialData.dfields.push(Object.assign(
      {},
      v,
      v.list_val?.length > 0 ? {list_val: [...v.list_val]} : {}
    ));
  }

  prepareDataToSubmit() {
    let params = super.prepareDataToSubmit();

    let utcOffset = momentTZ().tz(this.timezone).utcOffset() * 60;

    // params['start_dt'] = params.start_dt.unix();
    let start_dt_obj = params.start_dt.toObject();
    let start_dt = new Date(start_dt_obj.years, start_dt_obj.months, start_dt_obj.date, start_dt_obj.hours, start_dt_obj.minutes, start_dt_obj.seconds);
    // потому что при создании временной зоны, он дату сразу в ютс делает,
    // а потом накидывает смещение временной зоны, а я уже передаю туда время с учетом временной зоны, поэтому вычетаю его
    params.start_dt = momentTZ.utc(start_dt.toDateString() + ' ' + start_dt.toTimeString()).unix() - utcOffset;

    if (params['end_dt']) {
      // params['end_dt'] = params.end_dt.unix();
      let end_dt_obj = params.end_dt.toObject();
      let end_dt = new Date(end_dt_obj.years, end_dt_obj.months, end_dt_obj.date, end_dt_obj.hours, end_dt_obj.minutes, end_dt_obj.seconds);
      // потому что при создании временной зоны, он дату сразу в ютс делает,
      // а потом накидывает смещение временной зоны, а я уже передаю туда время с учетом временной зоны, поэтому вычетаю его
      params.end_dt = momentTZ.utc(end_dt.toDateString() + ' ' + end_dt.toTimeString()).unix() - utcOffset;
    }

    return params;
  }

  callbackSaveSuccess(data, submit_btn) {
    super.callbackSaveSuccess(data, submit_btn);
    if (!this.id) {
      this.form.addControl('id', new FormControl(data.body.id));
      this.id = data.body.id;
    }

    this.updateInitialData(this.form.value);
    this.form.markAsPristine();
  }

  getKWById(id) {
    return this.kw?.find(v => v.id == id);
  }

  onCancel() {
    if (this.id) {
      (this.form.get('dfields') as FormArray).clear();
      this.form.patchValue(this.initialData);
      this.initialData.dfields.forEach(
        (oData) => {
          let oRx = this.fb.formGroup(new DomainCRMEventDField());
          oRx.patchValue(Object.assign({}, oData, oData.list_val?.length > 0 ? {list_val: [...oData.list_val]} : {}));
          this.setFieldValidators(this.dfields.find(f => f.id == oData.field_id), oRx);
          (this.form.get('dfields') as FormArray).push(oRx);
        }
      );
      this.dfields.forEach( dfield => {
        if (dfield.required && this.getDField(dfield.id).length == 0) this.addDField(dfield);
      });
      this.form.markAsPristine();
    } else {
      // создаю временный айдишник и по нему удалю данное событие
      this.form.addControl('id', new FormControl(-1));
      this.afterDelete.emit({id: -1});
    }
  }

  onDelete(btn: MatButton) {
    const confirmDialog = this.dialog.open(ConfirmComponent, {
      data: {
        title: 'DIALOG.CONFIRM_TITLE',
        message: 'DIALOG.CONFIRM_MESSAGE_EVENT'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        btn.disabled = true;
        this.api.save({...this.form.value, dfields: []}).subscribe(
          saveResult => {
            this.api.delete(this.id).subscribe(
              deleteResult => {
                this.notifyService.showRequestResult(deleteResult, true);
                this.afterDelete.emit({id: this.id});
              },
              resp => {
                btn.disabled = false;
                this.isServerAvailable = this.notifyService.setFormErrors(resp, this.form) != 502;
              }
            );
          },
          resp => {
            btn.disabled = false;
            this.isServerAvailable = this.notifyService.setFormErrors(resp, this.form) != 502;
          }
        );
      }
    });
  }
}



