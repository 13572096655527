import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {NotifyService} from '../../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService, headers} from '../../../_helpers/base.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {DomainCRMEntity} from './entity';
import {JsonAppConfigService} from '../../../config/json-app-config.service';
import {Helper, IResponseBodyObject} from "../../../_helpers/api.helpers";
import {debounceTime, distinctUntilChanged, tap} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})


export class DomainCRMEntityService extends BaseService<DomainCRMEntity> {
  private api: HttpClient;
  private notify: NotifyService;
  public calling$ = new Subject<DomainCRMEntity>();

  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService, AppConfig: JsonAppConfigService) {
    super(DomainCRMEntity.className(), http, notifyService, router, route, authenticationService, AppConfig);
    this.api = http;
    this.notify = notifyService;
  }

  get_link_by_call_id(params: any = {}): Observable<IResponseBodyObject<any>> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat('get_link_by_call_id', 'DomainCDR', params);
    return this.http.post<IResponseBodyObject<any>>( this.AppConfig.getValue('apiUrl') + this.extraPath, requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }

  exchange_call_id(params: any = {}): Observable<IResponseBodyObject<any>> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat('exchange_call_id', 'DomainCDR', params);
    return this.http.post<IResponseBodyObject<any>>( this.AppConfig.getValue('apiUrl') + this.extraPath, requestBody, {headers: headers})
      .pipe(
        debounceTime(2000),
        distinctUntilChanged(),
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }

  is_callcenter_uuid(params: any = {}): Observable<IResponseBodyObject<any>> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat('is_callcenter_uuid', 'DomainCDR', params);
    return this.http.post<IResponseBodyObject<any>>( this.AppConfig.getValue('apiUrl') + this.extraPath, requestBody, {headers: headers})
      .pipe(
        debounceTime(2000),
        distinctUntilChanged(),
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }

  import_entity(obj: any, action: string = 'import'): Observable<IResponseBodyObject<any>>  {
    if (this.authenticationService.currentUserValue?.domain_id) {
      obj['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    let requestBody = Helper.requestFormat(action, this.objectName, obj);
    console.log(requestBody);

    return this.http.post<IResponseBodyObject<any>>( this.AppConfig.getValue('apiUrl') + this.extraPath, requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }

}
