import {Component, forwardRef, Input, OnInit, EventEmitter, Output, OnDestroy, AfterViewInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {NotifyService} from '../_helpers/notify.service';
import {BehaviorSubject, forkJoin, Observable, of, of as observableOf} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {AGENT_STATUS_LIST, STRATEGY_LIST} from '../_helpers/constant';
import {BaseListComponent} from '../_helpers/base-list.component';
import {Tier} from './tier';
import {AgentService} from './agent.service';
import {DomainUserService} from '../users/domain-user.service';
import {UserService} from '../users/user.service';
import {GroupService} from '../groups/group.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DomainAgentStatusService} from '../domains/domain-agent-status/domain-agent-status.service';
import {MatSort, Sort} from '@angular/material/sort';
import {
  DomainOrgUnitUserPositionService
} from "../domains/domain-org/domain-org-unit/domain-org-unit-user-position.service";

@Component({
  selector: 'app-agent-list',
  template: `
    <div fxLayout="row wrap">
      <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">

        <div class="example-container m-t-0 p-l-10 p-r-10">
          <div class="example-header">
            <mat-form-field>
              <input matInput #filter (keyup)="applyFilter($event.target.value)"
                     placeholder="{{'FILTER_BY_UUID'|translate}}"
                     matTooltip="{{'FILTER_BY_UUID'|translate}}">
              <button mat-icon-button matSuffix aria-label="clear" *ngIf="filter.value" (click)="filter.value=''; applyFilter('');">
                <mat-icon color="primary">close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="h-10 example-loading-shade">
            <mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate" color="primary"></mat-progress-bar>
            <div class="example-rate-limit-reached text-warning" *ngIf="isRateLimitReached" [translate]="'NOTIFY.502'"></div>
          </div>
          <div class="example-table-container responsive-table">
            <table mat-table [dataSource]="dataSource" class="example-table" matSort matSortActive="{{getSort()}}" matTableResponsive
                   matSortDisableClear matSortDirection="asc">
              <ng-container matColumnDef="status">
                <th fxShow mat-header-cell *matHeaderCellDef>{{'STATUS'|translate}}</th>
                <td fxShow mat-cell *matCellDef="let row">
                  <mat-select [value]="row.status_id" [(ngModel)]="row.status_id"
                              (selectionChange)="onAgentStatusChange(row)">
                    <mat-select-trigger>{{getStatus(row.status_id) | translate}}</mat-select-trigger>
                    <mat-option #matStatusOption *ngFor="let status of getAgentStatus();"
                                [value]="status.id"
                                [disabled]="!status.available_for_operator || !checkUserInUnit(row.user_id, status.available_for_org_unit || [])"
                                [class.cursor-not-allowed]="matStatusOption.disabled"
                    >
                      {{status.name | translate}}
                    </mat-option>
                  </mat-select>
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th fxShow fxHide.sm fxHide.md mat-header-cell *matHeaderCellDef>{{ 'USERNAME'|translate }}</th>
                <td fxShow fxHide.sm fxHide.md mat-cell *matCellDef="let row">{{row.name}}</td>
              </ng-container>
              <ng-container matColumnDef="uid">
                <th fxShow mat-header-cell *matHeaderCellDef>{{ 'EXTENSIONS'|translate }}</th>
                <td fxShow mat-cell *matCellDef="let row">{{row.extension | translate}}</td>
              </ng-container>
              <ng-container matColumnDef="level">
                <th [fxShow]="showLevel" mat-header-cell *matHeaderCellDef>{{ 'LEVEL'|translate }}</th>
                <td [fxShow]="showLevel" mat-cell *matCellDef="let row">
                  <input matInput placeholder="   " type="number" min="1" max="{{maxLevel}}" step="1"
                           [value]="row.level"  autocomplete="off" class="inline-input-number"
                           [(ngModel)]="row.level" (change)="onAgentChange(row)">
                </td>
              </ng-container>
              <ng-container matColumnDef="position" >
                <th [fxShow]="showPosition" mat-header-cell *matHeaderCellDef>{{ 'POSITION'|translate }} </th>
                <td [fxShow]="showPosition" mat-cell *matCellDef="let row">
                    <input matInput placeholder="   " type="number" min="1" max="{{maxPosition}}" step="1"
                           [value]="row.position" autocomplete="off" class="inline-input-number"
                           [(ngModel)]="row.position" (change)="onAgentChange(row)">
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell mat-button-header *matHeaderCellDef>{{ 'ACTIONS'|translate }}</th>
                <td mat-cell *matCellDef="let row" align="right">
                  <button *ngIf="row.update || row.status_update" mat-icon-button (click)="onAgentSave(row)">
                    <mat-icon class="text-primary">save</mat-icon>
                  </button>
                  <button mat-icon-button (click)="onDelete(row)">
                    <mat-icon class="text-danger">delete</mat-icon>
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                  (dblclick)="onRowClicked(row.id)" [class.blinker]="row.blinker"></tr>
            </table>
          </div>
          <mat-paginator matLocalStoragePageSize [length]="resultsLength"
                         [pageSize]="10" [pageSizeOptions]="[10, 50, 100]">
          </mat-paginator>
          <div class="example-header" fxLayout="row wrap">
            <div class="p-l-10 p-r-10 m-b-10" fxFlex="100">
              <mat-select-user #selectAgent [addItems]="agent_select" [icons]="true"
                               (selected)="onAgentAdd($event); selectAgent.control.setValue(null)"
                               [exclude$]="userSelect$" [filter]="filters"
                               placeholder="{{'ADD_AGENT_IN_QUEUE'|translate}}">
              </mat-select-user>
            </div>
          </div>
          <div class="example-header" fxLayout="row wrap">
            <div class="p-l-10 p-r-10 m-b-10" fxFlex="100">
              <ng-select #selectGroup [multiple]="false" [appendTo]="'body'"
                         (change)="onGroupAdd($event); selectGroup.handleClearClick()"
                         (keyup.enter)="selectGroup.close(); onGroupAdd(selectGroup.selectedValues);"
                               placeholder="{{'ADD_GROUP_IN_QUEUE'|translate}}">
                <ng-option *ngFor="let group of group_select" [value]="group.id">{{group.name | translate}}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>    `,
  styles: [
    '.mat-column-status {min-width: 120px; text-align: left;   padding-right: calc(5% + 10px);}',
    '.mat-column-uid {text-align: center;}',
    '.mat-column-name {text-align: center;}',
    '.mat-column-actions {text-align: center; max-width: 90px}',
    '.mat-column-level {text-align: center; width: 100px;}',
    '.mat-column-position {text-align: center; width: 100px;}'
  ],
  styleUrls: ['../material-component/mat-table-responsive/mat-table-responsive.directive.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(()=>AgentListComponent),
      multi: true
    }
  ]
})

export class AgentListComponent extends BaseListComponent<Tier> implements OnInit, OnDestroy, AfterViewInit {
  displayedColumns: string[] = [
    'status', 'name', 'uid', 'level', 'position', 'actions'];
  dataSource: MatTableDataSource<Tier> = new MatTableDataSource<Tier>([]);
  agent_status = [];
  agent_select = [];
  group_select = [];
  showLevel = true;
  showPosition = true;
  allowLevel = [5, 10, 50];
  maxLevel = 5;
  maxPosition = 5;
  showOrder: number = 0;
  filters = {filter: {field_list: [{
    field: 'agent',
    condition_type: 8,
    value: null
  }], type: 0 }};

  public users_units = {};

  @Input() queue: string;
  @Input() strategy = new FormControl('') ;
  @Output() mustSave = new EventEmitter();

  userSelect$: BehaviorSubject<Number[]> = new BehaviorSubject([]);

  constructor(public api: AgentService, translate: TranslateService, public agentStatusService: DomainAgentStatusService,
              public userService: UserService, public groupService: GroupService,
              public userDomainService: DomainUserService, notifyService: NotifyService, public route: ActivatedRoute, dialog: MatDialog,
              public router: Router, public domainOrgUnitUserPositionService: DomainOrgUnitUserPositionService) {
    super(api, translate, notifyService, dialog, router);

    this.maxLevel = this.allowLevel[this.api.getDomainMode()];
    this.maxPosition = this.allowLevel[this.api.getDomainMode()];

    this.groupService.toSelect({}, 'select')
      .subscribe(data=> data.forEach(item=>this.group_select.push({id: item.id, name: item.name})));

    this.agentStatusService.toSelect({
      sort: {name: '+'},
      filter: {field_list: [{field: 'available_for_mod', value: [0, 1], condition_type: 9}], type: 0}
    }).subscribe(data=> this.agent_status = data);

    this.dataSource.connect().subscribe(exclude=>
    {
      this.userSelect$.next(exclude.map(el => el.agent_id))
    })
  }


  ngOnDestroy() {
    this.userSelect$.complete();
  }

  changeStrategy(value) {
    const fnd_str = STRATEGY_LIST.find(val => val.id === value);
    if (fnd_str) {
      this.showLevel = fnd_str.level;
      this.showPosition = fnd_str.position;
      this.showOrder = fnd_str.order;
      const sorted = ['extension', 'level','position'][this.showOrder];
      this.dataSource.sort.sort({disableClear: false, id: null, start: null});
      this.dataSource.sort.sort({disableClear: false, id: sorted, start: 'asc'});
    }
  }

  getSort() {
    return ['extension', 'level','position'][(STRATEGY_LIST.find(val => val.id === this.strategy.value)?.order || 0)];
  }


  ngOnInit() {
    this.dataSource.sort = new MatSort();
    if (this.queue) this.extParams = {
      filter:
        {type: 0,
          field_list: [
            {
              field: 'queue_id',
              value: parseInt(this.queue, 10),
              condition_type: 0
            }
          ]
        }
    };
    //this.changeStrategy(this.strategy.value);
    this.strategy.valueChanges.subscribe(value=>{
      this.changeStrategy(value);
    })
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    // this.dataSource.filter = filterValue;


    if (this.queue && filterValue) {
      this.extParams = {
        filter: {
          type: 0,
          field_list: [
            {
              field: 'queue_id',
              value: parseInt(this.queue, 10),
              condition_type: 0
            },
            {
              field: 'domain_user.id.uid.agent_id',
              value: filterValue,
              condition_type: 3
            }
          ]
        }
      }
    } else if (this.queue) {
      this.extParams = {
        filter: {
          type: 0,
          field_list: [
            {
              field: 'queue_id',
              value: parseInt(this.queue, 10),
              condition_type: 0
            }
          ]
        }
      }
    } else if (filterValue) {
      this.extParams = {
        filter: {
          type: 0,
          field_list: [
            {
              field: 'domain_user.id.uid.agent_id',
              value: filterValue,
              condition_type: 3
            }
          ]
        }
      }
    } else this.extParams = {};

    if (this.paginator.pageIndex != 0) this.paginator.pageIndex = 0; // при поиске возвращаю пользователя на первую страницу
    this.refresh();

  }

  getStatus(id) {
    let asl = this.agent_status.concat(AGENT_STATUS_LIST).filter(item=>item.id == id);
    return (asl && asl.length>0)? asl[0].name: id;
  }

  getAgentStatus() {
    let statuses = this.agent_status.concat(AGENT_STATUS_LIST.filter(item => item.type=='user'));
    return statuses;
  }


  onRowClicked(id) {

  }

  onAgentChange(value){
    value.update = true;
  }

  checkUserInUnit(user_id, status_units: number[] = []) {
    return !status_units.length || this.users_units[user_id]?.some(unit_id => status_units.indexOf(unit_id) != -1);
  }

  onAgentStatusChange(value){
    value.status_update = true;
  }

  onAgentSave(agent){
    const flag = {update: !!agent.update, status: !!agent.status_update}
    delete agent.update;
    delete agent.status_update;

    if (flag.update) {
      this.api.save(agent).subscribe(data=>{}, error=>{});
    }
    if (flag.status) {
     this.userDomainService.agentStatusSwitch(agent.agent_id, agent.status_id).subscribe(
       data=> this.refresh(),
       error => this.refresh()
     );

    }
  }

  refresh() {
    super.refresh();
    this.changeStrategy(this.strategy.value);
    this.table.renderRows();
  }

  callbackList(data: Array<Tier>): Observable<Tier[]> {
    const source = of(data);
    if (data.length==0) return observableOf<Tier[]>([]);

    return source.pipe(
      mergeMap(data=>
        forkJoin(
          data.map(list =>
            this.userDomainService.get(list.agent_id, {__ignore__: {10002: true}})
              .pipe(
                map(user=> {
                  let tier = list;
                  tier.extension = user?.uid;
                  tier.user_id = user?.user_id;
                  tier.status_id = user?.agent?.status_id;
                  return tier;
                }),
                catchError(error => of({} as Tier))
              )
          )
        )
      ),
      mergeMap(data=>
        forkJoin(
          data.map(list =>
            this.userService.get(list.user_id, {__ignore__: {10002: true}})
              .pipe(
                map(user=> {
                  let tier = list;
                  tier.name = (user?.surname?.length>0) ? `${user.surname} ${user.name}` : user.name;
                  return tier;
                }),
                catchError(error => of({} as Tier))
              )
          )
        )
      ),
      mergeMap(
        data => {
          let user_ids = [];
          for (let tier of data) {
            if (user_ids.indexOf(tier.user_id) == -1) user_ids.push(tier.user_id);
          }
          return this.domainOrgUnitUserPositionService.list({
            filter: {field_list: [{field: 'user_id', value: user_ids, condition_type: 9}], type: 0}
          }, 'select').pipe(
            catchError(resp => observableOf(data)),
            map(data2 => {
              for (let v of data2['list']) {
                this.users_units[v.user_id] = [v.unit_id, ...(this.users_units[v.user_id] || [])];
              }
            return data;
          }))
        }
      )
    );
  }


  onAgentAdd(id){
    if (this.queue) this.extParams = {
      filter:
        {type: 0,
          field_list: [
            {
              field: 'queue_id',
              value: parseInt(this.queue, 10),
              condition_type: 0
            }
            ]
        }
    };
    const ds_ids = this.dataSource.data.map(ds=>ds.agent_id);
    if (ds_ids.includes(id)) return;
    if (!this.queue) {
      this.mustSave.emit(id);
    }
    else {
      this.api.agentNew(id, this.queue).pipe(
        catchError(resp => {
          this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502;
          return observableOf(null);
        }),
        map(res => res ? res.body : null)
      ).subscribe(data=>{
        this.refresh();
      });
    }
  }

  onGroupAdd(id){
    if (this.queue) this.extParams = {
      filter:
        {type: 0,
          field_list: [
            {
              field: 'queue_id',
              value: parseInt(this.queue, 10),
              condition_type: 0
            }
          ]
        }
    };
    //const status_id = this.agent_status.find(item=>item.base_status_id==-1)?.id || -1;
    const ds_ids = this.dataSource.data.map(ds=>ds.agent_id);
    if (!this.queue) {
      this.mustSave.emit(id);
    }
    else  {
      this.userDomainService.toSelect({ sort: {uid: '+'}, limit: 1000,
        filter: {field_list: [
            {
              field: 'agent',
              condition_type: 8, //  8 is not null
              value: null
            },
            {
              field: 'group_outbound',
              condition_type: 9,  // 9 - in, 10 - not in
              value: [id]
            }
          ], type: 0 }
      }).subscribe(data=> {
        const users_uniq = data
          .filter(x => x.group_outbound.includes(id))
          .filter(item=>!ds_ids.includes(item.id));
        if (users_uniq.length > 0)
          forkJoin(
            users_uniq.map(item =>this.api.agentNew(item.id, this.queue))
          )
            .subscribe(data => {
              this.refresh();
            }, resp=> {
              this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502;
              return observableOf(null);
              this.refresh();
            } );
      });
    }
  }

}
