import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver, Directive,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {MatButton} from '@angular/material/button';
import { KtdGridComponent, KtdGridLayout, ktdTrackById } from '@katoid/angular-grid-layout';
import {BehaviorSubject, forkJoin, fromEvent, merge, of, Subject, Subscription, SubscriptionLike} from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {FormControl, FormGroup} from '@angular/forms';
import {StorageMap} from '@ngx-pwa/local-storage';
import {DesktopService} from './base-desktop.service';
import {DomainService} from '../../domains/domain.service';
import {AuthenticationService} from '../../auth/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {DesktopColumns} from '../_common/desktop-columns';
import {NotifyService} from '../../_helpers/notify.service';
import {MonitorWSService} from '../_common/monitor-ws.service';
import {DomainCCMonitor2} from '../../monitor2/monitor2';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
import {RxFormHelpers} from '../../_helpers/form.helpers';
import {AppService} from '../../app.service';


@Directive()
export class BaseDesktopComponent implements OnInit, AfterViewInit {
  @ViewChild(KtdGridComponent, {static: false}) grid: KtdGridComponent;

  public data: any = [];
  public storageName = '';

  trackById = ktdTrackById;
  settings: FormGroup = new FormGroup({
    DragAndResize: new FormControl(false)
  });





  cols = 12;
  rowHeight = 50;
  tabIndex = 0;
  compactType = 'vertical';
  layout: any = [];

  layoutSizes: {[id: string]: [number, number]} = {};
  components = [];
  public form: FormGroup;
  private resizeSubscription: Subscription;
  private messages$; // сообщения сокета
  private messagesSub: SubscriptionLike;
  private ctiCallsSub;
  private ctiUserSub;
  public projectSettings = {};
  public rxFormHelpers = new RxFormHelpers();

  constructor(@Inject(DOCUMENT) public document: Document,
              public appService: AppService,
              public desktopService: DesktopService,
              public fb: RxFormBuilder,
              public translate: TranslateService,
              public domainService: DomainService,
              public monitorWSService: MonitorWSService,
              public authenticationService: AuthenticationService,
              public notifyService: NotifyService,
              public storage: StorageMap) {
    this.messages$ = this.appService.on<any>();
    this.messagesSub = this.messages$.subscribe(
      msg => this.onMessage(msg),
      err => console.log(err),
      () => console.log('complete')
    );


    console.log(authenticationService.getUserPerm('DomainCCMonitor2'))
    // this.appService.subscribe([{name: 'cc_monitor_v2_user', params: {requested_objects: 'all'}}, {name: 'cc_monitor_v2_queue', params: {requested_objects: 'all'}}]);
    //

    this.form = fb.formGroup(new DomainCCMonitor2())

    this.monitorWSService.get().subscribe(data=>{
      this.form.patchValue(data);
    }, error => {
    })



    const currentLang = this.translate.currentLang;
    this.translate.use(currentLang || 'ru');




  }

  clearStorage() {

    forkJoin([
      this.storage.delete(`${this.storageName}::DESKTOP::COLUMNS`),
      this.storage.delete(`${this.storageName}::DESKTOP::SETTINGS`)
    ]).subscribe(item=> {
      window.location.reload();
      //this.redraw();


    });


  }


  inSettings(children = []): any[] {
    return children?.filter(item => this.settings?.get(item.id)?.value==true && this.projectSettings[item.id] !== false) || [];
  }

  ngAfterViewInit() {

  }

  redraw(timeout=1000) {
    setTimeout(()=>{
      if (this.grid) {
        console.log('redraw');
        this.grid.resize();
        this.calculateLayoutSizes();
      }

    }, timeout);
  }

  getChecked(columns: DesktopColumns[]) {
    return columns.filter(opt => opt.checked == true).map(opt => opt.id);
  }

  setChecked(name: string, columns: DesktopColumns[], values: string[]) {
    columns.forEach(column=>column.checked=values.includes(column.id));
    this.desktopService.setMenu({name: name, columns: values});
  }

  ngOnInit() {
    this.resizeSubscription = merge(
      fromEvent(window, 'resize'),
      fromEvent(window, 'orientationchange')
    ).pipe(
      debounceTime(50)
    ).subscribe(() => {
      if(this.grid) {
        this.grid.resize();
        this.calculateLayoutSizes();
      }

    });

    this.desktopService.cc_monitor_v2_user$.subscribe(change=>{
      this.appService.unsubscribe(['cc_monitor_v2_user'])
      if (change.length>0) {
        this.appService.subscribe([{name: 'cc_monitor_v2_user', params: {requested_objects: change}}]);
      }
    })


    this.desktopService.cc_monitor_v2_queue$.subscribe(change=>{
      this.appService.unsubscribe(['cc_monitor_v2_queue'])
      if (change.length>0) {
        this.appService.subscribe([{name: 'cc_monitor_v2_queue', params: {requested_objects: change}}]);
      }
    })



    this.storage.get(`${this.storageName}::DESKTOP::SETTINGS`).subscribe(
      (store: any) => {

        if (store?.layout) {
          if (this.layout.length == store.layout.length) {
            this.layout = store.layout;
          } else {
            this.setStorage();
            this.redraw();
            return;

          }

        }
        if (store?.settings) {
          Object.keys(store?.settings).forEach(item=>{
            if (this.projectSettings.hasOwnProperty(item)) store.settings[item] = this.projectSettings[item];

          });
          this.settings.patchValue(store.settings);
        }

        this.redraw();
      });

    this.storage.get(`${this.storageName}::DESKTOP::QUEUE`).subscribe(
      (store: any) => {
        if(store?.queue){
          this.desktopService.currentQueue.next(store.queue);
        }
      });

    this.desktopService.currentQueue.subscribe(data => {
      if (data !== null) this.storage.set(`${this.storageName}::DESKTOP::QUEUE`, {
        queue: data
      }).subscribe();
    });

    this.desktopService.changeMenu.subscribe(menu=>{
      this.storage.get(`${this.storageName}::DESKTOP::COLUMNS`).subscribe(data=>{
        let _data = data || {};
        _data[menu.name] = menu.columns;
        this.storage.set(`${this.storageName}::DESKTOP::COLUMNS`, _data).subscribe();
      });
    });

    this.settings.valueChanges.subscribe(data => {
      this.setStorage();
    });
  }

  ngOnDestroy() {
    this.appService.unsubscribe(['cc_monitor_v2_user', 'cc_monitor_v2_queue']);
    if (this.messagesSub) this.messagesSub.unsubscribe();
    this.resizeSubscription.unsubscribe();
    if (this.ctiCallsSub) this.ctiCallsSub.unsubscribe();
    if (this.ctiUserSub) this.ctiUserSub.unsubscribe();
  }

  getView(gridItemId: string, grid: KtdGridComponent): [number, number] {
    const gridItemRenderData = grid.getItemRenderData(gridItemId);
    return [
      gridItemRenderData.width,
      gridItemRenderData.height
    ];
  }
  public onMessage(msg: any = {}) {
    //console.log('form. received message:', msg);

    if (typeof msg === 'string') msg = JSON.parse(msg);
    let member_index, member;

    if (msg.code === 407 || msg.code === 401) { // токена нет или он устарел
      this.notifyService.messageByCode(msg.code);
      // remove user from local storage to log user out
      this.notifyService.auth.logout();
      return false;
    }


    switch (`${msg.action}::${msg.obj}`) {
      case 'auth::WebSocketMember':  // событие установки соединения с сокетом, после которого нужно подписаться на нужные события
        if (this.desktopService.cc_monitor_v2_user$.value?.length>0) {
          this.appService.subscribe([{name: 'cc_monitor_v2_user', params: {requested_objects: this.desktopService.cc_monitor_v2_user$.value}}]);
        }
        if (this.desktopService.cc_monitor_v2_queue$.value?.length>0) {
          this.appService.subscribe([{name: 'cc_monitor_v2_queue', params: {requested_objects: this.desktopService.cc_monitor_v2_queue$.value}}]);
        }

        break;
      case 'subscribe::WebSocketMember':
        if (msg.code == 200) {
          // console.log('Запрашиваю данные из OnMessage');
          //this.getQueues();
          //this.getCustomStatus();
        }
        break;
      case 'user_update::MonitorCCv2':
        if (msg.code == 200) {
          this.desktopService.agentData$.next(msg.body);
          //this.dataSource.data = msg.body;

        } //else this.router.navigate([this.navigate]);

        break;

      case 'queue_update::MonitorCCv2':
        if (msg.code == 200) {

          msg.body.forEach(row => {
            row['percent_answered_calls'] = Math.floor((row?.percent_answered_calls || 0) * 100) / 100;
            row['sla'] = Math.floor((row?.sla || 0) * 100) / 100;
          });

          this.desktopService.queueData$.next(msg.body);
          //this.dataSourceQueue.data = msg.body;

        } //else this.router.navigate([this.navigate]);
        break;

      default:
        break;

    }


  }
  onLayoutUpdated(layout: KtdGridLayout) {
    console.log('layoutUpdate')
    setTimeout(()=>{
      this.layout = layout;
      this.calculateLayoutSizes();
      this.setStorage();
    }, 500)

  }

  onSelect(id: string, data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  setStorage() {
    this.storage.set(`${this.storageName}::DESKTOP::SETTINGS`, {
      layout: this.layout,
      settings: this.settings.value
    }).subscribe();
  }

  onActivate(id: string, data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(id: string, data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  /**
   * Calculates and sets the property 'this.layoutSizes' with the [width, height] of every item.
   * This is needed to set manually the [width, height] for every grid item that is a chart.
   */
  private calculateLayoutSizes() {
    if (this.grid) {
      const gridItemsRenderData = this.grid.getItemsRenderData();
      this.layoutSizes =
        Object.keys(gridItemsRenderData)
          .reduce((acc, cur) => ({
            ...acc,
            [cur]: [gridItemsRenderData[cur].width, gridItemsRenderData[cur].height]
          }), {});
    }
  }

  setTabIndex($event: any) {
    this.tabIndex = $event;
    console.log(this.tabIndex)
    if ($event == 0) {
      this.redraw();
    }
  }

  getVertoUID() {
    return new BehaviorSubject('1010');
  }

  save(submit_btn?: MatButton) {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    if (true || this.form.valid) {
      if (submit_btn) submit_btn.disabled = true;
      this.monitorWSService.set(this.form.value).subscribe(
        (data) => {
          if (submit_btn) submit_btn.disabled = false;
          this.notifyService.message('NOTIFY.200');
          this.setTabIndex(0);
        },
        resp => {
          if (submit_btn) submit_btn.disabled = false;
          this.notifyService.setFormErrors(resp);
        }
      );
    } else {
      Object.entries(this.form.controls).filter(filter => filter[1]['status']==='INVALID').forEach(item => {
        this.form.controls[`${item[0]}`].updateValueAndValidity();
        this.form.controls[`${item[0]}`].markAllAsTouched();
        console.log('invalid field: ', item);
      });

      this.notifyService.message('NOTIFY.REQUIRED');
    }

  }
}
