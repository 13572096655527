import {Injectable} from '@angular/core';
import {
  ALL_DOMAIN_MODE,
  MenuView,
  ModeDomain,
  PermAction,
  PermProjectAction,
  PermSelfAction,
  ROLE_TYPE_ALL,
  ROLE_TYPE_CUSTOM,
  ROLE_TYPE_DOMAIN,
  ROLE_TYPE_PROVIDER
} from '../_helpers/constant';
import {AuthenticationService} from '../auth/authentication.service';
import {MarketplaceService} from '../domains/marketplace/marketplace.service';

export interface BadgeItem {
    type: string;
    value: string;
}
export interface Separator {
    name: string;
    type?: string;
}

export interface MenuItem {
    state: string;
    name: string;
    type: string;
    object?: string;
    description?: string;
    icon: string;
    mode?: number[];
    map?: number[];
    license?: string;
    role?: number[];
    params?: any;
    view?: number[];
    badge?: BadgeItem[];
    separator?: Separator[];
    children?: MenuItem[];
    obj_perm?: number[];
}

const _MENU_: Array<MenuItem> =  [
  {
    state: 'dashboard',
    name: 'DASHBOARD',
    type: 'link',
    icon: 'dashboard',
    role: ROLE_TYPE_ALL,
    mode: ALL_DOMAIN_MODE,
    map: [1],
    view: [MenuView.DOMAIN],
    object: 'DomainDashboard',
    obj_perm: [PermAction.GET, PermSelfAction.GET, PermProjectAction.GET]
  },
  {
    state: 'dashboard',
    name: 'DASHBOARD',
    type: 'link',
    icon: 'dashboard',
    role: ROLE_TYPE_PROVIDER,
    mode: ALL_DOMAIN_MODE,
    map: [1],
    view: [MenuView.PROVIDER],
    object: 'SystemDashboard',
    obj_perm: [PermAction.GET]

  },
  {
    state: 'users',
    name: 'USERS',
    type: 'link',
    icon: 'people',
    role: ROLE_TYPE_ALL,
    mode: ALL_DOMAIN_MODE,
    view: [MenuView.PROVIDER, MenuView.DOMAIN],
    object: 'User',
    obj_perm: [
      PermAction.LIST, PermAction.DLIST, PermSelfAction.LIST, PermSelfAction.DLIST
    ]
  },
  {
    state: 'gscatalogs',
    name: 'GSCATALOGS',
    type: 'dict',
    icon: 'topic',
    role: ROLE_TYPE_PROVIDER,
    view: [MenuView.PROVIDER],
    children: [
      {
        name: 'ROLES',
        description: 'ROLES_DESCRIPTION',
        type: 'item',
        icon: 'verified_user',
        state: '/gscatalogs/roles',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'Role',
        obj_perm: [
          PermAction.LIST
        ]
      },
      {
        name: 'TARIFFS',
        description: 'TARIFFS_DESCRIPTION',
        type: 'item',
        icon: 'credit_card',
        state: '/gscatalogs/tariffs',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'Tariff',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'VOIP_PROVIDERS',
        description: 'VOIP_PROVIDERS_DESCRIPTION',
        type: 'item',
        icon: 'phone_locked',
        state: '/gscatalogs/providers',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'VoIPProvider',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'SYSTEM_AUDIO',
        description: 'SYSTEM_AUDIO_DESCRIPTION',
        type: 'item',
        icon: 'volume_up',
        state: '/gscatalogs/audio',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'SystemAudioFile',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'LOCAL_STREAM',
        description: 'LOCAL_STREAM_DESCRIPTION',
        type: 'item',
        icon: 'insert_drive_file',
        state: '/gscatalogs/local-stream',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'SystemAudioFile',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'ACL',
        description: 'ACL_DESCRIPTION',
        type: 'item',
        icon: 'topic',
        state: '/gscatalogs/acl',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'AccessControlListRow',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'GSCONDITIONS',
        description: 'GSCONDITIONS_DESCRIPTION',
        type: 'item',
        icon: 'mediation',
        state: '/gscatalogs/gsconditions',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'GSConditions',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'GSPRODUCTION_CALENDAR',
        description: 'GSPRODUCTION_CALENDAR_DESCRIPTION',
        type: 'item',
        icon: 'calendar_today',
        state: '/gscatalogs/gsproduction-calendar',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'GSProductionCalendar',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'SYSTEM_FILE_STORAGE',
        description: 'SYSTEM_FILE_STORAGE_DESCRIPTION',
        type: 'item',
        icon: 'cloud_upload',
        state: '/gscatalogs/system-file-storage',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'SystemAudioFile',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'SYSTEM_XML_TEMPLATES',
        description: 'SYSTEM_XML_TEMPLATES_DESCRIPTION',
        type: 'item',
        icon: 'account_tree',
        state: '/gscatalogs/system-text-templates',
        params: {category: 1},
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'TextTemplate',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'SYSTEM_TEXT_TEMPLATES',
        description: 'SYSTEM_TEXT_TEMPLATES_DESCRIPTION',
        type: 'item',
        icon: 'text_snippet',
        state: '/gscatalogs/system-text-templates',
        params: {category: 2},
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'TextTemplate',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'SYSTEM_NOTIFY_TEMPLATES',
        description: 'SYSTEM_NOTIFY_TEMPLATES_DESCRIPTION',
        type: 'item',
        icon: 'priority_high',
        state: '/gscatalogs/system-text-templates',
        params: {category: 3},
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'TextTemplate',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'LICENSES',
        description: 'LICENSES_DESCRIPTION',
        type: 'item',
        icon: 'vpn_key',
        state: '/gscatalogs/licenses',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'License',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'GSPLUGINS',
        description: 'GSPLUGINS_DESCRIPTION',
        type: 'item',
        icon: 'extension',
        state: '/gscatalogs/gsplugins',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'PluginSettings',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      }
    ]
  },
  {
    state: 'domain-catalogs',
    name: 'DOMAIN_CATALOGS',
    type: 'dict',
    icon: 'topic',
    role: ROLE_TYPE_ALL,
    mode: ALL_DOMAIN_MODE,
    view: [MenuView.DOMAIN],
    children: [
      {
        name: 'GROUPS',
        description: 'GROUPS_DESCRIPTION',
        type: 'item',
        icon: 'group_work',
        state: '/domain-catalogs/groups',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainGroup',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'LISTS',
        description: 'LISTS_DESCRIPTION',
        type: 'item',
        icon: 'list_alt',
        state: '/domain-catalogs/lists',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        object: 'DomainList',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'SEARCH_PHRASE',
        description: 'SEARCH_PHRASE_DESCRIPTION',
        type: 'item',
        icon: 'list_alt',
        state: '/domain-catalogs/search-phrases',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        object: 'DomainSearchPhraseGroup',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'VOICE_KIT',
        description: 'VOICE_KIT_DESCRIPTION',
        type: 'item',
        icon: 'graphic_eq',
        state: '/domain-catalogs/voice-kit',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainVoiceKit',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        state: '/domain-catalogs/knowledge-editor',
        name: 'MARKDOWN_EDIT',
        description: 'MARKDOWN_EDIT_DESCRIPTION',
        type: 'item',
        icon: 'school',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainDocument',
        obj_perm: [PermAction.DLIST]
      },
      {
        state: '/domain-catalogs/domain-file-storage',
        name: 'DOMAIN_FILE_STORAGE',
        description: 'DOMAIN_FILE_STORAGE',
        type: 'item',
        icon: 'school',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainFileStorage',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'VOIP_GSM_GATEWAYS',
        description: 'VOIP_GSM_GATEWAYS_DESCRIPTION',
        type: 'item',
        icon: 'sim_card',
        state: '/domain-catalogs/voip-gsm-gateways',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        object: 'DomainGateway',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'DOMAIN_PROJECTS',
        description: 'DOMAIN_PROJECTS_DESCRIPTION',
        type: 'item',
        icon: 'folder_shared',
        state: '/domain-catalogs/domain-projects',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainProject',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'WHITE_LABEL_SETTINGS',
        description: 'WHITE_LABEL_SETTINGS_DESCRIPTION',
        type: 'item',
        icon: 'folder_shared',
        state: '/domain-catalogs/domain-personal',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainWhiteLabel',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'AGENT_STATUSES',
        description: 'AGENT_STATUSES_DESCRIPTION',
        type: 'item',
        icon: 'fact_check',
        state: '/domain-catalogs/agent-statuses',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainAgentStatus',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'DOMAIN_AUDIO',
        description: 'DOMAIN_AUDIO_DESCRIPTION',
        type: 'item',
        icon: 'volume_up',
        state: '/domain-catalogs/audio',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        object: 'DomainAudioFile',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'LOCAL_STREAM',
        description: 'LOCAL_STREAM_DESCRIPTION',
        type: 'item',
        icon: 'insert_drive_file',
        state: '/domain-catalogs/local-stream',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        object: 'DomainAudioFile',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'ROLES',
        description: 'ROLES_DESCRIPTION',
        type: 'item',
        icon: 'verified_user',
        state: '/domain-catalogs/roles',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'Role',
        obj_perm: [
          PermAction.LIST
        ]
      },
      {
        name: 'DEVICES_AND_PROVISIONS',
        description: 'DEVICES_AND_PROVISIONS_DESCRIPTION',
        type: 'item',
        icon: 'mobile_screen_share',
        state: '/domain-catalogs/devices',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        children: [
          {
            name: 'DEVICE',
            description: 'DEVICE_DESCRIPTION',
            type: 'item',
            icon: 'mobile_screen_share',
            state: '/domain-catalogs/devices/device',
            role: ROLE_TYPE_ALL,
            mode: ALL_DOMAIN_MODE,
            view: [MenuView.DOMAIN],
            object: 'DomainDevice',
            obj_perm: [
              PermAction.LIST, PermAction.DLIST
            ],
          },
          {
            name: 'DOMAINAUTOPROVISIONSERVICES',
            description: 'DOMAINAUTOPROVISIONSERVICES_DESCRIPTION',
            type: 'item',
            icon: 'mobile_screen_share',
            state: '/domain-catalogs/devices/auto-provision-services',
            role: ROLE_TYPE_ALL,
            mode: [ModeDomain.BASE, ModeDomain.CC],
            view: [MenuView.DOMAIN],
            object: 'DomainDevice',
            obj_perm: [
              PermAction.LIST, PermAction.DLIST
            ]
          },
        ]
      },
      {
        name: 'ADDRESS_BOOK',
        description: 'ADDRESS_BOOK_DESCRIPTION',
        type: 'item',
        icon: 'menu_book',
        state: '/domain-catalogs/address-book',
        mode: [ModeDomain.BASE, ModeDomain.CC],
        role: ROLE_TYPE_ALL,
        view: [MenuView.DOMAIN],
        object: 'DomainAddressBook',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'SYSTEM_NOTIFY_TEMPLATES',
        description: 'SYSTEM_NOTIFY_TEMPLATES_DESCRIPTION',
        type: 'item',
        icon: 'priority_high',
        state: '/domain-catalogs/system-text-templates',
        params: {category: 3},
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'TextTemplate',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'DOMAINGATEWAYNUMBERPOOLGROUP',
        description: 'DOMAINGATEWAYNUMBERPOOLGROUP_DESCRIPTION',
        type: 'item',
        icon: 'screen_lock_rotation',
        state: '/domain-catalogs/number-rotation',
        license: 'NumberRotation',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainGatewayNumberPool',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'DOMAINHIDECONTACTS',
        description: 'DOMAINHIDECONTACTS_DESCRIPTION',
        type: 'item',
        icon: 'phone_locked',
        state: '/domain-catalogs/hide-contact',
        license: 'NumberRotation',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainHideContact',
        obj_perm: [
          PermAction.LIST
        ]
      },
      {
        name: 'DOMAINDETAILFORMS',
        description: 'DOMAINDETAILFORMS_DESCRIPTION',
        type: 'item',
        icon: 'preview',
        state: '/domain-catalogs/detail-form',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        object: 'DomainDetailForm',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'DOMAINDIALOGSCRIPTS',
        description: 'DOMAINDIALOGSCRIPTS_DESCRIPTION',
        type: 'item',
        icon: 'speaker_notes',
        state: '/domain-catalogs/dialog-script',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        children: [
          {
            name: 'DOMAINDIALOGSCRIPTS',
            description: 'DOMAINDIALOGSCRIPTS_DESCRIPTION',
            type: 'item',
            icon: 'speaker_notes',
            state: '/domain-catalogs/dialog-script/script',
            role: ROLE_TYPE_ALL,
            mode: ALL_DOMAIN_MODE,
            view: [MenuView.DOMAIN],
            object: 'DomainDialogScript',
            obj_perm: [
              PermAction.LIST, PermAction.DLIST
            ],
          },
          {
            name: 'DOMAINDIALOGFORMS',
            description: 'DOMAINFORMS_DESCRIPTION',
            type: 'item',
            icon: 'dynamic_form',
            state: '/domain-catalogs/dialog-script/form',
            role: ROLE_TYPE_ALL,
            mode: ALL_DOMAIN_MODE,
            view: [MenuView.DOMAIN],
            object: 'DomainDialogScriptForm',
            obj_perm: [
              PermAction.LIST, PermAction.DLIST
            ]
          }
        ]
      },
      {
        name: 'DOMAINORG',
        description: 'DOMAINORG_DESCRIPTION',
        type: 'item',
        icon: 'apartment',
        state: '/domain-catalogs/org',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        children: [
          {
            name: 'DOMAINORGUNITS',
            description: 'DOMAINORGUNITS_DESCRIPTION',
            type: 'item',
            icon: 'safety_divider',
            state: '/domain-catalogs/org/unit',
            role: ROLE_TYPE_ALL,
            mode: ALL_DOMAIN_MODE,
            view: [MenuView.DOMAIN],
            object: 'DomainOrgUnit',
            obj_perm: [
              PermAction.DLIST
            ]
          },
          {
            name: 'DOMAINORGPOSITIONS',
            description: 'DOMAINORGPOSITIONS_DESCRIPTION',
            type: 'item',
            icon: 'badge',
            state: '/domain-catalogs/org/position',
            role: ROLE_TYPE_ALL,
            mode: ALL_DOMAIN_MODE,
            view: [MenuView.DOMAIN],
            object: 'DomainOrgPosition',
            obj_perm: [PermAction.LIST]
          }
        ]
      }
    ]
  },
  {
    state: 'crm',
    name: 'CRM',
    type: 'dict',
    icon: 'contacts',
    role: ROLE_TYPE_ALL,
    mode: ALL_DOMAIN_MODE,
    license: 'Built-inCRM',
    map: [1],
    view: [MenuView.DOMAIN],
    object: 'DomainCRMCompany',
    obj_perm: [PermAction.LIST, PermAction.DLIST],
    children: [
      {
        name: 'DOMAINCRMCOMPANIES',
        description: 'DOMAINCRMCOMPANIES_DESCRIPTION',
        type: 'item',
        icon: 'maps_home_work',
        state: '/crm/companies',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        map: [1],
        license: 'Built-inCRM',
        view: [MenuView.DOMAIN],
        object: 'DomainCRMCompany',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'DOMAINCRMCONTACTS',
        description: 'DOMAINCRMCONTACTS_DESCRIPTION',
        type: 'item',
        icon: 'people',
        state: '/crm/contacts',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        map: [1],
        license: 'Built-inCRM',
        view: [MenuView.DOMAIN],
        object: 'DomainCRMEntity',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'DOMAINCRMREFEVENTS',
        description: 'DOMAINCRMREFEVENTS_DESCRIPTION',
        type: 'item',
        icon: 'receipt_long',
        state: '/crm/ref-events',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        license: 'Built-inCRM',
        view: [MenuView.DOMAIN],
        object: 'DomainCRMRefEvent',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'DOMAINCRMDFIELDCONFS',
        description: 'DOMAINCRMDFIELDCONFS_DESCRIPTION',
        type: 'item',
        icon: 'settings',
        state: '/crm/dfields',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        license: 'Built-inCRM',
        view: [MenuView.DOMAIN],
        object: 'DomainCRMDFieldConf',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'DOMAINCRMEVENTCALENDAR',
        description: 'DOMAINCRMEVENTCALENDAR_DESCRIPTION',
        type: 'item',
        icon: 'event',
        state: '/crm/event-calendar',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        license: 'Built-inCRM',
        view: [MenuView.DOMAIN],
        object: 'DomainCRMEventCalendar',
        obj_perm: [PermAction.DLIST]
      },
      {
        name: 'JOURNAL_GROUP_DFIELDS',
        description: 'JOURNAL_GROUP_DFIELDS_DESCRIPTION',
        type: 'item',
        icon: 'receipt_long',
        state: '/crm/journal-group-fields',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        license: 'Built-inCRM',
        view: [MenuView.DOMAIN],
        object: 'DomainCRMEvent',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'DOMAINCRMJOURNAL',
        description: 'DOMAINCRMJOURNAL_DESCRIPTION',
        type: 'item',
        icon: 'book',
        state: '/crm/journal',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        license: 'Built-inCRM',
        view: [MenuView.DOMAIN],
        object: 'DomainCRMEvent',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'DOMAINCRMSETTING',
        description: 'DOMAINCRMSETTING_DESCRIPTION',
        type: 'item',
        icon: 'settings',
        state: '/crm/settings',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        license: 'Built-inCRM',
        view: [MenuView.DOMAIN],
        object: 'DomainCRMEvent',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      }
    ]
  },
  {
    state: 'omni',
    name: 'DOC',
    type: 'dict',
    icon: 'account_tree',
    role: ROLE_TYPE_ALL,
    mode: [ModeDomain.CC],
    license: 'Built-inCustomerChat',
    map: [1],
    view: [MenuView.DOMAIN],
    object: 'DOCChannel',
    obj_perm: [PermAction.LIST, PermAction.DLIST],
    children: [
      {
        name: 'DOCCHANNELS',
        description: 'DOCCHANNELS_DESCRIPTION',
        type: 'item',
        icon: 'speaker_notes',
        state: '/omni/doc-channels',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        map: [1],
        license: 'Built-inCustomerChat',
        view: [MenuView.DOMAIN],
        object: 'DOCChannel',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'DOCCHATPLANS',
        description: 'DOCCHATPLANS_DESCRIPTION',
        type: 'item',
        icon: 'call_split',
        state: '/omni/doc-chatplans',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        map: [1],
        license: 'Built-inCustomerChat',
        view: [MenuView.DOMAIN],
        object: 'DOCChatPlan',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'DOCQUEUES',
        description: 'DOCQUEUES_DESCRIPTION',
        type: 'item',
        icon: 'contact_phone',
        state: '/omni/doc-queues',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        map: [1],
        license: 'Built-inCustomerChat',
        view: [MenuView.DOMAIN],
        object: 'DOCQueue',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'DOCDIALOGS',
        description: 'DOCDIALOGS_DESCRIPTION',
        type: 'item',
        icon: 'list',
        state: '/omni/doc-dialogs',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        map: [1],
        license: 'Built-inCustomerChat',
        view: [MenuView.DOMAIN],
        object: 'DOCDialog',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'OMNI_MONITOR',
        description: 'OMNI_MONITOR_DESCRIPTION',
        type: 'item',
        icon: 'list',
        state: '/omni/monitor',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        map: [1],
        license: 'Built-inCustomerChat',
        view: [MenuView.DOMAIN],
        object: 'DOCDialog',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'OMNI_DESKTOP',
        description: 'OMNI_DESKTOP_DESCRIPTION',
        type: 'item',
        icon: 'important_devices',
        state: '/omni/desktop',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        map: [1],
        license: 'Built-inCustomerChat',
        view: [MenuView.DOMAIN],
        object: 'DOCDialog',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'DOMAINCRMDFIELDCONFS',
        description: 'DOMAINOMNIDFIELDCONFS_DESCRIPTION',
        type: 'item',
        icon: 'settings',
        state: '/omni/dfields',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        license: 'Built-inCustomerChat',
        view: [MenuView.DOMAIN],
        object: 'DOCDFieldConf',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'DOC_REPORT_G1',
        description: 'DOC_REPORT_G1_DESCRIPTION',
        type: 'item',
        icon: 'show_chart',
        state: '/omni/doc-g1',
        license: 'Built-inCustomerChat',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DOCReportG1',
        obj_perm: [PermAction.GET, PermProjectAction.GET]
      },
      {
        name: 'DOC_REPORT_G2',
        description: 'DOC_REPORT_G2_DESCRIPTION',
        type: 'item',
        icon: 'show_chart',
        state: '/omni/doc-g2',
        license: 'Built-inCustomerChat',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DOCReportG2',
        obj_perm: [PermAction.GET, PermProjectAction.GET]
      },
      {
        name: 'OMNI_ATTEMPT',
        description: 'OMNI_ATTEMPT_DESCRIPTION',
        type: 'item',
        icon: 'coffee',
        state: '/omni/omni-attempt',
        license: 'Built-inCustomerChat',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DOCQueueAttemptStat',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'OMNI_MEMBER',
        description: 'OMNI_MEMBER_DESCRIPTION',
        type: 'item',
        icon: 'coffee',
        state: '/omni/omni-member',
        license: 'Built-inCustomerChat',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DOCQueueMemberStat',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
    ]
  },
  {
    state: 'domain-journals',
    name: 'DOMAIN_JOURNALS',
    type: 'dict',
    icon: 'book',
    role: ROLE_TYPE_ALL,
    map: [1],
    mode: ALL_DOMAIN_MODE,
    view: [MenuView.DOMAIN],
    children: [
      {
        name: 'FAX',
        description: 'FAX_DESCRIPTION',
        type: 'item',
        icon: 'receipt_long',
        state: '/domain-journals/fax',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        map: [1],
        view: [MenuView.DOMAIN],
        object: 'DomainFax',
        obj_perm: [
          PermAction.LIST, PermSelfAction.LIST
        ]
      },
      {
        name: 'TASK',
        description: 'TASK_DESCRIPTION',
        type: 'item',
        icon: 'task_alt',
        state: '/domain-journals/task',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        map: [1],
        view: [MenuView.DOMAIN],
        object: 'DomainCallTask',
        obj_perm: [
          PermAction.LIST, PermSelfAction.LIST
        ]
      },
      {
        name: 'VOICE_MAIL',
        description: 'VOICE_MAIL_DESCRIPTION',
        type: 'item',
        icon: 'mail',
        state: '/domain-journals/voice-mail',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        map: [1],
        view: [MenuView.DOMAIN],
        object: 'DomainVoiceMail',
        obj_perm: [
          PermAction.LIST, PermSelfAction.LIST
        ]
      },
      {
        name: 'REGISTRATION',
        description: 'REGISTRATION_DESCRIPTION',
        type: 'item',
        icon: 'support_agent',
        state: '/domain-journals/reg',
        map: [1],
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainUser',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'HOLD',
        description: 'HOLD_DESCRIPTION',
        type: 'item',
        icon: 'smart_button',
        state: '/domain-journals/hold',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainUser',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'JOURNAL_QUEUE_CDR',
        description: 'JOURNAL_QUEUE_CDR_DESCRIPTION',
        type: 'item',
        icon: 'smart_button',
        state: '/domain-journals/queue-cdr',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainUser',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'AUTH',
        description: 'AUTH_DESCRIPTION',
        type: 'item',
        icon: 'account_circle',
        state: '/domain-journals/auth',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'User',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'AGENT_STATUS',
        description: 'AGENT_STATUS_DESCRIPTION',
        type: 'item',
        icon: 'coffee',
        state: '/domain-journals/agent-status',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainUser',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'JOURNAL_MESSAGE',
        description: 'JOURNAL_MESSAGE_DESCRIPTION',
        type: 'item',
        icon: 'message',
        state: '/domain-journals/notify',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainNotify',
        obj_perm: [
          PermAction.LIST, PermAction.DLIST
        ]
      },
      {
        name: 'EVENT_DP',
        description: 'EVENT_DP_DESCRIPTION',
        type: 'item',
        icon: 'event',
        state: '/domain-journals/event',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainDPEResult',
        obj_perm: [
          PermAction.LIST, PermSelfAction.LIST
        ]
      },
      {
        name: 'DOMAINHIDECONTACTS',
        description: 'DOMAINHIDECONTACTS_DESCRIPTION',
        type: 'item',
        icon: 'phone_locked',
        state: '/domain-journals/hide-contact',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        license: 'NumberRotation',
        object: 'DomainHideContact',
        obj_perm: [
          PermAction.LIST, PermSelfAction.LIST
        ]
      },
      {
        name: 'LOGACTION',
        description: 'LOGACTION_DESCRIPTION',
        type: 'item',
        icon: 'auto_stories',
        state: '/domain-journals/actions',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        object: 'LogAction',
        obj_perm: [
          PermAction.LIST
        ]
      },
      {
        name: 'LOGEVENT',
        description: 'LOGEVENT_DESCRIPTION',
        type: 'item',
        icon: 'auto_stories',
        state: '/domain-journals/events',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        object: 'LogEvent',
        obj_perm: [
          PermAction.LIST
        ]
      },
      {
        name: 'JOURNAL.OMNI_AGENT_REG',
        description: 'JOURNAL.OMNI_AGENT_REG_DESCRIPTION',
        type: 'item',
        icon: 'auto_stories',
        state: '/domain-journals/omni-agent-reg',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.CC],
        view: [MenuView.DOMAIN],
        license: 'Built-inCustomerChat',
        object: 'DOCJournal',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'JOURNAL.OMNI_AGENT_STATUS',
        description: 'JOURNAL.OMNI_AGENT_STATUS_DESCRIPTION',
        type: 'item',
        icon: 'auto_stories',
        state: '/domain-journals/omni-agent-status',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.CC],
        view: [MenuView.DOMAIN],
        license: 'Built-inCustomerChat',
        object: 'DOCJournal',
        obj_perm: [
          PermAction.GET
        ]
      }
    ]
  },
  {
    state: 'pbx-hosts',
    name: 'HOSTS',
    type: 'link',
    icon: 'storage',
    role: ROLE_TYPE_PROVIDER,
    view: [MenuView.PROVIDER],
    object: 'PbxHost',
    obj_perm: [
      PermAction.DLIST
    ]
  },
  {
    state: 'domains',
    name: 'DOMAINS',
    type: 'link',
    icon: 'business_center',
    role: ROLE_TYPE_PROVIDER,
    view: [MenuView.PROVIDER],
    object: 'Domain',
    obj_perm: [
      PermAction.LIST, PermAction.DLIST
    ]
  },
  {
    state: 'pbx-settings',
    name: 'SYSTEM_SETTINGS',
    type: 'link',
    icon: 'settings',
    role: ROLE_TYPE_PROVIDER,
    view: [MenuView.PROVIDER],
    children: [
      {
        state: '/pbx-settings/system',
        name: 'SERVER_SETTINGS',
        description: 'SERVER_SETTINGS_DESCRIPTION',
        type: 'item',
        icon: 'cast',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'GSSystem',
        obj_perm: [PermAction.GET]
      },
      {
        state: '/pbx-settings/default',
        name: 'DEFAULT_SETTINGS',
        description: 'DEFAULT_SETTINGS_DESCRIPTION',
        type: 'item',
        icon: 'miscellaneous_services',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'GSDefaults',
        obj_perm: [PermAction.GET]
      },
      {
        state: '/pbx-settings/smtp',
        name: 'SMTP_SETTINGS',
        description: 'SMTP_SETTINGS_DESCRIPTION',
        type: 'item',
        icon: 'email',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'GSSmtp',
        obj_perm: [PermAction.GET]
      },
      {
        name: 'GSCROSSDOMAIN',
        description: 'GSCROSSDOMAIN_DESCRIPTION',
        type: 'item',
        icon: 'mediation',
        state: '/pbx-settings/gscross',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'GSCrossDomainPermission',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        state: '/pbx-settings/voice-directory',
        name: 'DPE.VOICE_DIRECTORY',
        description: 'DPE.VOICE_DIRECTORY_DESCRIPTION',
        type: 'item',
        icon: 'graphic_eq',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'GSVoiceDirectory',
        obj_perm: [PermAction.GET]
      },
      {
        state: '/pbx-settings/integration',
        name: 'INTEGRATION_SYSTEM_SETTINGS',
        description: 'INTEGRATION_SYSTEM_SETTINGS_DESCRIPTION',
        type: 'item',
        icon: 'extension',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        children: [
          {
            state: '/pbx-settings/integration/telegram',
            name: 'TG',
            description: 'TG_NAVS_DESCRIPTION',
            type: 'item',
            icon: 'telegram',
            role: ROLE_TYPE_PROVIDER,
            view: [MenuView.PROVIDER],
            object: 'TelegramSystemBotMember',
            obj_perm: [PermAction.DLIST]
          },
          {
            state: '/pbx-settings/integration/amocrm',
            name: 'DEPAMOCRM',
            description: 'AMOCRM_SETTINGS_DESCRIPTION',
            type: 'item',
            icon: 'amo',
            role: ROLE_TYPE_PROVIDER,
            view: [MenuView.PROVIDER],
            object: 'GSIntegrationAmoCRM',
            obj_perm: [PermAction.GET]
          },
          {
            state: '/pbx-settings/integration/bitrix24',
            name: 'DEPBITRIX24',
            type: 'item',
            description: 'BITRIX24_SETTINGS_DESCRIPTION',
            icon: 'bitrix24',
            role: ROLE_TYPE_PROVIDER,
            view: [MenuView.PROVIDER],
            object: 'GSIntegrationBitrix24',
            obj_perm: [PermAction.GET]
          },
          {
            state: '/pbx-settings/integration/nicru',
            name: 'NICRU',
            type: 'item',
            description: 'NICRU_DESCRIPTION',
            icon: 'nic',
            role: ROLE_TYPE_PROVIDER,
            view: [MenuView.PROVIDER],
            object: 'GSIntegrationNicRU',
            obj_perm: [PermAction.GET]
          },
          {
            state: '/pbx-settings/integration/retailcrm',
            name: 'DEPRETAILCRM',
            type: 'item',
            description: 'RETAILCRM_SETTINGS_DESCRIPTION',
            icon: 'retail',
            role: ROLE_TYPE_PROVIDER,
            view: [MenuView.PROVIDER],
            object: 'GSIntegrationRetailCRM',
            obj_perm: [PermAction.GET]
          },
          {
            state: '/pbx-settings/integration/yandex-disk',
            name: 'DEPYANDEXDISK',
            type: 'item',
            description: 'GSINTEGRATIONYANDEXDISK_DESCRIPTION',
            icon: 'yadisk',
            role: ROLE_TYPE_PROVIDER,
            view: [MenuView.PROVIDER],
            object: 'GSIntegrationYandexDisk',
            obj_perm: [PermAction.GET]
          },
          {
            state: '/pbx-settings/integration/yandex-tracker',
            name: 'DEPYANDEXTRACKER',
            type: 'item',
            description: 'GSINTEGRATIONYANDEXTRACKER_DESCRIPTION',
            icon: 'yatracker',
            role: ROLE_TYPE_PROVIDER,
            view: [MenuView.PROVIDER],
            object: 'GSIntegrationYandexTracker',
            obj_perm: [PermAction.GET]
          },
          {
            state: '/pbx-settings/integration/yandex-metrika',
            name: 'DEPYANDEXMETRIKA',
            type: 'item',
            description: 'GSINTEGRATIONYANDEXMETRIKA_DESCRIPTION',
            icon: 'yametrika',
            role: ROLE_TYPE_PROVIDER,
            view: [MenuView.PROVIDER],
            object: 'GSIntegrationYandexMetrika',
            obj_perm: [PermAction.GET]
          },

          {
            state: '/pbx-settings/integration/power-dns',
            name: 'GSINTEGRATIONPOWERDNS',
            type: 'item',
            description: 'GGSINTEGRATIONPOWERDNS_DESCRIPTION',
            icon: 'powerdns',
            role: ROLE_TYPE_PROVIDER,
            view: [MenuView.PROVIDER],
            object: 'GSIntegrationPowerDNS',
            obj_perm: [PermAction.GET]
          },
          {
            state: '/pbx-settings/integration/yclients',
            name: 'GSINTEGRATIONYCLIENTS',
            type: 'item',
            description: 'GSINTEGRATIONYCLIENTS_DESCRIPTION',
            icon: 'yclients',
            role: ROLE_TYPE_PROVIDER,
            view: [MenuView.PROVIDER],
            object: 'GSIntegrationYClients',
            obj_perm: [PermAction.GET]
          },
          {
            state: '/pbx-settings/integration/altegio',
            name: 'GSINTEGRATIONALTEGIO',
            type: 'item',
            description: 'GSINTEGRATIONALTEGIO_DESCRIPTION',
            icon: 'altegio',
            role: ROLE_TYPE_PROVIDER,
            view: [MenuView.PROVIDER],
            object: 'GSIntegrationAltegio',
            obj_perm: [PermAction.GET]
          },
          {
            state: '/pbx-settings/integration/sbercrm',
            name: 'GSINTEGRATIONSBERCRM',
            description: 'CRM_SETTINGS_DESCRIPTION',
            type: 'item',
            icon: 'sber',
            role: ROLE_TYPE_PROVIDER,
            view: [MenuView.PROVIDER],
            object: 'GSIntegrationSberCRM',
            obj_perm: [PermAction.GET]
          },
        ]
      },
      {
        state: '/pbx-settings/white-label',
        name: 'WHITE_LABEL_SETTINGS',
        description: 'WHITE_LABEL_SETTINGS_DESCRIPTION',
        type: 'item',
        icon: 'badge',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'GSWhiteLabel',
        obj_perm: [PermAction.GET]
      },
      {
        state: '/pbx-settings/front-hosts',
        name: 'FRONT_HOSTS',
        description: 'FRONT_HOSTS_DESCRIPTION',
        type: 'item',
        icon: 'storage',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'FrontHost',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        state: '/pbx-settings/defender-hosts',
        name: 'DEFENDER_HOSTS',
        description: 'DEFENDER_HOSTS_DESCRIPTION',
        type: 'item',
        icon: 'shield',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'DefenderHost',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        state: '/pbx-settings/cfto',
        name: 'GSCLUSTERINGANDFAULTTOLERANCEOPTIONS',
        description: 'GSCLUSTERINGANDFAULTTOLERANCEOPTIONS_DESCRIPTION',
        type: 'item',
        icon: 'pattern',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'GSClusteringAndFaultToleranceOptions',
        obj_perm: [PermAction.GET]
      },
      {
        state: '/pbx-settings/system-actions',
        name: 'SYSTEM_ACTIONS',
        description: 'SYSTEM_ACTIONS_DESCRIPTION',
        type: 'item',
        icon: 'build',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'GeoCode', // хотя там много объектов, выбрала это, чтобы хотя бы отобразить пункт
        obj_perm: [PermAction.GET]
      },
      {
        state: '/pbx-settings/task',
        name: 'LOCKED',
        description: 'LOCKED_DESCRIPTION',
        type: 'item',
        icon: 'lock',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'TaskManager',
        obj_perm: [PermAction.GET]
      },
      {
        state: '/pbx-settings/system-logging',
        name: 'SYSTEM_LOGGING',
        description: 'SYSTEM_LOGGING_DESCRIPTION',
        type: 'item',
        icon: 'receipt_long',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'GSLoggingDB',
        obj_perm: [PermAction.GET]
      }
    ]
  },
  {
    state: 'queues',
    name: 'QUEUES',
    type: 'link',
    icon: 'contact_phone',
    role: ROLE_TYPE_ALL,
    mode: ALL_DOMAIN_MODE,
    view: [MenuView.DOMAIN],
    object: 'DomainQueue',
    obj_perm: [
      PermAction.LIST, PermAction.DLIST, PermProjectAction.LIST, PermProjectAction.DLIST
    ]
  },
  {
    state: 'aons',
    name: 'AONS',
    type: 'link',
    icon: 'local_phone',
    role: ROLE_TYPE_ALL,
    mode: ALL_DOMAIN_MODE,
    view: [MenuView.DOMAIN],
    object: 'DomainGateway',
    obj_perm: [
      PermAction.LIST, PermAction.DLIST
    ]
  },
  {
    state: 'dialplans',
    name: 'DIALPLANS',
    type: 'link',
    icon: 'call_split',
    role: ROLE_TYPE_ALL,
    mode: [ModeDomain.BASE, ModeDomain.CC],
    view: [MenuView.DOMAIN],
    object: 'DomainDialPlan',
    obj_perm: [
      PermAction.LIST, PermAction.DLIST
    ]
  },
  // {
  //   state: 'conferences',
  //   name: 'CONFERENCES',
  //   type: 'link',
  //   icon: 'groups',
  //   role: ROLE_TYPE_ALL,
  //   mode: ALL_DOMAIN_MODE,
  //   view: [MenuView.DOMAIN],
  //   object: 'DomainConference',
  //   obj_perm: [
  //     PermAction.LIST, PermAction.DLIST
  //   ]
  // },
  {
    state: 'domain-teams',
    name: 'DOMAINMEETINGS',
    type: 'dict',
    icon: 'groups_3',
    role: ROLE_TYPE_ALL,
    mode: [ModeDomain.BASE, ModeDomain.CC],
    license: 'Domain Teams',
    view: [MenuView.DOMAIN],
    // object: 'DomainTeam',
    // obj_perm: [PermAction.DLIST],
    children: [
      {
        name: 'DOMAINMEETING_CALENDAR',
        description: 'DOMAINMEETING_CALENDAR_DESCRIPTION',
        type: 'item',
        icon: 'event',
        state: '/domain-teams/domain-meetings',
        role: ROLE_TYPE_DOMAIN,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        license: 'Domain Teams',
        view: [MenuView.DOMAIN],
        object: 'DTMeeting',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'SETTINGS',
        description: 'DOMAINMEETING_SETTINGS_DESCRIPTION',
        type: 'item',
        icon: 'settings',
        state: '/domain-teams/settings',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        license: 'Domain Teams',
        view: [MenuView.DOMAIN],
        object: 'DTMeetingSetting',
        obj_perm: [PermAction.GET]
      },
      {
        name: 'CURRENT_MEETS',
        description: 'CURRENT_MEETS_DESCRIPTION',
        type: 'item',
        icon: 'groups',
        state: '/domain-teams/current',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        license: 'Domain Teams',
        map: [1],
        view: [MenuView.DOMAIN],
        object: 'DTMeeting',
        obj_perm: [
          PermAction.LIST, PermSelfAction.LIST
        ]
      },
      {
        name: 'PAST_MEETS',
        description: 'PAST_MEETS_DESCRIPTION',
        type: 'item',
        icon: 'groups',
        state: '/domain-teams/conference',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        license: 'Domain Teams',
        map: [1],
        view: [MenuView.DOMAIN],
        object: 'DTMeeting',
        obj_perm: [
          PermAction.LIST, PermSelfAction.LIST
        ]
      },
    ]
  },
  {
    state: 'billing-reports',
    name: 'BILLING_REPORTS',
    type: 'dict',
    icon: 'insert_chart_outlined',
    role: ROLE_TYPE_PROVIDER,
    view: [MenuView.PROVIDER],
    children: [
      {
        name: 'BILLING_REPORTS_DOMAIN',
        description: 'BILLING_REPORTS_DOMAIN_DESCRIPTION',
        type: 'item',
        icon: 'table_chart',
        state: '/billing-reports/domain-period',
        role: ROLE_TYPE_PROVIDER,
        view: [MenuView.PROVIDER],
        object: 'SystemDashboard',
        obj_perm: [PermAction.GET]

      }]
  },
  {
    state: 'qm',
    name: 'QM',
    type: 'dict',
    icon: 'high_quality',
    role: ROLE_TYPE_ALL,
    mode: [ModeDomain.CC],
    view: [MenuView.DOMAIN],
    license: 'QualityManagement',
    children: [
      {
        name: 'QUALITY_SCRIPTS',
        description: 'QUALITY_SCRIPTS_DESCRIPTION',
        type: 'item',
        icon: 'fact_check',
        state: '/qm/scripts',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.CC],
        view: [MenuView.DOMAIN],
        license: 'QualityManagement',
        object: 'DomainQMScript',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'DOMAINQMCALLRATING',
        description: 'DOMAINQMCALLRATING_DESCRIPTION',
        type: 'item',
        icon: 'thumb_up',
        state: '/qm/call-rating',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.CC],
        view: [MenuView.DOMAIN],
        license: 'QualityManagement',
        object: 'DomainQMCallRating',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      }
    ]
  },
  {
    state: 'reports',
    name: 'REPORTS',
    type: 'dict',
    icon: 'insert_chart_outlined',
    role: ROLE_TYPE_ALL,
    mode: ALL_DOMAIN_MODE,
    map: [1],
    view: [MenuView.DOMAIN],
    children: [
      {
        name: 'CALLS_HISTORY',
        description: 'CALLS_HISTORY_DESCRIPTION',
        type: 'item',
        icon: 'table_chart',
        state: '/reports/calls-history',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        map: [1],
        view: [MenuView.DOMAIN],
        object: 'DomainReportCallsHistory',
        obj_perm: [PermAction.GET, PermSelfAction.GET, PermProjectAction.GET]
      },
      {
        name: 'GROUP_HISTORY',
        description: 'GROUP_HISTORY_DESCRIPTION',
        type: 'item',
        icon: 'bar_chart',
        state: '/reports/group-history',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainReportGroupV1',
        obj_perm: [PermAction.GET, PermSelfAction.GET, PermProjectAction.GET]
      },
      {
        name: 'KPI_OPERATOR',
        description: 'KPI_OPERATOR_DESCRIPTION',
        type: 'item',
        icon: 'show_chart',
        state: '/reports/kpi',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainReportOperatorsPerformance',
        obj_perm: [PermAction.GET, PermProjectAction.GET]
      },
      {
        name: 'SL_OPERATORS',
        description: 'SL_OPERATORS_DESCRIPTION',
        type: 'item',
        icon: 'show_chart',
        state: '/reports/sl',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainReportSLByUsers',
        obj_perm: [PermAction.GET, PermProjectAction.GET]
      },

      {
        name: 'IVR_REPORT_OPERATOR',
        description: 'IVR_REPORT_OPERATOR_DESCRIPTION',
        type: 'item',
        icon: 'show_chart',
        state: '/reports/ivr',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainDPEResult',
        obj_perm: [PermAction.LIST, PermSelfAction.LIST]
      },
      {
        name: 'QUEUE_CALLS',
        description: 'QUEUE_CALLS_DESCRIPTION',
        type: 'item',
        icon: 'groups',
        state: '/reports/queue',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainReportQueueCalls',
        obj_perm: [PermAction.GET, PermProjectAction.GET]
      },
      {
        name: 'INBOUND_ANALYZE',
        description: 'INBOUND_ANALYZE_DESCRIPTION',
        type: 'item',
        icon: 'show_chart',
        state: '/reports/inbound-analyze',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainReportInboundAnalyze',
        obj_perm: [PermAction.GET, PermProjectAction.GET]
      },
      {
        name: 'QUEUE_ANALYZE',
        description: 'QUEUE_ANALYZE_DESCRIPTION',
        type: 'item',
        icon: 'show_chart',
        state: '/reports/queue-analyze',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainReportDailyQueueRates',
        obj_perm: [PermAction.GET, PermProjectAction.GET]
      },
      {
        name: 'MONITORING',
        description: 'MONITORING_DESCRIPTION',
        type: 'item',
        icon: 'desktop_mac',
        state: '/reports/monitor',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        map: [1],
        view: [MenuView.DOMAIN],
        object: 'DomainCCMonitor',
        obj_perm: [PermAction.GET, PermProjectAction.GET]
      },
      {
        name: 'MONITORING2',
        description: 'MONITORING_DESCRIPTION2',
        type: 'item',
        icon: 'support_agent',
        state: '/reports/monitor2',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.CC],
        map: [1],
        view: [MenuView.DOMAIN],
        object: 'DomainCCMonitor2',
        obj_perm: [PermAction.GET, PermProjectAction.GET, PermSelfAction.GET]
      },
      {
        name: 'QUEUE_REPORT_G1',
        description: 'QUEUE_REPORT_G1_DESCRIPTION',
        type: 'item',
        icon: 'show_chart',
        state: '/reports/queue-g1',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainReportQueueG1',
        obj_perm: [PermAction.GET, PermProjectAction.GET]
      },
      {
        name: 'REPORT_USER_ACTIVITY',
        description: 'REPORT_USER_ACTIVITY_DESCRIPTION',
        type: 'item',
        icon: 'show_chart',
        state: '/reports/user-activity',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainReportDomainUserActivity',
        obj_perm: [PermAction.GET, PermProjectAction.GET]
      },
      {
        name: 'REPORT_SEARCH_PHRASE',
        description: 'REPORT_SEARCH_PHRASE_DESCRIPTION',
        type: 'item',
        icon: 'show_chart',
        state: '/reports/search-phrase',
        role: ROLE_TYPE_ALL,
        map: [1],
        mode: [ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainReportPhraseGroupAnalytic',
        obj_perm: [PermAction.GET, PermProjectAction.GET]
      },
      // {
      //   name: 'DOMAINHIDECONTACTCALL',
      //   description: 'DOMAINHIDECONTACTCALL_DESCRIPTION',
      //   type: 'item',
      //   icon: 'phone_locked',
      //   state: '/reports/hide-contact-call',
      //   role: ROLE_TYPE_ALL,
      //   mode: [ModeDomain.BASE, ModeDomain.CC],
      //   view: [MenuView.DOMAIN],
      //   license: 'NumberRotation',
      //   object: 'DomainHideContactCall',
      //   obj_perm: [PermAction.GET, PermProjectAction.GET, PermSelfAction.GET]
      // }
    ]
  },

  {
    state: 'dialer',
    name: 'DIALER',
    type: 'dict',
    icon: 'support_agent',
    role: ROLE_TYPE_ALL,
    mode: [ModeDomain.CC],
    view: [MenuView.DOMAIN],
    object: 'DomainDialer',
    obj_perm: [PermAction.LIST, PermAction.DLIST],
    children: [
      {
        name: 'DILER_TASK',
        description: 'DILER_TASK_DESCRIPTION',
        type: 'item',
        icon: 'support_agent',
        state: '/dialer/task',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        map: [1],
        view: [MenuView.DOMAIN],
        object: 'DomainDialer',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'DILER_REPORT_CRM_RESULT',
        description: 'DILER_REPORT_CRM_RESULT_DESCRIPTION',
        type: 'item',
        icon: 'article',
        state: '/dialer/report-crm-result',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        map: [1],
        view: [MenuView.DOMAIN],
        object: 'DomainReportDialerReportCRMResult',
        obj_perm: [PermAction.GET]
      },
      {
        name: 'DIALER_REPORT_AUTOCALL',
        description: 'DIALER_REPORT_AUTOCALL_DESCRIPTION',
        type: 'item',
        icon: 'settings_phone',
        state: '/dialer/report-autocall',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        map: [1],
        view: [MenuView.DOMAIN],
        object: 'DomainReportDialerReportG1',
        obj_perm: [PermAction.GET]
      }
    ]
  },

  // {
  //   state: 'chat',
  //   name: 'CHAT',
  //   type: 'dict',
  //   icon: 'message',
  //   role: ROLE_TYPE_ALL,
  //   mode: [ModeDomain.CC],
  //   view: [MenuView.DOMAIN],
  //   license: 'Built-inChat',
  //   object: 'DomainPersonalChat',
  //   obj_perm: [PermAction.LIST, PermAction.DLIST],
  //   children: [
  //     {
  //       name: 'DOMAINCHATSOURCE',
  //       description: 'DOMAINCHATSOURCE_DESCRIPTION',
  //       type: 'item',
  //       icon: 'maps_home_work',
  //       state: '/chat/source',
  //       role: ROLE_TYPE_ALL,
  //       mode: ALL_DOMAIN_MODE,
  //       map: [1],
  //       license: 'Built-inChat',
  //       view: [MenuView.DOMAIN],
  //       object: 'DomainCustomerChat',
  //       obj_perm: [PermAction.LIST, PermAction.DLIST]
  //     },
  //     {
  //       name: 'DOMAINCHAT',
  //       description: 'DOMAINCHAT_DESCRIPTION',
  //       type: 'item',
  //       icon: 'people',
  //       state: '/chat/messenger',
  //       role: ROLE_TYPE_ALL,
  //       mode: ALL_DOMAIN_MODE,
  //       map: [1],
  //       license: 'Built-inChat',
  //       view: [MenuView.DOMAIN],
  //       object: 'DomainPersonalChat',
  //       obj_perm: [PermAction.LIST, PermAction.DLIST]
  //     },
  //     {
  //       name: 'DOMAINCHATPLAN',
  //       description: 'DOMAINCHATPLAN_DESCRIPTION',
  //       type: 'item',
  //       icon: 'call_split',
  //       state: '/chat/plan',
  //       role: ROLE_TYPE_ALL,
  //       mode: ALL_DOMAIN_MODE,
  //       map: [1],
  //       license: 'Built-inChat',
  //       view: [MenuView.DOMAIN],
  //       object: 'DomainPersonalChat',
  //       obj_perm: [PermAction.LIST, PermAction.DLIST]
  //     }
  //   ]
  // },

  {
    state: 'marketplace',
    name: 'MARKETPLACE',
    type: 'link',
    icon: 'shopping_basket',
    role: ROLE_TYPE_ALL,
    mode: ALL_DOMAIN_MODE,
    view: [MenuView.DOMAIN],
    object: 'DomainExtension',
    obj_perm: [PermAction.LIST, PermAction.DLIST],
    children: [
      // порядок интеграций важен!! вставлять интеграции с иконкой соответсвующей секции!!
      // params:
      //   - tariff_name - название интеграции в биллинге;
      //   - dep_name - название интеграции в БД (к.п. начинается на DEP...)
      {
        name: 'DEPAMOCRM',
        description: 'AMOCRM_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/amocrm',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'AmoCRM',
          dep_name: 'DEPAmoCRM'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPBITRIX24',
        description: 'BITRIX24_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/bitrix24',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'Bitrix24',
          dep_name: 'DEPBitrix24'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPBPMONLINE',
        description: 'DEPBPMONLINE_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/bpmonline',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'BPM Online',
          dep_name: 'DEPBPMOnline'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPRETAILCRM',
        description: 'DEPRETAILCRM_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/retailcrm',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'RetailCRM',
          dep_name: 'DEPRetailCRM'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPELMA365',
        description: 'DEPELMA365_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/elma365',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'ELMA365',
          dep_name: 'DEPElma365'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPROISTAT',
        description: 'DEPROISTAT_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/roistat',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'RoiStat',
          dep_name: 'DEPRoiStat'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPYCLIENTS',
        description: 'DEPYCLIENTS_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/yclients',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'YClients',
          dep_name: 'DEPYClients'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPALTEGIO',
        description: 'DEPALTEGIO_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/altegio',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'Altegio',
          dep_name: 'DEPAltegio'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPPROSTIEZVONKI',
        description: 'DEPPROSTIEZVONKI_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/prostie-zvonki',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'ProstieZvonki',
          dep_name: 'DEPProstieZvonki'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPMEGAPLAN',
        description: 'DEPMEGAPLAN_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/megaplan',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'Megaplan',
          dep_name: 'DEPMegaplan'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPESTAFF',
        description: 'DEPESTAFF_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/estaff',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'E-Staff',
          dep_name: 'DEPEStaff'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPARGUSWFM',
        description: 'DEPARGUSWFM_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/arguswfm',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'Argus WFM',
          dep_name: 'DEPArgusWFM'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPUSEDESK',
        description: 'DEPUSEDESK_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/usedesk',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'UseDesk',
          dep_name: 'DEPUsedesk'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPUSERCRM',
        description: 'DEPUSERCRM_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/usercrm',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'UserCRM',
          dep_name: 'DEPUserCRM'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPENVYCRM',
        description: 'DEPENVYCRM_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/envycrm',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'EnvyCRM',
          dep_name: 'DEPEnvyCRM'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPNEURONET',
        description: 'DEPNEURONET_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/neuro-net',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'NeuroNet',
          dep_name: 'DEPNeuroNet'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPSKILLAZ',
        description: 'DEPSKILLAZ_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/skillaz',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'Skillaz',
          dep_name: 'DEPSkillaz'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPPLANFIX',
        description: 'DEPPLANFIX_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/planfix',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'PlanFix',
          dep_name: 'DEPPlanFix'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPCASCANA',
        description: 'DEPCASCANA_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/cascana',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'Cascana',
          dep_name: 'DEPCascana'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPSBERCRM',
        description: 'DEPSBERCRM_DESCRIPTION',
        type: 'item',
        icon: 'integration_instructions',
        state: '/marketplace/sbercrm',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'SberCRM',
          dep_name: 'DEPSberCRM'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },

      {
        name: 'DEPGOOTAX',
        description: 'GOOTAX_DESCRIPTION',
        type: 'item',
        icon: 'local_taxi',
        state: '/marketplace/gootax',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'GooTax',
          dep_name: 'DEPGooTax'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPRBTAXI',
        description: 'RBTAXI_DESCRIPTION',
        type: 'item',
        icon: 'local_taxi',
        state: '/marketplace/rbtaxi',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'RBTaxi',
          dep_name: 'DEPRBTaxi'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPYANDEXTAXI',
        description: 'YANDEXTAXI_DESCRIPTION',
        type: 'item',
        icon: 'local_taxi',
        state: '/marketplace/ytaxi',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'YandexTaxi',
          dep_name: 'DEPYandexTaxi'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },

      {
        name: 'DEPYANDEXSPEECHKIT',
        description: 'YANDEX_SPEECHKIT_DESCRIPTION',
        type: 'item',
        icon: 'mic_external_on',
        state: '/marketplace/yandex-speech-kit',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'YandexSpeechKit',
          dep_name: 'DEPYandexSpeechKit'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPTINKOFFVOICEKIT',
        description: 'DEPTINKOFFVOICEKIT_DESCRIPTION',
        type: 'item',
        icon: 'mic_external_on',
        state: '/marketplace/tinkoff-voice-kit',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'TinkoffVoiceKit',
          dep_name: 'DEPTinkoffVoiceKit'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPTINKOFFTQM',
        description: 'DEPTINKOFFTQM_DESCRIPTION',
        type: 'item',
        icon: 'high_quality',
        state: '/marketplace/tinkoff-qm',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'TinkoffTQM',
          dep_name: 'DEPTinkoffTQM'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEP3ITECHANALYTICS',
        description: 'DEP3ITECHANALYTICS_DESCRIPTION',
        type: 'item',
        icon: 'mic_external_on',
        state: '/marketplace/3itech',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: '3iTechAnalytics',
          dep_name: 'DEP3iTechAnalytics'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },

      {
        name: 'DEPYANDEXTRACKER',
        description: 'DEPYANDEXTRACKER_DESCRIPTION',
        type: 'item',
        icon: 'storage',
        state: '/marketplace/yandex-tracker',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'YandexTracker',
          dep_name: 'DEPYandexTracker'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPYANDEXDISK',
        description: 'DEPYANDEXDISK_DESCRIPTION',
        type: 'item',
        icon: 'storage',
        state: '/marketplace/yandex-disk',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'Yandex.Disk',
          dep_name: 'DEPYandexDisk'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPYANDEXMETRIKA',
        description: 'DEPYANDEXMETRIKA_DESCRIPTION',
        type: 'item',
        icon: 'storage',
        state: '/marketplace/yandex-metrika',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'YandexMetrika',
          dep_name: 'DEPYandexMetrika'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPSFTPDISK',
        description: 'DEPSFTPDISK_DESCRIPTION',
        type: 'storage',
        icon: 'integration_instructions',
        state: '/marketplace/sftp-disk',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'SftpDisk',
          dep_name: 'DEPSftpDisk'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },

      {
        name: 'DEPSMSTRAFFICRU',
        description: 'DEPSMSTRAFFICRU_DESCRIPTION',
        type: 'item',
        icon: 'sms',
        state: '/marketplace/sms-traffic-ru',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'SMSTrafficRu',
          dep_name: 'DEPSMSTrafficRu'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPMEGAFONA2PSMS',
        description: 'DEPMEGAFONA2PSMS_DESCRIPTION',
        type: 'item',
        icon: 'sms',
        state: '/marketplace/megafon-a2p-sms',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'Megafon A2P SMS',
          dep_name: 'DEPMegafonA2PSMS'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPWEBCALL',
        description: 'DEPWEBCALL_DESCRIPTION',
        type: 'item',
        icon: 'devices_other',
        state: '/marketplace/webcall',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'WebCall',
          dep_name: 'DEPWebCall'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      },
      {
        name: 'DEPMICROSOFTSKYPEFORBUSINESS',
        description: 'DEPMICROSOFTSKYPEFORBUSINESS_DESCRIPTION',
        type: 'item',
        icon: 'connect_without_contact',
        state: '/marketplace/s4b',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        params: {
          tariff_name: 'MicrosoftSkypeForBusiness',
          dep_name: 'DEPMicrosoftSkypeForBusiness'
        },
        object: 'DomainExtension',
        obj_perm: [
          PermAction.GET
        ]
      }
    ]
  },
  {
    state: 'domain-settings',
    name: 'DOMAIN_SETTINGS',
    type: 'dict',
    icon: 'settings',
    role: ROLE_TYPE_ALL,
    mode: ALL_DOMAIN_MODE,
    view: [MenuView.DOMAIN],
    children: [
      {
        name: 'SETTINGS',
        description: 'DOMAIN_SETTINGS.DESCRIPTION',
        type: 'item',
        icon: 'settings',
        state: '/domain-settings/settings',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        object: 'DomainSettings',
        obj_perm: [PermAction.GET]
      },
      {
        name: 'DOMAIN_SETTINGS.ACTIVE_DIRECTORY',
        description: 'DOMAIN_SETTINGS.ACTIVE_DIRECTORY_DESCRIPTION',
        type: 'item',
        icon: 'fa-windows',
        state: '/domain-settings/active-directory',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        license: 'ExternalAuthSystems',
        object: 'DomainExternalAuth',
        obj_perm: [PermAction.GET]
      },
      {
        name: 'DOMAIN_SETTINGS.AZURE',
        description: 'DOMAIN_SETTINGS.AZURE_DESCRIPTION',
        type: 'item',
        icon: 'fa-windows',
        state: '/domain-settings/azure',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        license: 'ExternalAuthSystems',
        object: 'DomainExternalAuth',
        obj_perm: [PermAction.GET]
      },
      {
        name: 'DOMAIN_SETTINGS.ADFS',
        description: 'DOMAIN_SETTINGS.ADFS_DESCRIPTION',
        type: 'item',
        icon: 'fa-windows',
        state: '/domain-settings/adfs',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        license: 'ExternalAuthSystems',
        object: 'DomainExternalAuth',
        obj_perm: [PermAction.GET]
      },
      {
        name: 'DOMAINDEVICERENTAL',
        description: 'DOMAINDEVICERENTAL_DESCRIPTION',
        type: 'item',
        icon: 'mobile_screen_share',
        state: '/domain-settings/device-rental',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'VoiceDirectory',
        obj_perm: [PermAction.GET]
      },
      {
        name: 'DPE.ALARM',
        description: 'DPE.ALARM_DESCRIPTION',
        type: 'item',
        icon: 'alarm',
        state: '/domain-settings/dp-alarm',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainDialPlanAlarm',
        obj_perm: [PermAction.GET]
      },
      {
        name: 'DPE.VOICE_DIRECTORY',
        description: 'DPE.VOICE_DIRECTORY_DESCRIPTION',
        type: 'item',
        icon: 'graphic_eq',
        state: '/domain-settings/voice-directory',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'VoiceDirectory',
        obj_perm: [PermAction.GET]
      },
      {
        name: 'DOMAINEVENTHANDLERS',
        description: 'DOMAINEVENTHANDLERS_DESCRIPTION',
        type: 'item',
        icon: 'precision_manufacturing',
        state: '/domain-settings/event-handling',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        object: 'DomainEventHandling',
        obj_perm: [PermAction.LIST, PermAction.DLIST]
      },
      {
        name: 'DOMAINEAPI',
        description: 'DOMAINEAPI_DESCRIPTION',
        type: 'item',
        icon: 'widgets',
        state: '/domain-settings/eapi',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainEAPI',
        obj_perm: [PermAction.GET]
      },
      {
        name: 'DOMAINCTI',
        description: 'DOMAINCTI_DESCRIPTION',
        type: 'item',
        icon: 'phonelink_setup',
        state: '/domain-settings/cti',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        object: 'DomainCTI',
        obj_perm: [PermAction.GET]
      },
      {
        name: 'DOMAINOMNI',
        description: 'DOMAINOMNI_DESCRIPTION',
        type: 'item',
        icon: 'account_tree',
        state: '/domain-settings/omni',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.CC],
        view: [MenuView.DOMAIN],
        license: 'Built-inCustomerChat',
        object: 'DomainOmni',
        obj_perm: [PermAction.GET]
      },
      {
        name: 'DOMAINHIDECONTACTSETTING',
        description: 'DOMAINHIDECONTACTSETTING_DESCRIPTION',
        type: 'item',
        icon: 'phone_locked',
        state: '/domain-settings/hide-contact',
        role: ROLE_TYPE_ALL,
        mode: [ModeDomain.BASE, ModeDomain.CC],
        view: [MenuView.DOMAIN],
        license: 'NumberRotation',
        object: 'DomainHideContactSetting',
        obj_perm: [PermAction.GET]
      },
      {
        name: 'TG',
        description: 'TG_BOT_MEMBERS_DESCRIPTION',
        type: 'item',
        icon: 'send',
        state: '/domain-settings/telegram-bot-members',
        role: ROLE_TYPE_ALL,
        mode: ALL_DOMAIN_MODE,
        view: [MenuView.DOMAIN],
        object: 'TelegramSystemBotMember',
        obj_perm: [PermAction.DLIST]
      }
    ]
  }
];

export const MARKETPLACE_INTEGRATIONS = [..._MENU_.find(item => item.state === 'marketplace').children];

@Injectable()
export class MenuService {

  constructor(public authenticationService: AuthenticationService,
              public domainExtension: MarketplaceService) {


  }


  checkPermObject(item, perm) {
    const key = item.object;
    const obj_perm = item.obj_perm;
    const user = this.authenticationService.currentUserValue;
    if (ROLE_TYPE_CUSTOM.includes(user?.role_type)) {
      if (perm.hasOwnProperty(key)) {
        if (this.checkProvider()) {

          return obj_perm?.some(r => perm[key]?.includes(r*Math.sign(user.role_type)));
        } else
        {
          return obj_perm?.some(r => perm[key]?.includes(r));
        }

      }
      return false;
    }
    return true;
  }

  checkView(item) {
    let  view = null;
    if (this.checkProvider()) view = MenuView.PROVIDER;
    else if (this.checkProviderInDomain()) view = MenuView.DOMAIN;
    else if (this.checkDomainUser()) view = MenuView.DOMAIN;
    return item.view?.includes(view)
  }

  checkMode(item) {
    const user = this.authenticationService.currentUserValue;
    return this.isMode(item, user?.domain_mode)
  }



  checkLic(item) {
    if (item.license) {
      const lic = this.getLicFromUser();
      return lic.includes(item.license);
    }
    return true;
  }

  getLicFromUser() {
    return (this.authenticationService.getUserLicense() || [])
      .filter(item=>item.available==true).map(item=>item.name);
  }

  checkRole(item) {
    const user = this.authenticationService.currentUserValue;
    return this.isRole(item, user?.role_type)
  }

  isRole(item, role) {
    return item.role?.includes(role)
  }

  isView(item, view) {
    return item.view?.includes(view)
  }

  isMode(item, mode) {
    return mode==null || item.mode?.includes(mode);
  }

  isSelf(perm, key, view) {
    if (perm.hasOwnProperty(key.toLowerCase())) {
      if (perm[key.toLowerCase()].perms && perm[key.toLowerCase()].perms.includes(4*view)) {
        return true;
      }
    }
    return false;
  }

  isEdit(perm, key) {}
  isDelete(perm, key) {}

  checkedPerm(item, role, has_domain, mode, perm): boolean {
    const key = item.object;
    const obj_perm = item.obj_perm;
    if (!key) return false;

    let showMenuItem = false;


    // Provider
    if (
      this.checkPermObject(item, perm) &&
      this.checkView(item) &&
      this.checkMode(item) &&
      this.checkLic(item) &&
      this.checkRole(item)) {
      showMenuItem = true;
    }

    return showMenuItem;
  }

  objectToState(object: string) {
    return this.findMenuItemByKey('object', object)?.state;
  }

  stateToObject(state: string) {
    return this.findMenuItemByKey('state', state)?.object;
  }

  checkDomainUser() {
    const user = this.authenticationService.currentUserValue;
    if (user?.domain_id && ROLE_TYPE_DOMAIN.includes(user?.role_type)) {
      return true;
    }
    return false;
  }

  checkSelfUser() {
    const user = this.authenticationService.currentUserValue;
    if (user?.domain_id && ROLE_TYPE_DOMAIN.includes(user?.role_type) &&
      user?.role_perms['User']?.includes(14)
    ) {
      return true;
    }
    return false;
  }

  checkProvider() {
    const user = this.authenticationService.currentUserValue;
    if (!user?.domain_id && ROLE_TYPE_PROVIDER.includes(user?.role_type)) {
      return true;
    }
    return false;
  }

  findMenuItemByKey(keyName: string, keyValue: string, reverse: boolean = false) {
    const stack = _MENU_;
    while (stack.length) {
      const node = stack[reverse ? 'pop' : 'shift']();
      if (node[keyName] === keyValue) return node;
      node.children && stack.push(...node.children);
    }
    return null;
  }

  checkProviderInDomain() {
    const user = this.authenticationService.currentUserValue;
    if (user?.domain_id && ROLE_TYPE_PROVIDER.includes(user?.role_type)) {
      return true;
    }
    return false;
  }

  getMenu(role: number, mode: number, has_domain= false, perm: any = null): MenuItem[] {
    return _MENU_.filter(item => {
      //if (item.children) console.log(item.children.length, role, mode, has_domain, perm);
      if (item.children) {
        return item.children && (item.children = item.children.filter(
          child => {
            if (child.children) return child.children &&
              (child.children = child.children.filter(grandChild => this.checkedPerm(grandChild, role, has_domain, mode, perm))).length
            else return this.checkedPerm(child, role, has_domain, mode, perm)
          }
        )).length;
      }
      else return this.checkedPerm(item, role, has_domain, mode, perm);
    });
  }

  allowMenu(menu: MenuItem[] = []) {
    const user = this.authenticationService.currentUserValue;
    return menu.filter(item=>{
      if (item.children) {
        return item.children && (item.children = item.children.filter(
          child => {
            if (child.children) return child.children &&
              (child.children = child.children.filter(grandChild => this.checkedPerm(grandChild, user?.role_type, !!user?.domain_id, user?.domain_mode, user?.role_perms))).length
            else return this.checkedPerm(child, user?.role_type, !!user?.domain_id, user?.domain_mode, user?.role_perms)
          }
        )).length;
      }
      else return this.checkedPerm(item, user?.role_type, !!user?.domain_id, user?.domain_mode, user?.role_perms);
    });
  }

  getMenuItem() {
    const user = this.authenticationService.currentUserValue;
    const res: MenuItem[] = this.getMenu(user?.role_type, user?.domain_mode, !!user?.domain_id, user?.role_perms);
    return res
  }

  getFirstMenuItem() {
    return this.getMenuItem().map(item => item.state)[0];
  }
}
