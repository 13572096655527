import {
  choice,
  maxLength,
  minLength,
  prop,
  propArray, propObject,
  required, unique
} from '@rxweb/reactive-form-validators';


export class DomainScriptFieldData {
  @required() source: number; // choices  = [DOMAIN_FORM_DATA_SOURCE_FORM (0), DOMAIN_FORM_DATA_SOURCE_SESSION (1), DOMAIN_FORM_DATA_SOURCE_DIALOG (2)];
  @required() path: string;
}

export class DDSFieldFilterValue {
  @propObject(DomainScriptFieldData) field: DomainScriptFieldData;
  @required() condition_type: number = 0; // [CONDITION_TYPE_EQUAL 0, CONDITION_TYPE_LIKE 3,CONDITION_TYPE_ENDSWITH 2, CONDITION_TYPE_STARTSWITH 1,CONDITION_TYPE_MORE 4, CONDITION_TYPE_MORE_OR_EQUAL 5, CONDITION_TYPE_LESS 6,CONDITION_TYPE_LESS_OR_EQUAL 7, CONDITION_TYPE_NOT_EQUAL 8,CONDITION_TYPE_IN 9, CONDITION_TYPE_NOT_IN 10]
  @prop() value: any;
}

export class DDSFieldFilter {
  @required() type: number = 0; // 0 - and | 1 - or
  @required() @propArray(DDSFieldFilterValue) field_list: DDSFieldFilterValue[];
  @propObject(DDSFieldFilter) filter: DDSFieldFilter;
}

export class DDSFormFieldBase {
  @required() model_name: string;
}


/* Поля формы */
// Текстовое поле (на форме отобразится как простой текст)
export class DDSFormFieldText extends DDSFormFieldBase {
  @required() model_name: string = 'DDSFormFieldText';
  @required() text: string;
}

// Разделительная линия (на форме отобразится как линия, разделяющая поля)
export class DDSFormFieldLine extends DDSFormFieldBase {
  @required() model_name: string = 'DDSFormFieldLine';
  @required() color: string;
}

// Вопрос (на форме отобразится как поле для ввода или выбора, в зависимости от типа значения)
export class FormQuestionFieldBase extends DDSFormFieldBase {
  @required() id: string;
  @required({
    conditionalExpression: x => ['DDSFormFieldUser', 'DDSFormFieldExtUser', 'DDSFormFieldQueue', 'DDSFormFieldExtQueue'].indexOf(x.model_name) == -1
  }) question: string = '';
  @prop() short_title: string;
  @prop() prompt: string = '';
  @required() required: boolean = false;
  @required() hidden: boolean = false;
  @required() editable: boolean = true; // автозаполняемое поле
  @propObject(DomainScriptFieldData) data_source: DomainScriptFieldData;
  @propObject(DDSFieldFilter) condition: DDSFieldFilter;
  @prop() blank_store: boolean = false; // сохранять ли автоматически пустое значение, если не указано иное

  static className() {
    return 'DOCChannelBase';
  }
}
/* Конец блока: Поля формы */


/* Возможные типы ответов для поля формы "Вопрос" */
// Короткий текст
export class DDSFormFieldShortString extends FormQuestionFieldBase {
  @required() model_name: string = 'DDSFormFieldShortString';
  @prop() default_value: string;
}

// Длинный текст
export class DDSFormFieldLongString extends FormQuestionFieldBase {
  @required() model_name: string = 'DDSFormFieldLongString';
}

export class DSSFormFieldChoice {
  @required() @unique({message: 'ERROR.UNIQUE'}) id: string;
  @prop() value: string;
  @propObject(DDSFieldFilter) condition: DDSFieldFilter;
}

// Один вариант
export class DDSFormFieldRadio extends FormQuestionFieldBase {
  @required() model_name: string = 'DDSFormFieldRadio';
  @required() @choice({minLength: 1}) @propArray(DSSFormFieldChoice) choices: DSSFormFieldChoice[];
}

// Один вариант в виде кнопки, а не радио
export class DDSFormFieldSingleButton extends FormQuestionFieldBase {
  @required() model_name: string = 'DDSFormFieldSingleButton';
  @required() @choice({minLength: 1}) @propArray(DSSFormFieldChoice) choices: DSSFormFieldChoice[];
}

// Несколько вариантов
export class DDSFormFieldCheckBox extends FormQuestionFieldBase {
  @required() model_name: string = 'DDSFormFieldCheckBox';
  @required() @choice({minLength: 1}) @propArray(DSSFormFieldChoice) choices: DSSFormFieldChoice[];
}

// Один вариант в виде выбора
export class DDSFormFieldSelect extends FormQuestionFieldBase {
  @required() model_name: string = 'DDSFormFieldSelect';
  @required() @choice({minLength: 1}) @propArray(DSSFormFieldChoice) choices: DSSFormFieldChoice[];
}

// Да/Нет
export class DDSFormFieldBoolean extends FormQuestionFieldBase {
  @required() model_name: string = 'DDSFormFieldBoolean';
}

// Целое число
export class DDSFormFieldInt extends FormQuestionFieldBase {
  @required() model_name: string = 'DDSFormFieldInt';
}

// Число
export class DDSFormFieldDecimal extends FormQuestionFieldBase {
  @required() model_name: string = 'DDSFormFieldDecimal';
}

// Почта
export class DDSFormFieldEmail extends FormQuestionFieldBase {
  @required() model_name: string = 'DDSFormFieldEmail';
}

// Телефон
export class DDSFormFieldPhone extends FormQuestionFieldBase {
  @required() model_name: string = 'DDSFormFieldPhone';
}

// Дата
export class DDSFormFieldDate extends FormQuestionFieldBase {
  @required() model_name: string = 'DDSFormFieldDate';
}

// Дата и время
export class DDSFormFieldDateTime extends FormQuestionFieldBase {
  @required() model_name: string = 'DDSFormFieldDateTime';
}

// Сотрудник
export class DDSFormFieldUser extends FormQuestionFieldBase {
  @required() model_name: string = 'DDSFormFieldUser';
}

// Внешний сотрудник
export class DDSFormFieldExtUser extends FormQuestionFieldBase {
  @required() model_name: string = 'DDSFormFieldExtUser';
}

// Очередь
export class DDSFormFieldQueue extends FormQuestionFieldBase {
  @required() model_name: string = 'DDSFormFieldQueue';
}

// Внешняя очередь
export class DDSFormFieldExtQueue extends FormQuestionFieldBase {
  @required() model_name: string = 'DDSFormFieldExtQueue';
}
/* Конец блока: Возможные типы ответов для поля формы "Вопрос" */

export class DomainDialogScriptFormPage {
  @required() @minLength({value: 2}) @maxLength({value: 250}) name: string;
  @required() @propArray(DDSFormFieldBase) body: DDSFormFieldBase[];
  @propObject(DDSFieldFilter) condition: DDSFieldFilter;
  @prop() show_result: boolean = true;
}

export class DomainDialogScriptFormEndReason {
  @required() id: string;
  @required() name: string;
  // статус и действие, которое нужно выполнить при выборе этой причины:
  // 0 - успешное завершение скрипта;
  // 1 - аварийное завершение скрипта;
  // 2 - успешное завершение скрипта и вызова;
  // 3 - успешное завершение скрипта, вызова и диалога/карточки.
  @required() reason: number = 1;
}

export class DomainDialogScriptForm {
  @prop() domain_id: number;
  @required() @minLength({value: 2}) @maxLength({value: 250}) name: string;
  @prop() ver: string;
  @required() @choice({minLength: 1}) @choice({minLength: 1}) @propArray(DomainDialogScriptFormEndReason) end_reason: DomainDialogScriptFormEndReason[];
  @required() @propArray(DomainDialogScriptFormPage) body: DomainDialogScriptFormPage[];

  static className() {
    return 'DomainDialogScriptForm';
  }
}

export class DomainDialogScriptFormResultRow {
  [key: string]: any;
}

export class DomainDialogScriptFormResult {
  @required() id?: number;
  @required() form_id: string;
  @required() form_ver: number;
  @required() script_result_id: number;
  @required() update_dt?: number;
  @required() @propObject(DomainDialogScriptFormResultRow) body: DomainDialogScriptFormResultRow;
  @prop() end_reason: string;

  static className() {
    return 'DomainDialogScriptFormResult';
  }
}
