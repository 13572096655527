import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Helper, IResponseListObject, IResponseBodyObject} from './api.helpers';
import {iif, Observable, of as observableOf, throwError} from 'rxjs';
import {catchError, delay, map, retryWhen, tap, concatMap} from 'rxjs/operators';
import {NotifyService} from './notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../auth/authentication.service';
import {ROLE_TYPE_CUSTOM, ROLE_TYPE_PROVIDER, RoleDomain} from './constant';
import {JsonAppConfigService} from '../config/json-app-config.service';
import {downloadBlobObject, getFileName} from './base.function';
export const headers = new HttpHeaders().set('Content-Type', 'application/json');


@Injectable({
  providedIn: 'root'
})
export class BaseService<T> {
  protected requestPathKey: string = 'apiUrl'; // ключ храненияв конфигурации основного пути запросов
  protected extraPath: string = ''; // дополнительный путь к АПИ
  constructor(public objectName: string,
              public http: HttpClient,
              public notifyService: NotifyService,
              public router: Router,
              public route: ActivatedRoute,
              public authenticationService: AuthenticationService,
              public AppConfig: JsonAppConfigService) {
  }

  public actions = {
    sync: false,
    xlsx: false,
    file: false,
    download: false,
    download_csv: false,
    download_xlsx: false,
    export: false
  };

  customFilter(id, start_dt, end_dt) {
    let params = {
      field: id,
      start_dt: start_dt,
      end_dt: end_dt
    };

    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }

    const requestBody = Helper.requestFormat('get', 'DomainReportFilter', params);
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue(this.requestPathKey) + this.extraPath, requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        }),
        map(data => data.body),
        catchError((error) => observableOf([]))
      );

  }

  getUser() {
    return this.authenticationService.currentUserValue;
  }

  getURL() {
    return this.AppConfig.getValue('sourceUrl');
  }

  getDomain() {
    return this.authenticationService.currentUserValue?.domain_id;
  }

  getDomainName() {
    return this.authenticationService.currentUserValue?.domain_name
  }

  getDomainMode() {
    return this.authenticationService.currentUserValue?.domain_mode
  }

  isSystemView() {
    return this.isRoleProvider() && !this.getDomain();
  }

  isDomainView() {
    return this.getDomain();
  }

  isRoleProvider() {
    return Object.values(ROLE_TYPE_PROVIDER).includes(this.authenticationService.currentUserValue?.role_type);
  }

  isRoleAdmin() {
    return this.authenticationService.currentUserValue?.role_type == RoleDomain.ADMIN;
  }

  isRoleCustom() {
    return Object.values(ROLE_TYPE_CUSTOM).includes(this.authenticationService.currentUserValue?.role_type);
  }

  isRoleOperator() {
    return this.authenticationService.currentUserValue?.role_type===4 &&
      this.authenticationService.currentUserValue?.role_perms?.User?.includes(14); //self
  }

  isRoleProject() {
    return this.authenticationService.currentUserValue?.role_type===4 &&
      this.authenticationService.currentUserValue?.role_perms?.User?.includes(24); //project list
  }

  changeObjectName(obj: any) {
  }

  list<P=T>(params: any = {}, action: string = 'list'): Observable<IResponseListObject<P | T>> {
    if (this.authenticationService.currentUserValue?.domain_id && !params['without_domain']) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    if (params['without_domain']) delete params['without_domain'];
    const requestBody = Helper.requestFormat(action, this.objectName, params);
    return this.http.post<IResponseListObject<P | T>>(this.AppConfig.getValue(this.requestPathKey) + this.extraPath, requestBody, {headers: headers})
      .pipe(
        tap((res)  => {
          this.notifyService.checkCode(res);
        }),
        retryWhen(errors =>
          errors.pipe(// delay(3000), take(3)
            concatMap((e, i) =>
              // Executes a conditional Observable depending on the result
              // of the first argument
              iif(
                () => {return (i == 2) || e.message == '403'},
                // If the condition is true we throw the error (the last error)
                throwError(e),
                // Otherwise we pipe this back into our stream and delay the retry
                observableOf(e).pipe(delay(3000))
              ))
          )),
        // catchError((error) => observableOf({total_count: 0} as IResponseListObject<T>))
      );
  }

  toSelect(params: any = {}, action: string = 'list'): Observable<T[]> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, this.objectName, params);
    return this.http.post<IResponseListObject<T>>(this.AppConfig.getValue(this.requestPathKey) + this.extraPath, requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        }),
        map(data => data.list as T[]
        ),
        retryWhen(errors => errors.pipe(
          // delay(3000),
          // take(3)
          concatMap((e, i) =>
            // Executes a conditional Observable depending on the result
            // of the first argument
            iif(
              () => {return (i == 2) || e.message == '403'},
              // If the condition is true we throw the error (the last error)
              throwError(e),
              // Otherwise we pipe this back into our stream and delay the retry
              observableOf(e).pipe(delay(3000))
            ))
        )),
        catchError((error) => {
          console.log(error);
          return observableOf([] as T[]);
        })
      );
  }

  toBodySelect(params: any = {}, action: string, parse_id = false): Observable<any[]> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, this.objectName, params);
    return this.http.post<IResponseBodyObject<T>>( this.AppConfig.getValue(this.requestPathKey) + this.extraPath, requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        }),
        map(data => {
          return Object.keys(data.body).map(item => {
            return {name: data.body[item], id: ((parse_id)? parseInt(item) : item)}
          });
        }),
        map(data=> data.sort((a,b) => (a.name<b.name)?-1:((a.name>b.name)?1:0)))
        ,
        catchError(() => observableOf([] as any[]))
      );
  }

  toBodyList<P=T>(params: any = {}, action: string): Observable<P[] | T[]> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, this.objectName, params);
    return this.http.post<IResponseBodyObject<any>>( this.AppConfig.getValue(this.requestPathKey) + this.extraPath, requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        }),
        map(data => {
          return data.body.list as (P[] | T[])
        })
        ,
        catchError(() => observableOf([] as (P[] | T[])))
      );
  }

  get<P=T>(id: number | string | null, params: any = {}, action: string = 'get'): Observable<P | T> {
    let ignore = {};
    if (params.__ignore__) {
      ignore = {...params.__ignore__};
      delete params['__ignore__'];
    }
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue?.domain_id;
    }
    if (typeof id === 'string') id = parseInt(id, 10);
    if (id) {
      params['id'] = id;
    } else if (params.hasOwnProperty('id')) {
      delete params['id'];
    }
    const requestBody = Helper.requestFormat(action, this.objectName, params);
    return this.http.post<IResponseBodyObject<P | T>>( this.AppConfig.getValue(this.requestPathKey) + this.extraPath, requestBody, {headers: headers})
      .pipe(
        tap(res => {
          if (res.code != 200 && (!res.notifies.length || !(ignore[res.notifies[0].msg_id]))) this.notifyService.checkCode(res);
        }),
        map(data => {
          this.changeObjectName(data.body);
          return data.body as (P |T);
        }),
        // catchError(() => observableOf({} as T))
      );
  }

  delete(id: number, action: string = 'delete'): Observable<IResponseBodyObject<T>>  {
    if (typeof id === 'string') id = parseInt(id, 10);
    const obj = {id: id};
    if (this.authenticationService.currentUserValue?.domain_id) {
      obj['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, this.objectName, obj);
    return this.http.post<IResponseBodyObject<T>>( this.AppConfig.getValue(this.requestPathKey) + this.extraPath, requestBody, {headers: headers})
      .pipe(tap(res => {  this.notifyService.checkCode(res);      }));
  }

  deleteAll(params: any = {}, action: string = 'delete'): Observable<IResponseBodyObject<T>>  {
    let ignore = {};
    if (params.__ignore__) {
      ignore = {...params.__ignore__};
      delete params['__ignore__'];
    }
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue?.domain_id;
    }
    const requestBody = Helper.requestFormat(action, this.objectName, params);
    return this.http.post<IResponseBodyObject<T>>( this.AppConfig.getValue(this.requestPathKey) + this.extraPath, requestBody, {headers: headers})
      .pipe(tap(res => this.notifyService.checkCode(res)));
  }

  save(obj: T, action_append: string = 'append', action_update: string = 'update'): Observable<IResponseBodyObject<T>>  {
    let ignore = {};
    if (obj['__ignore__']) {
      ignore = {...obj['__ignore__']};
      delete obj['__ignore__'];
    }

    if (this.authenticationService.currentUserValue?.domain_id) {
      obj['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    let requestBody = Helper.requestFormat(action_append, this.objectName, obj);
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty('id') && obj['id']) {
      requestBody = Helper.requestFormat(action_update, this.objectName, obj);
    }
    return this.http.post<IResponseBodyObject<T>>( this.AppConfig.getValue(this.requestPathKey) + this.extraPath, requestBody, {headers: headers})
      .pipe(
        tap(res => {
          if (res.code != 200 && (!res.notifies.length || !(ignore[res.notifies[0].msg_id]))) this.notifyService.checkCode(res);
        })
      );
  }


  details(id, state?: any) {
    console.log(this.router.url)
    this.router.navigate([this.router.url, 'edit', id], {state: state});
  }

  import(params: any = {}, action='import', object = this.objectName)  {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, object, params);
    return this.http.post( this.AppConfig.getValue(this.requestPathKey) + this.extraPath, requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }

  exportXLSX(params: any = {}, action='export', name=null, object = this.objectName) {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, object, params);

    const act = (this.actions[action]!==undefined)?action:'xlsx';
    this.authenticationService.is_token_alive().subscribe(() => {
      return this.http.post<Blob>( this.AppConfig.getValue(this.requestPathKey) + this.extraPath, requestBody,
        {headers: headers, observe: 'response', responseType: 'blob' as 'json'})
        .pipe(tap(res => {
            if (res.status == 200) {
              setTimeout(() => {
                this.actions[act] = false;
                if (res.body.size > 1000) {
                  this.notifyService.message('NOTIFY.200');
                }
                else {
                  this.notifyService.message('NOTIFY.500');
                }
              }, 1500);
            }
          }),
          catchError(error => {
            this.actions[act] = false;
            return observableOf(null);
          } ))
        .subscribe(
          (response: HttpResponse<Blob>) =>{
            if (response.body.size > 1000) {
              let filename: string = getFileName(response) || `${name}.xlsx`;
              downloadBlobObject(response, filename);
            }

          }, error=>{})
    });
  }

  downloadReport(object=this.objectName, params: any = {}, action='download', name=null, ext='xlsx') {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, object, params);

    this.authenticationService.is_token_alive().subscribe(() => {
      return this.http.post<Blob>(this.AppConfig.getValue(this.requestPathKey), requestBody,
        {headers: headers, observe: 'response', responseType: 'blob' as 'json'})
        .pipe(tap(res => {
            if (res.status == 200) {
              setTimeout(() => {
                this.actions[action] = false;
                this.notifyService.message('NOTIFY.200');
              }, 1500);
            }
          }),
          catchError(error => {
            this.actions[action] = false;
            return observableOf(null);
          } ))
        .subscribe(
          (response: HttpResponse<Blob>) => {
            console.log(response);
            let filename: string = getFileName(response) || `${name}.${ext}`;
            downloadBlobObject(response, filename);
          })
    });
  }

  downloadFile(route: string, name: string = null): void {
    this.http.get<Blob>(route,{observe: 'response', responseType: 'blob' as 'json'})
      .subscribe(
        (response: HttpResponse<Blob>) =>{
          let filename: string = getFileName(response) || `${name}`;
          downloadBlobObject(response, filename);
        }
      )
  }

  escapeFileName(name) {
    if (name)
    {
      name = name.replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g,'_');
    }
    return name;
  }


  action(action, params: any = {}): Observable<IResponseBodyObject<T>> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, this.objectName, params);
    return this.http.post<IResponseBodyObject<T>>( this.AppConfig.getValue(this.requestPathKey) + this.extraPath, requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }
}
