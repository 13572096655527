import {
  required,
  digit,
  prop,
  propObject,
  propArray,
  unique,
  choice,
  json,
  pattern, minNumber, maxLength, minLength, maxNumber
} from '@rxweb/reactive-form-validators';
import {RxFormHelpers} from '../../_helpers/form.helpers';
import {LSAudioList} from '../../local-stream/local-stream';

export class PBXUserQueueList {
  @required() @digit() @unique({message: 'ERROR.UNIQUE'}) queue_id: number;
  @required() @digit() user_id: number;
}

export class UserUID {
  @required() @unique({message: 'ERROR.UNIQUE'}) uid: string;
}

export class CRMUserMap {
  @required() crm_id: string;
  @digit() @unique({message: 'ERROR.UNIQUE'}) pbx_id: number;
  @prop() name: string;
}

export class DomainExtensionDEPTrafficCollectorParams {
  @required() dep_name: string = 'DEPTrafficCollector';
  @required() @digit() status: number = 1;
  @propArray() stat_access = [];
}

export class DomainExtensionDEPTrafficCollector {
  @prop() apikey: string;
  @propObject(DomainExtensionDEPTrafficCollectorParams) params;

  static className() {
    return 'DomainExtension';
  }
}

export class DEPAmoCRMNumber {
  @prop() number: string;
  @prop() number_name: string;
  @required() allow_processing: number[] = [0, 1]; // Разрешить обрабатывать вызов с определенным направлением: 0 - исх., 1 - вх., 2 - внутр.
  @required() c_transfer: boolean = true; // Переводить на ответственного
  @required() @digit() c_transfer_wait_time: number = 10; // Время ожидания соединения с ответственным менеджером
  @propObject(LSAudioList) c_transfer_say: LSAudioList; // Уведомление для клиента перед соединением с менеджером
  @propObject(LSAudioList) c_transfer_fail_say: LSAudioList; // Уведомление для клиента если менеджер не ответил
  @digit() responsible_no_answer_user: number; // Ответственный за пропущенные вызовы
  @digit() responsible_no_map_user: number; // Ответственный за несопоставленных операторов
  @digit() o_card_view: number = 1; // Режим показа окна при поступлении вызова ответственному: 0 - не показываем, 1 - при гудках, 2 - при ответе
  @prop() pipeline_id: number;
  @prop() processing_known_contact: number = 1;
}

export class DEPAmoCRMProcessing {
  [key: string]: DEPAmoCRMNumber;
}

export class DomainExtensionDEPAmoCRMParams {
  @required() dep_name: string = 'DEPAmoCRM';
  @prop() access_token: string;
  @prop() refresh_token: string;
  @required() crm_domain: string;

  @required() sync_crm_users: boolean = false;
  @required() @digit() status: number = 1;
  @required() @propArray(CRMUserMap) crm_users: CRMUserMap[] = [];
  @propObject(DEPAmoCRMProcessing) processing;

  static className() {
    return 'DomainExtension';
  }
}

export class DEPBitrix24Number {
  @prop() number: string;
  @prop() number_name: string;
  @required() allow_processing: number[] = [0, 1]; // Разрешить обрабатывать вызов с определенным направлением: 0 - исх., 1 - вх., 2 - внутр.
  @required() c_transfer: boolean = true; // Переводить на ответственного
  @required() @digit() c_transfer_wait_time: number = 10; // Время ожидания соединения с ответственным менеджером
  @propObject(LSAudioList) c_transfer_say: LSAudioList; // Уведомление для клиента перед соединением с менеджером
  @propObject(LSAudioList) c_transfer_fail_say: LSAudioList; // Уведомление для клиента если менеджер не ответил
  @required() @digit() processing_unknown_contact: number = 1; // Обработка неизвестных контактов: 0 - Ничего не делать, 1 - Создать лид, 2 - Создать контакт
  @required() @digit() processing_known_contact: number = 1; // Обработка известных контактов: 0 - Ничего не делать, 1 - Создать запись в вызовах
  @required({conditionalExpression: x => x.allow_processing.length > 0}) @digit() responsible_no_answer_user: number; // Ответственный за пропущенные вызовы
  @required({conditionalExpression: x => x.allow_processing.length > 0}) @digit() responsible_no_map_user: number; // Ответственный за несопоставленных операторов
  @digit() o_card_view: number = 1; // Режим показа окна при поступлении вызова ответственному: 0 - не показываем, 1 - при гудках, 2 - при ответе
  @prop() cb_form: string; // Форма из списка в Б24
  @required({conditionalExpression: x => !!x.cb_form}) @digit() queue_id: number; // ID очереди для приема
  // ответственный в очереди, если вызов пришел на очередь, за пропущенные вызовы // выбирать из тех, кто выбран в сопоставлении
  @propArray(PBXUserQueueList) responsible_no_answer_queues: PBXUserQueueList[] = [];
  // ответственный в очереди при вызове на сотрудника в очереди, которого нет в интеграции // выбирать тех, кто выбран в сопоставлении
  @propArray(PBXUserQueueList) responsible_no_map_queues: PBXUserQueueList[] = [];
  @prop() custom_element_priority: number;
}

export class DEPBitrix24Chat {
  @required() allow_processing: number = 1; // Разрешить обрабатывать вызов с определенным направлением: 0 - запрещено, 1 - разрешено.
  @prop() source: string;
  @prop() responsible_no_answer_user: number;
  @prop() responsible_no_map_user: number;
  @required() @digit() processing_unknown_contact: number = 1; // Обработка неизвестных контактов: 0 - Ничего не делать, 1 - Создать лид, 2 - Создать контакт
  @required() @digit() processing_known_contact: number = 1; // Обработка известных контактов: 0 - Ничего не делать, 1 - Создать запись в вызовах
  @prop() custom_element_priority: number;
}

export class DEPBitrix24Processing {
  [key: string]: DEPBitrix24Number;
}
export class DEPBitrix24ChatProcessing {
  @propObject(DEPBitrix24Chat) __ALL__: DEPBitrix24Chat;
}

export class DomainExtensionDEPBitrix24Params {
  @required() dep_name: string = 'DEPBitrix24';
  @prop() access_token: string;
  @prop() refresh_token: string;
  @prop() app_id: string;
  @prop() language: string;
  @required() crm_domain: string;

  @required() sync_crm_users: boolean = false;
  @prop() sync_agent_status: boolean = false;
  @prop() sync_agent_status_period: number = 30;
  @prop() callback_source: string;
  @required() @digit() status: number = 1;
  @required() @propArray(CRMUserMap) crm_users: CRMUserMap[] = [];
  @propObject(DEPBitrix24Processing) processing;//: any = {};
  @propObject(DEPBitrix24ChatProcessing) chat_processing: DEPBitrix24ChatProcessing;

  static className() {
    return 'DomainExtension';
  }
}

export class DEPSberCRMNumber {
  @prop() number: string;
  @prop() number_name: string;
  @required() allow_processing: number[] = [0, 1]; // Разрешить обрабатывать вызов с определенным направлением: 0 - исх., 1 - вх., 2 - внутр.
  @required() c_transfer: boolean = true; // Переводить на ответственного
  @required() @digit() c_transfer_wait_time: number = 10; // Время ожидания соединения с ответственным менеджером
  @propObject(LSAudioList) c_transfer_say: LSAudioList; // Уведомление для клиента перед соединением с менеджером
  @propObject(LSAudioList) c_transfer_fail_say: LSAudioList; // Уведомление для клиента если менеджер не ответил
  @required() @digit() processing_unknown_contact: number = 1; // Обработка неизвестных контактов: 0 - Ничего не делать, 1 - Создать лид, 2 - Создать контакт
  @required() @digit() processing_known_contact: number = 1; // Обработка известных контактов: 0 - Ничего не делать, 1 - Создать запись в вызовах
  @required({conditionalExpression: x => x.allow_processing.length > 0}) @digit() responsible_no_answer_user: number; // Ответственный за пропущенные вызовы
  @required({conditionalExpression: x => x.allow_processing.length > 0}) @digit() responsible_no_map_user: number; // Ответственный за несопоставленных операторов
  @digit() o_card_view: number = 1; // Режим показа окна при поступлении вызова ответственному: 0 - не показываем, 1 - при гудках, 2 - при ответе
  @prop() cb_form: string; // Форма из списка в Б24
  @required({conditionalExpression: x => !!x.cb_form}) @digit() queue_id: number; // ID очереди для приема
  // ответственный в очереди, если вызов пришел на очередь, за пропущенные вызовы // выбирать из тех, кто выбран в сопоставлении
  @propArray(PBXUserQueueList) responsible_no_answer_queues: PBXUserQueueList[] = [];
  // ответственный в очереди при вызове на сотрудника в очереди, которого нет в интеграции // выбирать тех, кто выбран в сопоставлении
  @propArray(PBXUserQueueList) responsible_no_map_queues: PBXUserQueueList[] = [];
  @prop() custom_element_priority: number;
}

export class DEPSberCRMProcessing {
  [key: string]: DEPSberCRMNumber;
}

export class DomainExtensionDEPSberCRMParams {
  @required() dep_name: string = 'DEPSberCRM';
  @prop() access_token: string;
  @prop() refresh_token: string;
  @prop() app_id: string;
  @prop() language: string;
  @required() crm_domain: string;

  @required() sync_crm_users: boolean = false;
  @prop() sync_agent_status: boolean = false;
  @prop() sync_agent_status_period: number = 30;
  @required() @digit() status: number = 1;
  @required() @propArray(CRMUserMap) crm_users: CRMUserMap[] = [];
  @propObject(DEPSberCRMProcessing) processing;//: any = {};

  static className() {
    return 'DomainExtension';
  }
}

export class DEPTaxiProcessing {
  [key: string]: DEPTaxiLang;
}

export class DEPTaxiLang {
  [key: string]: DEPTaxiStatus;
}

export class DEPActions {
  [key: number]: DEPAction;
}

export class DEPAction {
  @required()
  action: number = 0;
  @prop()
  data?: string | number;
}

export class DEPTaxiStatus {
  @required() allow_processing: boolean = true;
  @propObject(LSAudioList)
  playback: LSAudioList = new LSAudioList();
  @propObject(DEPAction) default: DEPAction = new DEPAction();
  @digit() time_action: number = 15;
  @propObject(DEPActions) actions: DEPActions;
}

export class DomainExtensionDEPYandexSpeechKitParams {
  @required() dep_name: string = 'DEPYandexSpeechKit';
  @required() @digit() status: number = 1;
  @required() folder_id: string;
  @required() jwt_private_key: string;
  @required() key_id: string;
  @required() service_account_id: string;

  static className() {
    return 'DomainExtension';
  }
}

export class DomainExtensionDEPTinkoffVoiceKitParams {
  @required() dep_name: string = 'DEPTinkoffVoiceKit';
  @required() @digit() status: number = 1;
  @prop() sync_from: number;
  @required() key: string;
  @required() secret: string;
  @required() cdr_stt: boolean = true;

  static className() {
    return 'DomainExtension';
  }
}

export class DomainExtensionDEPTinkoffQMParams {
  @required() dep_name: string = 'DEPTinkoffTQM';
  @required() @digit() status: number = 1;
  @required() @digit() operator_id_src: number = 0;
  @required() token: string;

  static className() {
    return 'DomainExtension';
  }
}

export class DEP3iTechAnalyticsTransport {
  @required() model_name: string = 'DEP3iTechAnalyticsTransport';
}

export class DEP3iTechAnalyticsTransportSFTP extends DEP3iTechAnalyticsTransport {
  @required() model_name: string = 'DEP3iTechAnalyticsTransportSFTP';
  @required() @pattern({expression:{onlyDigit: RxFormHelpers.ipV4OrCidr}, message: 'ERROR.IP_WITH_MASK'}) host: string;
  @required() @minNumber({value: 1}) @maxNumber({value: 65535}) port: number;
  @required() path: string;
  @required() login: string;
  @required() password: string;
  @required() put_record: boolean = true;
}

export class DomainExtensionDEP3iTechAnalyticsParams {
  @required() dep_name: string = 'DEP3iTechAnalytics';
  @required() @digit() status: number = 1;

  @required() @propObject(DEP3iTechAnalyticsTransport) transport: DEP3iTechAnalyticsTransport;
  @required() meta_type: number = 0; // 0 - META_TYPE_TXT, 1 - META_TYPE_XML, 2 - META_TYPE_JSON
  @required() @json({conditionalExpression: x => x.meta_type == 2, message: 'NOTIFY.JSON'}) meta_template: string;
  @digit() min_bill_sec: number; // Минимальная продолжительность разговора с оператором для формирование задачи
  @required() wait_ext_event: boolean = false;

  static className() {
    return 'DomainExtension';
  }
}

export class DomainExtensionDEPYandexTaxiParams {
  @required() dep_name: string = 'DEPYandexTaxi';
  @required() @digit() status: number = 1;
  // @required() company_id: string;
  @required() dbid: string;
  @required() client_key: string;
  @required() default_lang: string = 'ru';

  @required() @digit() notify_count_retry: number = 2;
  @required() @digit() @minNumber({value: 30}) notify_timeout_retry: number = 30;
  @required() @digit() outbound_group_to_client: number;
  @required() @digit() outbound_group_to_driver: number;

  // @required() url_order_by_client: string;
  // @required() url_order_by_driver: string;

  @propObject(DEPTaxiProcessing) client_ivr;
  @propObject(DEPTaxiProcessing) client_notify;
  @propObject(DEPTaxiProcessing) driver_ivr;
  @propObject(DEPTaxiProcessing) driver_notify;

  static className() {
    return 'DomainExtension';
  }
}

export class DomainExtensionDEPRBTaxiParams {
  @required() dep_name: string = 'DEPRBTaxi';
  @required() @digit() status: number = 1;
  @required() company_id: string;
  @required() api_key: string;
  @required() default_lang: string = 'ru';

  @required() @digit() notify_count_retry: number = 2;
  @required() @digit() @minNumber({value: 30}) notify_timeout_retry: number = 30;
  @required() @digit() outbound_group_to_client: number;
  @required() @digit() outbound_group_to_driver: number;

  // @required() url_order_by_client: string;
  // @required() url_order_by_driver: string;

  @propObject(DEPTaxiProcessing) client_ivr;
  @propObject(DEPTaxiProcessing) client_notify;
  @propObject(DEPTaxiProcessing) driver_ivr;
  @propObject(DEPTaxiProcessing) driver_notify;

  static className() {
    return 'DomainExtension';
  }
}

export class DomainExtensionDEPGooTaxParams {
  @required() dep_name: string = 'DEPGooTax';
  @required() @digit() status: number = 1;
  @required() company_id: string;
  @required() default_lang: string = 'ru';

  @required() @digit() notify_count_retry: number = 2;
  @required() @digit() @minNumber({value: 30}) notify_timeout_retry: number = 30;
  @required() @digit() outbound_group_to_client: number;
  @required() @digit() outbound_group_to_driver: number;

  @required() url_order_by_client: string;
  @required() url_order_by_driver: string;

  @propObject(DEPTaxiProcessing) client_ivr;
  @propObject(DEPTaxiProcessing) client_notify;
  @propObject(DEPTaxiProcessing) driver_ivr;
  @propObject(DEPTaxiProcessing) driver_notify;

  static className() {
    return 'DomainExtension';
  }
}

export class DomainExtensionDEPSMSTrafficRuParams {
  @required() dep_name: string = 'DEPSMSTrafficRu';
  @required() @digit() status: number = 1;

  @required() user: string = '';
  @required() password: string = '';
  @required() @choice({minLength: 1, message: 'ERROR.REQUIRED'}) sender: string[] = ['RuntelPBX'];

  static className() {
    return 'DomainExtension';
  }
}

export class DomainExtensionDEPMegafonA2PSMSParams {
  @required() dep_name: string = 'DEPMegafonA2PSMS';
  @required() @digit() status: number = 1;

  @required() user: string = '';
  @required() password: string = '';
  @required() @choice({minLength: 1, message: 'ERROR.REQUIRED'}) sender: string[] = [];

  static className() {
    return 'DomainExtension';
  }
}

export class DEPBPMOnlineNumber {
  @prop() number: string;
  @prop() number_name: string;
  @required() allow_processing: number[] = [0, 1]; // Разрешить обрабатывать вызов с определенным направлением: 0 - исх., 1 - вх., 2 - внутр.
  @required() c_transfer: boolean = true; // Переводить на ответственного
  @required() @digit() c_transfer_wait_time: number = 10; // Время ожидания соединения с ответственным менеджером
  @propObject(LSAudioList) c_transfer_say: LSAudioList; // Уведомление для клиента перед соединением с менеджером
  @propObject(LSAudioList) c_transfer_fail_say: LSAudioList; // Уведомление для клиента если менеджер не ответил
  @prop() responsible_no_answer_user: string; // Ответственный за пропущенные вызовы
  @prop() responsible_no_map_user: string; // Ответственный за несопоставленных операторов
  @digit() o_card_view: number = 1; // Режим показа окна при поступлении вызова ответственному: 0 - не показываем, 1 - при гудках, 2 - при ответе
}

export class DEPBPMOnlineProcessing {
  [key: string]: DEPBPMOnlineNumber;
}

export class DEPBPMOnlineCallDirect {
  @prop() '0': string; // CallDirectionInbound
  @prop() '1': string; // CallDirectionOutbound
  @prop() '2': string; // CallDirectionAny
}

export class DEPBPMOnlineParticipant {
  @prop() '0': string; // PARTICIPANT_ROLE_RESPONSIBLE
  @prop() '1': string; // PARTICIPANT_ROLE_PARTICIPANT
}


export class DEPRetailCRMNumber {
  @prop() number: string;
  @prop() number_name: string;
  @required() allow_processing: number[] = [0, 1]; // Разрешить обрабатывать вызов с определенным направлением: 0 - исх., 1 - вх., 2 - внутр.
  @required() c_transfer: boolean = true; // Переводить на ответственного
  @required() @digit() c_transfer_wait_time: number = 10; // Время ожидания соединения с ответственным менеджером
  @propObject(LSAudioList) c_transfer_say: LSAudioList; // Уведомление для клиента перед соединением с менеджером
  @propObject(LSAudioList) c_transfer_fail_say: LSAudioList; // Уведомление для клиента если менеджер не ответил
  @digit() responsible_no_answer_user: number; // Ответственный за пропущенные вызовы
  @digit() responsible_no_map_user: number; // Ответственный за несопоставленных операторов
  @digit() o_card_view: number = 1; // Режим показа окна при поступлении вызова ответственному: 0 - не показываем, 1 - при гудках, 2 - при ответе
}

export class DEPRetailCRMProcessing {
  [key: string]: DEPRetailCRMNumber;
}

export class DomainExtensionDEPRetailCRMParams {
  @required() dep_name: string = 'DEPRetailCRM';
  @prop() apikey: string;
  @required() crm_domain: string;

  @required() sync_crm_users: boolean = true;
  @required() @digit() status: number = 1;
  @required() @propArray(CRMUserMap) crm_users: CRMUserMap[] = [];
  @propObject(DEPRetailCRMProcessing) processing;

  static className() {
    return 'DomainExtension';
  }
}

export class DEPElma365Number {
  @prop() number: string;
  @prop() number_name: string;
  @required() allow_processing: number[] = [0, 1]; // Разрешить обрабатывать вызов с определенным направлением: 0 - исх., 1 - вх., 2 - внутр.
}

export class DEPElma365Processing {
  [key: string]: DEPElma365Number;
}

export class DomainExtensionDEPElma365Params {
  @required() dep_name: string = 'DEPElma365';
  @required() crm_domain: string;
  @required() crm_token: string;
  @required() user_token: string;
  @required() ext_token: string;

  @required() sync_crm_users: boolean = true;
  @required() @digit() status: number = 1;
  @required() @propArray(CRMUserMap) crm_users: CRMUserMap[] = [];
  @propObject(DEPElma365Processing) processing;
  @propObject(DEPBitrix24ChatProcessing) chat_processing: DEPBitrix24ChatProcessing;

  static className() {
    return 'DomainExtension';
  }
}

export class DomainExtensionDEPBPMOnlineParams {
  @required() dep_name: string = 'DEPBPMOnline';
  @prop() apikey: string;
  @required() crm_domain: string;
  @required() odata_suffix: string;
  @required() login: string;
  @required() password: string;
  @prop()  custom_search_url: string;

  @required() sync_crm_users: number = 0;
  @prop() int_phone_source: string;
  @prop() redirect_phone_source: string;
  @prop() sync_with_role: string;
  @prop() sync_exclude_number: number[] = [];

  @required() @digit() status: number = 1;
  @prop() crm_users: object;
  @prop() version: string = 'Marketing';
  @propObject(DEPBPMOnlineProcessing) processing;

  @propObject(DEPBPMOnlineCallDirect) collection_call_direction;
  @prop() collection_activity_status: string;
  @prop() collection_activity_category: string;
  @propObject(DEPBPMOnlineParticipant)  collection_activity_participant_role;
  @prop() collection_types: string;
  @prop() collection_file_type: string;

  static className() {
    return 'DomainExtension';
  }
}

export class DEPProstieZvonkiNumber {
  @prop() number: string;
  @prop() number_name: string;
  @required() allow_processing: number[] = [0, 1]; // Разрешить обрабатывать вызов с определенным направлением: 0 - исх., 1 - вх., 2 - внутр.
  @required() c_transfer: boolean = true; // Переводить на ответственного
  @required() @digit() c_transfer_wait_time: number = 10; // Время ожидания соединения с ответственным менеджером
  @propObject(LSAudioList) c_transfer_say: LSAudioList; // Уведомление для клиента перед соединением с менеджером
  @propObject(LSAudioList) c_transfer_fail_say: LSAudioList; // Уведомление для клиента если менеджер не ответил
}

export class DEPProstieZvonkiProcessing {
  [key: string]: DEPProstieZvonkiNumber;
}

export class DomainExtensionDEPProstieZvonkiParams {
  @required() dep_name: string = 'DEPProstieZvonki';
  @prop() apikey: string;

  @required() webhook: string;
  @required() @digit() status: number = 1;
  @propObject(DEPProstieZvonkiProcessing) processing;

  static className() {
    return 'DomainExtension';
  }
}

export class DEPRoiStatNumber {
  @prop() number: string;
  @prop() number_name: string;
  @required() allow_processing: number[] = [0, 1]; // Разрешить обрабатывать вызов с определенным направлением: 0 - исх., 1 - вх., 2 - внутр.
}

export class DEPRoiStatProcessing {
  [key: string]: DEPRoiStatNumber;
}

export class DomainExtensionDEPRoiStatParams {
  @required() dep_name: string = 'DEPRoiStat';
  @prop() apikey: string;

  @required() webhook: string;
  @required() @digit() status: number = 1;
  @propObject(DEPRoiStatProcessing) processing;

  static className() {
    return 'DomainExtension';
  }
}

export class DEPYClientsNumber {
  @prop() number: string;
  @prop() number_name: string;
  @required() allow_processing: number[] = [0, 1]; // Разрешить обрабатывать вызов с определенным направлением: 0 - исх., 1 - вх., 2 - внутр.
}

export class DEPYClientsProcessing {
  [key: string]: DEPYClientsNumber;
}

export class DomainExtensionDEPYClientsParams {
  @required() dep_name: string = 'DEPYClients';
  @prop() apikey: string;
  @required() crm_token: string;

  @required() @digit() status: number = 1;
  @propObject(DEPYClientsProcessing) processing;

  static className() {
    return 'DomainExtension';
  }
}

export class DEPAltegioNumber {
  @prop() number: string;
  @prop() number_name: string;
  @required() allow_processing: number[] = [0, 1]; // Разрешить обрабатывать вызов с определенным направлением: 0 - исх., 1 - вх., 2 - внутр.
}

export class DEPAltegioProcessing {
  [key: string]: DEPAltegioNumber;
}

export class DomainExtensionDEPAltegioParams {
  @required() dep_name: string = 'DEPAltegio';
  @prop() apikey: string;
  @required() crm_token: string;

  @required() @digit() status: number = 1;
  @propObject(DEPAltegioProcessing) processing;

  static className() {
    return 'DomainExtension';
  }
}

export class DEPMegaplanNumber {
  @prop() number: string;
  @prop() number_name: string;
  @required() allow_processing: number[] = [0, 1, 2]; // Разрешить обрабатывать вызов с определенным направлением: 0 - исх., 1 - вх., 2 - внутр.
  @required() c_transfer: boolean = true; // Переводить на ответственного
  @required() @digit() c_transfer_wait_time: number = 10; // Время ожидания соединения с ответственным менеджером
  @propObject(LSAudioList) c_transfer_say: LSAudioList; // Уведомление для клиента перед соединением с менеджером
  @propObject(LSAudioList) c_transfer_fail_say: LSAudioList; // Уведомление для клиента если менеджер не ответил
  @digit() responsible_no_map_user: number; // Ответственный за несопоставленных операторов
  @digit() responsible_no_answer_user: number; // Ответственный за неотвеченные
  @digit() responsible_no_new_entity: number; // Ответственный за новых клиентов
  @digit() o_card_view: number = 1; // Режим показа окна при поступлении вызова ответственному: 0 - не показываем, 1 - при гудках, 2 - при ответе
  @digit() processing_unknown_contact: number = 14; // Обработка неизвестных контактов: 0 - Ничего не делать, 11 - Создать клиента-Компанию, 12 - Создать клиента-Человек, 13 - Создать клиента-Компанию + Процесс, 14 - Создать клиента-Человек + Процесс
  @digit() processing_known_contact: number = 2; // Обработка известных контактов: 0 - Ничего не делать, 2 - Всегда создавать Процесс, 12 - Создавать Процесс, если нет активных
  @required({conditionalExpression: x => (x.processing_unknown_contact == 13 || x.processing_unknown_contact == 14 || x.processing_known_contact == 2 || x.processing_known_contact == 12)})@digit() deal_process: number; // Идентификатор процесса из Мегаплана
  @prop() custom_owner_field: string; // Кастомное поле для поиска ответственного менеджера
}

export class DEPMegaplanProcessing {
  [key: string]: DEPMegaplanNumber;
}

export class DomainExtensionDEPMegaplanParams {
  @required() dep_name: string = 'DEPMegaplan';
  @prop() apikey: string;

  @required() app_token: string;
  @required() app_uuid: string;
  @required() crm_domain: string;

  @required() sync_crm_users: boolean = false;
  @required() @digit() status: number = 1;
  @required() @propArray(CRMUserMap) crm_users: CRMUserMap[] = [];
  @propObject(DEPMegaplanProcessing) processing;

  static className() {
    return 'DomainExtension';
  }
}

export class DEPEStaffNumber {
  @prop() number: string;
  @prop() number_name: string;
  @required() allow_processing: number[] = [0, 1]; // Разрешить обрабатывать вызов с определенным направлением: 0 - исх., 1 - вх., 2 - внутр.
}

export class DEPEStaffProcessing {
  [key: string]: DEPEStaffNumber;
}

export class DomainExtensionDEPEStaffParams {
  @required() dep_name: string = 'DEPEStaff';
  @prop() apikey: string;

  @required() webhook: string;

  @required() @digit() status: number = 1;
  @prop() number_list: string[] = [];
  @propObject(DEPEStaffProcessing) processing;

  static className() {
    return 'DomainExtension';
  }
}

export class DomainExtensionDEPArgusWFMParams {
  @required() dep_name: string = 'DEPArgusWFM';
  @prop() apikey: string;
  @required() webhook: string;
  @required() @digit() status: number = 1;

  static className() {
    return 'DomainExtension';
  }
}

export class DEPUsedeskNumber {
  @prop() number: string;
  @prop() number_name: string;
  @required() allow_processing: number[] = [0, 1]; // Разрешить обрабатывать вызов с определенным направлением: 0 - исх., 1 - вх., 2 - внутр.
}

export class DEPUsedeskProcessing {
  [key: string]: DEPUsedeskNumber;
}

export class DomainExtensionDEPUsedeskParams {
  @required() dep_name: string = 'DEPUsedesk';
  @required() apikey: string;

  @required() @digit() status: number = 1;
  @propObject(DEPUsedeskProcessing) processing;

  static className() {
    return 'DomainExtension';
  }
}

export class DEPWebCallNumber {
  @prop() number: string;
  @prop() number_name: string;
  @required() @digit() dp_id: number;
  @required({conditionalExpression: x => x.type == 1}) @digit() group_id: number;
  @required({conditionalExpression: x => x.type == 1}) @digit() expire: number = 3;
  @required({conditionalExpression: x => x.type == 1}) @digit() attempts_count: number = 2;

  @required({conditionalExpression: x => x.type == 1})
  @minNumber({value: 30}) @maxNumber({value: 3600})  delay_next_call: number = 60;
  @prop() html: string = '';
  @json() json: string = '{}';
  @digit() type: number = 1;
}

export class DEPWebCallProcessing {
  [key: string]: DEPWebCallNumber;
}

export class DomainExtensionDEPWebCallParams {
  @required() dep_name: string = 'DEPWebCall';
  @prop() apikey: string;

  @required() @digit() status: number = 1;
  @propObject(DEPWebCallProcessing) processing;

  static className() {
    return 'DomainExtension';
  }
}

export class DEPUserCRMNumber {
  @prop() number: string;
  @prop() number_name: string;
  @required() allow_processing: number[] = [0, 1]; // Разрешить обрабатывать вызов с определенным направлением: 0 - исх., 1 - вх., 2 - внутр.
  @required() c_transfer: boolean = true; // Переводить на ответственного
  @required() @digit() c_transfer_wait_time: number = 10; // Время ожидания соединения с ответственным менеджером
  @propObject(LSAudioList) c_transfer_say: LSAudioList; // Уведомление для клиента перед соединением с менеджером
  @propObject(LSAudioList) c_transfer_fail_say: LSAudioList; // Уведомление для клиента если менеджер не ответил
  @prop() responsible_no_map_user: string; // Ответственный за несопоставленных операторов
}

export class DEPUserCRMProcessing {
  [key: string]: DEPUserCRMNumber;
}

export class DomainExtensionDEPUserCRMParams {
  @required() dep_name: string = 'DEPUserCRM';
  @prop() apikey: string;
  @required({conditionalExpression: x => !x.one_sided}) web_hook: string;
  @prop() match_users: boolean = false;
  @prop() one_sided?: boolean = false;

  @prop() http_events: string[] = [];
  @prop() ws_events: string[] = [];

  @required() @digit() status: number = 1;
  @required() @propArray(CRMUserMap) crm_users: CRMUserMap[] = [];
  @propObject(DEPUserCRMProcessing) processing;

  static className() {
    return 'DomainExtension';
  }
}

export class DEPEnvyCRMNumber {
  @prop() number: string;
  @prop() number_name: string;
  @required() allow_processing: number[] = [0, 1]; // Разрешить обрабатывать вызов с определенным направлением: 0 - исх., 1 - вх., 2 - внутр.
  @required() c_transfer: boolean = true; // Переводить на ответственного
  @required() @digit() c_transfer_wait_time: number = 10; // Время ожидания соединения с ответственным менеджером
  @propObject(LSAudioList) c_transfer_say: LSAudioList; // Уведомление для клиента перед соединением с менеджером
  @propObject(LSAudioList) c_transfer_fail_say: LSAudioList; // Уведомление для клиента если менеджер не ответил
  @prop() responsible_no_map_user: string; // Ответственный за несопоставленных операторов
}

export class DEPEnvyCRMProcessing {
  [key: string]: DEPEnvyCRMNumber;
}

export class DomainExtensionDEPEnvyCRMParams {
  @required() dep_name: string = 'DEPEnvyCRM';
  @prop() apikey: string;
  @required() web_hook: string;
  @prop() match_users: boolean = false;

  @prop() http_events: string[] = [];
  @prop() ws_events: string[] = [];

  @required() @digit() status: number = 1;
  @required() @propArray(CRMUserMap) crm_users: CRMUserMap[] = [];
  @propObject(DEPEnvyCRMProcessing) processing;

  static className() {
    return 'DomainExtension';
  }
}

export class DomainExtensionDEPPlanFixParams {
  @required() dep_name: string = 'DEPPlanFix';
  @prop() apikey: string;
  @required({conditionalExpression: x => !x.one_sided}) web_hook: string;

  @required() @digit() status: number = 1;
  @propObject(DEPUserCRMProcessing) processing;

  static className() {
    return 'DomainExtension';
  }
}

export class DomainExtensionDEPSftpDiskParams {
  @required() dep_name: string = 'DEPSftpDisk';

  @required() @pattern({expression: {onlyDigit: RxFormHelpers.ipV4}, message: 'ERROR.IP'}) ssh_host: string;
  @required() ssh_port: number = 22;
  @required() @pattern({expression: {onlyDigit: RxFormHelpers.domainOrIPV4}, message: 'ERROR.IP_OR_DOMAIN'}) http_host: string;
  @required() http_port: number = 443;

  @required() path: string = '~/';

  @required() @digit() status: number = 1;

  static className() {
    return 'DomainExtension';
  }
}

export class DomainExtensionDEPYandexDiskParams {
  @required() dep_name: string = 'DEPYandexDisk';

  @required() access_token: string;
  @required() refresh_token: string;

  @required() @digit() status: number = 1;

  static className() {
    return 'DomainExtension';
  }
}

export class DomainExtensionDEPYandexMetrikaParams {
  @required() dep_name: string = 'DEPYandexMetrika';

  @required() access_token: string;
  @required() refresh_token: string;

  @required() @digit() status: number = 1;

  static className() {
    return 'DomainExtension';
  }
}

export class DEPYandexTrackerNumber {
  @prop() number: string;
  @prop() number_name: string;
  @required() allow_processing: number[] = [0, 1]; // Разрешить обрабатывать вызов с определенным направлением: 0 - исх., 1 - вх., 2 - внутр.
  @required() queue: string;
  @required() queue_no_user: string;
}

export class DEPYandexTrackerProcessing {
  [key: string]: DEPYandexTrackerNumber;
}

export class DomainExtensionDEPYandexTrackerParams {
  @required() dep_name: string = 'DEPYandexTracker';
  @prop() access_token: string;
  @required() refresh_token: string;

  @required() org: string; // организация
  @required() field_call_src: string;
  @required() field_call_dst: string;
  @required() field_call_duration: string;
  @required() field_call_billsec: string;
  @required() field_call_direction: string;
  @required() field_call_dial_status: string;
  @required() field_call_hold_accum_seconds: string;
  @required() field_call_dt: string;
  @required() field_call_wait_answer: string;
  @required() field_call_unique: string;

  @required() @propArray(CRMUserMap) crm_users: CRMUserMap[] = [];
  @propObject(DEPYandexTrackerProcessing) processing;//: any = {};

  @required() @digit() status: number = 1;

  static className() {
    return 'DomainExtension';
  }
}

export class DEPS4B {
  @required() dep_name: string = 'DEPMicrosoftSkypeForBusiness';
  @required() @digit() status: number = 1;
  @required() @minLength({value: 1}) @maxLength({value: 250}) @pattern({
    expression: {onlyDigit: /^(?!:\/\/)(?=.{1,255}$)((.{1,63}\.){1,127}(?![0-9]*$)[a-z0-9-]+\.?)$/},
    message: 'ERROR.IP_OR_DOMAIN'
  }) mediation_server: string;
  @required() mediation_transport: number = 0; // choices=[PEER_PROTO_TCP = 0, PEER_PROTO_TLS = 2]
  @required() mediation_port: number = 5068; // min_value=1
  @required() out_profile: number;
  @required() @minLength({value: 4}) extension_re: string = '^(\\d{5})$';
  @prop() group_id: number;
  // @required() @pattern({expression: {onlyDigit: RxFormHelpers.url}, message: 'ERROR.URL'}) discovery_url: string;
  // @required({conditionalExpression: x => !!x.id}) refresh_token: string;
}

export class DEPNeuroNet {
  @required() dep_name: string = 'DEPNeuroNet';
  @required() @digit() status: number = 1;

  @required() web_hook: string;
  @required() api_login: string;
  @required() api_password: string;
}

export class DEPSkillazNumber {
  @required() allow_processing: number[] = [0, 1, 2]; // Разрешить обрабатывать вызов с определенным направлением: 0 - исх., 1 - вх., 2 - внутр.
}

export class DEPSkillazProcessing {
  [key: string]: DEPSkillazNumber;
}

export class DEPSkillaz {
  @required() dep_name: string = 'DEPSkillaz';
  @required() @digit() status: number = 1;
  @prop() apikey: string;
  @required() simple_auth: boolean = false; // Переводить на ответственного
  @required() web_hook: string;

  @prop() du_uid_list: string[] = [];
  @required() @choice({minLength: 1, message: 'ERROR.REQUIRED'}) servers: string[] = [];
  @propObject(DEPSkillazProcessing) processing;
}

export class DEPCascanaParams {
  @required() dep_name: string = 'DEPCascana';
  @required() webhook: string;
  @required() apikey: string;
  @required() @digit() status: number = 1;

  static className() {
    return 'DomainExtension';
  }
}


export class DomainExtensionParams {
  @required() dep_name: string;
  @required() @digit() status: number = 1;
}

export class DomainExtension {
  @prop() id?: number;
  @prop() domain_id: number;
  @prop() type: number[];
  @required() @propObject(undefined, {
    entityProvider: function() {
      let cls;
      switch (this.params.dep_name) {
        case 'TrafficCollector':
          cls = DomainExtensionDEPTrafficCollectorParams
          break;
        case 'DEPAmoCRM':
          cls = DomainExtensionDEPAmoCRMParams;
          break;
        case 'DEPBitrix24':
          cls = DomainExtensionDEPBitrix24Params;
          break;
        case 'DEPSberCRM':
          cls = DomainExtensionDEPSberCRMParams;
          break;
        case 'DEPYandexSpeechKit':
          cls = DomainExtensionDEPYandexSpeechKitParams;
          break;
        case 'DEPTinkoffVoiceKit':
          cls = DomainExtensionDEPTinkoffVoiceKitParams;
          break;
        case 'DEPTinkoffTQM':
          cls = DomainExtensionDEPTinkoffQMParams;
          break;
        case 'DEP3iTechAnalytics':
          cls = DomainExtensionDEP3iTechAnalyticsParams;
          break;
        case 'DEPGooTax':
          cls = DomainExtensionDEPGooTaxParams;
          break;
        case 'DEPYandexTaxi':
          cls = DomainExtensionDEPYandexTaxiParams;
          break;
        case 'DEPRBTaxi':
          cls = DomainExtensionDEPRBTaxiParams;
          break;
        case 'DEPSMSTrafficRu':
          cls = DomainExtensionDEPSMSTrafficRuParams;
          break;
        case 'DEPMegafonA2PSMS':
          cls = DomainExtensionDEPMegafonA2PSMSParams;
          break;
        case 'DEPRetailCRM':
          cls = DomainExtensionDEPRetailCRMParams;
          break;
        case 'DEPElma365':
          cls = DomainExtensionDEPElma365Params;
          break;
        case 'DEPBPMOnline':
          cls = DomainExtensionDEPBPMOnlineParams;
          break;
        case 'DEPProstieZvonki':
          cls = DomainExtensionDEPProstieZvonkiParams;
          break;
        case 'DEPRoiStat':
          cls = DomainExtensionDEPRoiStatParams;
          break;
        case 'DEPYClients':
          cls = DomainExtensionDEPYClientsParams;
          break;
        case 'DEPAltegio':
          cls = DomainExtensionDEPAltegioParams;
          break;
        case 'DEPWebCall':
          cls = DomainExtensionDEPWebCallParams;
          break;
        case 'DEPUserCRM':
          cls = DomainExtensionDEPUserCRMParams;
          break;
        case 'DEPEnvyCRM':
          cls = DomainExtensionDEPEnvyCRMParams;
          break;
        case 'DEPPlanFix':
          cls = DomainExtensionDEPPlanFixParams;
          break;
        case 'DEPSftpDisk':
          cls = DomainExtensionDEPSftpDiskParams;
          break;
        case 'DEPYandexDisk':
          cls = DomainExtensionDEPYandexDiskParams;
          break;
        case 'DEPYandexMetrika':
          cls = DomainExtensionDEPYandexMetrikaParams;
          break;
        case 'DEPYandexTracker':
          cls = DomainExtensionDEPYandexTrackerParams;
          break;
        case 'DEPMegaplan':
          cls = DomainExtensionDEPMegaplanParams;
          break;
        case 'DEPEStaff':
          cls = DomainExtensionDEPEStaffParams;
          break;
        case 'DEPArgusWFM':
          cls = DomainExtensionDEPArgusWFMParams;
          break;
        case 'DEPUsedesk':
          cls = DomainExtensionDEPUsedeskParams;
          break;
        case 'DEPMicrosoftSkypeForBusiness':
          cls = DEPS4B;
          break;
        case 'DEPNeuroNet':
          cls = DEPNeuroNet;
          break;
        case 'DEPSkillaz':
          cls = DEPSkillaz;
          break;
        case 'DEPCascana':
          cls = DEPCascanaParams;
          break;
        default:
          cls = DomainExtensionParams;
      }
      return cls;
    }
  }) params:
    DomainExtensionDEPTrafficCollectorParams |
    DomainExtensionDEPAmoCRMParams |
    DomainExtensionDEPBitrix24Params |
    DomainExtensionDEPSberCRMParams |
    DomainExtensionDEPYandexSpeechKitParams |
    DomainExtensionDEPTinkoffVoiceKitParams |
    DomainExtensionDEPTinkoffQMParams |
    DomainExtensionDEP3iTechAnalyticsParams |
    DomainExtensionDEPGooTaxParams |
    DomainExtensionDEPYandexTaxiParams |
    DomainExtensionDEPRBTaxiParams |
    DomainExtensionDEPSMSTrafficRuParams |
    DomainExtensionDEPMegafonA2PSMSParams |
    DomainExtensionDEPRetailCRMParams |
    DomainExtensionDEPElma365Params |
    DomainExtensionDEPBPMOnlineParams |
    DomainExtensionDEPProstieZvonkiParams |
    DomainExtensionDEPRoiStatParams |
    DomainExtensionDEPYClientsParams |
    DomainExtensionDEPAltegioParams |
    DomainExtensionDEPWebCallParams |
    DomainExtensionDEPUserCRMParams |
    DomainExtensionDEPEnvyCRMParams |
    DomainExtensionDEPPlanFixParams |
    DomainExtensionDEPSftpDiskParams |
    DomainExtensionDEPYandexDiskParams |
    DomainExtensionDEPYandexMetrikaParams |
    DomainExtensionDEPYandexTrackerParams |
    DomainExtensionDEPMegaplanParams |
    DomainExtensionDEPEStaffParams |
    DomainExtensionDEPArgusWFMParams |
    DomainExtensionDEPUsedeskParams |
    DEPS4B |
    DEPNeuroNet |
    DEPSkillaz |
    DEPCascanaParams |
    DomainExtensionParams;

  static className() {
    return 'DomainExtension';
  }
}
