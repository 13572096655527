import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DesktopColumns} from '../../desktop-columns';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {DesktopService} from '../../base-desktop.service';
import {PerfectScrollbarConfigInterface} from "ngx-perfect-scrollbar";

@Component({
  selector: 'app-desktop-queue',
  templateUrl: './desktop-queue.component.html',
  styleUrls: ['./desktop-queue.component.scss',
  '../../desktop-list.component.scss']//,
  //providers: [Monitor2Service]
})
export class DesktopQueueComponent implements OnInit, OnDestroy {
  public select_list = [];
  public showColumns: DesktopColumns[] = [];
  private subscription: Subscription = new Subscription();
  @Input() columns: DesktopColumns[];
  @Input() change = new EventEmitter();
  public queue: any = {};
  public config: PerfectScrollbarConfigInterface = {
    minScrollbarLength: 5,
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
    wheelSpeed: 2,
    wheelPropagation: true
  };
  constructor(public  desktopService: DesktopService) {

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.showColumns = this.columns.filter(item=>item.checked);
    let subMenu$ = this.desktopService.changeMenu.subscribe(menu => {
      if (menu.name == 'DesktopQueueComponent') {
        this.showColumns = this.columns.filter(item=>menu.columns.includes(item.id))
      }
    });

    let subData$ = this.desktopService.queueData$.subscribe(item=>{
      if (this.desktopService.currentQueue?.value)
        this.queue = item.find(val=>val.queue_id == this.desktopService.currentQueue.value)

    });

    this.desktopService.currentQueue.subscribe(value=>{
      if (value) this.desktopService.cc_monitor_v2_queue$.next([value]);
    })

    let subCurrent$ = this.desktopService.currentQueue.subscribe(current=>{
      this.queue = this.desktopService.queueData$?.value?.find(val=>val.queue_id == this.desktopService.currentQueue.value)

    });

    this.subscription.add(subMenu$);
    this.subscription.add(subData$);
    this.subscription.add(subCurrent$);

  }

  setCurrent(value) {
    this.queue = this.desktopService.queueData$?.value?.find(val=>val.queue_id == value);
    this.desktopService.currentQueue.next(value);
  }
}
