import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-chat-warn',
  templateUrl: 'chat-warn.component.html',
  styleUrls: ['chat-warn.component.scss'],
  styles: [`
      ::ng-deep .ng-dropdown-panel.above {
          z-index: 9999999;
      }
  `]
})

export class ChatWarnComponent {
  isServerAvailable: boolean = true;

  constructor(
    public dialogGroup: MatDialogRef<ChatWarnComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  submit(): void {
    this.dialogGroup.close(true)
  }

}
