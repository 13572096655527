import {AfterViewInit, Component, OnDestroy, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {OnInit} from '@angular/core';
import {forkJoin, Subject, Subscription} from "rxjs";
import {CTIService} from "../../../cti-panel/cti-panel.service";
import {FormArray, FormControl} from "@angular/forms";
import {MatTabChangeEvent, MatTabGroup} from "@angular/material/tabs";
import {DomainCRMEntityFormComponent} from "./entity-form.component";
import {DomainCrmSettingsService} from "../domain-crm-settings/domain-crm-settings.service";
import {DomainCrmSettings} from "../domain-crm-settings/domain-crm-settings";
import {NotifyService} from "../../../_helpers/notify.service";
import {DomainService} from "../../domain.service";
import {DomainCRMEventDField} from "../events/event";
import {RxFormBuilder} from "@rxweb/reactive-form-validators";
import {DomainCRMEventService} from "../events/event.service";
import {TranslateService} from "@ngx-translate/core";
import {debounceTime, distinctUntilChanged, switchMap} from "rxjs/operators";
import {DomainCRMEntityService} from "./entity.service";


@Component({
  selector: 'desktop-crm-entity',
  templateUrl: './desktop-entity.component.html',
  styles: [`
    /*:host .mat-tab-group {*/
    /*  height: calc(100% - 37px);*/
    /*  position: relative;*/
    /*  z-index: 1000;*/
    /*}*/
  `],
  styleUrls: []
})
export class DesktopEntityComponent implements OnInit, AfterViewInit, OnDestroy {
  public selectedTabCtrl = new FormControl(0);
  public crmList: {calls: {phone: string, callID: string}[], name?: string, id?: number}[] = []; // и прочие параметры DomainCRMEntity
  public crmSettings: DomainCrmSettings = null;
  public timezone = 'Europe/Moscow';
  public lsKey: string = 'desktopCRMEntityList';
  private subscription: Subscription = new Subscription();
  public entities = [];
  public entityLoading: boolean = false;
  public isEntitySearching: boolean = false;
  public totalEntity = null;
  public loadedEntity = 0;
  public entitySearch$: Subject<any> = new Subject<any>();


  @ViewChild('desktopEntityTabsGroup') desktopEntityTabsGroup: MatTabGroup;
  @ViewChildren('appEntityChild') appEntityChildren: QueryList<DomainCRMEntityFormComponent>; // всегда один элемент, т.к. шаблоны грузятся в ng-template

  constructor(
    public CTI: CTIService,
    public notifyService: NotifyService,
    private domainCrmSettingsService: DomainCrmSettingsService,
    private domainCRMEventService: DomainCRMEventService,
    private domainCRMEntityService: DomainCRMEntityService,
    private domainService: DomainService,
    public fb: RxFormBuilder,
    public translate: TranslateService
  ) {
  }

  ngOnInit() {
    forkJoin([
      this.domainCrmSettingsService.get(null),
      this.domainService.get_tz()
    ]).subscribe(
      results => {
        if (results[0]['id']) this.crmSettings = results[0];
        this.timezone = results[1];
        this.getCRMList();
        this.subscribe();
      },
      resp => {
        this.getCRMList();
        this.subscribe();
      }
    );
  }

  ngAfterViewInit() {
    this.selectedTabCtrl.setValue(1);
    this.selectedTabCtrl.setValue(0);
    setTimeout(() => {
      this.desktopEntityTabsGroup?.realignInkBar();
      this.appEntityChildren.forEach(aec => aec.entityTabsGroup?.realignInkBar());
    }, 50);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  subscribe() {
    // подписка на звонок контакта\компании CRM
    let subCti$ = this.CTI.crmEntity$.subscribe((entityData: any) => {
      // отображаю вкладку в CRM-окне Рабочего стола
      this.getCRMList(entityData);
    });
    this.subscription.add(subCti$);

    // подписка на нажатие кнопки "Отправить в CRM" на вкладке "База знаний" Рабочего стола
    let crmKnoweldge$ = this.CTI.crmKnoweldge$.subscribe((knowledgeData: any) => {
      // сохраняю в последнее Событие с типом "Звонок", в поле "Вопросы Базы знаний" из Настроек CRM
      if (knowledgeData && this.crmList[this.desktopEntityTabsGroup.selectedIndex]?.id) {
        this.addDocumentToEventField(knowledgeData);
      } else {
        this.notifyService.message('SEND_TO_CRM.SELECT_TAB');
      }
    });
    this.subscription.add(crmKnoweldge$);
    let subEntitySearch = this.entitySearch$.pipe(
      debounceTime(500), // в течении этого времени не учитываются изменения в поле поиска
      distinctUntilChanged(),
      switchMap((filter_value: string) => { // отменяю предыдущий запрос и начинаю новый
        this.entityLoading = true;
        this.totalEntity = null;
        this.loadedEntity = 0;
        this.entities = [];

        let field_list = [];
        if (filter_value) field_list.push({field: 'name', value: filter_value.toLowerCase(), condition_type: 3});
        if (this.crmList.filter(ent => ent.id != null).length > 0) {
          field_list.push({field: 'id', value: this.crmList.filter(ent => ent.id != null).map(ent => ent.id), condition_type: 10});
        }

        return (this.domainCRMEntityService.list({
          ...(field_list.length > 0 ? {filter: {field_list: field_list, type: 0}} : {}),
          sort: {'entity_type': '+', 'name': '+'},
          limit: 100,
          offset: this.loadedEntity
        }, 'select_with_detail'));
      })
    ).subscribe(
      data => {
        this.totalEntity = data['list']?.length || 0;
        this.loadedEntity += data['list']?.length || 0;
        this.entities = [...this.entities, ...(data['list'] || [])];
        this.entityLoading = false;
      },
      resp => {
        this.entityLoading = false;
      }
    );
    this.subscription.add(subEntitySearch);
  }

  getCRMList(entity?) {
    let crmList = localStorage.getItem(this.lsKey);
    let entityTabIndex = entity ? this.crmList.findIndex(e => (entity.id && e.id == entity.id) || (e.calls.length > 0 && entity.calls.length > 0 && e.calls[0].phone == entity.calls[0].phone)) : -1;

    if (entity) {
      if (entityTabIndex != -1 && this.crmList[entityTabIndex].id && (this.appEntityChildren?.get(0)?.id == this.crmList[entityTabIndex].id)) { // this.appEntityChildren?.get(0) - всегда один элемент, т.к. шаблоны грузятся в ng-template
        this.crmList[entityTabIndex].calls = entity.calls;
        console.log('%cПроверяю и создаю обращения по вызовам: ' + JSON.stringify(entity.calls) + '..', 'color: orange; font-size: 16px;');
        this.appEntityChildren.get(0).createCallEvents(entity.calls);
        if (entity.script_data) {
          this.appEntityChildren.get(0).showDialogScript(entity.script_data);
        }
      } else {
        this.crmList = crmList != null ? [{calls: []}, ...JSON.parse(crmList)] : [{calls: []}];
        entityTabIndex = this.crmList.findIndex(e => (entity.id && e.id == entity.id) || (e.calls.length > 0 && entity.calls.length > 0 && e.calls[0].phone == entity.calls[0].phone));
      }

      setTimeout(() => {
        if (entityTabIndex != -1) {
          this.desktopEntityTabsGroup.selectedIndex = entityTabIndex; // лучше напрямую ставить индекс в группе вкладок, чем через контрол, т.к. тогда не нужна задержка
          setTimeout(() => {
            this.desktopEntityTabsGroup?.realignInkBar();
            this.appEntityChildren.forEach(aec => aec.entityTabsGroup?.realignInkBar());
          }, 50);
        }
      }, 150);

    } else this.crmList = crmList != null ? [{calls: []}, ...JSON.parse(crmList)] : [{calls: {}}];
  }

  addDocumentToEventField(data) {
    if (this.crmSettings && this.crmSettings.event_inbound_call && this.crmSettings.event_field_selected_documents) {
      this.domainCRMEventService.list({
        filter: {type: 0, field_list: [{field: 'entity_id', value: this.crmList[this.desktopEntityTabsGroup.selectedIndex].id, condition_type: 0}]},
        sort: {id: '-'},
        limit: 1
      }).subscribe(
        entityEvents => {
          let lastCallEvent = entityEvents.list.find(e => e.ref_event_id == this.crmSettings.event_inbound_call);
          let kwField = lastCallEvent?.dfields?.find(f => f.field_id == this.crmSettings.event_field_selected_documents);
          if (lastCallEvent && (!kwField || kwField?.list_val?.indexOf(data.id) == -1)) {
            if (!kwField) {
              lastCallEvent.dfields.push(
                {
                  id: null,
                  event_id: lastCallEvent.id,
                  field_id: this.crmSettings.event_field_selected_documents,
                  list_val: [data.id]
                } as DomainCRMEventDField
              );
            } else {
              kwField.list_val = [...kwField.list_val, data.id];
            }
            // lastCallEvent.with_dfields = true;

            this.domainCRMEventService.save(lastCallEvent).subscribe(
              eventData => {
                this.CTI.crmEntity$.next(this.crmList[this.desktopEntityTabsGroup.selectedIndex]);
                this.notifyService.showRequestResult(
                  eventData,
                  false,
                  this.translate.instant('SEND_TO_CRM_RESULT', {
                    number: lastCallEvent.id,
                    type: this.translate.instant(this.appEntityChildren.get(0).entity_type == 0 ?  'COMPANY' : 'CONTACT'),
                    name: this.appEntityChildren.get(0).form.value.name,
                  })
                );

                if (this.appEntityChildren?.get(0)?.id == lastCallEvent.entity_id) {
                  let addedField = eventData.body.dfields.find(f => f.field_id == this.crmSettings.event_field_selected_documents);
                  let event = this.appEntityChildren.get(0).entityEvents.controls.find(evt => evt.value.id == lastCallEvent.id);
                  if (event) {
                    let eventField = event.get('dfields')?.['controls']?.find(f => f.value.id == addedField?.id);
                    if (eventField) eventField.patchValue({list_val: kwField.list_val});
                    else {
                      let rxModel = this.fb.formGroup(new DomainCRMEventDField());
                      rxModel.patchValue(addedField);
                      (event.get('dfields') as FormArray).push(rxModel);
                    }
                  }
                  this.appEntityChildren.get(0).getKnowledgeDocuments([data.id]);
                } // this.appEntityChildren?.get(0) - всегда один элемент, т.к. шаблоны грузятся в ng-template
              }
            );
          } else {
            this.notifyService.message(
              lastCallEvent ?
              'SEND_TO_CRM_ALREADY_EXISTS' :
              this.translate.instant(this.appEntityChildren.get(0).entity_type == 0 ? 'COMPANY_NO_CALL_ACTIVITY' : 'CONTACT_NO_CALL_ACTIVITY', {name: this.appEntityChildren.get(0).form.value.name})
            );
          }
        },
        resp => {
          this.notifyService.setFormErrors(resp);
        }
      );
    }
  }

  createCRMEntity(ent?) {
    let crmEntityData = ent ? {calls: [], id: ent.id, name: ent.name, entity_type: ent.entity_type} : {calls: [{phone: 'new_entity'}]};
    let desktopCRMEntityList: any[] = localStorage.getItem(this.lsKey) != null ? JSON.parse(localStorage.getItem(this.lsKey)) : [];
    let client = desktopCRMEntityList.find(v => ent ? ent.id == v.id : v.calls.find(c => c.phone == crmEntityData.calls[0].phone));
    if (!client) {
      desktopCRMEntityList.splice(0, 0, crmEntityData);
      localStorage.setItem(this.lsKey, JSON.stringify(desktopCRMEntityList));
    }
    // уведомляю рабочий стол о новом вызове, чтобы перечитались вкладки окна CRM
    this.CTI.crmEntity$.next(crmEntityData);
  }

  openCRMEntity(ent) {
    if (ent?.id != null) {
      this.createCRMEntity(ent);
      this.entities = [...this.entities.filter(e => e.id != ent.id)];
    }
  }

  entityScrollEnd(filter_value: string) {
    if (this.totalEntity === null || (this.loadedEntity < this.totalEntity)) {
      this.entityLoading = true;

      let field_list = [];
      if (filter_value) field_list.push({field: 'name', value: filter_value.toLowerCase(), condition_type: 3});
      if (this.crmList.filter(ent => ent.id != null).length > 0) {
        field_list.push({field: 'id', value: this.crmList.filter(ent => ent.id != null).map(ent => ent.id), condition_type: 10});
      }

      this.domainCRMEntityService.list({
        ...(field_list.length > 0 ? {filter: {field_list: field_list, type: 0}} : {}),
        sort: {'entity_type': '+', 'name': '+'},
        limit: 100,
        offset: this.loadedEntity
      }, 'select_with_detail').subscribe(
        (data) => {
          if (this.totalEntity === null) this.totalEntity = data.total_count;
          for (let v of data.list) {
            if (this.entities.findIndex(e => e.id == v['id']) == -1) {
              this.entities.push(v);
              this.loadedEntity += 1;
            }
          }
          // this.entities.sort((a,b) => (a.name < b.name) ? -1 : (a.name > b.name ? 1 : 0));
          this.entities = [...this.entities];
          this.entityLoading = false;
        },
        resp => {
          this.entityLoading = false;
          this.notifyService.setFormErrors(resp);
        }
      );
    }
  }

  changeSelectedTab(event: MatTabChangeEvent) {
    // this.selectedTabCtrl.setValue(event.index); // вызывает бесконечное переключение между вкладками, если нажимать очень быстро или до инициализации вкладки
    setTimeout(() => {
      this.desktopEntityTabsGroup?.realignInkBar();
      this.appEntityChildren.forEach(aec => aec.entityTabsGroup?.realignInkBar());
    }, 50);
  }

  renameTab(data: {id: number, name: string}) {
    if (data.id != null && data.name != '') {
      let ind = this.crmList.findIndex(ent => ent.id == data.id);
      if (ind != -1) this.crmList[ind].name = data.name;
      localStorage.setItem(this.lsKey, JSON.stringify(this.crmList.slice(1)));
    }
  }

  closeTab(i: number) {
    this.crmList.splice(i, 1);
    localStorage.setItem(this.lsKey, JSON.stringify(this.crmList.slice(1)));
  }

  findTabAndClose(id: number) {
    if (id != null) {
      let ind = this.crmList.findIndex(ent => ent.id == id);
      if (ind != -1) this.closeTab(ind);
    }
  }
}
