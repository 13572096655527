import {prop, required} from '@rxweb/reactive-form-validators';

export class DomainOrgUnitUserPosition {
  @prop() domain_id: number;
  @required() unit_id: number;
  @required() position_id: number;
  @required() user_id: number;

  static className() {
    return 'DomainOrgUnitUserPosition';
  }
}
