import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NgSelectComponent} from '@ng-select/ng-select';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {DomainUserService} from '../../users/domain-user.service';
import {TranslateService} from '@ngx-translate/core';
import {NotifyService} from '../../_helpers/notify.service';
import {RxFormHelpers} from '../../_helpers/form.helpers';
import {UserService} from '../../users/user.service';
import {IResponseListObject} from '../../_helpers/api.helpers';
import {BehaviorSubject, Observable, of as observableOf, Subject} from 'rxjs';
import {NgSelectScrollService} from "../../_helpers/ng-select-scroll.service";


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mat-select-user',
  templateUrl: './mat-select-user.component.html',
  styleUrls: ['./mat-select-user.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatSelectUserComponent),
      multi: true
    }
  ]
})
export class MatSelectUserComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('select') selects: NgSelectComponent[];

  @Input() control: FormControl = new FormControl(null);
  @Input() filter = {};
  @Input() bindValue = 'id';
  @Input() placeholder = '';
  @Input() icons = false;
  @Input() multiple = false;
  @Input() type = 0; // 0 - DomainUser, 1 - User
  @Input() addItems = [];
  @Input() exclude = [];
  @Input() clearable: boolean = false;
  @Input() appendTo: string = 'body';
  @Input() set value( item ) {
    setTimeout(()=>{
      if (item) item = parseInt(item ,10);
      this.control.setValue(item);
      this.preloadUsers();
    });
  }
  get value() {
    return this.control.value;
  }
  @Input() exclude$: BehaviorSubject<Number[]>;
  @Input() bindIdWhole: boolean = false;
  @Output() selected = new EventEmitter();
  public search$: Subject<any> = new Subject<any>();
  public searchSub;
  public rxFormHelpers;
  public userService: UserService | DomainUserService;
  public select_user = [];
  public select_user_buffer = [];
  public select_user_count = 0;  // поисковое количество всего
  public select_user_count_all = 0; // буферное количество всего
  public limit = 250;
  public run_once = 0;
  public sort = {};
  public common_filter = {filter: {field_list: [], type: 0, is_searching: false}};
  public loading: boolean = false;
  public minTermLength: number = 2; // при !локальной! фильтрации пока не работает
  public preload = [];

  constructor(
    public domainService: DomainUserService,
    public loginService: UserService,
    public translate: TranslateService,
    public notifyService: NotifyService,
    public ngSelectScrollService: NgSelectScrollService
  ) {
    this.rxFormHelpers = new RxFormHelpers();
  }

  getPreload() {
    return this.preload;
  }
  preloadUsers() {
    this.preload = [];

    if (!this.multiple && this.control.value && !this.select_user_buffer.find(val=> val.id==this.control.value)) {
      this.preload.push(this.control.value);
    }

    if (this.multiple && Array.isArray(this.control.value)) {
      this.preload = Array.from(this.control.value).filter(item=>!this.select_user_buffer.find(buf=>buf.id==item))
    }

    if ( this.preload.length > 0) {
      let fltr = {filter: {field_list: [], type: 0}};
      // @ts-ignore
      if (this.filter !== {}) {
        if (this.filter && this.filter['filter']) {
          fltr.filter.field_list = [...this.filter['filter']['field_list']];
          fltr.filter['type'] = this.filter['filter']['type'];
        }

        fltr.filter.field_list.push({
          field: 'id',
          condition_type: 9,
          value:  this.preload
        })

      } else {
        fltr.filter.field_list = [
          {
            field: 'id',
            condition_type: 9,
            value:  this.preload
          }
        ];
      }

      this.loading = true;
      ((this.type === 0 ? this.domainService : this.loginService)
        .list(Object.assign(fltr, {is_preloading: true}), 'select') as Observable<IResponseListObject<any>>)
        .subscribe((data: any) => {
          this.loading = false;
          this.select_user_buffer = this.select_user_buffer.concat(data.list?.filter(item => !this.exclude.includes(item.id)).filter(item => this.select_user_buffer.map(i => i.id).indexOf(item.id) < 0));
          this.select_user = data.list?.filter(item => !this.exclude.includes(item.id));
        }, resp => this.loading = false);
    }
  }

  getUser() {
    if (this.run_once === 0) {
      this.loading = true;
      // offset = 0, чтобы не пропустить в начале данные
      const query = Object.assign({},{run_once: true}, this.get_common_filter(), {limit: this.limit, offset: 0}, this.sort);
      ((this.type===0?this.domainService:this.loginService)
        .list(query, 'select') as Observable<IResponseListObject<any>>)
        .subscribe((data: any) => {
          this.run_once++;
          this.loading = false;

          this.select_user_buffer = this.select_user_buffer.concat(data.list?.filter(item=>!this.exclude.includes(item.id)).filter(item=>this.select_user_buffer.map(i=>i.id).indexOf(item.id)<0));
          this.select_user = this.select_user_buffer;
          this.select_user_count_all = data.total_count - this.exclude.length;
          this.select_user_count = data.total_count - this.exclude.length;
        }, resp => this.loading = false);
    }
  }

  init_common_filter() {
    this.common_filter.filter.field_list = [];

    if (this.filter && this.filter['filter']) {
      this.common_filter.filter.field_list = [...this.filter['filter']['field_list']];
      this.common_filter.filter['type'] = this.filter['filter']['type'];
    }
  }

  get_common_filter(){
    return this.common_filter.filter.field_list.length > 0 ? this.common_filter : {};
  }

  ngOnInit(): void {
    this.init_common_filter();
    // подписываюсь на событие получения пользователей
    // @ts-ignore
    (this.type===0?this.domainService:this.loginService).users$.subscribe(data => {
      // если прилетели отфильтрованные пользователи, то в общей рассылке не учитываю их, т.к. они отображаются только селекте с фильтром
      if (!data['params'] || !data['params']['filter'] || !data['params']['filter']['is_searching']) {
        if (data['clear']) { // очистка загруженных пользователей, перед тем как загрузить новые с учетом исключенных
          this.select_user_buffer = [];
          this.select_user = [];
          this.select_user_count_all = 0;
          this.select_user_count = 0;
        } else {
          // если пользователи не из предзагрузки, то инициализирую селекты, якобы они все были открыты
          if ((!('is_preloading' in data) || !data['is_preloading']) && !this.run_once) this.run_once++;

          this.select_user_buffer = this.select_user_buffer.concat(
            data.list
              ?.filter(item => !this.exclude.includes(item.id))
              .filter(item => this.select_user_buffer.map(i => i.id).indexOf(item.id) < 0)
          );
          this.select_user = this.select_user_buffer;
          this.select_user_count_all = data.total_count - this.exclude.length;
          this.select_user_count = data.total_count - this.exclude.length;
        }
      }
    });

    this.sort = {sort : (this.type == 0 ? {uid: '+'} : {login: '+'})};

    this.preloadUsers();

    if (this.exclude$) this.exclude$.subscribe(item=> {
      this.exclude = item;
      if (item.length > 0 || this.run_once != 0) {
        this.userService.clear_list();
        this.preloadUsers();
        this.run_once = 0;
        this.getUser();
      }
    });
    window.addEventListener('scroll', (evt) => this.ngSelectScrollService.onScroll(evt, this.selects), true);
  }

  ngOnDestroy(): void {
    if (this.searchSub) this.searchSub.unsubscribe();
    window.removeEventListener('scroll', (evt) => this.ngSelectScrollService.onScroll(evt, this.selects), true);
  }

  ngAfterViewInit(): void {
    if (this.type === 0) this.userService = this.domainService;
    else this.userService = this.loginService;

    this.searchSub = this.search$.pipe(
      debounceTime(500), // в течении этого времени не учитываются изменения в поле поиска
      distinctUntilChanged(),
      switchMap((search_data: any) => { // отменяю предыдущий запрос и начинаю новый
        var select = search_data['select'],
          value = search_data['text'];

        select.searchTerm = value;
        if (value && value.length>=this.minTermLength && this.select_user_buffer.length < this.select_user_count_all) {
          this.init_common_filter();
          const elFiltr = (this.type===0)?{field: 'uid', condition_type: 3, value: value}:{field: 'name', condition_type: 3, value: value};
          this.common_filter['filter']['field_list'].push(elFiltr);
          this.common_filter['filter']['is_searching'] = true;

          this.loading = true;
          return (this.userService.list(
            Object.assign({}, this.get_common_filter(), {limit: this.limit, offset: 0}, this.sort),
            'select'
          ) as Observable<IResponseListObject<any>>).pipe(
            map(data => {
                if (data['code'] != 200) throw data;
                this.select_user = data.list?.filter(item => !this.exclude.includes(item.id));
                this.select_user_count = data.total_count - this.exclude.length;
                return data;
              }
            ))
        } else {
          this.init_common_filter();
          this.common_filter['filter']['is_searching'] = false;
          this.select_user = this.select_user_buffer;
          this.select_user_count = this.select_user_buffer.length < this.select_user_count_all ? this.select_user_count_all-this.exclude.length : select.itemsList.filteredItems.length;
          return observableOf([]);
        }
      })
    ).subscribe(
      data => this.loading = false,
      resp => this.loading = false
    );
  }

  /* перекочевал в ngAfterViewInit
  serverSearch(select, value) {
    select.searchTerm = value;
    if (value && value.length>=this.minTermLength && this.select_user_buffer.length < this.select_user_count_all) {
      this.init_common_filter();
      const elFiltr = (this.type===0)?{field: 'uid', condition_type: 3, value: value}:{field: 'name', condition_type: 3, value: value};
      this.common_filter['filter']['field_list'].push(elFiltr);
      this.common_filter['filter']['is_searching'] = true;

      this.loading = true;
      if (this.searchSub) this.searchSub.unsubscribe();
      this.searchSub = (this.userService.list(Object.assign({}, this.get_common_filter(), {limit: this.limit, offset: 0}, this.sort), 'select') as Observable<IResponseListObject<any>>)
        .subscribe(data=>{
          // this.select_user_buffer = this.select_user_buffer
          //   .concat(data.list?.filter(item=>!this.exclude.includes(item.id))
          //     .filter(item=>this.select_user_buffer.map(i=>i.id).indexOf(item.id)<0));

          this.select_user = data.list?.filter(item => !this.exclude.includes(item.id));
          this.select_user_count = data.total_count - this.exclude.length;
          this.loading = false;
        }, resp => this.loading = false);
    } else {
      this.init_common_filter();
      this.common_filter['filter']['is_searching'] = false;
      this.select_user = this.select_user_buffer;
      this.select_user_count = this.select_user_buffer.length < this.select_user_count_all ? this.select_user_count_all-this.exclude.length : select.itemsList.filteredItems.length;
    }
  }
  */

  scrollEnd() {
    if (this.common_filter['filter']['is_searching'] && this.select_user.length<this.select_user_count) {
      let params = Object.assign(
        {},
        this.get_common_filter(),
        {
          limit: this.limit,
          offset: this.select_user.length - (this.select_user.length % this.limit) // общее количество среди найденных - остаток сверх лимита
        },
        this.sort
      );
      this.loading = true;
      (this.userService.list(params, 'select') as Observable<IResponseListObject<any>>).subscribe(
        data => {
          this.select_user = this.select_user.concat(data.list?.filter(item => !this.exclude.includes(item.id)).filter(item => this.select_user.map(i => i.id).indexOf(item.id) < 0));
          this.loading = false;
        },
        resp => this.loading = false
      );
    } else if (!this.common_filter['filter']['is_searching'] && this.select_user_buffer.length<this.select_user_count_all) {
      let params = Object.assign(
        {},
        this.get_common_filter(),
        {
          limit: this.limit,
          offset: this.select_user_buffer.length - (this.select_user_buffer.length % this.limit) // общее количество - остаток сверх лимита
        },
        this.sort
      );
      this.loading = true;
      (this.userService.list(params, 'select') as Observable<IResponseListObject<any>>).subscribe(
        data => {
          this.select_user_buffer = this.select_user_buffer.concat(data.list?.filter(item => !this.exclude.includes(item.id)).filter(item => this.select_user_buffer.map(i => i.id).indexOf(item.id) < 0));
          this.select_user = this.select_user_buffer;
          this.loading = false;
        },
        resp => this.loading = false
      );
    }

  }

  selectionChange(ev: any) {
    this.selected.emit(!this.bindIdWhole ? ev : this.select_user.find(u => u.id == ev));
  }
}
