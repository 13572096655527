import {Injectable} from '@angular/core';
import {of, of as observableOf} from 'rxjs';
import {catchError, map, mergeMap, tap, timeout} from 'rxjs/operators';
import {Helper, IResponseBodyObject} from '../_helpers/api.helpers';
import {AppConfiguration} from './app-configuration';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DomainWhiteLabel} from '../domains/domain-whilte-label/domain-white-label';
import {DomainSettings} from '../domains/domain-settings/domainSettings';
const headers = new HttpHeaders().set('Content-Type', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class JsonAppConfigService {
  private hostname = window.location.hostname;
  private configuration = new AppConfiguration();
  private file_configuration = {};

  constructor(private http: HttpClient) {}

  getValue(key: string, defaultValue?: any): any {
    return this.configuration[key] || defaultValue;
  }

  getValueFromFile(key: string, defaultValue?: any): any {
    return this.file_configuration[key];
  }

  tryPersonalLoad(id) {
    const requestBody = Helper.requestFormat('get', 'DomainWhiteLabel', {
      domain_id: id,
    });

    return this.http.post<IResponseBodyObject<DomainWhiteLabel>>(this.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        catchError(resp => {
          return of(null);
        }),
        tap(res => {
          if (res.code == 500){
            throw res;
          }
        }),
        map((data: any) => {
          if (data?.body?.params) return {
            ...data.body?.params,
            id: data.body?.id,
            domain_id: data.body?.domain_id,
          } as DomainSettings;

          else if (data?.body) return data.body;
          else return of(new DomainWhiteLabel())
        }),

      );
  }

  // This function needs to return a promise
  load(id = null, uri = null, src=this.hostname) {
    console.log(uri)
    console.log(src)
     return this.http.get<AppConfiguration>(uri ? (uri + 'assets/env/env.'+ src +'.json') : ('/assets/env/env.'+ src +'.json'))
      .pipe(
        catchError(error => this.http.get<AppConfiguration>(uri ? (uri + 'assets/env/env.default.json') : ('/assets/env/env.default.json'))),
        tap((configuration: AppConfiguration) => {
          this.configuration = Object.assign(new AppConfiguration(), configuration);
          if (this.configuration.sourceUrl && !this.configuration.fileUrl) {
            this.configuration.fileUrl = this.configuration.sourceUrl + 'get_file'
          }
          this.file_configuration = {...<AppConfiguration>configuration};
          return this.configuration;
        }),

        mergeMap(configuration => {
          const requestBody = Helper.requestFormat('get', 'GSWhiteLabel', {});
          return this.http.post<IResponseBodyObject<any>>(this.getValue('apiUrl'), requestBody, {headers: headers})
            .pipe(map(white_label_data => white_label_data.body))
        }),

        mergeMap(_=> {
          if (id) {
            const requestDomainBody = Helper.requestFormat('get', 'DomainWhiteLabel', { domain_id: id });
            return this.http.post<IResponseBodyObject<any>>(this.getValue('apiUrl'), requestDomainBody, {headers: headers})
              .pipe(
                catchError(resp => {
                  return of(_);
                }),
                tap(res => {
                  if (res.code == 200) return res; else return of(_);
                }),
                map(data => {
                  if (data?.body?.params) return {
                    ...data.body?.params,
                    id: data.body?.id,
                    domain_id: data.body?.domain_id,
                  } as DomainWhiteLabel;

                  else if (data?.body) return data.body;
                  else {
                    this.setConfig(_)
                    return of(_)
                                    }
                })
              )
            return of(_)
          }
          else return of(_)
        }),

        timeout(5000),
        catchError(err => {
          // АПИ временно не доступно;
          // устанавливаю флаг ошибки загрузки DomainWhiteLabel-настроек для перенаправления пользователя на страницу тех. работ
          this.configuration.loadingError = true;
          console.error(typeof err == 'object' ? err.message : err);
          return observableOf(null)
        }),

        tap(white_label_data => {
         this.setConfig(white_label_data)
        }),
        map(data=> {
          return data;
        })
      )
  }

  setConfig(white_label_data) {
    if (white_label_data) {
      this.configuration.loadingError = false;
      if ('title' in white_label_data && white_label_data['title'])
        this.configuration['title'] = white_label_data['title'];
      if ('site' in white_label_data && white_label_data['site'])
        this.configuration['site'] = white_label_data['site']; // сайт компании
      if ('minisidebar' in white_label_data)
        this.configuration['minisidebar'] = white_label_data['minisidebar'];
      if ('boxed' in white_label_data)
        this.configuration['boxed'] = white_label_data['boxed'];
      if ('theme' in white_label_data && white_label_data['theme'])
        this.configuration['theme'] = white_label_data['theme'];
      if ('background_login_url' in white_label_data && white_label_data['background_login_url'].length)
        this.configuration['backgroundLogin'] = white_label_data['background_login_url'];
      if ('auth_window_position' in white_label_data && ['auth_window_position'])
        this.configuration['authWindowPosition'] = white_label_data['auth_window_position'];
      if ('gauss_palette' in white_label_data)
        this.configuration['gaussPalette'] = white_label_data['gauss_palette'];

      if ('icon_logo_url' in white_label_data && white_label_data['icon_logo_url'])
        this.configuration['iconLogo'] = white_label_data['icon_logo_url'];
      else if ('icon_logo' in white_label_data && white_label_data['icon_logo'])
        this.configuration['iconLogo'] = white_label_data['icon_logo'];
      white_label_data
      if ('icon_logo_mini_url' in white_label_data && white_label_data['icon_logo_mini_url'])
        this.configuration['iconLogoMini'] = white_label_data['icon_logo_mini_url'];
      else if ('icon_logo_mini' in white_label_data && white_label_data['icon_logo_mini'])
        this.configuration['iconLogoMini'] = white_label_data['icon_logo_mini'];

      if ('favicon_url' in white_label_data && white_label_data['favicon_url'])
        this.configuration['favicon'] = white_label_data['favicon_url'];
      else if ('favicon' in white_label_data && white_label_data['favicon'])
        this.configuration['favicon'] = white_label_data['favicon'];

      if ('primary_color' in white_label_data && white_label_data['primary_color'])
        this.configuration['primaryColor'] = white_label_data['primary_color'];
      if ('primary_contrast_color' in white_label_data && white_label_data['primary_contrast_color'])
        this.configuration['primaryContrastColor'] = white_label_data['primary_contrast_color'];
      if ('accent_color' in white_label_data && white_label_data['accent_color'])
        this.configuration['accentColor'] = white_label_data['accent_color'];
      if ('accent_contrast_color' in white_label_data && white_label_data['accent_contrast_color'])
        this.configuration['accentContrastColor'] = white_label_data['accent_contrast_color'];
      if ('warn_color' in white_label_data && white_label_data['warn_color'])
        this.configuration['warnColor'] = white_label_data['warn_color'];
      if ('warn_contrast_color' in white_label_data && white_label_data['warn_contrast_color'])
        this.configuration['warnContrastColor'] = white_label_data['warn_contrast_color'];
      if ('body_color' in white_label_data && white_label_data['body_color'])
        this.configuration['bodyColor'] = white_label_data['body_color'];
      if ('dp_border_color' in white_label_data && white_label_data['dp_border_color'])
        this.configuration['dpBorderColor'] = white_label_data['dp_border_color'];
      if ('dp_elem_hover' in white_label_data && white_label_data['dp_elem_hover'])
        this.configuration['dpElemHover'] = white_label_data['dp_elem_hover'];
      if ('sidebar_icon_color' in white_label_data && white_label_data['sidebar_icon_color'])
        this.configuration['sidebarIconColor'] = white_label_data['sidebar_icon_color'];
      if ('sidebar_text_color' in white_label_data && white_label_data['sidebar_text_color'])
        this.configuration['sidebarTextColor'] = white_label_data['sidebar_text_color'];
      if ('header_bg' in white_label_data && white_label_data['header_bg'])
        this.configuration['headerBg'] = white_label_data['header_bg'];
      if ('header_color' in white_label_data && white_label_data['header_color'])
        this.configuration['headerColor'] = white_label_data['header_color'];
      if ('dock_url' in white_label_data && white_label_data['dock_url'])
        this.configuration['dockURL'] = white_label_data['dock_url'];
      if ('footer' in white_label_data && white_label_data['footer'])
        this.configuration['footer'] = white_label_data['footer'];

      if ('sales_email' in white_label_data && white_label_data['sales_email'])
        this.configuration['salesEmail'] = white_label_data['sales_email'];
      if ('sales_phone' in white_label_data && white_label_data['sales_phone'])
        this.configuration['salesPhone'] = white_label_data['sales_phone'];
      if ('support_email' in white_label_data && white_label_data['support_email'])
        this.configuration['supportEmail'] = white_label_data['support_email'];
      if ('support_phone' in white_label_data && white_label_data['support_phone'])
        this.configuration['supportPhone'] = white_label_data['support_phone'];
      if ('ticket' in white_label_data && white_label_data['ticket'])
        this.configuration['ticket'] = white_label_data['ticket'];
    }
  }
}
