import {MediaMatcher} from '@angular/cdk/layout';
import {Router} from '@angular/router';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../../auth/authentication.service';
import {JsonAppConfigService} from '../../../config/json-app-config.service';
import {NotificationBarService} from './notification-bar.service';

@Component({
  selector: 'notification-bar',
  templateUrl: 'notification-bar.component.html',
  styleUrls: []
})
export class NotificationBarComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList;

  constructor(
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public translate: TranslateService,
    public authenticationService: AuthenticationService,
    public notificationBar: NotificationBarService,
    private AppConfig: JsonAppConfigService
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
