import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {NotifyService} from '../../../_helpers/notify.service';
import {FormControl, FormGroup} from '@angular/forms';
import {RxFormHelpers} from '../../../_helpers/form.helpers';
import {digit, minLength, prop, propObject, required, RxFormBuilder} from '@rxweb/reactive-form-validators';


@Component({
  selector: 'app-speech-dialog',
  templateUrl: './directoryDialog.component.html',
  styleUrls: ['./directoryDialog.component.css']
})

export class DirectoryDialogComponent implements OnInit {
  public returnFile;
  public rxFormHelpers = new RxFormHelpers();
  public voice_select = []
  public form;


  constructor(
    public dialogRef: MatDialogRef<DirectoryDialogComponent>,
    public translate: TranslateService,
    public fb: RxFormBuilder,
    public notifyService: NotifyService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    //domainVoiceKitService.toSelect({}, 'select').subscribe(kits => this.voice_select = kits);
    let aps = new DPCAudioTag();
    aps.processor = new DPCAudioTagProcessorNumber();
    this.form = this.fb.formGroup(aps);
  }

  ngOnInit(): void {
  }

  changeModelType(model_name){
    let serviceModel;
    switch (model_name) {
      case 'DPEAudioTagProcessorNumber': serviceModel = new DPCAudioTagProcessorNumber(); break;
      case 'DPEAudioTagProcessorMoney': serviceModel = new DPCAudioTagProcessorMoney(); break;
    }
    (this.form as FormGroup).setControl('processor', this.fb.formGroup(serviceModel));
  }

}

export class DPCAudioTagProcessorNumber {
  @prop()
  dpe_name: string = 'DPEAudioTagProcessorNumber';
  @prop() @digit()
  split: number = 0;
}

export class DPCAudioTagProcessorMoney {
  @prop()
  dpe_name: string = 'DPEAudioTagProcessorMoney';
  @required()
  currency: string = 'RUB';
}

export class DPCAudioTag {
  @prop()
  id?: number;
  @required()
  dpe_name: string = 'DPEAudioTag';
  @prop() @digit()
  source: number = 0;
  @required() @minLength({value: 2})
  value: string;
  @required()
  @propObject(undefined, {entityProvider: function() {
      let entity;
      switch (this.processor.dpe_name) {
        case 'DPEAudioTagProcessorNumber': entity = DPCAudioTagProcessorNumber; break;
        case 'DPEAudioTagProcessorMoney': entity = DPCAudioTagProcessorMoney; break;

      }
      return entity;
    }}) processor: DPCAudioTagProcessorNumber | DPCAudioTagProcessorMoney;

  @prop() @digit()
  directory: number = 0;
  @required()
  directory_lang: string = 'ru';
}
