import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject, SubscriptionLike, Observer, interval} from 'rxjs';
import {share, distinctUntilChanged, takeWhile, filter, startWith} from 'rxjs/operators';
import {getUUID4} from './base.function';
import {NotifyService} from './notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {JsonAppConfigService} from '../config/json-app-config.service';
import {WebSocketSubject, WebSocketSubjectConfig} from 'rxjs/webSocket';
import {AuthenticationService} from "../auth/authentication.service";

@Injectable()
export class SocketService implements OnDestroy{
  /*
  0 – авторизация,
  1 – авторизован и готов принимать сообщения, в т.ч. subscribe,
  2 – соединение закрывается,
  3 – соединение закрыто.
  */
  public readyState: number = 0;
  protected isConnected: boolean;
  public message_actions = {};

  public config: WebSocketSubjectConfig<any>;
  private statusSub: SubscriptionLike;
  public reconnection$: Observable<number>;
  public websocket$: WebSocketSubject<any>;
  private connection$: Observer<boolean>;
  protected wsMessages$: Subject<any>;
  public status: Observable<boolean>;

  public reconnectionTimer = null; // ID таймера на переподключение
  protected reconnectionInterval: number = 60000; // Пауза между переподключениями
  private attemptInterval: number = 10000; // Пауза между попытками переподключения
  private attemptCnt: number = 4; // Количество попыток подключения
  public reconnectionTimeTimer = null; // ID таймера отсчета секунд до переподключения (для отображения, что нет соединения с сокетом)
  public secBeforeReconnect; // Время в секундах до переподключения (для отображения, что нет соединения с сокетом)
  public pingTimeout; // ID таймера на проверку пинга (для проверки, что сокет жив)
  public pingInterval = 90000; // Интервал проверки пинга (для проверки, что сокет жив)
  public pingOut = false;

  constructor(public channels: any[] = [], public http: HttpClient, public notifyService: NotifyService,
              public router: Router, public route: ActivatedRoute, public authenticationService: AuthenticationService,
              public translate: TranslateService, public AppConfig: JsonAppConfigService) {
    this.secBeforeReconnect = this.reconnectionInterval / 1000;
    this.wsMessages$ = new Subject<any>();
    this.config = {
      url: this.AppConfig.getValue('wsUrl'),
      openObserver: {
        next: (event: Event) => {
          console.log('%cсокет создан', 'color: darkorange; font-size: 14px;', new Date());
          this.connection$.next(true);
        }
      },
      closeObserver: {
        next: () => {
          this.readyState = 3; // соединение закрыто
          this.websocket$ = null;
          console.log('%cсокет закрыт', 'color: darkorange; font-size: 14px;', new Date());
          this.connection$.next(false);
        }
      },
      serializer: (data: any) => {
        if (!data.action_id) data.action_id = getUUID4(4);

        // кладу в метаданные по action_id данные, отправляемые в сокет
        // метаданные хранят в себе сам запрос (sent) и кнопки (btn):
        //  - sent - отправленные данные;
        //  - btn - кнопка, которую нужно будет вернуть в исходное состояние (например, чтобы перестала мигать или крутиться).
        if (data.action_id in this.message_actions) this.message_actions[data.action_id]['sent'] = data;
        else this.message_actions[data.action_id] = {sent: data};

        return JSON.stringify(data);
      },
      deserializer: (data) => {
        let msg = JSON.parse(data.data);
        // прикрепляю в полученное сообщение отправленные данные, которые хранятся в метаданных по action_id
        msg['metadata'] = this.message_actions[msg['action_id']];
        // удаляю сообщение из метаданных т.к. оно больше не нужно
        delete this.message_actions[msg['action_id']];
        return msg;
      }
    };

    // connection status
    this.status = new Observable<boolean>((observer) => {
      this.connection$ = observer;
    }).pipe(share(), distinctUntilChanged());

    // run reconnect if not connection
    this.statusSub = this.status.subscribe(
      (isConnected) => {
        this.isConnected = isConnected;

        if (!this.reconnection$ && typeof(isConnected) === 'boolean' && !isConnected) {
          console.log('%cпереподключение по статусу', 'color: darkorange; font-size: 14px;', new Date());
          this.reconnect();
        }
      }
    );

  }

  ngOnDestroy() {
    this.disconnect();
  }

  setConfig(config) {
    this.config = config;
    console.log('CONFIG WS::')
    console.log(this.config)
  }

  updateConfigUrl(wss) {
    this.config['url'] = wss
    this.setConfig(this.config)
  }

  protected subscribe_on_connect(msg) { }

  protected subscribe_on_reconnect() { }

  public connect(): void {
    this.readyState = 0;
    this.pingOut = false;
    if (this.pingTimeout) clearTimeout(this.pingTimeout);
    // создаю сокет
    console.log('%cсоздаю сокет', 'color: darkorange; font-size: 14px;', new Date());
    this.websocket$ = new WebSocketSubject(this.config);

    this.websocket$.subscribe(
      (msg) => {
        this.subscribe_on_connect(msg);
      },
      (error: Event) => {
        if (!(this.websocket$ || this.reconnection$)) {
          console.log('Reconnect');
          console.log('%cпереподключение по ошибке:' + error, 'color: darkorange; font-size: 14px;', new Date());
          this.reconnect();
        }
      });
  }

  public disconnect() {
    this.clearReconnectTimers();
    this.statusSub.unsubscribe();
    this.readyState = 2;
    // отписались от событий. теперь сокет можно закрывать
    if (this.websocket$) this.websocket$.complete();
    else this.readyState = 3;
  }

  public clearReconnectTimers(): void {
    if (this.reconnectionTimer) {
      console.log('%cудаление таймера на переподключение сокета', 'background-color: orange;');
      clearTimeout(this.reconnectionTimer);
      this.reconnectionTimer = null;
    }
    if (this.reconnectionTimeTimer) {
      console.log('%cудаление таймера отсчета секунд до переподключения сокета', 'background-color: orange;');
      clearInterval(this.reconnectionTimeTimer);
      this.reconnectionTimeTimer = null;
    }
    this.secBeforeReconnect = this.reconnectionInterval / 1000;
  }

  public reconnect(): void {
    console.log('%cпереподключение', 'background-color:orange;', new Date());
    this.reconnection$ = interval(this.attemptInterval).pipe(
      startWith(0),
      takeWhile((v, index) => {
        console.log('%cпопытка переподключения №'+index, 'background-color:orange;', new Date());
        console.log('%cthis.websocket$ ' + this.websocket$ + ' this.readyState:' + this.readyState + ' this.pingOut: '+this.pingOut, 'background-color:orange;');
        return index < this.attemptCnt && ((!this.websocket$ && this.readyState == 3) || this.pingOut);
      })
    );

    this.reconnection$.subscribe(
      () => {
        return this.connect()
      },
      null,
      () => {
        // Subject complete if reconnect attemts ending
        console.log('%cудаляю переподключение', 'color: darkorange; font-size: 14px;', new Date());
        this.reconnection$ = null;

        // пытаюсь переподключить сокет или установить таймер, когда не работает сервер, а не пользователь не зарегистрирован
        this.subscribe_on_reconnect()
      });
  }

  /*
  * on message event
  * */
  public on<T>(channel: string = ''): Observable<T> {
    return channel ? this.wsMessages$.pipe(filter((msg: any) => msg['channel'] == channel)) : this.wsMessages$;
  }

  async sendMessage(data: any = {}) {
    if (this.isConnected) await this.websocket$.next(data);
    else this.notifyService.message('ERROR.WS.NO_CONNECTION');
  }

}
